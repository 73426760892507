import { ValidatorFn } from "@angular/forms";
import { Duration } from "luxon";
import { parseNumber } from "src/utils/converters/core-converters";
import { isInstanceOf } from "src/utils/miscellaneous";
import { makeValidatorOptional } from "src/utils/validators";

export class TimeOfDay {
  public constructor({ hour, minute }: ClassProperties<TimeOfDay>) {
    const extraHours = Math.floor(minute / 60);
    const remainingMinutes = minute % 60;
    // Wrap values around, including negatives, to stay within the correct domain.
    this.hour = (24 + hour + extraHours) % 24;
    this.minute = (60 + remainingMinutes) % 60;
  }

  public static readonly midnight = new TimeOfDay({ hour: 0, minute: 0 });

  public readonly hour: number;
  public readonly minute: number;

  public static deserialize(value: string): TimeOfDay {
    const [hour, minute] = value.split(":").map(parseNumber);
    if (hour === null || minute === null) {
      throw new Error(`Cannot parse value as TimeOfDay: ${value}`);
    }
    return new TimeOfDay({ hour, minute });
  }

  /**
   * Compares two times (conforms to `Array.prototype.sort`'s compare function).
   * @param first The first half of the comparison.
   * @param second The second half of the comparison.
   * @returns `0` if the two values represent the same time of day, a negative
   * number if `first` is earlier than `second`, and a positive number
   * otherwise.
   */
  public static compare(first: TimeOfDay, second: TimeOfDay): number {
    const firstMinutesFromMidnight = first.hour * 60 + first.minute;
    const secondMinutesFromMidnight = second.hour * 60 + second.minute;
    return firstMinutesFromMidnight - secondMinutesFromMidnight;
  }

  public isAfter(other: TimeOfDay): boolean {
    return TimeOfDay.compare(this, other) > 0;
  }

  public isBefore(other: TimeOfDay): boolean {
    return TimeOfDay.compare(this, other) < 0;
  }

  public isAfterOrSame(other: TimeOfDay): boolean {
    return TimeOfDay.compare(this, other) >= 0;
  }

  public isSame(time: TimeOfDay): boolean {
    return TimeOfDay.compare(this, time) === 0;
  }

  public addDuration(duration: Duration): TimeOfDay {
    return new TimeOfDay({
      hour: this.hour,
      minute: this.minute + duration.as("minutes"),
    });
  }

  public getDurationFromMidnight(): Duration {
    return Duration.fromObject(this);
  }

  public getDurationUntil(other: TimeOfDay): Duration {
    return other
      .getDurationFromMidnight()
      .minus(this.getDurationFromMidnight());
  }

  public serialize(): string {
    const hour = this.hour.toFixed().padStart(2, "0");
    const minute = this.minute.toFixed().padStart(2, "0");
    return `${hour}:${minute}`;
  }
}

type EarliestTimeOfDayError = [
  "earliestTimeOfDay",
  { earliest: TimeOfDay; actual: TimeOfDay; exact: boolean },
];
export function earliestTimeOfDayValidator(
  earliest: TimeOfDay,
  { isMidnightInNextDay = false } = {},
): ValidatorFn {
  return makeValidatorOptional<EarliestTimeOfDayError>(
    isInstanceOf(TimeOfDay),
    (actual) => {
      if (isMidnightInNextDay) {
        // When midnight is considered part of the next day...
        if (actual.isSame(TimeOfDay.midnight)) {
          // it's the last possible valid time so it's always within any range.
          return null;
        } else if (earliest.isSame(TimeOfDay.midnight)) {
          // However, when the earliest time is midnight, the only possible valid
          // value is midnight, so flag it as needing to be exact.
          return { earliestTimeOfDay: { earliest, actual, exact: true } };
        }
        // Otherwise, we can compare as normal since neither the value nor the
        // earliest time are midnight, or midnight is still in the current day.
      }

      if (actual.isAfterOrSame(earliest)) {
        return null;
      } else {
        return { earliestTimeOfDay: { earliest, actual, exact: false } };
      }
    },
  );
}
