import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AlertsService } from "src/app/core/alerts.service";
import { ApiError } from "src/app/core/api-error";
import { Permission, UserService } from "src/app/core/auth";
import { Modal } from "src/app/core/modal.service";
import { SitesService } from "src/app/core/sites";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import { parseNumber } from "src/utils";
import { HelpAssistService } from "../help-assist.service";
import { HelpAssistHasScheduledTicketForRequest } from "../models/help-assist-has-schedule-ticket-for-request.model";
import { HelpAssistTicketAppointmentDraftUpdate } from "../models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import {
  getHelpAssistTicketRouteUrl,
  HelpAssistTicketReference,
} from "../models/ticket/base-help-assist-ticket.model";

type ViewType = "mainView" | "generalTicketView" | "appointmentTicketView";

export interface TicketModalContext {
  appointment?: HelpAssistTicketAppointmentDraftUpdate;
  appointmentId?: AppointmentReference["id"];
  areGeneralTicketsEnabled?: boolean;
  areSchedulingTicketsEnabled?: boolean;
  isAppointmentCreation?: boolean;
  orderSearchTerms?: readonly string[];
  view?: ViewType;
}

@Component({
  selector: "mr-help-assist-modal[display]",
  templateUrl: "./help-assist-modal.component.html",
  styleUrls: ["./help-assist-modal.component.scss"],
  providers: [Modal],
})
export class HelpAssistModalComponent {
  public constructor(
    public readonly modal: Modal<
      TicketModalContext,
      "createdGeneralTicket" | "createdAppointmentTicket" | "cancelled"
    >,
    private readonly router: Router,
    private readonly user: UserService,
    private readonly alerts: AlertsService,
    private readonly helpAssist: HelpAssistService,
    private readonly sites: SitesService,
  ) {}

  public readonly modalView: {
    AppointmentView: ViewType;
    GeneralView: ViewType;
    MainView: ViewType;
  } = {
    AppointmentView: "appointmentTicketView",
    GeneralView: "generalTicketView",
    MainView: "mainView",
  };

  public currentView: ViewType =
    this.modal.context.view || this.modalView.GeneralView;

  public readonly appointment = this.modal.context.appointment ?? null;

  public readonly appointmentId = this.modal.context.appointmentId ?? null;

  public readonly isAppointmentCreation =
    this.modal.context.isAppointmentCreation ?? false;

  public readonly orderSearchTerms = this.modal.context.orderSearchTerms;

  public readonly areSchedulingTicketsEnabled =
    this.modal.context.areSchedulingTicketsEnabled ?? false;

  public readonly areGeneralTicketsEnabled =
    this.modal.context.areGeneralTicketsEnabled ?? false;

  // TODO: Can this be consolidated into just ViewHelpDeskTicketDetails?
  // Presumably, the "admin" permission here is for extended information, so
  // it should always extend the base details permission.
  private readonly hasTicketViewPermission =
    this.user.permissions.has(Permission.ViewHelpDeskAdminDetails) ||
    this.user.permissions.has(Permission.ViewHelpDeskTicketDetails);

  public readonly hasOrders =
    this.appointment !== null &&
    this.appointment.orders !== null &&
    this.appointment.orders.length > 0;

  public async openTicket(type: ViewType): Promise<void> {
    try {
      if (type !== this.modalView.GeneralView) {
        const site = await firstValueFrom(this.sites.selectedChanges);
        const request = new HelpAssistHasScheduledTicketForRequest({
          appointmentDraft: this.appointment,
          appointmentId: this.appointmentId,
          site,
        });

        const ticket = await this.helpAssist.hasScheduledTicketFor(request);
        if (ticket) {
          this.alerts.warn({
            title: "An open ticket already exists",
            message: "Can not create a new ticket while an open ticket exists.",
          });

          this.modal.close();

          await this.router.navigateByUrl(getHelpAssistTicketRouteUrl(ticket));
        } else {
          this.currentView = type;
        }
      } else {
        this.currentView = type;
      }
    } catch (error) {
      await this.handleSubmitError(error);
    }
  }

  private async navigateToDetailsPage(
    ticket: HelpAssistTicketReference,
  ): Promise<void> {
    if (!this.hasTicketViewPermission) {
      return;
    }

    await this.router.navigateByUrl(getHelpAssistTicketRouteUrl(ticket));
  }

  public async close(): Promise<void> {
    await this.modal.choose("cancelled");
  }

  public async handleAppointmentSubmitSuccess(
    ticket: HelpAssistTicketReference,
  ): Promise<void> {
    this.alerts.success({ title: "Saved Successfully!" });
    await this.modal.choose("createdAppointmentTicket");
    await this.navigateToDetailsPage(ticket);
  }

  public async handleGenericSubmitSuccess(
    ticket: HelpAssistTicketReference,
  ): Promise<void> {
    this.alerts.success({ title: "Saved Successfully!" });
    await this.modal.choose("createdGeneralTicket");
    await this.navigateToDetailsPage(ticket);
  }

  public async handleSubmitError(error: unknown): Promise<void> {
    if (
      error instanceof ApiError &&
      error.apiCode === "OPEN_TICKET_EXISTS" &&
      error.apiTarget
    ) {
      this.alerts.warn({
        title: "An active Help Assist Ticket already exists.",
        message: "You will be redirected to the existing Help Assist ticket.",
      });

      this.modal.close();

      const preExistingTicketId = parseNumber(error.apiTarget);
      if (preExistingTicketId === null) {
        throw new Error(
          `Could not parse API target as number: ${error.apiTarget}`,
        );
      }
      const site = await firstValueFrom(this.sites.selectedChanges);
      await this.navigateToDetailsPage({ id: preExistingTicketId, site });
    } else {
      this.alerts.error({
        title: "Failed to Save",
        message: "Your ticket was not created. Please try again.",
        error,
      });
    }
  }
}
