import { Component, Input } from "@angular/core";

@Component({
  selector: "mr-go-back-link[link]",
  template: `
    <a [routerLink]="path" [queryParams]="queryParams">
      <mr-icon name="chevron" aria-hidden="true"></mr-icon>
      <ng-content></ng-content>
    </a>
  `,
  styleUrls: ["go-back-link.component.scss"],
})
export class GoBackLinkComponent {
  @Input() public set link(value: string) {
    const url = new URL(value, window.location.origin);
    this.path = value.split("?")[0];
    this.queryParams = Object.fromEntries(url.searchParams.entries());
  }

  public path!: string;
  public queryParams!: Readonly<Record<string, string | undefined>>;
}
