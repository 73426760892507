import * as t from "io-ts";
import { TableColumnFilterType } from "src/app/core/table/table-column-filter.model";
import {
  dayFromStringCodec,
  durationFromStringCodec,
  maybe,
  SortOrder,
  timeOfDayFromStringCodec,
  uuidStringCodec,
  weekdaysFromStringCodec,
} from "src/utils";

const columnFilterCodec = t.union(
  [
    t.strict({
      type: t.literal(TableColumnFilterType.Boolean),
      value: t.boolean,
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.DateRange),
      value: t.type({
        start: dayFromStringCodec,
        end: dayFromStringCodec,
      }),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.DurationRange),
      value: t.type({
        min: t.union([durationFromStringCodec, t.null]),
        max: t.union([durationFromStringCodec, t.null]),
      }),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.DayRange),
      value: t.type({
        start: dayFromStringCodec,
        end: dayFromStringCodec,
      }),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.Days),
      value: weekdaysFromStringCodec,
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.Dropdown),
      value: t.union([t.number, uuidStringCodec, t.string, t.null]),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.MultiSelect),
      value: t.readonlyArray(
        t.union([t.number, uuidStringCodec, t.string, t.null]),
      ),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.Number),
      value: t.number,
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.NumberIdentifier),
      value: t.string,
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.NumberRange),
      value: t.type({
        min: t.union([t.number, t.null]),
        max: t.union([t.number, t.null]),
      }),
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.Text),
      value: t.string,
    }),
    t.strict({
      type: t.literal(TableColumnFilterType.Time),
      value: timeOfDayFromStringCodec,
    }),
  ],
  "SettingsTableColumnFilter",
);

export const settingsTableColumnCodec = t.intersection(
  [t.type({ key: t.string }), t.partial({ filter: maybe(columnFilterCodec) })],
  "SettingsTableColumn",
);
type Column = t.TypeOf<typeof settingsTableColumnCodec>;

export const settingsTableColumnsByKeyCodec = t.record(
  t.string,
  maybe(t.readonlyArray(maybe(settingsTableColumnCodec))),
  "SettingsTableColumnsByKey",
);
export type ColumnsByKey = t.TypeOf<typeof settingsTableColumnsByKeyCodec>;

// Interfaces are displayed in dev tooling rather than the more complex type
// definition which is easier to read in general.
export interface SettingsTableColumn extends Column {}

const tableSortCodec = t.strict({
  key: t.string,
  order: t.union([
    t.literal(SortOrder.Ascending),
    t.literal(SortOrder.Descending),
  ]),
});

export const settingsTableSortCodec = t.record(
  t.string,
  maybe(tableSortCodec),
  "SettingsTableSort",
);
type Sort = t.TypeOf<typeof settingsTableSortCodec>;

// Interfaces are displayed in dev tooling rather than the more complex type
// definition which is easier to read in general.
export interface SettingsTableSort extends Sort {}
