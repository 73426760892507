import { Directive, HostListener } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { isNil, mapValues, omitBy, pickBy } from "lodash-es";
import { BehaviorSubject, defer, merge, Observable, ReplaySubject } from "rxjs";
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import {
  getFlaggedRunner,
  isInstanceOf,
  throwUnhandledCaseError,
} from "src/utils";
import { AlertsService } from "./alerts.service";

type FormControlConfig<T> = [
  T | { value: T; disabled: boolean },
  (ValidatorFn | ValidatorFn[])?,
  (AsyncValidatorFn | AsyncValidatorFn[])?,
];

type FormControlType<T> = NonNullable<T> extends readonly unknown[]
  ? FormArray
  : FormGroup;

type FormValue<T> = { [P in keyof T]: FormControlType<T[P]> | T[P] };

export type FormGroupConfig<T> = {
  [P in keyof T]: FormControlType<T[P]> | FormControlConfig<T[P]>;
};

@Directive()
export abstract class FormComponent<T extends object> {
  public constructor(
    formBuilder: FormBuilder,
    formControlsConfig: FormGroupConfig<NullableProperties<T>>,
  ) {
    this.form = formBuilder.group(formControlsConfig);
    this.defaultValue = this.form.value as NullableProperties<T>;
    this.initialValue = this.form.value as NullableProperties<T>;

    this.controlsSubject.next(this.form.controls);
  }

  public readonly form: FormGroup;
  /** The default value in the initial form controls configuration. */
  private readonly defaultValue: NullableProperties<T>;
  /** The original value from after the form finished loading. */
  private initialValue: NullableProperties<T>;
  /**
   * Whether or not to display the native browser prompt "Changes you made may
   * not be saved" when this form is dirty.
   */
  protected isDirtyFormBrowserPromptEnabled = true;

  private readonly controlsSubject = new ReplaySubject<{
    [key: string]: AbstractControl | undefined;
  }>(1);

  private readonly runWithIsLoadingFlag = getFlaggedRunner();
  public get isLoading(): boolean {
    return this.runWithIsLoadingFlag.isRunning;
  }

  public get canSubmit(): boolean {
    return this.form.valid && this.form.enabled && this.form.dirty;
  }

  public get canSubmitClean(): boolean {
    return this.form.valid && this.form.enabled;
  }

  public get isSubmitting(): boolean {
    return this.#isSubmittingChanges.getValue();
  }
  readonly #isSubmittingChanges = new BehaviorSubject(false);
  public readonly isSubmittingChanges =
    this.#isSubmittingChanges.asObservable();

  // Native browser "Changes you made may not be saved" prompt for dirty forms.
  @HostListener("window:beforeunload", ["$event"])
  public unloadHandler(event: BeforeUnloadEvent): boolean {
    if (this.isDirtyFormBrowserPromptEnabled && this.form.dirty) {
      return (event.returnValue = false);
    }
    return true;
  }

  // TODO: might want to rename this to something better like `loadInitialValue`.
  public runWithIsLoading(load: () => Promise<void> | void): void {
    // This is essentially always run within the `ngOnInit` hook which has no
    // way to handle errors without manually handling them individually in every
    // form, so unless and until we add error handling to all forms, it's easier
    // to just disable this here and not return a promise.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.runWithIsLoadingFlag(async () => {
      await load();
      // Capture the final value after the async load for resetting the form.
      this.initialValue = this.form.value as NullableProperties<T>;
    });
  }

  private async baseRunSubmit<Result = void>(
    submit: (value: T) => Promise<Result> | Result,
  ): Promise<
    | { code: "invalid-fields" }
    | { code: "submit-failed"; error: unknown }
    | { code: "success"; value: Result }
  > {
    if (this.form.invalid) {
      return { code: "invalid-fields" };
    }

    try {
      this.#isSubmittingChanges.next(true);
      this.form.disable();

      // We can probably safely assert that all the properties not explicitly
      // marked nullable/optional have been set if the form is valid.
      const value = this.getValue() as T;

      const result = await submit(value);

      this.form.markAsPristine();
      this.form.markAsUntouched();

      return { code: "success", value: result };
    } catch (error) {
      return { code: "submit-failed", error };
    } finally {
      this.form.enable();
      this.#isSubmittingChanges.next(false);
    }
  }

  /**
   * Validate the form values and prepare the form fields (e.g. disabling them)
   * for submission and then run the provided submission logic.
   *
   * Note: this throws on any found errors or validation issues.
   *
   * @param submit - The actual submission action to run.
   */
  public async runSubmit<Result = void>(
    submit: (value: T) => Promise<Result> | Result,
  ): Promise<{ value: Result }> {
    const result = await this.baseRunSubmit(submit);

    switch (result.code) {
      case "invalid-fields":
        throw new Error("Cannot submit invalid form.");
      case "submit-failed":
        throw result.error;
      case "success":
        return result;
      default:
        throwUnhandledCaseError("form submit result", result);
    }
  }

  /**
   * Validate the form values, prepare the form fields (e.g. disabling them) for
   * submission, and then run the provided submission logic. Alerts the user to
   * errors or success status of the submission.
   *
   * @param submit - The actual submission action to run.
   * @param config - Additional parameters for handling alerts.
   */
  public async runSubmitWithAlerts<Result = void>(
    submit: (value: T) => Promise<Result> | Result,
    {
      alerts,
      handleErrors,
    }: {
      /** The alerts service to use to present status to the user. */
      alerts: AlertsService;
      /**
       * A callback for custom error handling. (Returns `true` when the error is
       * successfully handled.)
       */
      handleErrors?(error: unknown): boolean;
    },
  ): Promise<{ value: Result } | null> {
    const result = await this.baseRunSubmit(submit);

    switch (result.code) {
      case "invalid-fields": {
        alerts.error({
          title: "Failed to Save",
          message:
            "Some fields have errors. Please fix the errors and try again.",
        });
        return null;
      }
      case "submit-failed": {
        const isHandled = handleErrors?.(result.error) ?? false;
        if (!isHandled) {
          alerts.error({
            title: "Failed to Save",
            message: "Your changes were not saved. Please try again.",
            error: result.error,
          });
        }
        return null;
      }
      case "success": {
        alerts.success({ title: "Saved Successfully!" });
        return result;
      }
      default:
        throwUnhandledCaseError("form submit result", result);
    }
  }

  protected getControl<K extends StringKeys<T>>(name: K): AbstractControl {
    const control = this.form.get(name);
    if (!control) {
      throw new Error(`Could not find form control "${name}".`);
    }
    return control;
  }

  protected getControlChanges<K extends StringKeys<T>>(
    name: K,
  ): Observable<AbstractControl> {
    return this.controlsSubject.pipe(
      map((controls) => {
        const control = controls[name];
        if (!control) {
          throw new Error(`Could not find form control "${name}".`);
        }
        return control;
      }),
      distinctUntilChanged(),
    );
  }

  public isValidChanges<K extends StringKeys<T>>(
    name?: K,
  ): Observable<boolean> {
    const statusChanges = name
      ? this.getControlChanges(name).pipe(
          switchMap((control) => control.statusChanges),
          startWith(this.getControl(name).status),
        )
      : this.form.statusChanges.pipe(startWith(this.form.status));
    return statusChanges.pipe(
      map((status) => status === "VALID"),
      distinctUntilChanged(),
    );
  }

  public isValid<K extends StringKeys<T>>(name?: K): boolean {
    return name ? this.getControl(name).valid : this.form.valid;
  }

  public setAndUpdateValidators<K extends StringKeys<T>>(
    name: K,
    validators: ValidatorFn | ValidatorFn[] | null,
  ): void {
    this.reconfigure(name, { validators });
  }

  public reconfigure<K extends StringKeys<T>>(
    name: K,
    {
      asyncValidators,
      isDirty,
      isDisabled,
      isTouched,
      validators,
      value,
    }: {
      asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null;
      isDirty?: boolean;
      isDisabled?: boolean;
      isTouched?: boolean;
      validators?: ValidatorFn | ValidatorFn[] | null;
      value?: T[K] | FormControlType<T[K]> | null;
    } = {},
  ): void {
    const control = this.getControl(name);

    if (isDirty !== undefined) {
      if (isDirty) {
        control.markAsDirty();
      } else {
        control.markAsPristine();
      }
    }

    if (isDisabled !== undefined) {
      if (isDisabled) {
        control.disable();
      } else {
        control.enable();
      }
    }

    if (isTouched !== undefined) {
      if (isTouched) {
        control.markAsTouched();
      } else {
        control.markAsUntouched();
      }
    }

    if (value !== undefined) {
      this.setValue(name, value);
    }
    if (validators !== undefined) {
      control.setValidators(validators);
    }
    if (asyncValidators !== undefined) {
      control.setAsyncValidators(asyncValidators);
    }
    if (value !== undefined || validators !== undefined) {
      control.updateValueAndValidity();
    }
  }

  public getValueChanges(): Observable<NullableProperties<T>>;
  public getValueChanges<K extends StringKeys<T>>(
    name: K,
  ): Observable<T[K] | null>;
  public getValueChanges<K extends StringKeys<T>>(
    name?: K,
  ): Observable<NullableProperties<T> | T[K] | null>;
  public getValueChanges<K extends StringKeys<T>>(
    name?: K,
  ): Observable<NullableProperties<T> | T[K] | null> {
    if (name) {
      return this.getControlChanges(name).pipe(
        switchMap((control) =>
          control.valueChanges.pipe(
            map((value) => parseControlValue<NullableProperties<T>[K]>(value)),
            // Emit an initial value whenever the control changes since
            // `valueChanges` doesn't. Must be inside the `switchMap` in order
            // to respond to control changes.
            startWith(this.getValue(name)),
          ),
        ),
        distinctUntilChanged(),
      );
    } else {
      return merge(
        this.form.valueChanges.pipe(
          // Disabled controls won't have their values included in
          // `valueChanges` but we still want to include them. Merging with
          // `getValue()` will ensure that we include all those fields as well
          // since it checks the value of each control individually.
          map((value: NullableProperties<T>[K]) => ({
            ...this.getValue(),
            ...value,
          })),
        ),
        // Get the initial value on first subscription so we always have
        // something to replay. `startWith` doesn't work because creation of
        // the observable could happen well before subscription, and the value
        // would be stale.
        defer(() => [this.getValue(name)]),
      ).pipe(distinctUntilChanged(isShallowEqual));
    }
  }

  public getValidatedValueChanges(): Observable<T | null>;
  public getValidatedValueChanges<K extends StringKeys<T>>(
    name: K,
  ): Observable<T[K] | null>;
  public getValidatedValueChanges<K extends StringKeys<T>>(
    name?: K,
  ): Observable<T | T[K] | null> {
    return this.getValueChanges(name).pipe(
      switchMap((value) =>
        this.isValidChanges(name).pipe(
          // Assuming the validators were set up correctly, we can assume that all
          // required values in T are set so we can safely cast NullableProperties<T> to T.
          map((isValid) => (isValid ? (value as T | T[K] | null) : null)),
        ),
      ),
    );
  }

  public getValue(): NullableProperties<T>;
  public getValue<K extends StringKeys<T>>(name: K): T[K] | null;
  public getValue<K extends StringKeys<T>>(
    name?: K,
  ): NullableProperties<T> | T[K] | null;
  public getValue<K extends StringKeys<T>>(
    name?: K,
  ): NullableProperties<T> | T[K] | null {
    return name
      ? getControlValue(this.getControl(name))
      : (mapValues(this.form.controls, getControlValue) as T | null);
  }

  public setValue(value: NullableProperties<FormValue<T>>): void;
  public setValue<K extends StringKeys<T>>(
    name: K,
    value: T[K] | FormControlType<T[K]> | null,
  ): void;
  public setValue<K extends StringKeys<T>>(
    nameOrValue: K | NullableProperties<T>,
    maybeValue?: T[K] | FormControlType<T[K]> | null,
  ): void {
    if (typeof nameOrValue === "string") {
      const name = nameOrValue;
      const value = maybeValue;
      if (value === undefined) {
        throw new Error("Missing value.");
      }

      const existingControl = this.getControl(name);
      if (isFormArray(value)) {
        if (existingControl.value !== null && !isFormArray(existingControl)) {
          throw new Error(
            `Cannot set a form array control to "${name}" as it is not a form array.`,
          );
        }
        this.form.setControl(name, value);
      } else {
        existingControl.setValue(value);
      }
    } else {
      const formValue = nameOrValue;

      const arrayValues = pickBy(formValue, isFormArray);
      for (const [arrayName, arrayValue] of Object.entries(arrayValues)) {
        this.form.setControl(arrayName, arrayValue);
      }

      const literalValues = omitBy(formValue, isFormArray);
      this.form.patchValue(literalValues);
    }

    // Update with the latest controls in case any were updated above.
    this.controlsSubject.next(this.form.controls);
    this.form.updateValueAndValidity();
  }

  /**
   * Resets the form back to the default value in the initial form controls
   * configuration.
   */
  public resetToDefaultValue(): void {
    this.resetToValue(this.defaultValue);
  }

  /**
   * Resets the form back to the original value from after the form finished
   * loading.
   */
  public resetToInitialValue(): void {
    this.resetToValue(this.initialValue);
  }

  private resetToValue(value: NullableProperties<T>): void {
    this.form.reset(value);
    // Odd fix: Marking this pristine fixes bug 15314 even though `this.form.reset`
    // supposedly resets all descendants back to pristine & untouched as well.
    this.form.markAsPristine();
  }
}

const isFormArray = isInstanceOf(FormArray);

function getControlValue<T = unknown>(control: AbstractControl): T | null {
  return parseControlValue(control.value);
}

function parseControlValue<T>(value: unknown): T | null {
  if (value instanceof FormArray) {
    // FormArray types are only allowed for array values, so converting
    // to `T` type from `any[]` should be valid.
    return value.controls.map(getControlValue) as unknown as T;
  } else {
    // No real way to ensure the type, but if used correctly, the values should
    // be enforced at construction time. If they're wrong, it's probably because
    // of bad template bindings with controls that return the wrong type.
    return value as T;
  }
}

function isShallowEqual<T>(a: T, b: T): boolean {
  if (isNil(a) || isNil(b)) {
    return a === b;
  }

  const aKeys = new Set(Object.keys(a));
  const bKeys = Object.keys(b);

  // Make sure all of `b`s keys are in `a`.
  if (aKeys.size !== bKeys.length || bKeys.some((key) => !aKeys.has(key))) {
    return false;
  }

  // Make sure all of `a`s keys are in `b` and that each value matches.
  for (const key of aKeys) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}
