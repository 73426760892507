import { AfterViewChecked, Directive, ElementRef, Input } from "@angular/core";
import { range } from "lodash-es";

const headerSelector = ":scope > table > thead > tr > th";
const dataSelector = ":scope > table > tbody > tr > td";
const stickyCellClass = "js-cell-sticky";
const lastStickyCellClass = "js-cell-sticky--last";

@Directive({
  selector: "[mrTableStickyColumns]",
})
export class TableStickyColumnsDirective implements AfterViewChecked {
  public constructor(private readonly element: ElementRef<HTMLElement>) {}

  @Input() public stickyColumnCount!: number;

  public ngAfterViewChecked(): void {
    this.clearExistingStyles();

    const columns = range(1, this.stickyColumnCount + 1).map((column) =>
      this.element.nativeElement.querySelectorAll(
        `${headerSelector}:nth-child(${column}), ${dataSelector}:nth-child(${column})`,
      ),
    );

    let currentColumnOffset = 0;
    for (const [columnIndex, column] of columns.entries()) {
      let nextColumnOffsetIncrement: number | undefined;
      for (const cell of column) {
        if (!(cell instanceof HTMLElement)) {
          throw new Error("Unexpected element type.");
        }

        cell.style.left = `${currentColumnOffset}px`;
        cell.classList.add(stickyCellClass);
        if (columnIndex === columns.length - 1) {
          cell.classList.add(lastStickyCellClass);
        }

        // Offset should be the same for every cell in the column, so just use
        // the first one that we see.
        nextColumnOffsetIncrement ??= cell.offsetWidth;
      }
      currentColumnOffset += nextColumnOffsetIncrement ?? 0;
    }
  }

  private clearExistingStyles(): void {
    const allCells = this.element.nativeElement.querySelectorAll(
      `${headerSelector}, ${dataSelector}`,
    );
    for (const cell of allCells) {
      if (!(cell instanceof HTMLElement)) {
        throw new Error("Unexpected element type.");
      }
      cell.style.left = "";
      cell.classList.remove(stickyCellClass, lastStickyCellClass);
    }
  }
}
