<mr-field-container
  #container
  [customFieldId]="fieldId"
  [label]="label"
  [description]="description"
  [labelHidden]="isLabelHidden"
  [errorsHidden]="areErrorsHidden"
  [kind]="kind"
  [additionalErrorMessageTemplate]="additionalErrorMessageTemplate"
>
  <div class="field">
    <ng-content select="mr-icon"></ng-content>
    <input
      #input
      [type]="type"
      [id]="container.fieldId"
      [placeholder]="placeholder"
      [attr.title]="isLabelHidden ? label : null"
      [attr.aria-label]="isLabelHidden ? label : null"
      [attr.aria-describedby]="container.descriptionId"
      [disabled]="isDisabled"
      [readOnly]="isReadonly"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
      (change)="onBlurChange(input.value); $event.stopPropagation()"
      (blur)="onBlur()"
      (keyup.enter)="onBlur()"
      [attr.min]="min"
      [attr.max]="max"
      [attr.step]="step"
      [attr.minlength]="minlength"
      [attr.maxlength]="maxlength"
      [ngClass]="{
        highlighted: canHighlight && value !== originalValue
      }"
    />
    <span *ngIf="units" class="units">{{ units }}</span>
    <span *ngIf="showAsterisk" class="asterisk">*</span>
  </div>
  <div *ngIf="canHighlight && value !== originalValue" class="original-value">
    {{ originalValue }}
  </div>
</mr-field-container>
