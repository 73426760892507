import { DateTime } from "luxon";
import { getSiteRouteUrl, Site, SiteModelReference } from "src/app/core/sites";
import { EquipmentType } from "src/app/partner/global/equipment-type.model";
import {
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  isExistent,
  ODataModel,
  parseDateTime,
  PartnerResourceModel,
} from "src/utils";
import { Dock } from "../docks/dock.model";
import { DoorGroup } from "../door-groups/door-group.model";
import { BaseEquipment, EquipmentLocationType } from "./base-equipment.model";

export const equipmentResource = createPartnerResourceUrl("equipment");
export const expandedEquipmentResource = equipmentResource.modify((resource) =>
  resource.addExpandField("docks").addExpandField("doorGroups"),
);

type ApiModelList = PartnerResourceModel<typeof expandedEquipmentResource>;
export interface ApiExpandedEquipment extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiExpandedEquipment>();

interface SimpleDeserializeArguments {
  readonly site: Site;
  readonly types: readonly EquipmentType[];
}
interface DeserializeArguments extends SimpleDeserializeArguments {
  readonly docks: readonly Dock[];
  readonly doorGroups: readonly DoorGroup[];
}

export class Equipment
  extends BaseEquipment
  implements EquipmentReference, SimpleEquipment
{
  private constructor(args: ClassProperties<Equipment>) {
    super(args);
    this.createdDate = args.createdDate;
    this.docks = args.docks;
    this.doorGroups = args.doorGroups;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
  }

  @api() public readonly createdDate: DateTime;
  @api() public readonly docks: readonly Dock[];
  @api() public readonly doorGroups: readonly DoorGroup[];
  @api() public readonly id: EquipmentReference["id"];
  @api() public readonly modifiedDate: DateTime;

  public static deserialize(
    data: ApiExpandedEquipment,
    { docks, doorGroups, site, types }: DeserializeArguments,
  ): Equipment {
    const equipmentDocks = data.docks
      .map((apiDock) => docks.find(({ id }) => id === apiDock.id))
      // Ignore docks not associated with the current site.
      .filter(isExistent);

    const equipmentDoorGroups = data.doorGroups
      .map((apiGroup) => doorGroups.find(({ id }) => id === apiGroup.id))
      // Ignore door groups not associated with the current site.
      .filter(isExistent);

    return new Equipment({
      ...deserializeSimpleEquipment(data, { site, types }),
      createdDate: parseDateTime(data.createdDate),
      docks: equipmentDocks,
      doorGroups: equipmentDoorGroups,
      locationType: EquipmentLocationType[data.availability],
      modifiedDate: parseDateTime(data.modifiedDate),
      site,
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    args: DeserializeArguments,
  ): readonly Equipment[] {
    return value.map((x) => Equipment.deserialize(x, args));
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getEquipmentRouteUrl(this, ...childPaths);
  }
}

export function deserializeSimpleEquipment(
  data: Pick<ApiExpandedEquipment, "id" | "quantity" | "equipmentTypeID">,
  { site, types }: SimpleDeserializeArguments,
): SimpleEquipment {
  const type = types.find(({ id }) => id === data.equipmentTypeID);
  if (!type) {
    throw new Error(
      `Could not find equipment type with ID "${data.equipmentTypeID}".`,
    );
  }
  return {
    getRouteUrl: (...childPaths) =>
      getEquipmentRouteUrl({ id: data.id, site }, ...childPaths),
    id: data.id,
    quantity: data.quantity,
    type,
  };
}

export interface SimpleEquipment
  extends Pick<Equipment, "id" | "quantity" | "type"> {
  getRouteUrl(...childPaths: string[]): string;
}

export function getEquipmentRouteUrl(
  equipment: EquipmentReference,
  ...childPaths: string[]
): string {
  return getSiteRouteUrl(
    equipment.site,
    "settings/equipment",
    String(equipment.id),
    ...childPaths,
  );
}

export interface EquipmentReference extends SiteModelReference<"equipment"> {}
