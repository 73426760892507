import { OverlayRef } from "@angular/cdk/overlay";
import { PopoverContent } from "./popover-config";

export class PopoverRef<T> {
  public constructor(
    public readonly overlay: OverlayRef,
    public readonly content: PopoverContent,
    public readonly data: T,
  ) {}

  public close(): void {
    this.overlay.dispose();
  }
}
