import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[mrClickOutside]",
})
export class ClickOutsideDirective {
  public constructor(private readonly elementRef: ElementRef) {}

  @Output()
  public readonly clickOutside = new EventEmitter<void>();

  @HostListener("document:click", ["$event"])
  public onClick(event: MouseEvent): void {
    if (
      event.target instanceof HTMLElement &&
      this.elementRef.nativeElement instanceof HTMLElement &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.clickOutside.emit();
    }
  }
}
