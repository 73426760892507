import { Component, Input } from "@angular/core";
import { SelectMode } from "@danielmoncada/angular-datetime-picker";
import { Day } from "src/app/core/day.model";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { calculateWeeklyRange } from "src/app/core/forms/date-time-picker-base.component";

@Component({
  selector: "mr-date-range-stepper[minDay]",
  template: `
    <label>{{ label }}</label>
    <div>
      <mr-icon-button
        class="prev-day-button"
        label="Previous Day"
        (click)="setToPreviousWeeklyRange()"
      >
        <mr-icon class="left-chevron" name="chevron"></mr-icon>
      </mr-icon-button>

      <mr-date-picker
        [ngModel]="value"
        label=""
        labelHidden
        [forceWeeklyRange]="forceWeeklyRange"
        [maxDay]="maxDay"
        [minDay]="minDay"
        [selectMode]="selectMode"
        (ngModelChange)="onChange($event)"
        [disableClear]="true"
      ></mr-date-picker>

      <mr-icon-button
        class="next-day-button"
        label="Next Day"
        (click)="setToNextWeeklyRange()"
      >
        <mr-icon class="right-chevron" name="chevron"></mr-icon>
      </mr-icon-button>
    </div>
  `,
  styleUrls: ["./date-range-stepper.component.scss"],
})
export class DateRangeStepperComponent extends BaseFieldComponent<Day[]> {
  public constructor() {
    super();
  }

  @Input() public forceWeeklyRange = false;
  @Input() public maxDay: Day | null = null;
  @Input() public minDay!: Day;
  @Input() public selectMode: SelectMode = "single";

  public setToPreviousWeeklyRange(): void {
    if (!this.value) {
      return;
    }

    const dayInThePreviousWeek = this.value[0].minus({ days: 7 });

    const weeklyRange = calculateWeeklyRange(
      dayInThePreviousWeek,
      this.minDay,
      this.maxDay,
    );

    if (weeklyRange[0] <= weeklyRange[1]) {
      this.onChange([new Day(weeklyRange[0]), new Day(weeklyRange[1])]);
    }
  }

  public setToNextWeeklyRange(): void {
    if (!this.value) {
      return;
    }

    const dayInTheNextWeek = this.value[0].plus({ days: 7 });

    const weeklyRange = calculateWeeklyRange(
      dayInTheNextWeek,
      this.minDay,
      this.maxDay,
    );

    if (weeklyRange[0] <= weeklyRange[1]) {
      this.onChange([new Day(weeklyRange[0]), new Day(weeklyRange[1])]);
    }
  }
}
