import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from "@angular/router";
import { chunk } from "lodash-es";
import {
  animationFrameScheduler,
  firstValueFrom,
  merge,
  MonoTypeOperatorFunction,
  NEVER,
  Observable,
  ObservableInput,
  of,
  OperatorFunction,
  pipe,
  scheduled,
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  scan,
  skip,
  startWith,
  switchMap,
} from "rxjs/operators";
import { ColumnFilter } from "src/app/core/table";
import { parseNumber } from "./converters";
import { isExistent } from "./miscellaneous";

/**
 * Gets the first existent (defined, non-null) value from an observable as a Promise.
 * @param observable The observable to pull the first value from.
 */
export function firstExistentValueFrom<T>(
  observable: Observable<T | null | undefined>,
): Promise<T> {
  return firstValueFrom(observable.pipe(filter(isExistent)));
}

/**
 * Maps a stream of router events to a flag indicating whether a route
 * is under way.
 */
export function isNavigationStateChange(): OperatorFunction<Event, boolean> {
  return pipe(
    map((event) => {
      if (event instanceof NavigationStart) {
        return true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        return false;
      }
      return null;
    }),
    filter(isExistent),
  );
}

/**
 * Maps over each emitted array from the source observable, applying updates
 * from an observable using an update function.
 * @param updates The sequence of updated items for the source observable's
 * array value.
 * @param updater The callback invoked for every update received, returning
 * the updated array to emit from the source observable.
 */
export function mapArrayUpdates<
  Item,
  Collection extends
    | readonly Item[]
    | { readonly value: readonly Item[] }
    | null = readonly Item[] | null,
  Update = Item,
>(
  updates: Observable<Update>,
  updater: (
    all: NonNullable<Collection>,
    updated: Update,
  ) => NonNullable<Collection>,
): MonoTypeOperatorFunction<Collection> {
  return switchMap((collection) =>
    isExistent(collection)
      ? updates.pipe(scan(updater, collection), startWith(collection))
      : of(collection),
  );
}

/**
 * Maps over a collection of table column filters and returns any matching
 * carrier ID being filtered on.
 * @param columnKey The unique key identifier for the column that may have a
 * filter set with carrier information.
 */
export function mapColumnFiltersToSelectedCarrier(
  columnKey: string,
): OperatorFunction<readonly ColumnFilter[] | undefined, number | undefined> {
  return pipe(
    map((columnFilters) =>
      columnFilters?.find((columnFilter) => columnFilter.key === columnKey),
    ),
    map((columnFilter) =>
      typeof columnFilter?.value === "number" ? columnFilter.value : undefined,
    ),
  );
}

/**
 * Maps the emissions from source **if** the source doesn't emit `null`.
 * Otherwise, the `null` value is passed through unchanged.
 *
 * @param project - The projection function to apply to each emission.
 */
export function mapNullable<T, U>(
  project: (value: T, index: number) => U,
): OperatorFunction<T | null, U | null> {
  return map((value, index) => (value === null ? null : project(value, index)));
}

/**
 * Groups the previous and current value together like `pairwise` but includes
 * an initial value with the first source emission so that it emits immediately.
 *
 * @param initial - The initial value to include with the first source emission.
 */
export function pairwiseWithInitial<T, U>(
  initial: U,
): OperatorFunction<T, [T | U, T]> {
  const pairwiseInstance =
    // When used with `startWith`, the initial value of `pairwise` should only
    // ever appear in the "previous" value, never any future values, so we can
    // safely assert the type only on the first element of the pairwise result.
    pairwise() as OperatorFunction<T | U, [T | U, T]>;
  return pipe(startWith(initial), pairwiseInstance);
}

/**
 * Partitions an observable into two separate streams based on a predicate filter.
 * @param observable The observable to partition the stream of.
 * @param predicate Predicate for determining how to split the results.
 * `true` return values go to the first result observable, `false` to the second.
 */
export function partition<T, R extends T>(
  observable: Observable<T>,
  predicate: (value: T) => value is R,
): [Observable<R>, Observable<Exclude<T, R>>];
export function partition<T>(
  observable: Observable<T>,
  predicate: (value: T) => boolean,
): [Observable<T>, Observable<T>];
export function partition<T, R extends T = T>(
  observable: Observable<T>,
  predicate: (value: T) => boolean,
): [Observable<R>, Observable<Exclude<T, R>>] {
  const success = observable.pipe(
    filter((value): value is R => predicate(value)),
  );
  const failure = observable.pipe(
    filter((value): value is Exclude<T, R> => !predicate(value)),
  );
  return [success, failure];
}

/**
 * Pauses and resumes the emissions of a source observable based on the provided
 * triggers. The source is unsubscribed while paused.
 * @param pause An observable that emits whenever the source should be paused.
 * @param resume An observable that emits whenever the source should be resumed.
 */
export function pauseable<T>(
  pause: Observable<unknown>,
  resume: Observable<unknown>,
  { isFirstResumeSkipped = false } = {},
): MonoTypeOperatorFunction<T> {
  return (observable) =>
    merge(
      pause.pipe(map(() => "paused" as const)),
      resume.pipe(map(() => "unpaused" as const)),
    ).pipe(
      startWith("initial" as const),
      distinctUntilChanged(),
      switchMap((state) =>
        state === "paused"
          ? NEVER
          : observable.pipe(
              skip(isFirstResumeSkipped && state !== "initial" ? 1 : 0),
            ),
      ),
    );
}

export function pauseWhen<T>(
  pause: Observable<boolean>,
  options?: { isFirstResumeSkipped?: boolean },
): MonoTypeOperatorFunction<T> {
  return pauseable(
    pause.pipe(filter((value) => value === true)),
    pause.pipe(filter((value) => value === false)),
    options,
  );
}

/**
 * Provides a default value when the source observable emits null or undefined.
 * @param defaultValue The default value.
 */
export function withDefault<T, U extends T>(
  defaultValue: U,
): OperatorFunction<T | null | undefined, T> {
  return map((value) => (isExistent(value) ? value : defaultValue));
}

export function pluckDistinctUntilChanged<T, K extends keyof T>(
  key: K,
  comparator?: (previous: T[K] | null, current: T[K] | null) => boolean,
): OperatorFunction<T, T[K]>;
export function pluckDistinctUntilChanged<T, K extends keyof T>(
  key: K,
  comparator?: (previous: T[K] | null, current: T[K] | null) => boolean,
): OperatorFunction<T | null | undefined, T[K] | null>;
export function pluckDistinctUntilChanged<T, K extends keyof T>(
  key: K,
  comparator?: (previous: T[K] | null, current: T[K] | null) => boolean,
): OperatorFunction<T | null | undefined, T[K] | null> {
  return pipe(
    map((obj) => obj?.[key] ?? null),
    distinctUntilChanged(comparator),
  );
}

/**
 * Selects the model item by ID specified in the currently activated route
 * from the list of all items in the source observable.
 * @param route The current component route.
 */
export function selectRoutedItem<T extends { id: number }>(
  route: ActivatedRoute | ActivatedRouteSnapshot,
  customId?: string,
): OperatorFunction<readonly T[] | null, T | null> {
  const paramMap =
    route.paramMap instanceof Observable ? route.paramMap : of(route.paramMap);
  return switchMap((items) =>
    paramMap.pipe(
      map((params) => params.get(customId ?? "id")),
      map((id) => parseNumber(id)),
      map(
        (id) =>
          (isExistent(id) && items?.find((item) => item.id === id)) || null,
      ),
    ),
  );
}

/**
 * Maps the emissions from source **if** the source doesn't emit `null`.
 * Otherwise, the `null` value is passed through unchanged.
 *
 * @param project - The projection function to apply to each emission.
 */
export function switchMapNullable<T, U>(
  project: (value: T, index: number) => ObservableInput<U>,
): OperatorFunction<T | null, U | null> {
  return switchMap((value, index) =>
    value === null ? of(null) : project(value, index),
  );
}

/**
 * Chunks out large datasets to be returned in smaller incremental units
 * every browser animation frame. This reduces the render time for the
 * user and helps in preventing other UI components from locking up while
 * loading large datasets into view.
 *
 * @param itemsPerFrame - The amount of items to render per animation frame.
 */
export function progressiveGrowth<T>(
  itemsPerFrame = 10,
): MonoTypeOperatorFunction<readonly T[] | null> {
  return switchMap((data) => {
    if (data === null || data.length <= itemsPerFrame) {
      return [data];
    }
    const [firstChunk, ...remainingChunks] = chunk(data, itemsPerFrame);
    return scheduled(remainingChunks, animationFrameScheduler).pipe(
      // Emit the first chunk immediately to flush out the previous value as
      // soon as an update exists. This seems to help prevent rendering
      // inconsistencies where the template still sees the previous set but is
      // expecting a new one.
      startWith(firstChunk),
      scan((current, next) => [...current, ...next]),
    );
  });
}
