import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Door>();

export class HelpAssistTicketDoor {
  private constructor(args: ClassProperties<HelpAssistTicketDoor>) {
    this.id = args.id;
    this.name = args.name;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;

  public static deserialize(apiModel: ApiTicketDoor): HelpAssistTicketDoor {
    return new HelpAssistTicketDoor({
      id: apiModel.id,
      name: apiModel.name,
    });
  }
}

export type ApiTicketDoor = Pick<Api.Door, "id" | "name">;
