import { Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Weekday } from "../base-week.model";
import { Weekdays } from "../weekdays.model";
import { BaseFieldComponent } from "./base-field.component";
import { FieldContainerParentService } from "./field-container.component";

const everyDay = new Weekdays({
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
});

@Component({
  selector: "mr-weekday-select[label]",
  templateUrl: "./weekday-select.component.html",
  styles: [
    `
      :host {
        display: block;
      }
      mr-field-container > div {
        display: flex;
        flex-wrap: wrap;
        gap: var(--mr-spacing-small);
      }
      .all-day-box {
        flex-basis: 100%;
      }
      mr-checkbox {
        width: 6.3rem;
      }
      ul {
        font-size: var(--mr-text-size-extra-small);
        list-style-type: none;
        margin: var(--mr-spacing-extra-small) var(--mr-spacing-extra-small) 0 0;
        color: var(--mr-color-white);
        background-color: var(--mr-color-red);
        display: inline-block;
        border-radius: var(--mr-border-radius);
        padding: var(--mr-spacing-extra-small) var(--mr-spacing-small);
      }
    `,
  ],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => WeekdaySelectComponent),
    },
  ],
})
export class WeekdaySelectComponent extends BaseFieldComponent<Weekdays | null> {
  private nonEveryDayValue: Weekdays | null = null;

  public get isEveryDayChecked(): boolean {
    return !!this.nonEveryDayValue;
  }

  public override onChange(value: Weekdays | null): void {
    super.onChange(value?.hasAnySelected() ? value : null);
  }

  public onDayChecked(day: Weekday, value: boolean): void {
    this.onChange(new Weekdays({ ...this.value, [day]: value }));
  }

  public onEveryDayChange(value: boolean): void {
    let newValue: Weekdays | null;
    if (value) {
      newValue = everyDay;
      this.nonEveryDayValue = this.value;
    } else {
      newValue = this.nonEveryDayValue;
      this.nonEveryDayValue = null;
    }
    this.onChange(newValue);
  }
}
