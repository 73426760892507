import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Duration } from "luxon";
import { DurationScale } from "src/app/core/pipes/duration.pipe";
import { isExistent } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-duration-select[label]",
  templateUrl: "./duration-select.component.html",
  styleUrls: ["./textbox.component.scss", "./duration-select.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DurationSelectComponent),
    },
  ],
})
export class DurationSelectComponent extends BaseFieldComponent<Duration | null> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public scale: DurationScale = "minutes";
  @Input() public unitFormat?: "short";
  @Input() public placeholder = "";
  @Input() public small = false;
  @Input() public kind: "form" | "tiled" = "form";

  @Input() public set min(value: number | Duration | null) {
    this.minNumber = Duration.isDuration(value) ? value.as(this.scale) : value;
  }
  public minNumber: number | null = null;

  @Input() public set max(value: number | Duration | null) {
    this.maxNumber = Duration.isDuration(value) ? value.as(this.scale) : value;
  }
  public maxNumber: number | null = null;

  @Input() public set step(value: number | Duration | null) {
    this.stepNumber = Duration.isDuration(value) ? value.as(this.scale) : value;
  }
  public stepNumber: number | null = null;

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  public override get value(): Duration | null {
    return this._value;
  }
  public override set value(value: Duration | null) {
    this._value = value;
    this.displayNumber = isExistent(value) ? value.as(this.scale) : null;
  }
  private _value: Duration | null = null;

  public displayNumber: number | null = null;

  public onInputChange(value: number | null): void {
    this.onChange(
      isExistent(value) ? Duration.fromObject({ [this.scale]: value }) : null,
    );
  }
}
