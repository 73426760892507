import { AbstractControl, AsyncValidatorFn, ValidatorFn } from "@angular/forms";
import { isNil, omit } from "lodash-es";
import { Observable, of, throwError } from "rxjs";

/**
 * Updates the error value in the control for the given error key. Leaves all
 * other errors intact.
 * @param control - The control to update the error in.
 * @param key - The object key for the validation error.
 * @param error - The error value.
 */
export function updateValidationError<T extends [string, unknown]>(
  control: AbstractControl,
  key: T[0],
  error: T[1] | null,
): void {
  if (error) {
    control.setErrors({ ...control.errors, [key]: error });
  } else {
    const remainingErrors = omit(control.errors, key);
    control.setErrors(
      Object.keys(remainingErrors).length ? remainingErrors : null,
    );
  }
}

export function makeValidatorOptional<
  E extends [name: string, error: { actual: unknown }],
>(
  isValueCorrect: (value: unknown) => value is E[1]["actual"],
  baseValidator: (value: E[1]["actual"]) => ValidatorResult<E>,
): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    } else if (!isValueCorrect(control.value)) {
      throw new Error(`Unexpected control value: ${String(control.value)}`);
    } else {
      return baseValidator(control.value);
    }
  };
}

export function makeAsyncValidatorOptional<
  E extends [name: string, error: { actual: unknown }],
>(
  isValueCorrect: (value: unknown) => value is E[1]["actual"],
  baseValidator: (value: E[1]["actual"]) => Observable<ValidatorResult<E>>,
): AsyncValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return of(null);
    } else if (!isValueCorrect(control.value)) {
      return throwError(
        () => new Error(`Unexpected control value: ${String(control.value)}`),
      );
    } else {
      return baseValidator(control.value);
    }
  };
}

// Validator error types for Angular's build in `Validators` validators. We can
//  use these in our own validators for consistency in error messaging.
export type EmailValidatorError = ["email", true];
export type MaxLengthValidatorError = [
  "maxlength",
  { requiredLength: number; actualLength: number },
];
export type RequiredValidatorError = ["required", true];

// Empty values should be allowed for most all validators.
// The `required` validator can be added to handle the empty case, if needed.
export function isEmptyValue(value: unknown): value is null | undefined | "" {
  return isNil(value) || value === "";
}

type ErrorObject<T extends [name: string, error: unknown]> = {
  [K in T[0]]: T[1];
};

export type ValidatorResult<T extends [name: string, error: unknown]> =
  ErrorObject<T> | null;
