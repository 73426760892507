import { NgClass } from "@angular/common";
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { isEqual } from "lodash-es";
import { combineLatest, firstValueFrom, of, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { Permission, UserService } from "src/app/core/auth";
import { PageComponent } from "src/app/core/layout/page.component";
import { ChoiceBehavior, ModalService } from "src/app/core/modal.service";
import {
  isExistent,
  pluckDistinctUntilChanged,
  progressiveGrowth,
} from "src/utils";
import { ColumnManagementModalComponent } from "./column-management-modal.component";
import { TableColumnFilterComponent } from "./table-column-filter.component";
import { ClearColumnFilter, ColumnFilter } from "./table-column-filter.model";
import { TableItemDirective } from "./table-item.directive";
import { TableService } from "./table.service";

@UntilDestroy()
@Component({
  selector: "mr-table[data]",
  templateUrl: "./table.component.html",
  styleUrls: [
    "./table.component.scss",
    // Used when there are sticky columns configured.
    "./table-sticky-columns.scss",
    // Used when using the help assist variant.
    "./table-help-assist.scss",
  ],
})
export class TableComponent<T> implements OnInit {
  public constructor(
    private readonly modal: ModalService,
    private readonly user: UserService,
    @Optional() private readonly service?: TableService,
    @Optional() private readonly page?: PageComponent,
  ) {}

  @Input() public set data(value: readonly T[] | null) {
    this.dataSubject.next(value);
  }
  private readonly dataSubject = new ReplaySubject<readonly T[] | null>(1);
  public readonly dataChanges = this.dataSubject.pipe(
    progressiveGrowth(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  // TODO: Generalize the HA-specific styles into a cohesive variant and combine
  // the rest with the "normal" as much as possible so that we have one unified
  // table format again with a slight semantic variant for "compact" or
  // "non-alternating rows" or whatever is left.
  @Input() public kind: "normal" | "help-assist" = "normal";

  @ContentChildren(TableItemDirective, { descendants: true })
  public set columns(value: QueryList<TableItemDirective<T>>) {
    this.columnsSubject.next(value.toArray());
  }
  private readonly columnsSubject = new ReplaySubject<
    Array<TableItemDirective<T>>
  >(1);
  private readonly columnsChanges = this.columnsSubject.pipe(
    distinctUntilChanged((prev, next) => isEqual(prev, next)),
  );

  @ViewChildren(TableColumnFilterComponent)
  public readonly columnFilterFields!: QueryList<TableColumnFilterComponent>;

  @Input() public areDynamicColumnsEnabled = false;

  @Input() public isSortEnabled = false;

  @Input() public isFilteringEnabled = false;

  @Input() public set canClickRow(value: boolean | Permission) {
    this.isRowClickEnabled =
      typeof value === "boolean" ? value : this.user.permissions.has(value);
  }
  public isRowClickEnabled = false;

  @Input() public disableColumnConfiguration?: boolean;

  @Output() public readonly displayedColumnsUiModelKeys = new EventEmitter<
    string[]
  >();

  @Output() public readonly rowClick = new EventEmitter<T>();

  @Output() public readonly showMoreClick = new EventEmitter<void>();

  @Input() public rowClass?: (
    record: T,
    index: number,
  ) => NgClass["ngClass"] | null;

  public readonly arePreviousResultsAvailable =
    this.service?.pagination.hasPrevious;

  public readonly areNextResultsAvailable = this.service?.pagination.hasNext;

  public readonly fixedColumnsChanges = this.columnsChanges.pipe(
    map((columns) => columns.filter((column) => column.isFixed)),
  );

  private readonly defaultColumnsChanges = this.columnsChanges.pipe(
    map((columns) => columns.filter((column) => !column.hidden)),
  );

  public readonly displayedColumnsChanges = combineLatest([
    this.columnsChanges,
    this.defaultColumnsChanges,
    this.service?.displayedColumnsChanges ?? of(null),
  ]).pipe(
    map(([columns, defaultColumns, columnConfigs]) => {
      const displayedColumns = columnConfigs?.length
        ? columnConfigs
            .map((columnConfig) => {
              const column = columns.find(
                (item) => item.key === columnConfig.key,
              );
              if (column) {
                // TODO: update this filter in some other way that doesn't
                // involve mutating the column itself inside of an observable
                // operation.
                column.filter = columnConfig.filter;
              }
              return column;
            })
            .filter(isExistent)
        : defaultColumns;

      const displayedColumnsUiModelKeys = displayedColumns.map(
        (column) => column.uiModelKey,
      );
      this.displayedColumnsUiModelKeys.emit(displayedColumnsUiModelKeys);
      return displayedColumns;
    }),
    shareReplay(1),
  );

  public readonly pageScrollLeftPositionChanges = this.page?.scrollChanges.pipe(
    pluckDistinctUntilChanged("left"),
  );

  public get areColumnsConfigurable(): boolean {
    if (this.disableColumnConfiguration) {
      return false;
    }
    return !!this.service;
  }

  public get isUtilityColumnVisible(): boolean {
    return this.areColumnsConfigurable || this.isFilteringEnabled;
  }

  public ngOnInit(): void {
    const { service } = this;
    if (service) {
      this.defaultColumnsChanges
        .pipe(untilDestroyed(this))
        .subscribe((columns) => {
          service.setDefaultColumns(columns.map((column) => column.key));
        });

      this.columnsChanges.pipe(untilDestroyed(this)).subscribe((columns) => {
        service.setAvailableColumns(columns);
      });
    }
  }

  public emitRowClick(record: T): void {
    if (this.isRowClickEnabled) {
      this.rowClick.emit(record);
    }
  }

  public getRowClasses(record: T, index: number): NgClass["ngClass"] {
    return this.rowClass?.(record, index) || "";
  }

  public async openColumnManagement(): Promise<void> {
    if (!this.service) {
      return;
    }

    const keys = await this.modal.open(
      ColumnManagementModalComponent,
      {
        allColumns: await firstValueFrom(this.columnsChanges),
        selectedColumns: await firstValueFrom(this.displayedColumnsChanges),
      },
      { choice: ChoiceBehavior.Optional },
    ).result;

    if (keys) {
      await this.service.updateDisplayedColumns(keys);
    }
  }

  public async updateFilter(
    value: ColumnFilter | ClearColumnFilter,
  ): Promise<void> {
    if (value.isCleared) {
      await this.service?.removeFilter(value.key);
    } else {
      await this.service?.addFilter(value);
    }
  }

  public async clearFilter(key: string): Promise<void> {
    if (!this.service || !this.columnFilterFields) {
      return;
    }

    await this.service.removeFilter(key);

    const columnField = this.columnFilterFields.find(
      (field) => field.key === key,
    );

    if (columnField) {
      columnField.clear();
    }
  }

  public async clearFilters(): Promise<void> {
    if (!this.service) {
      return;
    }

    await this.service.clearFilters();
    this.columnFilterFields.forEach((field) => field.clear());
  }

  public async loadPreviousPage(): Promise<void> {
    await this.service?.pagination.requestPrevious();
  }

  public async loadNextPage(): Promise<void> {
    await this.service?.pagination.requestNext();
  }
}
