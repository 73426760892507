import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Dayjs, isDayjs } from "dayjs";
import { DateTime, Interval } from "luxon";
import { Day } from "src/app/core/day.model";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";

type Value = { start: Day; end: Day } | null;
type DisplayValue = { start: Date; end: Date } | null;
type LibraryOutput =
  | { start: Dayjs; end: Dayjs }
  | { start: Date; end: Date }
  | { start: null; end: null };

@Component({
  selector: "mr-date-range-filter[label]",
  template: `
    <input
      ngxDaterangepickerMd
      readonly
      startKey="start"
      endKey="end"
      opens="auto"
      placeholder="Min - Max"
      [attr.aria-label]="label"
      [showClearButton]="true"
      [alwaysShowCalendars]="true"
      [showDropdowns]="true"
      [linkedCalendars]="true"
      [locale]="{ applyLabel: 'Select', format: 'MMM D, YYYY' }"
      [ngModel]="displayValue"
      (ngModelChange)="setDay($event)"
      [ranges]="ranges"
    />
  `,
  styleUrls: ["./date-range-filter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangeFilterComponent),
    },
  ],
})
export class DateRangeFilterComponent extends BaseFieldComponent<Value> {
  // Override the base value property so we can set the "display" value
  // for the backing library when `value` is updated externally.
  @Input() public override set value(value: Value) {
    this._value = value;
    this.displayValue = getDisplayRangeValue(value);
  }
  public override get value(): Value {
    return this._value;
  }
  private _value: Value = null;

  public displayValue: DisplayValue = null;

  private hasEmittedFirstValue = false;

  public readonly ranges = getRanges();

  public setDay({ start, end }: LibraryOutput): void {
    if (!this.hasEmittedFirstValue) {
      // Ignore the initial value that gets set when we bind to ngModel.
      this.hasEmittedFirstValue = true;
      return;
    }

    // If the value is already null and we're setting it to null, this is a
    // duplicate update after we've already updated that occurs because
    // changing `displayValue` causes a second update.
    const isNullDisplayValueUpdate = (!start || !end) && this.value === null;

    if (
      start instanceof Date ||
      end instanceof Date ||
      isNullDisplayValueUpdate
    ) {
      // Ignore the duplicate update that fires when ngModel is updated with
      // our new `displayValue` value after the `onChange` call.
      // TODO: Find a better way to avoid double updates on displayValue change.
      return;
    }

    // If the end date is not selected, set it to the start date to avoid displaying invalid date.
    if (isDayjs(end) && !end.isValid()) {
      end = start;
    }

    if (!start || !end) {
      this.onChange(null);
      return;
    }

    this.onChange({
      start: getDay(start),
      // The UI element shows the "end" date as inclusive. In order to filter
      // correctly and include the end date, we need to filter exclusively on
      // the next day. Thus, add a day to make the range exclusive.
      end: getDay(end).plus({ days: 1 }),
    });
  }
}

function getDay(value: Dayjs): Day {
  return new Day({
    year: value.year(),
    // Moment months are zero-based like JS dates for silly reasons.
    month: value.month() + 1,
    day: value.date(),
  });
}

function getDisplayRangeValue(value: Value): DisplayValue {
  if (!value) {
    return null;
  }
  const { start, end } = value;
  // Include the time component so that it's placed in the local timezone rather
  // than UTC. This is for display purposes only.
  return {
    start: new Date(`${start.toString()}T00:00`),
    // Remove the day we added above for making the range exclusive.
    end: new Date(`${end.minus({ days: 1 }).toString()}T00:00`),
  };
}

function getRanges(): { [name: string]: [Date, Date] } {
  const today = DateTime.local().startOf("day");

  // Weekday starts on Monday in Luxon but we want it to be Sunday. If the
  // selected day is a Sunday, this will put the "start of the week" according
  // to Luxon a week behind what we want (since Sunday is the last day of the
  // previous week). For any other day, the start will be Monday of the correct
  // week, so we just need to go back a day to Sunday.
  const startOfWeekOffset = today.weekday === 7 ? 6 : -1;
  const thisWeek = Interval.after(
    today.startOf("week").plus({ days: startOfWeekOffset }),
    { days: 6 },
  );
  const lastWeek = thisWeek.mapEndpoints((endpoint) =>
    endpoint.minus({ weeks: 1 }),
  );

  return {
    Today: [today.toJSDate(), today.toJSDate()],
    Yesterday: [
      today.minus({ days: 1 }).toJSDate(),
      today.minus({ days: 1 }).toJSDate(),
    ],
    "This Week": [thisWeek.start.toJSDate(), thisWeek.end.toJSDate()],
    "Last Week": [lastWeek.start.toJSDate(), lastWeek.end.toJSDate()],
    "This Month": [
      today.startOf("month").toJSDate(),
      today.endOf("month").toJSDate(),
    ],
    "Last Month": [
      today.minus({ months: 1 }).startOf("month").toJSDate(),
      today.minus({ months: 1 }).endOf("month").toJSDate(),
    ],
  };
}
