export enum Permission {
  AutoExtendReservation = "AutoExtendReservation",
  CreateActivity = "CreateActivity",
  CreateAdminSite = "CreateAdminSite",
  CreateAppointmentAdvanced = "CreateAppointmentAdvanced",
  CreateAppointmentAdvancedPropertyUnloaderOptionPartner = "CreateAppointmentAdvancedPropertyUnloaderOptionPartner",
  CreateAppointmentBackdated = "CreateAppointmentBackdated",
  CreateAppointmentGuided = "CreateAppointmentGuided",
  CreateAutoAppointQuota = "CreateAutoAppointQuota",
  CreateAutoAppointRule = "CreateAutoAppointRule",
  CreateBuyer = "CreateBuyer",
  CreateClosure = "CreateClosure",
  CreateClosureGameDay = "CreateClosureGameDay",
  CreateDock = "CreateDock",
  CreateDoor = "CreateDoor",
  CreateDoorGroup = "CreateDoorGroup",
  CreateEquipment = "CreateEquipment",
  CreateGlobalCarrier = "CreateGlobalCarrier",
  CreateHelpAssistTicket = "CreateHelpAssistTicket",
  CreateMoveRequest = "CreateMoveRequest",
  CreatePartnerCarrier = "CreatePartnerCarrier",
  CreatePurchaseOrder = "CreatePurchaseOrder",
  CreatePurchaseOrderBackdated = "CreatePurchaseOrderBackdated",
  CreateRack = "CreateRack",
  CreateRackException = "CreateRackException",
  CreateReservation = "CreateReservation",
  DeleteAutoAppointQuota = "DeleteAutoAppointQuota",
  DeleteAutoAppointRule = "DeleteAutoAppointRule",
  DeleteBuyer = "DeleteBuyer",
  DeleteEquipment = "DeleteEquipment",
  DeleteClosure = "DeleteClosure",
  EditActivity = "EditActivity",
  EditAdminSite = "EditAdminSite",
  EditAppointmentAddException = "EditAppointmentAddException",
  EditAppointmentBypassApproval = "EditAppointmentBypassApproval",
  EditAppointmentCancel = "EditAppointmentCancel",
  EditAppointmentCancelUnrestricted = "EditAppointmentCancelUnrestricted",
  EditAppointmentCompletedRemoveOrder = "EditAppointmentCompletedRemoveOrder",
  EditAppointmentGateManagement = "EditAppointmentGateManagement",
  EditAppointmentInformation = "EditAppointmentInformation",
  EditAppointmentInformationPropertyUnloaderOptionPartner = "EditAppointmentInformationPropertyUnloaderOptionPartner",
  EditAppointmentInformationUnrestricted = "EditAppointmentInformationUnrestricted",
  EditAppointmentPropertyStatus = "EditAppointmentPropertyStatus",
  EditAppointmentSlotAdvanced = "EditAppointmentSlotAdvanced",
  EditAppointmentSlotGuided = "EditAppointmentSlotGuided",
  EditAutoAppointQuota = "EditAutoAppointQuota",
  EditAutoAppointRule = "EditAutoAppointRule",
  EditBuyer = "EditBuyer",
  EditClosure = "EditClosure",
  EditClosureGameDay = "EditClosureGameDay",
  EditDock = "EditDock",
  EditDoor = "EditDoor",
  EditDoorGroup = "EditDoorGroup",
  EditEmailTemplate = "EditEmailTemplate",
  EditEquipment = "EditEquipment",
  EditGlobalCarrier = "EditGlobalCarrier",
  EditHelpAssistTicket = "EditHelpAssistTicket",
  EditHelpAssistTicketAdvanced = "EditHelpAssistTicketAdvanced",
  EditHelpAssistTicketAdvancedApprovalOverride = "EditHelpAssistTicketAdvancedApprovalOverride",
  EditPartner = "EditPartner",
  EditPartnerCarrier = "EditPartnerCarrier",
  EditRack = "EditRack",
  EditReservation = "EditReservation",
  EditSchedule = "EditSchedule",
  EditSite = "EditSite",
  EditSitePropertyInformation = "EditSitePropertyInformation",
  EditVendor = "EditVendor",
  ViewActivityList = "ViewActivityList",
  ViewAdmin = "ViewAdmin",
  ViewAdminSiteList = "ViewAdminSiteList",
  ViewAppointmentDetails = "ViewAppointmentDetails",
  ViewAppointmentDetailsAdvanced = "ViewAppointmentDetailsAdvanced",
  ViewAppointmentDetailsPropertyTimestampsAndDoors = "ViewAppointmentDetailsPropertyTimestampsAndDoors",
  ViewAppointmentHistory = "ViewAppointmentHistory",
  ViewAppointmentList = "ViewAppointmentList",
  ViewAppointmentListFilterTabs = "ViewAppointmentListFilterTabs",
  ViewAutoAppointList = "ViewAutoAppointList",
  ViewBuyerList = "ViewBuyerList",
  ViewClosureList = "ViewClosureList",
  ViewDashboard = "ViewDashboard",
  ViewDockList = "ViewDockList",
  ViewDoorGroupList = "ViewDoorGroupList",
  ViewDoorList = "ViewDoorList",
  ViewEmailTemplateList = "ViewEmailTemplateList",
  ViewEquipmentList = "ViewEquipmentList",
  ViewEquipmentTypeList = "ViewEquipmentTypeList",
  ViewExpiringSoonReservations = "ViewExpiringSoonReservations",
  ViewGameDayClosureList = "ViewGameDayClosureList",
  ViewGameDaySchedule = "ViewGameDaySchedule",
  ViewGlobalCarrierList = "ViewGlobalCarrierList",
  ViewGlobalSearch = "ViewGlobalSearch",
  ViewHelpAssistTicketListTabAllOpenTickets = "ViewHelpAssistTicketListTabAllOpenTickets",
  ViewHelpDeskAdminDetails = "ViewHelpDeskAdminDetails",
  ViewHelpDeskTicketDetails = "ViewHelpDeskTicketDetails",
  ViewHelpDeskTicketList = "ViewHelpDeskTicketList",
  ViewMoveRequest = "ViewMoveRequest",
  ViewMoveRequestList = "ViewMoveRequestList",
  ViewNotifications = "ViewNotifications",
  ViewPartnerCarrierList = "ViewPartnerCarrierList",
  ViewPartnerList = "ViewPartnerList",
  ViewPurchaseOrderDetails = "ViewPurchaseOrderDetails",
  ViewPurchaseOrderHistory = "ViewPurchaseOrderHistory",
  ViewPurchaseOrderList = "ViewPurchaseOrderList",
  ViewPurchaseOrderListFilterTabs = "ViewPurchaseOrderListFilterTabs",
  ViewRackList = "ViewRackList",
  ViewReports = "ViewReports",
  ViewReservationListAll = "ViewReservationListAll",
  ViewReservationListCarrier = "ViewReservationListCarrier",
  ViewSchedule = "ViewSchedule",
  ViewSite = "ViewSite",
  ViewSitePerformance = "ViewSitePerformance",
  ViewVendorList = "ViewVendorList",
}

const allPermissions = new Set<Permission>(Object.values(Permission));

export function isPermission(value: unknown): value is Permission {
  // If the list of all permissions has the value, then it must be a permission,
  // otherwise it's not. Thus, this type assertion should be fine.
  return allPermissions.has(value as Permission);
}

export function isPermissionArray(
  values: unknown,
): values is readonly Permission[] {
  return Array.isArray(values) && values.every(isPermission);
}
