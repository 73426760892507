import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/es6/NonEmptyString";
import { firstValueFrom, ReplaySubject } from "rxjs";
import {
  decodeOrElseNull,
  throwUnhandledCaseError,
  UUID,
  uuidStringCodec,
} from "src/utils";
import { mapClaimsToPermissions } from "./map-claims-to-permissions";
import { Permission } from "./permissions";

@Injectable({ providedIn: "root" })
export class UserService implements DetailsProperty<UserDetails> {
  public constructor(private readonly msal: MsalService) {}

  public get details(): UserDetails {
    // The root component and route guards ensures that the user is loaded and
    // valid, so this is safe to assert as non-null for all usages after that.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.#details!;
  }
  #details: UserDetails | null = null;

  readonly #isLoadedNotifier = new ReplaySubject<true>(1);
  public readonly isLoaded = firstValueFrom(this.#isLoadedNotifier);

  public get isValid(): boolean {
    return this.#details !== null;
  }

  public get permissions(): ReadonlySet<Permission> {
    return this.#details?.permissions ?? new Set();
  }

  public load(account: AccountInfo): void {
    this.#details = getDetails(account);
    this.#isLoadedNotifier.next(true);
  }

  public isSame(user: { readonly userId: UUID }): boolean {
    return this.#details?.id.isSame(user.userId) ?? false;
  }

  public isCarrier(
    this: this,
  ): this is this & DetailsProperty<{ readonly type: UserType.Carrier }> {
    return this.#details?.type === UserType.Carrier;
  }
  public isPartner(
    this: this,
  ): this is this & DetailsProperty<{ readonly type: UserType.Partner }> {
    return this.#details?.type === UserType.Partner;
  }
  public isVendor(): boolean {
    return this.#details?.type === UserType.Vendor;
  }

  public logout(): void {
    this.msal.logout();
  }
}

function getDetails(account: AccountInfo): UserDetails | null {
  const idToken = decodeToken(account.idTokenClaims);
  if (!idToken) {
    return null;
  }

  return {
    ...getUserTypeDetails(idToken),
    displayName: account.name || account.username,
    emailAddress: idToken.email || null,
    id: idToken.oid,
    permissions: mapClaimsToPermissions([idToken.extension_Role]),
  };
}

function getUserTypeDetails(idToken: TokenClaims): UserTypeDetails {
  switch (idToken.extension_ExternalOrgType) {
    case "Carrier":
      return {
        type: UserType.Carrier,
        carrierKey: idToken.extension_ExternalOrgID,
      };
    case "Partner":
      return {
        type: UserType.Partner,
        partnerKey: idToken.extension_ExternalOrgID,
      };
    case "Vendor":
      return { type: UserType.Vendor };
    case "":
    case undefined:
      return { type: UserType.Unknown };
    default:
      throwUnhandledCaseError(
        "token org type",
        idToken["extension_ExternalOrgType"],
      );
  }
}

export enum UserType {
  Unknown,
  Partner,
  Carrier,
  Vendor,
}

interface DetailsProperty<T> {
  readonly details: T;
}

export type UserDetails = UserTypeDetails & {
  readonly displayName: string;
  readonly emailAddress: string | null;
  readonly id: UUID;
  readonly permissions: ReadonlySet<Permission>;
};
type UserTypeDetails =
  | { readonly type: UserType.Carrier; readonly carrierKey: string }
  | { readonly type: UserType.Partner; readonly partnerKey: UUID }
  | { readonly type: UserType.Vendor }
  | { readonly type: UserType.Unknown };

const tokenClaimsCodec = t.intersection([
  t.type({
    extension_Role: t.string,
    oid: uuidStringCodec,
  }),
  t.partial({
    email: t.string,
  }),
  t.union([
    t.type({
      extension_ExternalOrgID: NonEmptyString,
      extension_ExternalOrgType: t.literal("Carrier"),
    }),
    t.type({
      extension_ExternalOrgID: uuidStringCodec,
      extension_ExternalOrgType: t.literal("Partner"),
    }),
    t.type({
      extension_ExternalOrgType: t.literal("Vendor"),
    }),
    t.partial({
      extension_ExternalOrgType: t.literal(""),
    }),
  ]),
]);
const decodeToken = decodeOrElseNull(tokenClaimsCodec);
type TokenClaims = t.TypeOf<typeof tokenClaimsCodec>;
