import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { BooleanAttribute, isExistent, parseBooleanAttribute } from "src/utils";

@Directive()
export abstract class BaseFieldComponent<T>
  implements ControlValueAccessor, OnInit
{
  @Input() public fieldId?: string;
  @Input() public label!: string;
  @Input() public description?: string;
  @Input() public canChangeCallback?: (value: T | null) => Promise<boolean>;

  @Input() public set value(value: T | null) {
    this._baseValue = value;
    this.valueSubject.next(value);
  }
  public get value(): T | null {
    return this._baseValue;
  }
  private _baseValue: T | null = null;
  private readonly valueSubject = new BehaviorSubject(this._baseValue);
  public readonly valueChanges = this.valueSubject.asObservable();

  @Input() public defaultValue?: T;

  @Input() public set labelHidden(value: BooleanAttribute) {
    this.isLabelHidden = parseBooleanAttribute(value);
  }
  public isLabelHidden = false;

  @Input() public set errorsHidden(value: BooleanAttribute) {
    this.areErrorsHidden = parseBooleanAttribute(value);
  }
  public areErrorsHidden = false;

  @Input() public set disabled(value: BooleanAttribute) {
    this.isDisabled = parseBooleanAttribute(value);
  }
  @HostBinding("class.disabled") public isDisabled = false;

  @Input() public set readonly(value: BooleanAttribute) {
    this.isReadonly = parseBooleanAttribute(value);
  }
  @HostBinding("class.readonly") public isReadonly = false;

  @Output() public readonly unfocus = new EventEmitter<void>();
  @Output() public readonly valueChange = new EventEmitter<T>();

  private formOnChangeCallback?: (value: T) => void;
  private formOnTouchedCallback?: () => void;

  public ngOnInit(): void {
    if (isExistent(this.defaultValue)) {
      this.onChange(this.defaultValue);
    }
  }

  /**
   * Propagates the given value up to the form, possibly gated behind the
   * `canChangeCallback`. If the callback returns `false`, the change is aborted
   * without an emission.
   *
   * @param value - The value to propagate up to the form.
   */
  public async propagateChange(value: T): Promise<boolean> {
    // Check if we should allow the update or not before committing it.
    if (this.canChangeCallback ? await this.canChangeCallback(value) : true) {
      this.onChange(value);
      return true;
    } else {
      // Force the base component back to the original value after we
      // "cancelled" the change. Otherwise, it leaves the new value displayed
      // despite not having emitted it up to the parent form.
      const originalValue = this.value;
      this.value = value;
      // `ChangeDetectorRef.detectChanges()` would be better here but that would
      // require passing it into this base class from every implementation.
      // Using `setTimeout` should be safe, though, since it only affects the
      // visual value and updates essentially immediately (next tick).
      await new Promise((resolve) => setTimeout(resolve));
      this.value = originalValue;
      return false;
    }
  }

  /**
   * Propagates the given value up to the form.
   *
   * **Note:** this method is deprecated in favor of `propagateChange` but is
   * left available since many field components still use it.
   *
   * @param value - The value to propagate up to the form.
   */
  public onChange(value: T): void {
    this.value = value;
    this.valueChange.emit(value);
    if (this.formOnChangeCallback) {
      this.formOnChangeCallback(value);
    }
  }

  public onBlur(): void {
    this.unfocus.emit();
    if (this.formOnTouchedCallback) {
      this.formOnTouchedCallback();
    }
  }

  public writeValue(value: T): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: T) => void): void {
    this.formOnChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.formOnTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
