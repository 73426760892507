import { Component, HostBinding } from "@angular/core";
import { environment } from "src/environments";

@Component({
  selector: "mr-environment-visual-aid",
  templateUrl: "environment-visual-aid.component.html",
  styleUrls: ["environment-visual-aid.component.scss"],
})
export class EnvironmentVisualAidComponent {
  @HostBinding("hidden")
  public readonly isHidden = environment.type !== "sandbox";

  public readonly environmentName: string = environment.type;
}
