import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import {
  ExpandedODataModel,
  FilterODataModel,
  getApiDetailsDecorator,
  getUpdatedPropertiesFilter,
} from "src/utils";
import { HelpAssistEventType } from "../../help-assist-enums";
import { HelpAssistTicketScheduleUpdate } from "../ticket-schedule/help-assist-ticket-appointment-schedule-update.model";
import { BaseHelpAssistTicket } from "./base-help-assist-ticket.model";
import { HelpAssistTicket } from "./help-assist-ticket.model";

export type HelpAssistTicketUpdateArgs =
  ClassProperties<HelpAssistTicketUpdate>;

const api = getApiDetailsDecorator<Api.HelpAssistTicket>();

export class HelpAssistTicketUpdate extends BaseHelpAssistTicket {
  public constructor({ base, schedule, ...args }: HelpAssistTicketUpdateArgs) {
    super(args);

    this.base = base;

    this.events = args.events;
    this.orders = args.orders;

    this.schedule = schedule;
    this.version = base?.version;

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, base, args);
  }

  public readonly events: readonly TicketEvent[] | null;
  @api() public readonly orders: string | null;

  public readonly schedule: HelpAssistTicketScheduleUpdate | null;
  public readonly version?: string;

  private readonly filterUpdatedProperties: FilterPartial<ApiHelpAssistTicketUpdate>;
  public readonly base: HelpAssistTicket | null;

  public serialize(): Partial<
    ApiHelpAssistTicketUpdate & {
      appointmentSchedule?: Partial<Api.HelpAssistTicketScheduleUpdate> | null;
    }
  > {
    const filterProperties = this.filterUpdatedProperties({
      emailAddress: this.emailAddress,
      events:
        this.events?.map((event) => ({
          comment: event.comment,
          ticketId: this.base?.id ?? null,
          type: event.type,
        })) ?? [],
      orders: this.orders,
      phoneNumber: this.phoneNumber || null,
      siteId: this.site.id,
      type: this.type,
    });

    return {
      ...filterProperties,
      appointmentSchedule: this.schedule?.serialize() ?? null,
    };
  }
}

type ApiHelpAssistTicketUpdate = ExpandedODataModel<
  FilterODataModel<
    Api.HelpAssistTicket,
    "siteId" | "emailAddress" | "phoneNumber" | "type" | "orders"
  >,
  { events: "comment" | "ticketId" | "type" }
>;

interface TicketEvent {
  readonly comment: string | null;
  readonly type: HelpAssistEventType;
}
