import { DateTime } from "luxon";
import {
  createGlobalResourceUrl,
  getApiDetailsDecorator,
  ODataModel,
  ODataResourceModel,
  parseDateTime,
  UUID,
} from "src/utils";

export const helpAssistUserResource =
  createGlobalResourceUrl("HelpAssistUsers");

type ApiModelList = ODataResourceModel<typeof helpAssistUserResource>;
export interface ApiHelpAssistUser extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiHelpAssistUser>();

export class HelpAssistUser {
  private constructor(args: ClassProperties<HelpAssistUser>) {
    this.id = args.id;
    this.userId = args.userId;
    this.emailAddress = args.emailAddress;
    this.userDisplayName = args.userDisplayName;
    this.carrierId = args.carrierId;
    this.createdOn = args.createdOn;
    this.partnerKey = args.partnerKey;
    this.role = args.role;
  }

  @api() public readonly id: number;
  @api() public readonly carrierId: number | null;
  @api() public readonly userId: UUID;
  @api() public readonly emailAddress: string | null;
  @api() public readonly userDisplayName: string;
  @api() public readonly createdOn: DateTime;
  @api() public readonly partnerKey: string;
  @api() public readonly role: string;

  public static deserialize(apiModel: ApiHelpAssistUser): HelpAssistUser {
    return new HelpAssistUser({
      carrierId: apiModel.carrierId ?? null,
      createdOn: parseDateTime(apiModel.createdOn),
      emailAddress: apiModel.emailAddress ?? null,
      id: apiModel.id,
      partnerKey: apiModel.partnerKey,
      role: apiModel.role,
      userDisplayName: apiModel.userDisplayName,
      userId: new UUID(apiModel.userId),
    });
  }

  public static deserializeList({
    value,
  }: {
    value: ApiHelpAssistUser[];
  }): readonly HelpAssistUser[] {
    return value.map((ticket) => HelpAssistUser.deserialize(ticket));
  }
}
