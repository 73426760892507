import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Vendor>();

export class HelpAssistTicketAppointmentDraftOrderVendor {
  private constructor(
    args: Omit<
      ClassProperties<HelpAssistTicketAppointmentDraftOrderVendor>,
      "displayName"
    >,
  ) {
    this.id = args.id;
    this.name = args.name;
    this.number = args.number;

    // Computed Properties
    this.displayName = `${this.name} (${this.number})`;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;
  @api({ key: "vendorNumber" }) public readonly number: string;

  // Computed Properties
  public readonly displayName: string;

  public static deserialize(
    apiModel: Pick<Api.Vendor, "id" | "name" | "vendorNumber">,
  ): HelpAssistTicketAppointmentDraftOrderVendor {
    return new HelpAssistTicketAppointmentDraftOrderVendor({
      id: apiModel.id,
      name: apiModel.name,
      number: apiModel.vendorNumber,
    });
  }
}
