import {
  createGlobalResourceUrl,
  getApiDetailsDecorator,
  getEnumMember,
  ODataModel,
  ODataResourceModel,
} from "src/utils";

export enum AppointmentStatusCode {
  Unknown = "**UNKNOWN**",
  Open = "OP",
  GateIn = "GI",
  GateOut = "GO",
  Cancelled = "CA",
  OnComplex = "OC",
  GateOutDoorOccupied = "DO",
  GateOutOffComplex = "DC",
  OffComplex = "XC",
}

export const appointmentStatusesGlobalResource = createGlobalResourceUrl(
  "appointmentStatuses",
);

type ApiModelList = ODataResourceModel<
  typeof appointmentStatusesGlobalResource
>;

export interface ApiAppointmentStatus extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiAppointmentStatus>();

export abstract class BaseAppointmentStatus {
  protected constructor(args: ClassProperties<AppointmentStatus>) {
    this.id = args.id;
    this.code = args.code;
    this.name = args.name;
  }

  @api() public readonly id: number;
  @api() public readonly code: AppointmentStatusCode;
  @api({ key: "name" }) public readonly name: string;
}

export class AppointmentStatus extends BaseAppointmentStatus {
  protected constructor(args: ClassProperties<AppointmentStatus>) {
    super(args);
  }

  public static unknown = new AppointmentStatus({
    code: AppointmentStatusCode.Unknown,
    id: NaN,
    name: "Unknown Status",
  });

  public static deserialize(data: ApiAppointmentStatus): AppointmentStatus {
    return new AppointmentStatus(deserializeApiAppointmentStatus(data));
  }

  public static deserializeList({
    value,
  }: ApiModelList): readonly AppointmentStatus[] {
    return value.map((x) => AppointmentStatus.deserialize(x));
  }
}

export function deserializeApiAppointmentStatus(
  data: ApiAppointmentStatus,
): AppointmentStatus {
  return {
    code: getEnumMember(
      AppointmentStatusCode,
      data.code,
      AppointmentStatusCode.Unknown,
    ),
    id: data.id,
    name: data.name,
  };
}
