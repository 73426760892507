import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { Day } from "src/app/core/day.model";
import { FormComponent } from "src/app/core/form.component";
import { SitesService } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import {
  emailValidator,
  minuteStepValidator,
  phoneNumberValidator,
  pluckDistinctUntilChanged,
} from "src/utils";
import {
  HelpAssistEventType,
  HelpAssistTicketType,
} from "../help-assist-enums";
import { HelpAssistService } from "../help-assist.service";
import { HelpAssistTicketAppointmentDraftUpdate } from "../models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { HelpAssistTicketScheduleUpdate } from "../models/ticket-schedule/help-assist-ticket-appointment-schedule-update.model";
import { HelpAssistTicketReference } from "../models/ticket/base-help-assist-ticket.model";
import { HelpAssistTicketUpdate } from "../models/ticket/help-assist-ticket-update.model";

interface FormValue {
  additionalComments: string;
  idealAppointmentDate: Day;
  idealStartTime: TimeOfDay;
  emailAddress: string;
  phoneNumber: string;
}

@UntilDestroy()
@Component({
  selector: "mr-scheduling-ticket-form",
  templateUrl: "./scheduling-ticket-form.component.html",
  styleUrls: ["./scheduling-ticket-form.component.scss"],
})
export class HelpAssistSchedulingTicketFormComponent
  extends FormComponent<FormValue>
  implements OnInit
{
  public constructor(
    formBuilder: FormBuilder,
    private readonly sites: SitesService,
    private readonly helpAssist: HelpAssistService,
    user: UserService,
  ) {
    super(formBuilder, {
      additionalComments: [null, Validators.maxLength(1000)],
      emailAddress: [
        user.details.emailAddress,
        [Validators.required, emailValidator],
      ],
      idealAppointmentDate: [null],
      idealStartTime: [null],
      phoneNumber: [null, phoneNumberValidator],
    });
  }

  @Input()
  public appointment: HelpAssistTicketAppointmentDraftUpdate | null = null;

  @Input() public appointmentId: AppointmentReference["id"] | null = null;

  @Output() public readonly submitSuccess =
    new EventEmitter<HelpAssistTicketReference>();
  @Output() public readonly submitError = new EventEmitter<unknown>();

  public readonly selectedSiteChanges = this.sites.selectedChanges;

  public readonly idealStartTimeMinuteStepChanges =
    this.selectedSiteChanges.pipe(
      pluckDistinctUntilChanged("appointmentInterval"),
    );

  public readonly minimumDayChanges = this.selectedSiteChanges.pipe(
    map((site) => Day.getTodayIn(site.timeZone)),
  );

  public ngOnInit(): void {
    this.reconfigure("idealAppointmentDate", {
      value: this.appointment?.idealAppointmentDate,
    });

    this.idealStartTimeMinuteStepChanges
      .pipe(untilDestroyed(this))
      .subscribe((step) => {
        this.reconfigure("idealStartTime", {
          validators: minuteStepValidator(step),
        });
      });
  }

  private async createRequest(
    formValue: FormValue,
  ): Promise<HelpAssistTicketUpdate> {
    const site = await firstValueFrom(this.sites.selectedChanges);

    return new HelpAssistTicketUpdate({
      schedule: new HelpAssistTicketScheduleUpdate({
        appointmentId: this.appointmentId,
        site: this.appointment?.site ?? site,
        ticketAppointment: this.appointment
          ? new HelpAssistTicketAppointmentDraftUpdate({
              ...this.appointment,
              idealAppointmentDate: formValue.idealAppointmentDate,
              idealStartTime: formValue.idealStartTime,
            })
          : null,
      }),
      base: null,
      emailAddress: formValue.emailAddress,
      events: formValue.additionalComments
        ? [
            {
              comment: formValue.additionalComments,
              type: HelpAssistEventType.Commented,
            },
          ]
        : null,
      orders: null,
      phoneNumber: formValue.phoneNumber,
      site: this.appointment?.site ?? site,
      type: HelpAssistTicketType.Scheduling,
    });
  }

  public async submit(): Promise<void> {
    try {
      const successResponse = await this.runSubmit(async (formValue) => {
        const request = await this.createRequest(formValue);

        return this.helpAssist.update(request);
      });

      if (successResponse) {
        this.submitSuccess.emit(successResponse.value);
      }
    } catch (error) {
      this.submitError.emit(error);
    }
  }
}
