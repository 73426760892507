import { DateTime, Duration, DurationUnit } from "luxon";
import { Site } from "src/app/core/sites";
import { isExistent } from "../miscellaneous";

export function parseDateTime(
  value: string,
  site?: Pick<Site, "timeZone">,
): DateTime;
export function parseDateTime(
  value: unknown,
  site?: Pick<Site, "timeZone">,
): DateTime | null;
export function parseDateTime(
  value: unknown,
  site?: Pick<Site, "timeZone">,
): DateTime | null {
  if (!value || typeof value !== "string") {
    return null;
  }
  return DateTime.fromISO(value, { zone: site?.timeZone });
}

export function formatDateTime(value: DateTime): string;
export function formatDateTime(value: DateTime | null): string | null;
export function formatDateTime(value: DateTime | null): string | null {
  if (!value) {
    return null;
  }
  return value.toUTC().toISO();
}

export function parseDuration(value: number, unit: DurationUnit): Duration;
export function parseDuration(
  value: number | null | undefined,
  unit: DurationUnit,
): Duration | null;
export function parseDuration(
  value: number | null | undefined,
  unit: DurationUnit,
): Duration | null {
  return isExistent(value) ? Duration.fromObject({ [unit]: value }) : null;
}
