import { Injectable } from "@angular/core";
import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/es6/BooleanFromString";
import { NumberFromString } from "io-ts-types/es6/NumberFromString";
import { fromPairs, isEqual, merge } from "lodash-es";
import { firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs/operators";
import { Dock } from "src/app/partner/settings/docks/dock.model";
import { environment } from "src/environments";
import {
  ColumnSort,
  createGlobalResourceUrl,
  dayFromStringCodec,
  decodeOrElseNull,
  isExistent,
  jsonFromStringCodec,
  maybe,
  MutexQueue,
  pluckDistinctUntilChanged,
  UUID,
  uuidStringCodec,
} from "src/utils";
import { Day } from "./day.model";
import { RequestService } from "./request.service";
import {
  SettingsTableColumn,
  settingsTableColumnsByKeyCodec,
  settingsTableSortCodec,
} from "./settings-table-columns";

const userPreferencesResource = createGlobalResourceUrl("userPreferences");

@Injectable({ providedIn: "root" })
export class UserPreferencesService {
  public constructor(private readonly request: RequestService) {}

  private readonly preferencesUpdateQueue = new MutexQueue();

  private readonly sessionCache = new ReplaySubject<UserPreferences>(1);

  public readonly helpAssistMenuContextChanges = this.sessionCache.pipe(
    map((settings) =>
      settings.helpAssistMenuContext?.partner
        ? ({
            partnerKey: settings.helpAssistMenuContext.partner.key,
            siteId: settings.helpAssistMenuContext.site?.id ?? null,
          } as const)
        : null,
    ),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
  );

  public readonly partnerKeyChanges = this.sessionCache.pipe(
    map((settings) => settings.partnerKey ?? null),
    distinctUntilChanged((a, b) => a === b || (a && b && a.isSame(b)) || false),
    shareReplay(1),
  );

  public readonly siteIdChanges = this.sessionCache.pipe(
    map((settings) => settings.siteId ?? null),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly tableColumnsChanges = this.sessionCache.pipe(
    map((settings) => settings.tableColumns ?? {}),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  private readonly tableSortChanges = this.sessionCache.pipe(
    map((settings) => settings.tableSort ?? {}),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  public readonly isBuildInfoVisibleChanges = this.sessionCache.pipe(
    map((settings) => settings.isBuildInfoVisible ?? false),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public readonly pageFiltersChanges = this.sessionCache.pipe(
    map((settings) => settings.pageFilters ?? {}),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  public readonly scheduleDayChanges = this.sessionCache.pipe(
    map((settings) => settings.scheduleDay || null),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  public readonly scheduleDocksFilterChanges = this.sessionCache.pipe(
    map((settings): readonly number[] => settings.scheduleDocksFilter ?? []),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  public readonly searchCollapsedChanges: Observable<
    Required<SearchCollapsed>
  > = this.sessionCache.pipe(
    map((settings) =>
      merge(
        {
          appointments: false,
          carriers: false,
          orders: false,
          sites: false,
          vendors: false,
        },
        settings.searchCollapsed,
      ),
    ),
    // Need to use an arrow function to not turn the result type into `any`.
    distinctUntilChanged((a, b) => isEqual(a, b)),
    shareReplay(1),
  );

  public async load(): Promise<void> {
    const preferences = await firstValueFrom(
      this.request.getAll(userPreferencesResource).pipe(
        filter(isExistent),
        map((data) => data.value.map(({ key, value }) => [key, value])),
        map(fromPairs),
        map(parseUserPreferences),
      ),
    );

    this.sessionCache.next(preferences);
  }

  public getTableColumnsChanges(
    tableKey: string,
  ): Observable<readonly SettingsTableColumn[] | null> {
    return this.tableColumnsChanges.pipe(
      pluckDistinctUntilChanged(tableKey),
      map((columns) => columns?.filter(isExistent)),
      map((columns) => (columns?.length ? columns : null)),
    );
  }

  public getTableSortChanges(
    tableKey: string,
  ): Observable<ColumnSort | undefined> {
    return this.tableSortChanges.pipe(
      pluckDistinctUntilChanged(tableKey),
      map((sort) => sort ?? undefined),
    );
  }

  public getPageFilterChanges(
    pageKey: string,
    filterKey: string,
  ): Observable<string | null> {
    return this.pageFiltersChanges.pipe(
      pluckDistinctUntilChanged(pageKey),
      map((filters) => filters ?? {}),
      pluckDistinctUntilChanged(filterKey),
      map((value) => value || null),
    );
  }

  public async setSessionScheduleDay(value: Day): Promise<void> {
    await this.update("scheduleDay", () => value, { sessionOnly: true });
  }

  public async setScheduleDocksFilter(value: readonly Dock[]): Promise<void> {
    await this.update("scheduleDocksFilter", () =>
      value.length > 0 ? value.map((dock) => dock.id) : undefined,
    );
  }

  public setHelpAssistMenuContext(
    value: {
      readonly partnerKey: UUID;
      readonly siteId: number | null;
    } | null,
    { sessionOnly = false } = {},
  ): Promise<void> {
    return this.update(
      "helpAssistMenuContext",
      () =>
        value !== null
          ? {
              partner: { key: value.partnerKey },
              site: value.siteId !== null ? { id: value.siteId } : undefined,
            }
          : undefined,
      { sessionOnly },
    );
  }

  public async setPartnerKey(
    value: UUID | null,
    { sessionOnly = false } = {},
  ): Promise<void> {
    await this.update("partnerKey", () => value ?? undefined, { sessionOnly });
  }

  public async setSiteId(
    value: number | null,
    { sessionOnly = false } = {},
  ): Promise<void> {
    await this.update("siteId", () => value ?? undefined, { sessionOnly });
  }

  public async setTableColumns(
    tableKey: string,
    value: readonly SettingsTableColumn[],
  ): Promise<void> {
    await this.update("tableColumns", (tableColumns) => ({
      ...tableColumns,
      [tableKey]: value,
    }));
  }

  public async setTableSort(
    tableKey: string,
    value: ColumnSort | undefined,
  ): Promise<void> {
    await this.update("tableSort", (tableSort) => ({
      ...tableSort,
      [tableKey]: value,
    }));
  }

  public async setIsBuildInfoVisible(value: boolean): Promise<void> {
    await this.update("isBuildInfoVisible", () => value, {
      sessionOnly: environment.type === "prod",
    });
  }

  public async setSearchCollapsed(
    section: keyof SearchCollapsed,
    isCollapsed: boolean,
  ): Promise<void> {
    await this.update("searchCollapsed", (searchCollapsed) => ({
      ...searchCollapsed,
      [section]: isCollapsed || undefined,
    }));
  }

  public async setPageFilters(
    pageKey: string,
    filterKey: string,
    filterValue: string | null,
  ): Promise<void> {
    await this.update("pageFilters", (pageFilters) =>
      filterOutEmpty({
        ...pageFilters,
        [pageKey]: filterOutEmpty({
          ...pageFilters?.[pageKey],
          [filterKey]: filterValue || undefined,
        }),
      }),
    );
  }

  public getPageFilterController<T>(
    pageKey: string,
    filterKey: string,
    codec: t.Type<T, string, string>,
  ): Controller<T> {
    const parser = decodeOrElseNull(codec);
    return {
      changes: this.getPageFilterChanges(pageKey, filterKey).pipe(
        map((value) => (value ? parser(value) : null)),
      ),
      set: (value: T | null) =>
        this.setPageFilters(
          pageKey,
          filterKey,
          value === null ? null : codec.encode(value),
        ),
    };
  }

  private async update<K extends keyof UserPreferences>(
    key: K,
    update: (original: UserPreferences[K]) => UserPreferences[K],
    { sessionOnly = false } = {},
  ): Promise<void> {
    await this.preferencesUpdateQueue.add(async () => {
      const currentSession = await firstValueFrom(this.sessionCache);
      const newValue = update(currentSession[key]);

      const newSession: UserPreferences = {
        ...currentSession,
        [key]: newValue || update(currentSession[key]),
      };
      this.sessionCache.next(newSession);

      if (!sessionOnly) {
        await this.put(key, newValue);
      }

      // Make sure the new preferences has emitted before releasing the mutex.
      await firstValueFrom(
        this.sessionCache.pipe(
          filter((preferences) => preferences === newSession),
        ),
      );
    });
  }

  private async put<K extends keyof UserPreferences>(
    key: K,
    value: UserPreferences[K],
  ): Promise<void> {
    const serializedValue = serializeUserPreferenceValue(key, value);

    if (serializedValue !== null) {
      await firstValueFrom(
        this.request.put(userPreferencesResource.appendId(key), {
          serialize: () => ({ value: serializedValue }),
        }),
      );
    } else {
      await this.delete(key);
    }
  }

  private async delete<K extends keyof UserPreferences>(key: K): Promise<void> {
    await firstValueFrom(
      this.request.delete(userPreferencesResource.appendId(key)),
    );
  }
}

const searchCollapsedCodec = t.partial(
  {
    appointments: t.boolean,
    carriers: t.boolean,
    orders: t.boolean,
    sites: t.boolean,
    vendors: t.boolean,
  },
  "SearchCollapsed",
);
type SearchCollapsed = t.TypeOf<typeof searchCollapsedCodec>;
export type SearchCollapsedSection = keyof SearchCollapsed;

const pageFiltersByKeyCodec = t.record(
  t.string,
  maybe(t.record(t.string, maybe(t.string))),
  "PageFiltersByKey",
);

const helpAssistMenuContextCodec = t.type(
  {
    partner: maybe(t.type({ key: uuidStringCodec })),
    site: maybe(t.type({ id: t.number })),
  },
  "HelpAssistMenuContext",
);

const userPreferencesCodec = t.partial(
  {
    helpAssistMenuContext: maybe(
      jsonFromStringCodec.pipe(helpAssistMenuContextCodec),
    ),
    isBuildInfoVisible: maybe(BooleanFromString),
    pageFilters: maybe(jsonFromStringCodec.pipe(pageFiltersByKeyCodec)),
    partnerKey: maybe(uuidStringCodec),
    scheduleDay: maybe(dayFromStringCodec),
    scheduleDocksFilter: maybe(jsonFromStringCodec.pipe(t.array(t.number))),
    searchCollapsed: maybe(jsonFromStringCodec.pipe(searchCollapsedCodec)),
    siteId: maybe(NumberFromString),
    tableColumns: maybe(
      jsonFromStringCodec.pipe(settingsTableColumnsByKeyCodec),
    ),
    tableSort: maybe(jsonFromStringCodec.pipe(settingsTableSortCodec)),
  },
  "UserPreferences",
);
interface UserPreferences extends t.TypeOf<typeof userPreferencesCodec> {}

const userPreferencesDecoder = decodeOrElseNull(userPreferencesCodec);
function parseUserPreferences(value: unknown): UserPreferences {
  return userPreferencesDecoder(value) ?? {};
}

function serializeUserPreferenceValue<K extends keyof UserPreferences>(
  key: K,
  value: UserPreferences[K],
): string | null {
  const stringValue: string | undefined = userPreferencesCodec.encode({
    [key]: value,
  })[key];
  return stringValue || null;
}

interface Controller<T> {
  readonly changes: Observable<T | null>;
  set(value: T | null): Promise<void>;
}

/** Remove `undefined` properties and return `undefined` if none are left. */
function filterOutEmpty<U>(
  object: Readonly<Record<string, U | undefined>>,
): Readonly<Record<string, U>> | undefined {
  const cleanedObject: Record<string, U> = {};
  for (const [key, value] of Object.entries(object)) {
    if (value !== undefined) {
      cleanedObject[key] = value;
    }
  }
  return Object.entries(cleanedObject).length > 0 ? cleanedObject : undefined;
}
