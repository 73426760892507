import { isRight } from "fp-ts/es6/Either";
import * as t from "io-ts";
import { isNil, toNumber } from "lodash-es";
import Delta from "quill-delta";

export function parseDelta(deltaJson: string | null | undefined): Delta | null {
  if (!deltaJson) {
    return null;
  }
  const delta = deltaCodec.decode(JSON.parse(deltaJson));
  return isRight(delta) ? new Delta(delta.right) : null;
}
// This is not a complete definition but it suffices for how we use Deltas now.
const deltaCodec = t.type({
  ops: t.array(t.partial({ attributes: t.UnknownRecord, insert: t.string })),
});

export function parseInteger(value: string | null): number | null {
  if (isNil(value)) {
    return null;
  }
  const number = parseInt(value, 10);
  return Number.isNaN(number) ? null : number;
}

export function formatInteger(value: number | null): string | null {
  if (isNil(value)) {
    return null;
  }
  return Number.isNaN(value) ? null : value.toString();
}

export type BooleanAttribute = boolean | string | null | undefined;
export function parseBooleanAttribute(value: BooleanAttribute): boolean {
  // Handle the bare attribute case, e.g. <foo disabled>
  // by checking if the value is a string (will be "").
  return (typeof value === "boolean" && value) || typeof value === "string";
}

export function parseNumber(value: unknown): number | null {
  if (value === null) {
    return null;
  }
  // Don't convert blank strings to 0 like `toNumber` does.
  if (typeof value === "string" && !value.trim()) {
    return null;
  }
  const number = toNumber(value);
  return Number.isNaN(number) ? null : number;
}
