import { isExistent } from "src/utils/miscellaneous";

export interface VersionInfo {
  build: string | null;
  dirty: string[] | null;
  hash: string;
  version: string;
}

export function getBuildIdentifier({
  build,
  dirty,
  environment,
  hash,
  version,
}: VersionInfo & { environment: string }): string {
  const buildDetails = [
    build || "0",
    hash.substr(0, 7),
    dirty ? "dirty" : null,
    environment,
  ];
  return `${version}+${buildDetails.filter(isExistent).join(".")}`;
}
