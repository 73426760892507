<section #section (scroll)="onScroll()">
  <div class="header-content">
    <mr-go-back-link *ngIf="backLink" [link]="backLink">
      {{ backLinkLabel || "Go Back" }}
    </mr-go-back-link>

    <ng-content select="[pagePreHeader]"></ng-content>

    <header [class.with-subheader]="subHeader || subHeaderTemplate">
      <div class="page-title-wrapper">
        <p class="super-header" *ngIf="superHeader">
          {{ superHeader }}
        </p>

        <div class="header">
          <h1>
            <ng-container *ngIf="!headerTemplate; else headerTemplate!">
              {{ header }}
            </ng-container>
          </h1>

          <div class="header-suffix">
            <ng-content select="[pageHeaderSuffix]"></ng-content>
          </div>
        </div>

        <h2 *ngIf="subHeader || subHeaderTemplate">
          <ng-container *ngIf="!subHeaderTemplate; else subHeaderTemplate!">
            {{ subHeader }}
          </ng-container>
        </h2>
      </div>

      <div class="page-header-actions">
        <ng-content select="[pageHeaderAction]"></ng-content>
      </div>
    </header>

    <div class="header-details" *ngIf="headerDetails">
      <ng-container *ngTemplateOutlet="headerDetails"></ng-container>
    </div>

    <ng-content select="mr-tab-list"></ng-content>

    <hr *ngIf="!hideSeparator" />
  </div>

  <ng-content></ng-content>
</section>

<ng-content select="mr-sidebar"></ng-content>

<ng-container
  *mrIfHasPermission="
    Permission.CreateHelpAssistTicket;
    operator: 'and';
    condition: helpButtonTemplate;
    else: defaultHelpButtonTemplate
  "
  [ngTemplateOutlet]="helpButtonTemplate"
></ng-container>

<ng-template #defaultHelpButtonTemplate>
  <mr-help-button></mr-help-button>
</ng-template>

<mr-environment-visual-aid></mr-environment-visual-aid>
