import {
  createGlobalResourceUrl,
  ODataModel,
  ODataResourceModel,
} from "src/utils";

export const carrierTypesResource = createGlobalResourceUrl("carrierTypes");

type ApiModelList = ODataResourceModel<typeof carrierTypesResource>;
export interface ApiCarrierType extends ODataModel<ApiModelList> {}

export class CarrierType {
  private constructor(args: ClassProperties<CarrierType>) {
    this.id = args.id;
    this.name = args.name;
  }

  public readonly id: number;
  public readonly name: string;

  public static deserialize(data: ApiCarrierType): CarrierType {
    return new CarrierType({ id: data.id, name: data.name });
  }

  public static deserializeList({
    value,
  }: ApiModelList): readonly CarrierType[] {
    return value.map((x) => CarrierType.deserialize(x));
  }

  /**
   * Whether the carrier type is a Less Than Truck Load (LTL) type.
   */
  public isLtl(): boolean {
    return this.name === "LTL";
  }
}
