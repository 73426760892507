import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import {
  AppointmentReference,
  getAppointmentRouteUrl,
} from "src/app/partner/appointments/base-appointment.model";
import { getEnumMember, parseDateTime } from "src/utils";
import { HelpAssistTicketStatus } from "../help-assist-enums";
import { AssociatedTicketComment } from "./associated-ticket-comment.model";
import {
  getHelpAssistTicketRouteUrl,
  HelpAssistTicketReference,
} from "./ticket/base-help-assist-ticket.model";
import { HelpAssistTicket } from "./ticket/help-assist-ticket.model";

export class AssociatedTicket implements HelpAssistTicketReference {
  private constructor(args: ClassProperties<AssociatedTicket>) {
    this.allPurchaseOrders = args.allPurchaseOrders;
    this.appointmentConfirmationNumber = args.appointmentConfirmationNumber;
    this.appointmentId = args.appointmentId;
    this.appointmentStatus = args.appointmentStatus;
    this.associatedTicket = args.associatedTicket;
    this.comments = args.comments;
    this.id = args.id;
    this.idealAppointmentDate = args.idealAppointmentDate;
    this.idealStartTime = args.idealStartTime;
    this.purchaseOrderMatches = args.purchaseOrderMatches;
    this.site = args.site;
    this.status = args.status;
  }

  public readonly allPurchaseOrders: string[];
  public readonly appointmentConfirmationNumber: string | null;
  public readonly appointmentId: AppointmentReference["id"] | null;
  public readonly appointmentStatus: string | null;
  /**
   * The other ticket that this ticket is associated with and was used to look
   * up this ticket.
   */
  public readonly associatedTicket: HelpAssistTicket;
  public readonly comments: AssociatedTicketComment[];
  public readonly id: HelpAssistTicketReference["id"];
  public readonly idealAppointmentDate: DateTime | null;
  public readonly idealStartTime: TimeOfDay | null;
  public readonly purchaseOrderMatches: string[];
  public readonly site: Site;
  public readonly status: HelpAssistTicketStatus;

  public static deserialize(
    data: Api.AssociatedTicket,
    { associatedTicket }: DeserializeArguments,
  ): AssociatedTicket {
    return new AssociatedTicket({
      allPurchaseOrders: data.allPurchaseOrders,
      appointmentConfirmationNumber: data.appointmentConfirmationNumber ?? null,
      appointmentId: data.appointmentId ?? null,
      appointmentStatus: data.appointmentStatus ?? null,
      associatedTicket,
      comments: AssociatedTicketComment.deserializeList(data.comments),
      id: data.ticketId,
      idealAppointmentDate: data.idealAppointmentDate
        ? parseDateTime(data.idealAppointmentDate)
        : null,
      idealStartTime: data.idealStartTime
        ? TimeOfDay.deserialize(data.idealStartTime)
        : null,
      purchaseOrderMatches: data.purchaseOrderMatches,
      site: associatedTicket.site,
      status: getEnumMember(HelpAssistTicketStatus, data.status),
    });
  }

  public static deserializeList(
    data: readonly Api.AssociatedTicket[],
    args: DeserializeArguments,
  ): readonly AssociatedTicket[] {
    return data.map((ticket) => AssociatedTicket.deserialize(ticket, args));
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getHelpAssistTicketRouteUrl(this, ...childPaths);
  }

  public getAppointmentRouteUrl(...childPaths: string[]): string | null {
    return this.appointmentId !== null
      ? getAppointmentRouteUrl(
          { id: this.appointmentId, site: this.associatedTicket.site },
          ...childPaths,
        )
      : null;
  }
}

interface DeserializeArguments {
  readonly associatedTicket: HelpAssistTicket;
}
