import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import * as t from "io-ts";
import { Duration, IANAZone } from "luxon";
import { ManagedType, SiteAvailabilityStatus } from "src/app/core/constants";
import { HelpAssistTicketType } from "src/app/partner/help-assist/help-assist-enums";
import {
  apiPropertyClass,
  decodeOrElseNull,
  getApiDetailsDecorator,
  isExistent,
  jsonFromStringCodec,
  maybe,
} from "src/utils";

const api = getApiDetailsDecorator<Api.SiteUpdate>();

export enum ScheduleDoorGrouping {
  DockAndDoorGroup = "Dock",
  None = "None",
}

export enum UnitType {
  Case = "Cases",
  Pallet = "Pallets",
}

export enum DockCapacityType {
  Hourly = "Hourly",
  Daily = "Daily",
}

export enum AppointmentLabelType {
  AppointmentNumber = "AppointmentNumber",
  CarrierOfRecord = "CarrierOfRecord",
  LoadNumber = "LoadNumber",
  PrimaryVendor = "PrimaryVendor",
  PurchaseOrderNumber = "PurchaseOrderNumber",
  ManagedTypes = "ManagedType",
}

export enum AppointmentStart {
  AppointmentTime = "AppointmentTime",
  GateIn = "GatedIn",
  OnComplex = "OnComplex",
  UnloadStart = "UnloadStart",
}

export enum AppointmentEnd {
  GateOut = "GatedOut",
  OffComplex = "OffComplex",
  UnloadEnd = "UnloadEnd",
}

export enum CarrierAppointmentFlowType {
  CarrierDependent = "CarrierDependent",
  LTL = "LTL",
  NonLTL = "NonLTL",
}

export enum FieldValidation {
  Disabled = "disabled",
  Optional = "optional",
  Required = "required",
}

@apiPropertyClass
export class CustomAppointmentDurationCalculationProperty {
  public constructor(
    args: ClassProperties<CustomAppointmentDurationCalculationProperty>,
  ) {
    this.buffer = args.buffer;
    this.endPoint = args.endPoint;
    this.isEnabled = args.isEnabled;
    this.startPoint = args.startPoint;
  }

  @api({ key: "customMPUCalcBuffer" }) public readonly buffer: Duration;
  @api({ key: "customMPUCalcEnd" })
  public readonly endPoint: AppointmentEnd | null;
  @api({ key: "useCustomMPUForApptDuration" })
  public readonly isEnabled: boolean;
  @api({ key: "customMPUCalcStart" })
  public readonly startPoint: AppointmentStart | null;
}

@apiPropertyClass
export class SiteHelpAssistProperty {
  public constructor(args: ClassProperties<SiteHelpAssistProperty>) {
    this.amendmentRequestedNotificationReceivers =
      args.amendmentRequestedNotificationReceivers;
    this.approvalRequestedNotificationReceivers =
      args.approvalRequestedNotificationReceivers;
    this.areAppointmentsAutomaticallyApproved =
      args.areAppointmentsAutomaticallyApproved;
    this.areGeneralTicketsAllowed = args.areGeneralTicketsAllowed;
    this.areSchedulingTicketsAllowed = args.areSchedulingTicketsAllowed;
    this.autoApproveReservationsCutoff = args.autoApproveReservationsCutoff;
    this.isAmendmentRequestedNotificationEnabled =
      args.isAmendmentRequestedNotificationEnabled;
    this.isApprovalRequestedNotificationEnabled =
      args.isApprovalRequestedNotificationEnabled;
    this.isTicketApprovedNotificationEnabled =
      args.isTicketApprovedNotificationEnabled;
    this.isTicketCreatedNotificationEnabled =
      args.isTicketCreatedNotificationEnabled;
    this.isTicketDeclinedNotificationEnabled =
      args.isTicketDeclinedNotificationEnabled;
    this.isTicketModifiedNotificationEnabled =
      args.isTicketModifiedNotificationEnabled;
    this.ticketApprovedNotificationReceivers =
      args.ticketApprovedNotificationReceivers;
    this.ticketCreatedNotificationReceivers =
      args.ticketCreatedNotificationReceivers;
    this.ticketDeclinedNotificationReceivers =
      args.ticketDeclinedNotificationReceivers;
    this.ticketModifiedNotificationReceivers =
      args.ticketModifiedNotificationReceivers;
    this.ticketResolutionTimeDuration = args.ticketResolutionTimeDuration;
  }

  public static readonly default: ClassProperties<SiteHelpAssistProperty> = {
    amendmentRequestedNotificationReceivers: null,
    approvalRequestedNotificationReceivers: null,
    areAppointmentsAutomaticallyApproved: false,
    areGeneralTicketsAllowed: false,
    areSchedulingTicketsAllowed: true,
    autoApproveReservationsCutoff: Duration.fromObject({ hours: 48 }),
    isAmendmentRequestedNotificationEnabled: false,
    isApprovalRequestedNotificationEnabled: false,
    isTicketApprovedNotificationEnabled: false,
    isTicketCreatedNotificationEnabled: false,
    isTicketDeclinedNotificationEnabled: false,
    isTicketModifiedNotificationEnabled: false,
    ticketApprovedNotificationReceivers: null,
    ticketCreatedNotificationReceivers: null,
    ticketDeclinedNotificationReceivers: null,
    ticketModifiedNotificationReceivers: null,
    ticketResolutionTimeDuration: Duration.fromObject({ hours: 24 }),
  };

  @api({ key: "helpAssistNotifyOnAmendmentRequestedReceivers" })
  public readonly amendmentRequestedNotificationReceivers: string | null;
  @api({ key: "helpAssistNotifyOnApprovalRequestedReceivers" })
  public readonly approvalRequestedNotificationReceivers: string | null;
  @api({ key: "automaticAppointmentApproval" })
  public readonly areAppointmentsAutomaticallyApproved: boolean;
  @api({ key: "helpAssistGeneralHelpTicketsAllowed" })
  public readonly areGeneralTicketsAllowed: boolean;
  @api({ key: "helpAssistTicketsSchedulingAllowed" })
  public readonly areSchedulingTicketsAllowed: boolean;
  @api() public readonly autoApproveReservationsCutoff: Duration;
  @api({ key: "helpAssistNotifyOnAmendmentRequested" })
  public readonly isAmendmentRequestedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnApprovalRequested" })
  public readonly isApprovalRequestedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnTicketApproved" })
  public readonly isTicketApprovedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnTicketCreated" })
  public readonly isTicketCreatedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnTicketDeclined" })
  public readonly isTicketDeclinedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnTicketModified" })
  public readonly isTicketModifiedNotificationEnabled: boolean;
  @api({ key: "helpAssistNotifyOnTicketApprovedReceivers" })
  public readonly ticketApprovedNotificationReceivers: string | null;
  @api({ key: "helpAssistNotifyOnTicketCreatedReceivers" })
  public readonly ticketCreatedNotificationReceivers: string | null;
  @api({ key: "helpAssistNotifyOnTicketDeclinedReceivers" })
  public readonly ticketDeclinedNotificationReceivers: string | null;
  @api({ key: "helpAssistNotifyOnTicketModifiedReceivers" })
  public readonly ticketModifiedNotificationReceivers: string | null;
  @api({ key: "helpAssistTicketResolutionTime" })
  public readonly ticketResolutionTimeDuration: Duration;
}

export abstract class BaseSite {
  protected constructor(args: ClassProperties<BaseSite>) {
    this.address = args.address;
    this.appointmentAutoCancelLimit = args.appointmentAutoCancelLimit;
    this.appointmentInterval = args.appointmentInterval;
    this.appointmentLabelType = args.appointmentLabelType;
    this.appointmentMessaging = args.appointmentMessaging;
    this.appointmentTimerStart = args.appointmentTimerStart;
    this.areAppointmentOrdersAtDifferentDocksAllowed =
      args.areAppointmentOrdersAtDifferentDocksAllowed;
    this.areBackdatedAppointmentsAllowed = args.areBackdatedAppointmentsAllowed;
    this.areBackdatedPurchaseOrdersAllowed =
      args.areBackdatedPurchaseOrdersAllowed;
    this.areDuplicatePurchaseOrderNumbersAllowed =
      args.areDuplicatePurchaseOrderNumbersAllowed;
    this.autoAppointMaxUnloadDuration = args.autoAppointMaxUnloadDuration;
    this.autoAppointMinUnloadDuration = args.autoAppointMinUnloadDuration;
    this.businessDayOffset = args.businessDayOffset;
    this.canRestrictedUsersViewAppointmentTimestampsAndDoors =
      args.canRestrictedUsersViewAppointmentTimestampsAndDoors;
    this.carrierAppointmentFlow = args.carrierAppointmentFlow;
    this.customAppointmentDurationCalculation =
      args.customAppointmentDurationCalculation;
    this.customAppointmentOrdersDetailsColumns =
      args.customAppointmentOrdersDetailsColumns;
    this.customAppointmentOrdersModifyColumns =
      args.customAppointmentOrdersModifyColumns;
    this.customName = args.customName;
    this.customUnloaderDescription = args.customUnloaderDescription;
    this.defaultDoorGroupId = args.defaultDoorGroupId;
    this.defaultMaximumUnloadDurationPerUnit =
      args.defaultMaximumUnloadDurationPerUnit;
    this.defaultName = args.defaultName;
    this.defaultUnloadDurationPerUnit = args.defaultUnloadDurationPerUnit;
    this.dockCapacityType = args.dockCapacityType;
    this.earlyArrivalDuration = args.earlyArrivalDuration;
    this.earlyScheduleThresholdDuration = args.earlyScheduleThresholdDuration;
    this.gatePassMessage = args.gatePassMessage;
    this.helpAssist = args.helpAssist;
    this.helpAssistTicketCategories = args.helpAssistTicketCategories;
    this.information = args.information;
    this.isAdvancedSchedulingEnabled = args.isAdvancedSchedulingEnabled;
    this.isAisleNumberUsed = args.isAisleNumberUsed;
    this.isAppointmentCancellationAllowed =
      args.isAppointmentCancellationAllowed;
    this.isAppointmentCarrierETAEnabled = args.isAppointmentCarrierETAEnabled;
    this.isAppointmentContactInformationEnabled =
      args.isAppointmentContactInformationEnabled;
    this.isAppointmentIntermodalFlagEnabled =
      args.isAppointmentIntermodalFlagEnabled;
    this.isAppointmentLoadWeightEnabled = args.isAppointmentLoadWeightEnabled;
    this.isAppointmentUnloaderPartnerSelectionRestricted =
      args.isAppointmentUnloaderPartnerSelectionRestricted;
    this.isDriverCdlCaptureRequired = args.isDriverCdlCaptureRequired;
    this.isDriverNameCaptureRequired = args.isDriverNameCaptureRequired;
    this.isDriverPhoneNumberCaptureRequired =
      args.isDriverPhoneNumberCaptureRequired;
    this.isDropLoadDisallowedForTruckLoadManagedType =
      args.isDropLoadDisallowedForTruckLoadManagedType;
    this.isGateOutDoorOccupiedAppointmentStatusEnabled =
      args.isGateOutDoorOccupiedAppointmentStatusEnabled;
    this.isGuardPromptsEnabled = args.isGuardPromptsEnabled;
    this.isLateDueDatePromptRequired = args.isLateDueDatePromptRequired;
    this.isLoadTypeRequired = args.isLoadTypeRequired;
    this.isManualPurchaseOrderCreationAllowed =
      args.isManualPurchaseOrderCreationAllowed;
    this.isOffComplexAppointmentStatusEnabled =
      args.isOffComplexAppointmentStatusEnabled;
    this.isOnComplexAppointmentStatusEnabled =
      args.isOnComplexAppointmentStatusEnabled;
    this.isPastDueDateNotificationLimited =
      args.isPastDueDateNotificationLimited;
    this.isPurchaseOrderEntryDateEnabled = args.isPurchaseOrderEntryDateEnabled;
    this.isPurchaseOrderReuseAllowed = args.isPurchaseOrderReuseAllowed;
    this.isPurchaseOrderUnitCountsCaptureRestricted =
      args.isPurchaseOrderUnitCountsCaptureRestricted;
    this.isSameDayAppointmentFlagRequired =
      args.isSameDayAppointmentFlagRequired;
    this.isTotalWarehousePalletCountOverrideEnabled =
      args.isTotalWarehousePalletCountOverrideEnabled;
    this.isTrailerNumberRequired = args.isTrailerNumberRequired;
    this.isUsingContractManagedType = args.isUsingContractManagedType;
    this.isVendorSameDayReschedulingEnabled =
      args.isVendorSameDayReschedulingEnabled;
    this.isYardManagementSystemEnabled = args.isYardManagementSystemEnabled;
    this.isSearchByLoadNumberEnabled = args.isSearchByLoadNumberEnabled;
    this.lateArrivalDuration = args.lateArrivalDuration;
    this.lateDueDateMessage = args.lateDueDateMessage;
    this.lateScheduleThresholdDuration = args.lateScheduleThresholdDuration;
    this.maximumAppointmentDuration = args.maximumAppointmentDuration;
    this.maximumFutureAppointmentSchedulingLimit =
      args.maximumFutureAppointmentSchedulingLimit;
    this.maximumReservationTimeSlots = args.maximumReservationTimeSlots;
    this.minimumAppointmentDuration = args.minimumAppointmentDuration;
    this.noShowNoCallDuration = args.noShowNoCallDuration;
    this.number = args.number;
    this.pointOfOriginValidation = args.pointOfOriginValidation;
    this.rescheduleMinimumAdvancedDuration =
      args.rescheduleMinimumAdvancedDuration;
    this.scheduleAppointmentHoverDisplayFields =
      args.scheduleAppointmentHoverDisplayFields;
    this.scheduleAppointmentSelectionDisplayFields =
      args.scheduleAppointmentSelectionDisplayFields;
    this.scheduleDoorGrouping = args.scheduleDoorGrouping;
    this.status = args.status;
    this.supportEmail = args.supportEmail;
    this.timeZone = args.timeZone;
    this.trailerTemperatureActualValidation =
      args.trailerTemperatureActualValidation;
    this.trailerTemperatureSetValidation = args.trailerTemperatureSetValidation;
    this.unitCountsValidation = args.unitCountsValidation;
    this.unitType = args.unitType;
    this.weeksAveragedForUnloadTime = args.weeksAveragedForUnloadTime;
  }

  @api() public readonly address: string | null;
  @api({ key: "apptCancelLimit" })
  public readonly appointmentAutoCancelLimit: Duration | null;
  @api() public readonly appointmentInterval: Duration;
  @api({ key: "scheduleLabelApptField" })
  public readonly appointmentLabelType: AppointmentLabelType;
  @api() public readonly appointmentMessaging: string | null;
  @api({ key: "appointmentStartOptions" })
  public readonly appointmentTimerStart: AppointmentStart;
  @api({ key: "allowApptOrdersDiffDock" })
  public readonly areAppointmentOrdersAtDifferentDocksAllowed: boolean;
  @api({ key: "allowBackdatedAppointments" })
  public readonly areBackdatedAppointmentsAllowed: boolean;
  @api({ key: "allowBackdatedPurchaseOrders" })
  public readonly areBackdatedPurchaseOrdersAllowed: boolean;
  @api({ key: "allowDuplicateOrders" })
  public readonly areDuplicatePurchaseOrderNumbersAllowed: boolean;
  @api({ key: "autoAppointMaxUnloadMinutes" })
  public readonly autoAppointMaxUnloadDuration: Duration | null;
  @api({ key: "autoAppointMinUnloadMinutes" })
  public readonly autoAppointMinUnloadDuration: Duration | null;
  @api() public readonly businessDayOffset: Duration;
  @api({ key: "appointmentTimeStampsAndDoorDetailsAreViewable" })
  public readonly canRestrictedUsersViewAppointmentTimestampsAndDoors: boolean;
  @api() public readonly carrierAppointmentFlow: CarrierAppointmentFlowType;
  @api(CustomAppointmentDurationCalculationProperty)
  public readonly customAppointmentDurationCalculation: CustomAppointmentDurationCalculationProperty;
  @api({ key: "apptDetailsOrdersColumnOptions" })
  public readonly customAppointmentOrdersDetailsColumns:
    | readonly AppointmentOrdersColumnKey[]
    | null;
  @api({ key: "apptModifyOrdersColumnOptions" })
  public readonly customAppointmentOrdersModifyColumns:
    | readonly AppointmentOrdersColumnKey[]
    | null;
  @api({ key: "displayName" }) public readonly customName: string | null;
  @api({ key: "guidedFlowUnloaderDescription" })
  public readonly customUnloaderDescription: string | null;
  @api({ key: "defaultDoorGroup" })
  public readonly defaultDoorGroupId: number | null;
  @api({ key: "maxCalcMinutesPerUnit" })
  public readonly defaultMaximumUnloadDurationPerUnit: Duration | null;
  @api({ key: "name" }) public readonly defaultName: string;
  @api({ key: "minutesPerUnit" })
  public readonly defaultUnloadDurationPerUnit: Duration;
  @api() public readonly dockCapacityType: DockCapacityType;
  @api({ key: "earlyArrivalInMinutes" })
  public readonly earlyArrivalDuration: Duration | null;
  @api({ key: "earlyScheduleThreshold" })
  public readonly earlyScheduleThresholdDuration: Duration;
  @api() public readonly gatePassMessage: string | null;
  @api(SiteHelpAssistProperty)
  public readonly helpAssist: ClassProperties<SiteHelpAssistProperty>;
  @api()
  public readonly helpAssistTicketCategories: readonly HelpAssistTicketCategory[];
  @api({ key: "additionalSupportInfo" })
  public readonly information: SiteInformation | null;
  @api({ key: "advancedScheduling" })
  public readonly isAdvancedSchedulingEnabled: boolean;
  @api({ key: "useAisleNumbers" }) public readonly isAisleNumberUsed: boolean;
  @api({ key: "unrestrictedAppointmentActions" })
  public readonly isAppointmentCancellationAllowed: boolean;
  @api({ key: "carrierETA" })
  public readonly isAppointmentCarrierETAEnabled: boolean | null;
  @api({ key: "captureAppointmentContactInformation" })
  public readonly isAppointmentContactInformationEnabled: boolean;
  @api({ key: "captureApptIntermodal" })
  public readonly isAppointmentIntermodalFlagEnabled: boolean;
  @api({ key: "captureApptLoadWeight" })
  public readonly isAppointmentLoadWeightEnabled: boolean;
  @api({ key: "restrictPartnerSelectionOnUnloader" })
  public readonly isAppointmentUnloaderPartnerSelectionRestricted: boolean;
  @api({ key: "captureDriverCDL" })
  public readonly isDriverCdlCaptureRequired: boolean | null;
  @api({ key: "captureDriverName" })
  public readonly isDriverNameCaptureRequired: boolean | null;
  @api({ key: "captureDriverPhoneNumber" })
  public readonly isDriverPhoneNumberCaptureRequired: boolean | null;
  @api({ key: "noDropAllowedOnManagedTypeT" })
  public readonly isDropLoadDisallowedForTruckLoadManagedType: boolean;
  @api({ key: "goDoFeature" })
  public readonly isGateOutDoorOccupiedAppointmentStatusEnabled: boolean;
  @api({ key: "captureLateDueDate" })
  public readonly isLateDueDatePromptRequired: boolean;
  @api({ key: "captureApptLoadType" })
  public readonly isLoadTypeRequired: boolean;
  @api({ key: "allowManualOrders" })
  public readonly isManualPurchaseOrderCreationAllowed: boolean;
  @api({ key: "offComplexFeature" })
  public readonly isOffComplexAppointmentStatusEnabled: boolean;
  @api({ key: "onComplexFeature" })
  public readonly isOnComplexAppointmentStatusEnabled: boolean;
  @api({ key: "limitPastDueDateNotifications" })
  public readonly isPastDueDateNotificationLimited: boolean;
  @api({ key: "captureOrderEntryDate" })
  public readonly isPurchaseOrderEntryDateEnabled: boolean;
  @api({ key: "allowOrderCloning" })
  public readonly isPurchaseOrderReuseAllowed: boolean;
  @api({ key: "restrictPOPalletandCaseCaptureGuided" })
  public readonly isPurchaseOrderUnitCountsCaptureRestricted: boolean;
  @api({ key: "promptSameDayApptConfirmation" })
  public readonly isSameDayAppointmentFlagRequired: boolean;
  @api({ key: "enableSearchPOByClientAppointmentNumber" })
  public readonly isSearchByLoadNumberEnabled: boolean;
  @api({ key: "appointmentPalletOverride" })
  public readonly isTotalWarehousePalletCountOverrideEnabled: boolean;
  @api({ key: "captureTrailerNumber" })
  public readonly isTrailerNumberRequired: boolean;
  @api({ key: "manageTypeCFeature" })
  public readonly isUsingContractManagedType: boolean;
  @api({ key: "allowReschedulingOfVendorSameDayAppointment" })
  public readonly isVendorSameDayReschedulingEnabled: boolean;
  @api({ key: "enableGuardPrompts" })
  public readonly isGuardPromptsEnabled: boolean;
  @api({ key: "enableYardManagementSystem" })
  public readonly isYardManagementSystemEnabled: boolean;
  @api({ key: "lateArrivalInMinutes" })
  public readonly lateArrivalDuration: Duration;
  @api() public readonly lateDueDateMessage: string | null;
  @api({ key: "lateScheduleThreshold" })
  public readonly lateScheduleThresholdDuration: Duration;
  @api({ key: "maxAppointmentDuration" })
  public readonly maximumAppointmentDuration: Duration;
  @api() public readonly maximumReservationTimeSlots: number;
  @api({ key: "appointmentDateLimit" })
  public readonly maximumFutureAppointmentSchedulingLimit: Duration | null;
  @api({ key: "minimumUnloadMinutes" })
  public readonly minimumAppointmentDuration: Duration;
  @api({ key: "noShowNoCallInMinutes" })
  public readonly noShowNoCallDuration: Duration | null;
  @api() public readonly number: number;
  @api({ key: "rescheduleMinimumAdvancedHours" })
  public readonly rescheduleMinimumAdvancedDuration: Duration | null;
  public readonly pointOfOriginValidation: Readonly<
    Record<ManagedType, FieldValidation>
  >;
  @api({ key: "scheduleAppointmentHoverOptions" })
  public readonly scheduleAppointmentHoverDisplayFields:
    | readonly ScheduleAppointmentDisplayFieldKey[]
    | null;
  @api({ key: "scheduleAppointmentSelectionOptions" })
  public readonly scheduleAppointmentSelectionDisplayFields:
    | readonly ScheduleAppointmentDisplayFieldKey[]
    | null;
  @api({ key: "scheduleDisplayDoorGrouping" })
  public readonly scheduleDoorGrouping: ScheduleDoorGrouping;
  @api() public readonly status: SiteAvailabilityStatus;
  @api() public readonly supportEmail: string | null;
  @api() public readonly timeZone: IANAZone;
  @api({ key: "captureTrailerTempActual" })
  public readonly trailerTemperatureActualValidation: FieldValidation;
  @api({ key: "captureTrailerTempSet" })
  public readonly trailerTemperatureSetValidation: FieldValidation;
  @api({ key: "capturePoQuantities" })
  public readonly unitCountsValidation: FieldValidation;
  @api() public readonly unitType: UnitType;
  @api({ key: "wksAvgUnloadTime" })
  public readonly weeksAveragedForUnloadTime: number;
}

const siteInformationCodec = t.readonly(
  t.type({
    deltaJson: t.string,
    html: t.string,
  }),
  "SiteInformation",
);

export const siteInformationFromStringCodec = jsonFromStringCodec.pipe(
  siteInformationCodec,
  "SiteInformationFromString",
);
export type SiteInformation = t.TypeOf<typeof siteInformationFromStringCodec>;

const appointmentOrdersColumnKeyCodec = t.keyof({
  asn: null,
  asnShipDate: null,
  backhaulPickupConfirmationNumber: null,
  comments: null,
  dueDate: null,
  freightBasis: null,
  freightIdentifier: null,
  inboundPalletCount: null,
  managedType: null,
  number: null,
  pickupDate: null,
  pointOfOrigin: null,
  receivedCaseCount: null,
  receivedPalletCount: null,
  suggestedDoor: null,
  tempZone: null,
  unitCounts: null,
  vendor: null,
  vendorNumber: null,
});

export type AppointmentOrdersColumnKey = t.TypeOf<
  typeof appointmentOrdersColumnKeyCodec
>;

export const appointmentOrdersColumnKeysCodec = jsonFromStringCodec.pipe(
  t.readonlyArray(maybe(appointmentOrdersColumnKeyCodec)),
);

const decodeAppointmentPurchaseOrderOptions = decodeOrElseNull(
  appointmentOrdersColumnKeysCodec,
);
export function parseAppointmentPurchaseOrderOptions(
  value: string | null | undefined,
): readonly AppointmentOrdersColumnKey[] | null {
  // Ignore unrecognized options and fallback to default if the JSON is invalid.
  return value
    ? decodeAppointmentPurchaseOrderOptions(value)?.filter(isExistent) ?? null
    : null;
}

export const allAppointmentOrdersColumnKeys: readonly AppointmentOrdersColumnKey[] =
  Object.keys(appointmentOrdersColumnKeyCodec.keys);

const scheduleAppointmentDisplayFieldKeyCodec = t.keyof({
  carrierETA: null,
  carrierOfRecord: null,
  consigneeCode: null,
  cube: null,
  doors: null,
  driverCDLNumber: null,
  driverName: null,
  driverPhoneNumber: null,
  dueDate: null,
  effectiveDeliveryCarrierName: null,
  freightBasis: null,
  gateInTimestamp: null,
  gateOutTimestamp: null,
  isDropLoad: null,
  isIntermodal: null,
  isPimTagged: null,
  isUnscheduledSameDay: null,
  lateGateInFlag: null,
  lateGateOutFlag: null,
  loadNumber: null,
  loadType: null,
  mainPurchaseOrder: null,
  managedType: null,
  offComplexTimestamp: null,
  onComplexTimestamp: null,
  originalAppointmentDate: null,
  priority: null,
  scheduledArrivalTime: null,
  scheduledDuration: null,
  slotWasReserved: null,
  suggestedDoor: null,
  tempZone: null,
  totalCaseCount: null,
  totalLoadWeight: null,
  totalWarehousePalletCount: null,
  trailer: null,
  trailerTemperatureActual: null,
  trailerTemperatureSet: null,
  unloadEndTimestamp: null,
  unloader: null,
  unloadStartTimestamp: null,
  vendor: null,
  yardLocation: null,
});
export type ScheduleAppointmentDisplayFieldKey = t.TypeOf<
  typeof scheduleAppointmentDisplayFieldKeyCodec
>;

export const scheduleAppointmentDisplayFieldKeysCodec =
  jsonFromStringCodec.pipe(
    t.readonlyArray(maybe(scheduleAppointmentDisplayFieldKeyCodec)),
  );

export const allScheduleAppointmentDisplayFieldKeys: readonly ScheduleAppointmentDisplayFieldKey[] =
  Object.keys(scheduleAppointmentDisplayFieldKeyCodec.keys);

export interface HelpAssistTicketCategory {
  readonly name: string;
  readonly ticketType: HelpAssistTicketType;
}
