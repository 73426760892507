import { Component, Input } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import {
  Appointment,
  SimpleAppointment,
} from "src/app/partner/appointments/appointment.model";
import { mustHaveOne } from "src/utils";

@Component({
  selector: "mr-appointment-information-icons[appointment]",
  templateUrl: "./appointment-information-icons.component.html",
  styleUrls: ["./appointment-information-icons.component.scss"],
})
export class AppointmentInformationIconsComponent {
  @Input() public kind: "verbose" | "normal" | "condensed" | "inline" =
    "verbose";

  @Input() public set appointment(value: Appointment | SimpleAppointment) {
    this.appointmentInput.next(value);
  }
  private readonly appointmentInput = new ReplaySubject<
    Appointment | SimpleAppointment
  >(1);

  public readonly iconsChanges = this.appointmentInput.pipe(
    map(({ isBackhaul, isCFM, isDropLoad, lastActivity, reservation }) =>
      mustHaveOne({
        isBackhaul,
        isCFM,
        isDropLoad,
        isInReservation: reservation !== null,
        lastActivityReasonCodeIcon: lastActivity?.iconName
          ? ({
              name: lastActivity.iconName,
              reasonCodeTitle: lastActivity.reasonCodeTitle,
            } as const)
          : null,
      } as const),
    ),
  );
}
