import { Pipe, PipeTransform } from "@angular/core";
import { startCase } from "lodash-es";
import { Weekdays } from "../weekdays.model";

@Pipe({ name: "weekdays" })
export class WeekdaysPipe implements PipeTransform {
  public transform(value: Weekdays, format: "full" | "short" = "full"): string {
    return formatWeekdays(value, format);
  }
}

export function formatWeekdays(
  value: Weekdays,
  format: "full" | "short" = "full",
): string {
  return [...value.getSelected()]
    .map(startCase)
    .map((day) => (format === "short" ? day.substr(0, 3) : day))
    .join(", ");
}
