import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { UUID } from "src/utils";

export class HelpAssistMarkAllAsReadRequest {
  public constructor(args: ClassProperties<HelpAssistMarkAllAsReadRequest>) {
    this.partnerKey = args.partnerKey;
    this.siteId = args.siteId;
  }

  public readonly partnerKey: UUID | null;
  public readonly siteId: Site["id"] | null;

  public serialize(): Api.HelpAssistMarkAllAsReadRequest {
    return {
      partnerKey: this.partnerKey?.toString() ?? undefined,
      siteId: this.siteId ?? undefined,
    };
  }
}
