import { ValidatorFn } from "@angular/forms";
import { Duration } from "luxon";
import { durationValidator } from ".";

export const appointmentDurationMax = Duration.fromObject({ days: 1 });

export function getAppointmentDurationValidator({
  step,
  minimum = step,
}: {
  step: Duration;
  minimum?: Duration;
}): ValidatorFn {
  return durationValidator(minimum, appointmentDurationMax, step);
}
