import { Component, forwardRef, Input, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeComponent,
  SelectMode,
} from "@danielmoncada/angular-datetime-picker";
import { DateTime, IANAZone, Zone } from "luxon";
import { Day } from "src/app/core/day.model";
import {
  datePickerFixedTime,
  datePickerTimeZone,
  getAdjustedDateTime,
} from "src/app/core/forms/date-picker.component";
import {
  CUSTOM_DATE_TIME_FORMATS,
  LuxonDateTimeAdapter,
} from "src/app/core/forms/luxon-date-time-adapter";
import { TimeOfDay } from "../time-of-day.model";
import { BaseFieldComponent } from "./base-field.component";

type Value = DateTime | DateTime[] | null;

@Component({
  selector: "mr-date-time-picker-base[fieldId][descriptionId][label][timeZone]",
  styleUrls: [
    "./textbox.component.scss",
    "./date-time-picker-base.component.scss",
  ],
  template: `
    <div class="field">
      <mr-icon name="calendar"></mr-icon>
      <mr-icon
        name="close"
        *ngIf="value && !isDisabled && !disableClear"
        (click)="onChange(null)"
      ></mr-icon>
      <input
        [mrHighlighted]="isHighlighted"
        [highlightStyle]="highlightStyle"
        [id]="fieldId"
        [attr.aria-label]="label || null"
        [attr.aria-describedby]="descriptionId"
        [selectMode]="selectMode"
        [ngModel]="value"
        [owlDateTime]="picker"
        [owlDateTimeTrigger]="picker"
        [disabled]="isDisabled"
        [max]="maxDateTime"
        [min]="minDateTime"
        readonly
        (ngModelChange)="onChange($event)"
      />
      <owl-date-time
        #picker
        [pickerType]="fixedTime ? 'calendar' : 'both'"
        [startAt]="value || startTime"
        [stepMinute]="minuteStep"
        [stepHour]="1"
        [hour12Timer]="true"
        [disabled]="isDisabled"
        backdropClass="mr-date-time-picker-backdrop"
        panelClass="mr-date-time-picker-panel"
        (afterPickerClosed)="onBlur()"
        (dateSelected)="onSelect($event)"
      ></owl-date-time>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerBaseComponent),
    },
    { provide: DateTimeAdapter, useClass: LuxonDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS },
  ],
})
export class DateTimePickerBaseComponent extends BaseFieldComponent<Value> {
  public declare fieldId: string;

  @Input() public descriptionId!: string;
  @Input() public disableClear = false;
  @Input() public highlightStyle: "background" | "border" = "background";
  @Input() public isHighlighted = false;
  @Input() public minuteStep = 1;
  @Input() public minDateTime: DateTime | null = null;
  @Input() public maxDateTime: DateTime | null = null;
  @Input() public selectMode: SelectMode = "single";
  @Input() public forceWeeklyRange = false;

  /** The fixed, unchangeable time to set in the result. */
  @Input() public fixedTime?: TimeOfDay;

  @Input() public set timeZone(value: Zone) {
    this._timeZone = value;
    this.startTime = DateTime.utc().setZone(value).startOf("day");
  }
  public get timeZone(): Zone {
    return this._timeZone;
  }
  private _timeZone!: Zone;

  // Set an explicit start time so the library doesn't auto-fill with the
  // current time. Setting the current time messes up the step logic, so that if
  // the step is 15 and the current time is 8:24, the next step would be 8:39
  // instead of 8:30 (though 8:24 should never have been allowed in the first
  // place).
  public startTime!: DateTime;

  @ViewChild("picker", { static: false })
  private readonly dateTimePicker!: OwlDateTimeComponent<unknown>;

  public override onChange(value: DateTime | null): void {
    value = this.minDateTime
      ? constrainToMinimum(value, this.minDateTime)
      : value;
    super.onChange(adjustTime(value, this.timeZone, this.fixedTime));
  }

  public onSelect(value: DateTime): void {
    if (this.forceWeeklyRange) {
      const weeklyRange = calculateWeeklyRange(
        value,
        this.minDateTime,
        this.maxDateTime,
        this.timeZone,
        this.fixedTime,
      );
      super.onChange(weeklyRange);
      this.dateTimePicker.close();
    }
  }

  public override writeValue(value: Value | null): void {
    if (value instanceof Array) {
      super.writeValue(
        value.map(
          (dateTime) =>
            adjustTime(dateTime, this.timeZone, this.fixedTime) ?? dateTime,
        ),
      );
    } else {
      super.writeValue(adjustTime(value, this.timeZone, this.fixedTime));
    }
  }
}

function adjustTime(
  value: DateTime | null,
  timeZone: IANAZone,
  fixedTime?: TimeOfDay,
): DateTime | null {
  if (!value) {
    return null;
  }

  const valueWithCorrectTime = fixedTime ? value.set(fixedTime) : value;

  // Take whatever time is displayed to the user and force it into the
  // correct time zone, regardless of what zone it was in.
  return valueWithCorrectTime.setZone(timeZone, { keepLocalTime: true });
}

export function constrainToMinimum(
  value: DateTime | null,
  minDateTime: DateTime,
): DateTime | null {
  if (minDateTime && value && value.toMillis() <= minDateTime.toMillis()) {
    return minDateTime;
  }
  return value;
}

export function constrainToMaximum(
  value: DateTime | null,
  maxDateTime: DateTime,
): DateTime | null {
  if (maxDateTime && value && value.toMillis() >= maxDateTime.toMillis()) {
    return maxDateTime;
  }
  return value;
}

export function calculateWeeklyRange(
  selectedDay: DateTime | Day,
  minDateTime: DateTime | Day | null,
  maxDateTime: DateTime | Day | null,
  timeZone: IANAZone = datePickerTimeZone,
  fixedTime: TimeOfDay = datePickerFixedTime,
): DateTime[] {
  selectedDay =
    selectedDay instanceof Day ? getAdjustedDateTime(selectedDay) : selectedDay;

  minDateTime =
    minDateTime instanceof Day ? getAdjustedDateTime(minDateTime) : minDateTime;

  maxDateTime =
    maxDateTime instanceof Day ? getAdjustedDateTime(maxDateTime) : maxDateTime;

  const currentDayIndex = getCurrentDayIndex(selectedDay);

  let startDate: DateTime | null = selectedDay.minus({
    days: currentDayIndex,
  });

  startDate = minDateTime
    ? constrainToMinimum(startDate, minDateTime)
    : startDate;

  startDate = adjustTime(startDate, timeZone, fixedTime);

  if (!startDate) {
    throw new Error(
      "An error happened while calculating the start date of the weekly range.",
    );
  }

  let endDate: DateTime | null = startDate.plus({
    days: 6 - getCurrentDayIndex(startDate),
  });

  endDate = maxDateTime ? constrainToMaximum(endDate, maxDateTime) : endDate;

  endDate = adjustTime(endDate, timeZone, fixedTime);

  if (!endDate) {
    throw new Error(
      "An error happened while calculating the end date of the weekly range.",
    );
  }

  return [startDate, endDate];
}

function getCurrentDayIndex(selectedDay: DateTime): number {
  return selectedDay.weekday === 7 ? 0 : selectedDay.weekday;
}
