import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import {
  Brand,
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  getEnumMember,
  ODataModel,
  parseDateTime,
  parseNumber,
  parseTimeOfDay,
  PartnerResourceModel,
  UUID,
} from "src/utils";
import { HelpAssistEventType } from "./help-assist-enums";
import { HelpAssistUser } from "./help-assist-user.model";
import type { HelpAssistTicket } from "./models/ticket/help-assist-ticket.model";

export const helpTicketEventResource = createPartnerResourceUrl(
  "HelpAssistTicketEvents",
);

export const expandedHelpTicketEventResource = helpTicketEventResource.modify(
  (resource) => resource.addExpandField("user"),
);

type ItemValue = DateTime | TimeOfDay | number | string | null;

export class ChangeItem {
  public constructor(args: ClassProperties<ChangeItem>) {
    this.fieldName = args.fieldName;
    this.innerObject = args.innerObject;
    this.newValue = args.newValue;
    this.originalValue = args.originalValue;
  }

  public readonly fieldName: string;
  public readonly innerObject: readonly ChangeItem[] | null;
  public readonly newValue: ItemValue;
  public readonly originalValue: ItemValue;

  public static deserialize(apiModel: Api.ChangeItem): ChangeItem {
    return new ChangeItem({
      fieldName: apiModel.fieldName,
      innerObject:
        apiModel.innerObject &&
        ChangeItem.deserializeList(apiModel.innerObject),
      newValue: parseChangeValue(apiModel.newValue),
      originalValue: parseChangeValue(apiModel.originalValue),
    });
  }

  public static deserializeList(apiModel: Api.ChangeItem[]): ChangeItem[] {
    return apiModel.map((item) => ChangeItem.deserialize(item));
  }
}

type ApiModelList = PartnerResourceModel<
  typeof expandedHelpTicketEventResource
>;
export interface ApiExpandedHelpTicketEvent extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiExpandedHelpTicketEvent>();

interface DeserializeArgs {
  ownerUser: HelpAssistUser;
}

export class HelpAssistEvent {
  private constructor(args: ClassProperties<HelpAssistEvent>) {
    this.id = args.id;
    this.comment = args.comment;
    this.createdOn = args.createdOn;
    this.emailId = args.emailId;
    this.ownerUser = args.ownerUser;
    this.ticketId = args.ticketId;
    this.type = args.type;
    this.user = args.user;
    this.changes = args.changes;
  }

  @api() public readonly id: Brand<number, "help-assist-event">;
  @api() public readonly changes: readonly ChangeItem[] | null;
  @api() public readonly comment: string | null;
  @api() public readonly createdOn: DateTime;
  @api() public readonly emailId: UUID | null;
  public readonly ownerUser: HelpAssistUser;
  @api() public readonly ticketId: HelpAssistTicket["id"] | null;
  @api() public readonly type: HelpAssistEventType;
  @api() public readonly user: HelpAssistUser;

  public static deserialize(
    apiModel: ApiExpandedHelpTicketEvent,
    { ownerUser }: DeserializeArgs,
  ): HelpAssistEvent {
    return new HelpAssistEvent({
      changes: apiModel.changes && ChangeItem.deserializeList(apiModel.changes),
      comment: apiModel.comment ?? null,
      createdOn: parseDateTime(apiModel.createdOn),
      emailId: apiModel.emailId ? new UUID(apiModel.emailId) : null,
      id: apiModel.id,
      ownerUser,
      ticketId: apiModel.ticketId,
      type: getEnumMember(HelpAssistEventType, apiModel.type),
      user: HelpAssistUser.deserialize(apiModel.user),
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    args: DeserializeArgs,
  ): HelpAssistEvent[] {
    return value.map((event) => HelpAssistEvent.deserialize(event, args));
  }
}

function normalizeChangeValue(value: string | null): string | null {
  // The API still returns a "null" string for some values.
  return value === "null" || value === "" ? null : value;
}

function parseChangeValue(value: string | null): ItemValue {
  const normalizedValue = normalizeChangeValue(value);

  if (normalizedValue === null) {
    return null;
  }

  if (parseNumber(normalizedValue) !== null) {
    return normalizedValue;
  }

  const timeOfDay = parseTimeOfDay(normalizedValue);
  if (timeOfDay !== null) {
    return timeOfDay;
  }

  const dateTime = parseDateTime(normalizedValue);
  if (dateTime.isValid) {
    return dateTime;
  }

  return normalizedValue;
}
