import { Component, HostBinding, Input } from "@angular/core";
import { FieldContainerComponent } from "./field-container.component";

@Component({
  selector: "mr-radio-group-field-container",
  template: `
    <fieldset>
      <legend [class.no-description]="!description">
        {{ label }}<span *ngIf="isRequired()">*</span>
      </legend>
      <div [id]="descriptionId" class="description" *ngIf="description">
        {{ description }}
      </div>
      <ng-content></ng-content>
    </fieldset>
    <ul *ngIf="!areErrorsHidden && getErrors() as errors">
      <li *ngIf="errors['required']">{{ errorLabel }} is required</li>
    </ul>
  `,
  styleUrls: ["radio-group-field-container.component.scss"],
})
export class RadioGroupFieldContainerComponent extends FieldContainerComponent {
  @Input()
  @HostBinding("class")
  public orientation: "horizontal" | "vertical" = "vertical";
}
