import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { PhoneNumber } from "src/app/core/pipes/phone-number.pipe";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

type Value = string | null;

@Component({
  selector: "mr-phone-number-input[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [description]="description"
      [kind]="kind"
    >
      <div class="field">
        <span class="phone-country-code">+1</span>
        <input
          type="tel"
          [id]="container.fieldId"
          placeholder="___-___-____"
          [attr.title]="isLabelHidden ? label : null"
          [attr.aria-label]="isLabelHidden ? label : null"
          [attr.aria-describedby]="container.descriptionId"
          [disabled]="isDisabled"
          [readOnly]="isReadonly"
          [ngModel]="value"
          (keypress)="allowOnlyNumbers($event)"
          (ngModelChange)="onInputChange($event)"
          (blur)="onBlur()"
          (drop)="onDrop($event)"
          (paste)="onPaste($event)"
          maxlength="12"
        />
      </div>
    </mr-field-container>
  `,
  styleUrls: [
    "./textbox.component.scss",
    "./phone-number-input.component.scss",
  ],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
    },
  ],
})
export class PhoneNumberInputComponent extends BaseFieldComponent<Value> {
  public constructor(
    private readonly phoneNumberPipe: PhoneNumber,
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public kind: "form" | "tiled" = "form";

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  private _value: Value = null;

  public override get value(): Value {
    return this._value;
  }

  public override set value(value: Value) {
    if (!this.phoneNumberPipe) {
      return;
    }

    this._value = this.phoneNumberPipe.transform(value);
  }

  public onInputChange(value: string): void {
    const displayValue = this.phoneNumberPipe.transform(value);
    const modelValue = this.phoneNumberPipe.parse(displayValue);

    this.onChange(modelValue);
  }

  public onPaste(e: ClipboardEvent): void {
    e.preventDefault();

    const value = e.clipboardData?.getData("text") ?? "";

    this.onInputChange(value);
  }

  public onDrop(e: DragEvent): void {
    e.preventDefault();

    const value = e.dataTransfer?.getData("text") ?? "";

    this.onInputChange(value);
  }

  public allowOnlyNumbers(event: KeyboardEvent): boolean {
    return !isNaN(Number(event.key));
  }
}
