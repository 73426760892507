import { isNil } from "lodash-es";
import { environment, GlobalEnvironmentConfig } from "src/environments";

export interface Options {
  api: keyof GlobalEnvironmentConfig["apiUrls"];
}

type PathSegment = string | number;
export type Path = PathSegment | [PathSegment, ...PathSegment[]];

function trimSlashes(path: string): string {
  return path.replace(/^\/+(.*?)\/+$/, "$1");
}

function normalizePath(path: Path): string {
  return (Array.isArray(path) ? path : [path])
    .map((segment) => trimSlashes(segment.toString()))
    .join("/");
}

export class ResourceUrl<T extends Options = Options> {
  public constructor(path: Path, options: Partial<T> = {}) {
    const { api = "mr" } = options;
    this.options = options;
    this.baseUrl = environment.apiUrls[api];

    // Storing the path and query params in a URL object ensures we can create
    // a valid URL from it later. We'll use an arbitrary, static base URL to
    // avoid having to deal with whatever path component the actual base URL
    // may have. Any valid base URL **without a path component** will do as it
    // will be replaced when creating the actual URL.
    this.workingUrl = new URL(
      normalizePath(path),
      "http://fakeurl.capstonelogistics.com",
    );
  }

  // This strongly types the `new this.constructor` below for derived classes.
  // https://stackoverflow.com/a/47859625
  // and
  // https://github.com/microsoft/TypeScript/issues/3841#issuecomment-502845949
  public ["constructor"]: new (
    ...args: ConstructorParameters<typeof ResourceUrl>
  ) => this;

  protected readonly options: Partial<T>;
  protected readonly baseUrl: URL;
  protected readonly workingUrl: URL;

  /**
   * The path component of the resource.
   */
  public get path(): string {
    // Remove the leading slash to normalize like our other paths.
    // In particular, this prevents it from "rooting" when combined
    // with the base URL in `.url`.
    return this.workingUrl.pathname.substring(1);
  }

  /**
   * The full URL of the resource including query parameters.
   */
  public get url(): URL {
    return new URL(this.path + this.workingUrl.search, this.baseUrl);
  }

  /**
   * Set the path component of the resource.
   * @param path The path to set.
   */
  public setPath(path: Path): this {
    const resource = this.clone();
    resource.workingUrl.pathname = normalizePath(path);
    return resource;
  }

  /**
   * Add path to the beginning of the current resource path.
   * @param path The path to add to the beginning.
   */
  public prependPath(path: Path): this {
    return this.setPath([normalizePath(path), this.path]);
  }

  /**
   * Add path to the end of the current resource path.
   * @param path The path to add to the end.
   */
  public appendPath(path: Path): this {
    return this.setPath([this.path, normalizePath(path)]);
  }

  public setParam(name: string, value: string | undefined | null): this {
    const resource = this.clone();
    const { searchParams } = resource.workingUrl;
    if (isNil(value)) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }
    return resource;
  }

  public toString(): string {
    return this.url.href;
  }

  protected clone(options: Partial<T> = this.options): this {
    const clone = new this.constructor(this.path, options);
    clone.workingUrl.search = this.workingUrl.search;
    return clone;
  }
}
