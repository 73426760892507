import { HttpErrorResponse } from "@angular/common/http";
import * as t from "io-ts";
import { outdent } from "outdent";
import { decodeOrElseNull, jsonFromStringCodec, maybe } from "src/utils";

export class ApiError extends Error {
  private constructor(
    public readonly status: number,
    public readonly body: string,
    public readonly url?: string,
    public readonly apiCode?: string,
    public readonly apiMessage?: string,
    public readonly apiTarget?: string,
    public readonly apiDetails?: ReadonlyArray<{ readonly code: string }>,
    public readonly innerApiError?: { readonly message?: string },
  ) {
    super(outdent`
      API Error
      Status: ${status}
      Code: ${apiCode ?? "<None>"}
      Message: ${
        (apiMessage ?? "<None>") +
        (innerApiError?.message ? `\n\t↳ ${innerApiError.message}` : "")
      }
      Request: ${url ?? "<None>"}
      Details:
      ${body}
    `);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = "ApiError";
  }

  public static create(args: HttpErrorResponse | ApiErrorArguments): ApiError {
    const { status, body, url } = processArguments(args);
    const { error } = parseApiErrorResponse(body) ?? {};
    const innerError = error?.innererror;

    return new ApiError(
      status,
      body,
      url,
      error?.code?.trim() || undefined,
      error?.message?.trim() || undefined,
      error?.target,
      error?.details,
      innerError
        ? {
            ...innerError,
            message: innerError.message?.trim() || undefined,
          }
        : undefined,
    );
  }

  public override toString(): string {
    return this.stack ?? this.message;
  }
}

interface ApiErrorArguments {
  readonly status: number;
  readonly body: string;
  readonly url?: string;
}

function processArguments(
  args: HttpErrorResponse | ApiErrorArguments,
): ApiErrorArguments {
  if (args instanceof HttpErrorResponse) {
    const { url, status, error } = args;
    return { status, body: JSON.stringify(error), url: url ?? undefined };
  } else {
    return args;
  }
}

const parseApiErrorResponse = decodeOrElseNull(
  jsonFromStringCodec.pipe(
    t.type({
      error: t.partial({
        code: maybe(t.string),
        details: maybe(
          t.readonlyArray(
            t.intersection([
              t.type({
                code: t.string,
              }),
              t.partial({
                message: t.string,
                target: t.string,
              }),
            ]),
          ),
        ),
        innererror: maybe(
          t.partial({
            message: t.string,
            stacktrace: maybe(t.string),
            type: maybe(t.string),
          }),
        ),
        message: maybe(t.string),
        target: maybe(t.string),
      }),
    }),
  ),
);
