import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";
import { HelpAssistTicketAppointmentDraftOrderVendor } from "./help-assist-ticket-appointment-draft-order-vendor.model";
import { GlobalApiTicketOrder } from "./help-assist-ticket-appointment-draft.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketAppointmentOrder>();

export class HelpAssistTicketAppointmentDraftOrder {
  private constructor(
    args: ClassProperties<HelpAssistTicketAppointmentDraftOrder>,
  ) {
    this.id = args.id;
    this.vendor = args.vendor;
  }

  @api() public readonly id: number;
  @api({
    key: "vendorId",
    navigationProperty: "vendor",
    uiModel: HelpAssistTicketAppointmentDraftOrderVendor,
  })
  public readonly vendor: HelpAssistTicketAppointmentDraftOrderVendor | null;

  public static deserialize(
    apiModel: GlobalApiTicketOrder,
  ): HelpAssistTicketAppointmentDraftOrder {
    return new HelpAssistTicketAppointmentDraftOrder({
      id: apiModel.id,
      vendor:
        apiModel.vendor &&
        HelpAssistTicketAppointmentDraftOrderVendor.deserialize(
          apiModel.vendor,
        ),
    });
  }
}
