<mr-radio-group-field-container
  [label]="label"
  [description]="description"
  [kind]="kind"
  [orientation]="labelOrientation"
>
  <label *ngFor="let option of options" [class.disabled]="isDisabled">
    <input
      type="radio"
      [disabled]="isDisabled"
      [name]="radioGroupName"
      [value]="option.value"
      [ngModel]="value"
      (ngModelChange)="propagateChange($event)"
      (change)="$event.stopPropagation()"
    />
    <div class="template">
      <ng-container [ngTemplateOutlet]="option.template"></ng-container>
    </div>
  </label>
</mr-radio-group-field-container>
