import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { QuillToolbarConfig } from "ngx-quill";
import { getQuillFormatsFromToolbar } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-rich-textarea[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [labelHidden]="isLabelHidden"
      [description]="description"
    >
      <ng-container *ngIf="isReadonly || isDisabled; else editable">
        <quill-view
          [id]="container.fieldId"
          [attr.title]="isLabelHidden ? label : null"
          [attr.aria-label]="isLabelHidden ? label : null"
          [attr.aria-describedby]="container.descriptionId"
          format="json"
          [content]="value"
        ></quill-view>
      </ng-container>
      <ng-template #editable>
        <quill-editor
          [id]="container.fieldId"
          [attr.title]="isLabelHidden ? label : null"
          [attr.aria-label]="isLabelHidden ? label : null"
          [attr.aria-describedby]="container.descriptionId"
          format="json"
          [placeholder]="placeholder"
          [modules]="{ toolbar: toolbar }"
          [formats]="formats"
          [ngModel]="value"
          (ngModelChange)="onChange($event)"
          (onBlur)="onBlur()"
        ></quill-editor>
      </ng-template>
    </mr-field-container>
  `,
  styleUrls: ["rich-textarea.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RichTextareaComponent),
    },
  ],
})
export class RichTextareaComponent extends BaseFieldComponent<string> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public placeholder = "";

  @Input()
  public set toolbar(value: QuillToolbarConfig | undefined) {
    this.#toolbar = value;
    this.formats = value && getQuillFormatsFromToolbar(value);
  }
  public get toolbar(): QuillToolbarConfig | undefined {
    return this.#toolbar;
  }
  #toolbar?: QuillToolbarConfig;
  public formats?: string[];

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }
}
