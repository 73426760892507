import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DateTime } from "luxon";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  merge,
  Observable,
  Subject,
} from "rxjs";
import { filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { Pagination } from "src/app/core/pagination";
import { RequestService } from "src/app/core/request.service";
import { Site, SitesService } from "src/app/core/sites";
import { ColumnFilter } from "src/app/core/table";
import { AppointmentStatusesService } from "src/app/partner/appointments/appointment-statuses.service";
import { PartnersService } from "src/app/partner/global/partners.service";
import { HelpAssistAppointmentRequest } from "src/app/partner/help-assist/models/help-assist-appointment-request.model";
import { HelpAssistTicketAssignAppointmentRequest } from "src/app/partner/help-assist/models/help-assist-ticket-assign-appointment-request.model";
import {
  ColumnSort,
  deserializeApiModelList,
  firstExistentValueFrom,
  isExistent,
  isOneOfCreator,
  mustHaveEvery,
  notFoundErrorToNull,
  parseNumber,
  setFiltersOnResource,
  setSortOnResource,
  SortOrder,
  switchMapNullable,
  throwUnhandledCaseError,
  UUID,
} from "src/utils";
import { AppointmentStatusCode } from "../appointments/appointment-status.model";
import { Attachment } from "./attachment.model";
import { AttachmentsService } from "./attachments.service";
import { HelpAssistTicketStatus } from "./help-assist-enums";
import { HelpAssistEventCreation } from "./help-assist-event-creation.model";
import { HelpAssistEvent } from "./help-assist-event.model";
import { HelpAssistEventsService } from "./help-assist-events.service";
import { HelpAssistHasScheduledTicketForRequest } from "./models/help-assist-has-schedule-ticket-for-request.model";
import { HelpAssistMarkAllAsReadRequest } from "./models/help-assist-mark-all-as-read-request.model";
import { HelpAssistTicketApproveOrDeclineRequest } from "./models/help-assist-ticket-appointment-approve-or-decline-request.model";
import { HelpAssistTicketAssignUserRequest } from "./models/help-assist-ticket-assign-user-request.model";
import { HelpAssistTicketCloseAssociatedTicketsRequest } from "./models/help-assist-ticket-close-associated-tickets-request.model";
import { HelpAssistTicketDetailsUpdateRequest } from "./models/help-assist-ticket-details-update-request.model";
import { HelpAssistTicketStatusChangeRequest } from "./models/help-assist-ticket-status-change-request.model";
import { AssociatedAppointment } from "./models/ticket-associated-appointment.model";
import { AssociatedTicket } from "./models/ticket-associated-ticket.model";
import { HelpAssistTicketReference } from "./models/ticket/base-help-assist-ticket.model";
import { HelpAssistTicketUpdate } from "./models/ticket/help-assist-ticket-update.model";
import {
  expandedHelpTicketsResourceGlobal,
  expandedHelpTicketsResourcePartner,
  GlobalHelpAssistTicket,
  HelpAssistTicket,
  helpTicketsResourceGlobal,
  helpTicketsResourcePartner,
  PartnerHelpAssistTicket,
} from "./models/ticket/help-assist-ticket.model";

const ticketFilters = [
  "your-tickets",
  "open-tickets",
  "unassigned-tickets",
  "closed-tickets",
  "auto-approved-reservations",
] as const;
export type TicketFilter = typeof ticketFilters[number];
export const isTicketFilter = isOneOfCreator(ticketFilters);

const ticketCountFilters = ["pending-approval-tickets"] as const;
export type TicketCountFilter = typeof ticketCountFilters[number];
export const isTicketCountFilter = isOneOfCreator(ticketCountFilters);

@Injectable({ providedIn: "root" })
export class HelpAssistService {
  public constructor(
    private readonly attachments: AttachmentsService,
    private readonly helpTicketEvents: HelpAssistEventsService,
    private readonly partners: PartnersService,
    private readonly request: RequestService,
    private readonly route: ActivatedRoute,
    private readonly sites: SitesService,
    private readonly statuses: AppointmentStatusesService,
    private readonly user: UserService,
  ) {}

  private readonly itemBaseResourceChanges = this.sites
    .getResourceInSelectedPartner(expandedHelpTicketsResourcePartner)
    .pipe(shareReplay(1));

  public readonly modifiableResourceChanges = this.sites
    .getResourceInSelectedPartner(helpTicketsResourcePartner)
    .pipe(shareReplay(1));

  private readonly globalDeserializeArguments = combineLatest([
    this.partners.changes,
    this.statuses.changes,
  ]).pipe(map(([partners, statuses]) => mustHaveEvery({ partners, statuses })));

  private readonly partnerDeserializeArguments = combineLatest([
    this.globalDeserializeArguments,
    this.sites.selectedChanges,
  ]).pipe(map(([args, site]) => args && { ...args, site }));

  private readonly markAllAsReadNotifierSubject = new BehaviorSubject<void>(
    undefined,
  );
  public readonly markAllAsReadNotifier =
    this.markAllAsReadNotifierSubject.asObservable();

  private readonly markAllAsReadLoadingSubject = new BehaviorSubject(false);
  public readonly markAllAsReadLoadingChanges =
    this.markAllAsReadLoadingSubject.asObservable();

  private readonly ticketUpdateSubject = new Subject<PartnerHelpAssistTicket>();
  public readonly ticketUpdate = this.ticketUpdateSubject.asObservable();

  private readonly receivedTicketSubject =
    new BehaviorSubject<PartnerHelpAssistTicket | null>(null);

  public readonly selectedTicketChanges = merge(
    this.receivedTicketSubject,
    this.ticketUpdate,
  );

  private readonly selectedTicketErrorSubject = new BehaviorSubject<unknown>(
    null,
  );
  public readonly selectedTicketErrorChanges =
    this.selectedTicketErrorSubject.asObservable();

  public readonly queriedHelpAssistTicketChanges = combineLatest([
    this.route.queryParamMap,
    this.ticketUpdate.pipe(startWith(null)),
  ]).pipe(
    map(([params]) => parseNumber(params.get("ticket"))),
    switchMapNullable((ticketId) => this.getSelected(ticketId)),
    shareReplay(1),
  );

  public getListChanges({
    filter: helpTicketFilter,
    columnFilters,
    sort = { key: "lastEventCreatedOn", order: SortOrder.Descending },
    pagination,
    partnerKey,
    siteId,
  }: {
    filter?: TicketFilter;
    columnFilters?: readonly ColumnFilter[];
    sort?: ColumnSort | StringKeys<GlobalHelpAssistTicket>;
    pagination?: Pagination;
    partnerKey?: UUID | null;
    siteId?: Site["id"] | null;
  } = {}): Observable<readonly GlobalHelpAssistTicket[] | null> {
    const resourceChanges = this.sites.selectedChanges.pipe(
      filter(isExistent),
      map((site) =>
        expandedHelpTicketsResourceGlobal
          .addFilterIfExists(helpTicketFilter, (resource, value) => {
            switch (value) {
              case "your-tickets": {
                return resource
                  .addFilter("status", "!=", HelpAssistTicketStatus.Closed)
                  .addFilter("status", "!=", HelpAssistTicketStatus.Expired)
                  .addOrFilters((filterResource) => [
                    filterResource.addFilter(
                      ["ownerUser", "userId"],
                      "==",
                      this.user.details.id,
                    ),
                    filterResource.addFilter(
                      ["assignedUser", "userId"],
                      "==",
                      this.user.details.id,
                    ),
                  ]);
              }
              case "open-tickets": {
                return resource
                  .addFilter("status", "!=", HelpAssistTicketStatus.Closed)
                  .addFilter("status", "!=", HelpAssistTicketStatus.Expired);
              }
              case "unassigned-tickets": {
                return resource
                  .addFilter("status", "!=", HelpAssistTicketStatus.Closed)
                  .addFilter("status", "!=", HelpAssistTicketStatus.Expired)
                  .addFilter("assignedUser", "==", null);
              }
              case "closed-tickets": {
                return resource.addOrFilters((filterResource) => [
                  filterResource
                    .addFilter("status", "==", HelpAssistTicketStatus.Closed)
                    .addFilter("isAutoApproved", "!=", true),
                  filterResource
                    .addFilter("status", "==", HelpAssistTicketStatus.Expired)
                    .addFilter("isAutoApproved", "!=", true),
                  filterResource
                    .addFilter("isAutoApproved", "==", true)
                    .addFilter(
                      "createdOn",
                      "<=",
                      DateTime.utc()
                        .setZone(site.timeZone)
                        .startOf("day")
                        .minus({ days: 7 }),
                    )
                    .addRawFilter(
                      `appointmentSchedule/appointment/appointmentStatus/code eq '${AppointmentStatusCode.OffComplex}'`,
                    ),
                ]);
              }
              case "auto-approved-reservations": {
                return resource
                  .addFilter("isAutoApproved", "==", true)
                  .addOrFilters((orFilter) => [
                    orFilter.addFilter(
                      "createdOn",
                      ">=",
                      DateTime.utc()
                        .setZone(site.timeZone)
                        .startOf("day")
                        .minus({ days: 7 }),
                    ),
                    orFilter.addRawFilter(
                      `appointmentSchedule/appointment/appointmentStatus/code eq '${AppointmentStatusCode.OffComplex}'`,
                    ),
                  ]);
              }
              default: {
                throwUnhandledCaseError("help assist list filter type", value);
              }
            }
          })
          .addFilterIfExists(sort, (resource, value) =>
            setSortOnResource(resource, GlobalHelpAssistTicket, value),
          )
          .addFilterIfExists(columnFilters, (resource, value) =>
            setFiltersOnResource(resource, GlobalHelpAssistTicket, value, {
              timeZone: site.timeZone,
            }),
          )
          .addFilterIfExists(partnerKey, (resource, value) =>
            resource.addFilter("partnerKey", "==", value),
          )
          .addFilterIfExists(siteId, (resource, value) =>
            resource.addFilter("siteId", "==", value),
          ),
      ),
    );

    return deserializeApiModelList({
      deserialize: GlobalHelpAssistTicket.deserializeList,
      list: resourceChanges.pipe(
        switchMap((resource) => this.request.getMany(resource, { pagination })),
      ),
      arguments: this.globalDeserializeArguments,
    });
  }

  public getCountChanges({
    filter: ticketCountFilter,
    partnerKey,
    siteId,
  }: {
    filter?: TicketCountFilter;
    partnerKey?: UUID | null;
    siteId?: Site["id"] | null;
  } = {}): Observable<number | null> {
    let resource = helpTicketsResourceGlobal.withCount();

    if (partnerKey) {
      resource = resource.addFilter("partnerKey", "==", partnerKey);
    }

    if (siteId) {
      resource = resource.addFilter("siteId", "==", siteId);
    }

    switch (ticketCountFilter) {
      case "pending-approval-tickets":
        resource = resource
          .addFilter("status", "!=", HelpAssistTicketStatus.Closed)
          .addFilter("status", "!=", HelpAssistTicketStatus.Expired)
          .addFilter(
            ["appointmentSchedule", "appointmentApprovalRequired"],
            "==",
            true,
          );
        break;
    }

    resource = resource.top(0);

    return this.request
      .getAll(resource)
      .pipe(
        map((result) =>
          result && isExistent(result["@odata.count"])
            ? result["@odata.count"]
            : null,
        ),
      );
  }

  public async getAssociatedTickets(
    ticket: HelpAssistTicket,
  ): Promise<readonly AssociatedTicket[]> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { value } = await firstExistentValueFrom(
      this.request.get(
        resource
          .appendId(ticket.id)
          .appendFunctionPropertyPath("associatedTickets"),
      ),
    );

    return AssociatedTicket.deserializeList(value, {
      associatedTicket: ticket,
    });
  }

  public async getAssociatedAppointments(
    ticket: HelpAssistTicket,
  ): Promise<readonly AssociatedAppointment[]> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { value } = await firstExistentValueFrom(
      this.request.get(
        resource
          .appendId(ticket.id)
          .appendFunctionPropertyPath("associatedAppointments"),
      ),
    );

    return AssociatedAppointment.deserializeList(value, {
      associatedTicket: ticket,
    });
  }

  public async getSelected(
    id: HelpAssistTicket["id"],
  ): Promise<PartnerHelpAssistTicket | null> {
    this.receivedTicketSubject.next(null);
    this.selectedTicketErrorSubject.next(null);

    const ticket = await this.get(id).catch((error) => {
      this.selectedTicketErrorSubject.next(error);
      return notFoundErrorToNull(error);
    });

    this.receivedTicketSubject.next(ticket);

    return ticket;
  }

  public async changeTicketStatus(
    request: HelpAssistTicketStatusChangeRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(request.ticket.id)
          .appendFunctionPropertyPath("changeStatus"),
        request,
      ),
    );

    await this.getSelected(request.ticket.id);
  }

  public async closeAssociatedTickets(
    request: HelpAssistTicketCloseAssociatedTicketsRequest,
    ticket: HelpAssistTicket,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(ticket.id)
          .appendFunctionPropertyPath("closeAssociatedTickets"),
        request,
      ),
    );

    await this.getSelected(ticket.id);
  }

  public async assignUser(
    request: HelpAssistTicketAssignUserRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(request.ticket.id)
          .appendFunctionPropertyPath("assignUser"),
        request,
      ),
    );

    await this.getSelected(request.ticket.id);
  }

  public async assignAppointment(
    request: HelpAssistTicketAssignAppointmentRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(request.ticket.id)
          .appendFunctionPropertyPath("assignAppointment"),
        request,
      ),
    );

    await this.getSelected(request.ticket.id);
  }

  public async markAllAsRead(context?: {
    partnerKey: UUID;
    siteId: Site["id"] | null;
  }): Promise<void> {
    const resource = helpTicketsResourceGlobal;

    const request = new HelpAssistMarkAllAsReadRequest({
      partnerKey: context?.partnerKey ?? null,
      siteId: context?.siteId ?? null,
    });

    try {
      this.markAllAsReadLoadingSubject.next(true);

      await firstValueFrom(
        this.request.post(
          resource.appendFunctionPropertyPath("markAllAsRead"),
          request,
        ),
      );

      this.markAllAsReadNotifierSubject.next();
    } finally {
      this.markAllAsReadLoadingSubject.next(false);
    }
  }

  public async requestAppointment(
    request: HelpAssistAppointmentRequest,
  ): Promise<HelpAssistTicketReference> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { id } = await firstValueFrom(
      this.request.post(
        resource.appendFunctionPropertyPath("requestAppointment"),
        request,
      ),
    );
    return { id, site: request.site };
  }

  public async approveTicketAppointment(
    request: HelpAssistTicketApproveOrDeclineRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(request.ticket.id)
          .appendFunctionPropertyPath("approveAppointment"),
        request,
      ),
    );
  }

  public async denyTicketAppointment(
    request: HelpAssistTicketApproveOrDeclineRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource
          .appendId(request.ticket.id)
          .appendFunctionPropertyPath("declineAppointment"),
        request,
      ),
    );
  }

  public async hasScheduledTicketFor(
    request: HelpAssistHasScheduledTicketForRequest,
  ): Promise<HelpAssistTicketReference | null> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const response = await firstValueFrom(
      this.request.post(
        resource.appendFunctionPropertyPath("hasScheduledTicketFor"),
        request,
      ),
    );
    return response?.value ? { id: response.value, site: request.site } : null;
  }

  public async update(
    update: HelpAssistTicketUpdate,
  ): Promise<PartnerHelpAssistTicket> {
    const id = update.base
      ? await this.patch(update.base.id, update)
      : await this.post(update);

    const value = await this.get(id);
    this.ticketUpdateSubject.next(value);
    return value;
  }

  public async updateDetails(
    request: HelpAssistTicketDetailsUpdateRequest,
  ): Promise<void> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);

    await firstValueFrom(
      this.request.post(
        resource.appendFunctionPropertyPath("updateDetails"),
        request,
      ),
    );

    await this.getSelected(request.ticket.id);
  }

  public async addAttachment(
    ticket: HelpAssistTicket,
    file: File,
  ): Promise<Attachment["id"]> {
    const attachmentId = await this.attachments.update(ticket, file);
    await this.getSelected(ticket.id);
    return attachmentId;
  }

  public async deleteAttachmentFile(attachment: Attachment): Promise<void> {
    await this.attachments.delete(attachment);
    await this.getSelected(attachment.ticket.id);
  }

  public async addComment(
    update: HelpAssistEventCreation,
  ): Promise<HelpAssistEvent["id"]> {
    const eventId = await this.helpTicketEvents.post(update);
    await this.getSelected(update.ticket.id);
    return eventId;
  }

  private async get(
    id: HelpAssistTicket["id"],
  ): Promise<PartnerHelpAssistTicket> {
    const resource = await firstValueFrom(this.itemBaseResourceChanges);
    const apiModel = await firstExistentValueFrom(
      this.request.get(resource.appendId(id)),
    );

    const args = await firstExistentValueFrom(this.partnerDeserializeArguments);
    return PartnerHelpAssistTicket.deserialize(apiModel, args);
  }

  private async post(
    update: HelpAssistTicketUpdate,
  ): Promise<HelpAssistTicket["id"]> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { id } = await firstValueFrom(this.request.post(resource, update));
    return id;
  }

  private async patch(
    id: HelpAssistTicket["id"],
    update: HelpAssistTicketUpdate,
  ): Promise<HelpAssistTicket["id"]> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    await firstValueFrom(this.request.patch(resource.appendId(id), update));
    return id;
  }
}
