import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormComponent } from "src/app/core/form.component";
import { SortableOption } from "src/app/core/forms/sortable-list.component";
import { Modal } from "src/app/core/modal.service";

@Component({
  selector: "mr-display-fields-selector-modal",
  template: `
    <mr-modal [header]="modal.context.header">
      <p>
        <ng-container *ngIf="modal.context.limit; else noLimitDescription">
          Select up to {{ modal.context.limit }} items and drag and drop and
          click arrow to rearrange content.
        </ng-container>
        <ng-template #noLimitDescription>
          Drag and drop and click arrows to rearrange columns
        </ng-template>
      </p>

      <form [formGroup]="form">
        <mr-sortable-list
          formControlName="fields"
          [options]="modal.context.options"
          [selected]="modal.context.selectedOptions"
          [limit]="modal.context.limit"
        ></mr-sortable-list>
      </form>

      <ng-template mrModalActions>
        <mr-button (click)="save()" [disabled]="!canSubmit"
          >Save Changes</mr-button
        >
        <mr-button kind="cancel" (click)="close()">Cancel</mr-button>
      </ng-template>
    </mr-modal>
  `,
  styles: ["mr-modal { max-width: 410px; }"],
  providers: [Modal],
})
export class DisplayFieldsSelectorModalComponent<
  T extends string,
> extends FormComponent<{
  fields: readonly T[];
}> {
  public constructor(
    public readonly modal: Modal<
      DisplaySelectorModalContext<T>,
      readonly T[] | undefined
    >,
    formBuilder: FormBuilder,
  ) {
    super(formBuilder, { fields: [null] });
  }

  public async save(): Promise<void> {
    await this.runSubmit(async ({ fields }) => this.modal.choose(fields));
  }

  public async close(): Promise<void> {
    await this.modal.choose(undefined);
  }
}

interface DisplaySelectorModalContext<T extends string> {
  readonly header: string;
  readonly limit: number | null;
  readonly options: ReadonlyArray<SortableOption<T>>;
  readonly selectedOptions: ReadonlyArray<SortableOption<T>>;
}
