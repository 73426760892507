import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.ActivityUpdate>();

export enum ActivityAction {
  Cancel = "Cancel",
  Exception = "Exception",
  Modify = "Modify",
  NoShowNoCallCancel = "NoShowNoCallCancel",
  OnComplex = "OnComplex",
  Schedule = "Schedule",
}

export interface ReasonCode {
  customRecipients: string | null;
  iconName: string | null;
  isAppointmentEmailsNotificationEnabled: boolean;
  isBuyerEmailNotificationEnabled: boolean;
  system: boolean;
  title: string;
}

export class ActivityAppointmentStatus {
  public constructor(args: ClassProperties<ActivityAppointmentStatus>) {
    this.appointmentStatusId = args.appointmentStatusId;
    this.isDefault = args.isDefault;
  }

  public readonly appointmentStatusId: number;
  public readonly isDefault: boolean;
}

export abstract class BaseActivity {
  protected constructor(args: ClassProperties<BaseActivity>) {
    this.action = args.action;
    this.commentsRequired = args.commentsRequired;
    this.isActive = args.isActive;
    this.reasonCodes = args.reasonCodes;
    this.site = args.site;
    this.title = args.title;
  }

  @api() public readonly action: ActivityAction;
  @api() public readonly commentsRequired: boolean;
  @api({ key: "active" }) public readonly isActive: boolean;
  @api({ key: "reasonChoices" }) public readonly reasonCodes: ReasonCode[];
  @api({ key: "siteID" }) public readonly site: Site;
  @api() public readonly title: string;
}
