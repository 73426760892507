import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { firstValueFrom, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ODataResourceUrl } from "src/utils";

export class Pagination {
  private readonly requestNotifierSubject = new Subject<string>();
  /**
   * Emits a notification with the page link whenever a request to load a new
   * page is received.
   */
  public readonly requestNotifier = this.requestNotifierSubject.asObservable();

  private readonly pageChanges = new ReplaySubject<Page>(1);

  public readonly hasPrevious = this.pageChanges.pipe(
    map((page) => page.hasPrevious),
  );
  public readonly hasNext = this.pageChanges.pipe(map((page) => page.hasNext));

  /**
   * Reset paging from the beginning, clearing out all previous link info.
   * @param resource The resource URL to start paging on.
   * @param result The results of the first page request.
   */
  public reset<T>(
    resource: ODataResourceUrl<Api.ODataList<T>>,
    result: Api.ODataList<T>,
  ): void {
    const page = new Page();
    page.load(resource.toString(), result);
    this.pageChanges.next(page);
  }

  /**
   * Loads a new page at the provided link with that link's request results.
   * This ensures we have the appropriate "next" link for the given page link.
   * @param link The next or previous page link to load.
   * @param result The results of `link` request.
   */
  public async loadPage(
    link: string,
    result: Api.ODataList<unknown>,
  ): Promise<void> {
    const page = await firstValueFrom(this.pageChanges);
    page.load(link, result);
    // Re-emit the page so that the hasPrevious and hasNext observables update.
    this.pageChanges.next(page);
  }

  /**
   * Initiate a request to load the previous page of results.
   */
  public async requestPrevious(): Promise<void> {
    const page = (await firstValueFrom(this.pageChanges)).getPrevious();
    if (page) {
      this.requestNotifierSubject.next(page.link);
    }
  }

  /**
   * Initiate a request to load the next page of results.
   */
  public async requestNext(): Promise<void> {
    const page = (await firstValueFrom(this.pageChanges)).getNext();
    if (page) {
      this.requestNotifierSubject.next(page.link);
    }
  }
}

class Page {
  private info?: PageInfo;

  public get hasPrevious(): boolean {
    return !!this.info?.previous;
  }

  public get hasNext(): boolean {
    return !!this.info?.next;
  }

  public load(
    link: string,
    { "@odata.nextLink": nextLink }: Api.ODataList<unknown>,
  ): void {
    const info = this.info?.next ?? { link };
    info.next = nextLink ? { previous: info, link: nextLink } : undefined;

    this.info = info;
  }

  public getPrevious(): PageInfo | undefined {
    const info = this.info?.previous;
    if (info) {
      // Since there is no previous page link on the resource, we simulate a
      // previous page load by moving back two pages and requesting to load the
      // next page.
      this.info = info.previous;
    }
    return info;
  }

  public getNext(): PageInfo | undefined {
    return this.info?.next;
  }
}

interface PageInfo {
  previous?: PageInfo;
  link: string;
  next?: PageInfo;
}
