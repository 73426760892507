import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ElementsModule } from "../elements/elements.module";
import { FormsModule } from "../forms/forms.module";
import { ColumnManagementModalComponent } from "./column-management-modal.component";
import { DateRangeFilterComponent } from "./date-range-filter.component";
import { DaysFilterComponent } from "./days-filter.component";
import { TableColumnFilterComponent } from "./table-column-filter.component";
import { TableColumnSortComponent } from "./table-column-sort.component";
import { TableItemDirective } from "./table-item.directive";
import { TableStickyColumnsDirective } from "./table-sticky-columns.directive";
import { TableComponent } from "./table.component";

@NgModule({
  imports: [CommonModule, ElementsModule, FormsModule],
  declarations: [
    ColumnManagementModalComponent,
    DateRangeFilterComponent,
    DaysFilterComponent,
    TableColumnFilterComponent,
    TableColumnSortComponent,
    TableComponent,
    TableItemDirective,
    TableStickyColumnsDirective,
  ],
  exports: [TableComponent, TableItemDirective],
})
export class TableModule {}
