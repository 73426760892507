<mr-field-container
  #container
  [customFieldId]="fieldId"
  [label]="label"
  [description]="description"
  [errorsHidden]="true"
>
  <div>
    <mr-checkbox class="all-day-box" (valueChange)="onEveryDayChange($event)"
      >Every Day</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.sunday : null"
      (valueChange)="onDayChecked('sunday', $event)"
      [disabled]="isEveryDayChecked"
      >Sunday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.monday : null"
      (valueChange)="onDayChecked('monday', $event)"
      [disabled]="isEveryDayChecked"
      >Monday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.tuesday : null"
      (valueChange)="onDayChecked('tuesday', $event)"
      [disabled]="isEveryDayChecked"
      >Tuesday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.wednesday : null"
      (valueChange)="onDayChecked('wednesday', $event)"
      [disabled]="isEveryDayChecked"
      >Wednesday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.thursday : null"
      (valueChange)="onDayChecked('thursday', $event)"
      [disabled]="isEveryDayChecked"
      >Thursday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.friday : null"
      (valueChange)="onDayChecked('friday', $event)"
      [disabled]="isEveryDayChecked"
      >Friday</mr-checkbox
    >
    <mr-checkbox
      [value]="value ? value.saturday : null"
      (valueChange)="onDayChecked('saturday', $event)"
      [disabled]="isEveryDayChecked"
      >Saturday</mr-checkbox
    >
  </div>
</mr-field-container>

<!-- Handle errors here exclusively for this component. -->
<ul *ngIf="container.getErrors() as errors">
  <li *ngIf="errors['required']">{{ container.errorLabel }} is required</li>
</ul>
