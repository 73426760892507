import { NgIfContext } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { Permission } from "src/app/core/auth";

@Directive({ selector: "[mrPageHeaderDetails]" })
export class PageHeaderDetailsDirective {}

@UntilDestroy()
@Component({
  selector: "mr-page[header],mr-page[headerTemplate]",
  templateUrl: "./page.component.html",
  styleUrls: ["page.component.scss"],
})
export class PageComponent implements AfterViewInit {
  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  @Input() public header: string | null = null;
  @Input() public headerTemplate?: TemplateRef<NgIfContext<boolean>>;
  @Input() public subHeader: string | null = null;
  @Input() public subHeaderTemplate?: TemplateRef<NgIfContext<boolean>>;
  @Input() public superHeader: string | null = null;
  @Input() public hideSeparator?: boolean;
  @Input() public kind: "main" | "table" | "form" | "setting" = "main";
  @Input() public helpButtonTemplate?: TemplateRef<void>;
  @Input() public backLink: string | null = null;
  @Input() public backLinkLabel: string | null = null;

  @ContentChild(PageHeaderDetailsDirective, { read: TemplateRef })
  public headerDetails?: TemplateRef<void>;

  @ViewChild("section", { static: true })
  public readonly sectionElement!: ElementRef<HTMLElement>;

  public readonly Permission = Permission;

  private readonly scrollSubject = new BehaviorSubject<Scroll>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });
  public readonly scrollChanges = this.scrollSubject.asObservable();

  public ngAfterViewInit(): void {
    // Auto scroll to the top of the page if the fragment "top" exists
    this.route.fragment
      .pipe(
        filter((fragment) => fragment === "top"),
        // Have to compute the URL from the route since we can't navigate using
        // `relativeTo` to stay on the same page because the `ActivatedRoute`
        // isn't updated (in time) to have the right value here.
        map(() => {
          // We need to specify an arbitrary base URL for parsing the router URL
          // which has no base. It isn't ever used for any actual navigation.
          const baseUrl = "https://non-existent-path.capstonelogistics.com";
          const url = new URL(this.router.url, baseUrl);
          // Remove the hash/fragment from the current URL so we can navigate to
          // the same route without it.
          url.hash = "";
          // Remove the arbitrary base to get the new route.
          return url.href.replace(baseUrl, "");
        }),
        switchMap((route) =>
          // Use `replaceUrl` so we don't break the back button.
          this.router.navigateByUrl(route, { replaceUrl: true }),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // Ideally, this should be replaced by `scrollPositionRestoration` on
        // the RouterModule root definition, but it doesn't seem to work.
        // Possibly related to the fact that this section is what's being
        // scrolled rather than the root/body element?
        // TODO: Figure out how to make `scrollPositionRestoration` work.
        this.sectionElement.nativeElement.scrollTo({
          behavior: "smooth",
          left: 0,
          top: 0,
        });
      });
  }

  public onScroll(): void {
    const { scrollHeight, scrollLeft, scrollTop, scrollWidth } =
      this.sectionElement.nativeElement;
    this.scrollSubject.next({
      height: scrollHeight,
      left: scrollLeft,
      top: scrollTop,
      width: scrollWidth,
    });
  }
}

interface Scroll {
  readonly height: number;
  readonly left: number;
  readonly top: number;
  readonly width: number;
}
