import { APP_BASE_HREF } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus, OIDC_DEFAULT_SCOPES } from "@azure/msal-browser";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { from } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { appendUrlPath, isNot, throwUnhandledCaseError } from "src/utils";
import { UserService } from "./core/auth";

@UntilDestroy()
@Component({
  selector: "mr-login",
  template: "<mr-loading></mr-loading>",
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
      }
      mr-loading {
        margin: auto;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  public constructor(
    @Inject(APP_BASE_HREF) private readonly appBasePath: string,
    private readonly msal: MsalService,
    private readonly msalBroadcast: MsalBroadcastService,
    private readonly router: Router,
    private readonly user: UserService,
  ) {}

  public readonly loginStatusChanges = this.msalBroadcast.inProgress$.pipe(
    filter((status) => status === InteractionStatus.None),
    switchMap(() => {
      const accounts = this.msal.instance.getAllAccounts();
      if (accounts.length === 0) {
        // No accounts available after "interaction session" ended so something
        // must have gone wrong with the login.
        return ["failed" as const];
      } else {
        return from(this.user.isLoaded).pipe(map(() => "loaded" as const));
      }
    }),
    startWith("loading" as const),
  );

  public ngOnInit(): void {
    this.loginStatusChanges
      .pipe(
        filter(isNot("loading" as const)),
        switchMap((status) => {
          switch (status) {
            case "failed": {
              const baseUrl = appendUrlPath(
                window.location.origin,
                this.appBasePath,
              );

              // Login failed, possibly due to a stale login form session, so
              // try to login silently again with a fresh session.
              // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3542#issuecomment-827727976
              return this.msal.loginRedirect({
                // I'm not sure what scopes are ultimately required but this is
                // what's used when no object is provided to `loginRedirect` and
                // it seems to work fine. We're using the "default"
                // `loginRedirect` for some error cases in `RootComponent` also
                // without issue.
                scopes: OIDC_DEFAULT_SCOPES,
                // After logging in, redirect to any other page than this
                // `/login` page or else the user will be stuck here.
                redirectStartPage: baseUrl.href,
              });
            }
            case "loaded": {
              return this.router.navigateByUrl("/");
            }
            default: {
              throwUnhandledCaseError("login loading status", status);
            }
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
