import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getSiteRouteUrl, Site, SiteModelReference } from "src/app/core/sites";
import { getApiDetailsDecorator } from "src/utils";
import { HelpAssistTicketType } from "../../help-assist-enums";

const api = getApiDetailsDecorator<Api.HelpAssistTicket>();

export abstract class BaseHelpAssistTicket {
  protected constructor(args: ClassProperties<BaseHelpAssistTicket>) {
    this.emailAddress = args.emailAddress;
    this.phoneNumber = args.phoneNumber;
    this.site = args.site;
    this.type = args.type;
  }

  @api() public readonly type: HelpAssistTicketType;
  @api() public readonly emailAddress: string;
  @api() public readonly phoneNumber: string | null;
  @api({ key: "siteId", navigationProperty: "site", uiModel: Site })
  public readonly site: Site;
}

export function getHelpAssistTicketRouteUrl(
  ticket: HelpAssistTicketReference,
  ...childPaths: string[]
): string {
  return getSiteRouteUrl(
    ticket.site,
    "help-assist",
    String(ticket.id),
    ...childPaths,
  );
}

export interface HelpAssistTicketReference
  extends SiteModelReference<"help-assist-ticket"> {}
