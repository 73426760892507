<div
  *ngIf="fixedColumnsChanges | async as fixedColumns"
  mrTableStickyColumns
  [stickyColumnCount]="fixedColumns.length"
  [class.help-assist-table]="kind === 'help-assist'"
>
  <table
    *ngIf="displayedColumnsChanges | async as columns"
    class="mr-table"
    [class.sticky-table]="fixedColumns.length > 0"
  >
    <thead>
      <tr>
        <th *ngFor="let column of columns">
          <mr-table-column-sort
            *ngIf="isSortEnabled && column.isSortable; else columnHeaderContent"
            [column]="column"
          >
            <ng-container
              [ngTemplateOutlet]="columnHeaderContent"
            ></ng-container>
          </mr-table-column-sort>
          <ng-template #columnHeaderContent>
            <ng-container
              *ngIf="column.headerTemplate; else headerText"
              [ngTemplateOutlet]="column.headerTemplate"
            ></ng-container>
            <ng-template #headerText
              ><ng-container *ngIf="!column.hideHeaderText">{{
                column.header
              }}</ng-container></ng-template
            >
          </ng-template>
        </th>

        <th class="utility-column" *ngIf="isUtilityColumnVisible">
          <mr-icon-button
            *ngIf="areColumnsConfigurable"
            label="Manage Columns"
            (click)="openColumnManagement()"
          >
            <mr-icon name="add"></mr-icon>
          </mr-icon-button>
        </th>
      </tr>

      <tr *ngIf="isFilteringEnabled" class="filter-row">
        <th *ngFor="let column of columns">
          <mr-table-column-filter
            *ngIf="column.hasFilter"
            label="{{ column.header }} Filter"
            [defaultValue]="column.filter"
            [key]="column.key"
            [type]="column.filterType"
            [dropdownData]="column.filterDropdownData"
            [dropdownSearchNotifier]="column.filterDropdownSearchNotifier"
            [durationScale]="column.filterDurationScale"
            [trueLabel]="column.filterTrueLabel"
            [falseLabel]="column.filterFalseLabel"
            (valueChange)="updateFilter($event)"
          ></mr-table-column-filter>
        </th>

        <th class="utility-column">
          <button class="clear-all-button" (click)="clearFilters()">
            <mr-icon name="close" aria-hidden="true"></mr-icon>
            <span>Clear All</span>
          </button>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let record of dataChanges | async; let index = index"
        (click)="emitRowClick(record)"
        [class.row-click-pointer]="isRowClickEnabled"
        [ngClass]="getRowClasses(record, index)"
      >
        <ng-container *ngFor="let column of columns">
          <ng-container
            *ngTemplateOutlet="
              column.template;
              context: { $implicit: record, index: index }
            "
          ></ng-container>
        </ng-container>
        <td class="utility-column" *ngIf="isUtilityColumnVisible"></td>
      </tr>
    </tbody>
  </table>
</div>

<div
  *ngIf="dataChanges | async"
  class="show-more"
  [style.left.px]="pageScrollLeftPositionChanges | async"
>
  <button
    *ngIf="arePreviousResultsAvailable | async"
    (click)="loadPreviousPage()"
  >
    Show Previous Page
  </button>

  <button *ngIf="areNextResultsAvailable | async" (click)="loadNextPage()">
    Show Next Page
  </button>
</div>

<ng-container *ngIf="dataChanges | async as loadedData; else loading">
  <ng-container *ngIf="loadedData.length; else empty"></ng-container>
</ng-container>

<ng-template #loading>
  <mr-loading></mr-loading>
</ng-template>

<ng-template #empty>
  <ng-content select="mr-empty-state"></ng-content>
</ng-template>
