import { Directive, TemplateRef } from "@angular/core";

export interface TicketAppointmentDetailsEntryDirectiveContext<T> {
  value: T;
}

@Directive({ selector: "[mrTicketAppointmentDetailsEntry]" })
export class TicketAppointmentDetailsEntryDirective<T> {
  public constructor(
    public template: TemplateRef<
      TicketAppointmentDetailsEntryDirectiveContext<T>
    >,
  ) {}
}
