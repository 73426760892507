import { Duration } from "luxon";
import {
  BehaviorSubject,
  concat,
  firstValueFrom,
  from,
  Observable,
  OperatorFunction,
  Subscription,
  timer,
} from "rxjs";
import { filter, map, pluck, switchMap, takeUntil } from "rxjs/operators";
import { isNot } from "./miscellaneous";

/** A cache manager for an API request's response value. */
export class RequestCache<T> {
  /**
   * Creates a new cache manager for an API request's response value.
   * @param timeout - The duration that the cached value should live for.
   * (Default: 1 minute)
   */
  public constructor(
    private readonly timeout = Duration.fromObject({ minutes: 1 }),
  ) {}

  private readonly cache = new BehaviorSubject<{
    readonly key: string;
    readonly value: T | null | typeof expired;
  }>({ key: "", value: expired });

  private cacheUpdateSubscription?: Subscription;

  /** Gets the latest value in the cache. */
  public getValue(): Promise<T | undefined> {
    return firstValueFrom(
      this.cache.pipe(
        pluck("value"),
        // Skip the loading indicator (`null`) and wait for the final value.
        filter(isNot(null)),
        map((value) => (value === expired ? undefined : value)),
      ),
    );
  }

  /**
   * Attach an observable stream to the cache, emitting the current value if
   * available and loading all subsequent source emissions into the cache.
   * @param key - The unique key of the cache item to retrieve, if available.
   */
  public load(key: string): OperatorFunction<T | null, T | null> {
    return (valueChanges) =>
      from(this.updateSubscription(key, valueChanges)).pipe(
        switchMap(() => this.cache),
        pluck("value"),
        filter(isNot(expired)),
      );
  }

  /**
   * Resubscribe to the value observable in order to re-request it so that it's
   * no longer stale if the current value in the cache is expired or for a
   * different key.
   */
  private async updateSubscription(
    key: string,
    valueChanges: Observable<T | null>,
  ): Promise<void> {
    const cache = await firstValueFrom(this.cache);
    if (cache.value !== expired && cache.key === key) {
      // Maintain the current subscription if it's still valid.
      return;
    }

    this.cacheUpdateSubscription?.unsubscribe();

    this.cacheUpdateSubscription = concat(
      valueChanges.pipe(
        map((value) => ({ key, value })),
        takeUntil(timer(this.timeout.as("milliseconds"))),
      ),
      [{ key, value: expired } as const],
    ).subscribe({
      next: (value) => this.cache.next(value),
      error: (error) => this.cache.error(error),
    });
  }
}

const expired = Symbol("cache expired");
