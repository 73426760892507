import { DateTime } from "luxon";
import { Day } from "src/app/core/day.model";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { UUID } from "../codecs";
import { formatDateTime } from "../converters";
import { isExistent } from "../miscellaneous";
import {
  ExtendODataModel,
  FilterODataModel,
  FunctionPropertyField,
  FunctionPropertyType,
  NavigationPropertyCountModel,
  NavigationPropertyField,
  NavigationPropertyType,
  ODataModel,
  ReplaceODataModel,
} from "./odata-resource-types";
import { Options, Path, ResourceUrl } from "./resource-url";

/**
 * The API model type of an `ODataResourceUrl`, including collection
 * information, if applicable.
 * @template T The `ODataResourceUrl` type to extract the model type from.
 */
export type ODataResourceModel<T> = T extends ODataResourceUrl<
  infer Resource,
  unknown,
  infer _ResourceModel
>
  ? Resource
  : never;

interface ODataReference {
  "@odata.id": string;
}

export interface ODataOptions extends Options {
  includeMetadata: boolean;
  maxPageSize?: number;
}

/**
 * Represents a URL for an OData API resource with many helper functions for
 * the various filtering, selecting, etc. actions on a resource.
 *
 * **Note:** This is an immutable object. All methods return a new instance
 * of the class with the updated properties.
 * @template Resource The type of the API model or collection of models this
 * resource URL represents.
 */
export class ODataResourceUrl<
  // Using `any` is safe when extending but could still lead to lost type info.
  // TODO: figure out how to type this better.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Resource = any,
  ResourceName = unknown,
  ResourceModel = ODataModel<Resource>,
> extends ResourceUrl<ODataOptions> {
  /**
   * Create a new instance of `ODataResourceUrl`.
   *
   * **Note:** This is an immutable object. All methods return a new instance
   * of the class with the updated properties.
   * @param path The relative resource path.
   * @param options.api The name of the API endpoint this resource exists on.
   * @param options.includeMetadata Whether to include full metadata in the
   * resource.
   */
  public constructor(path: Path, options: Partial<ODataOptions> = {}) {
    super(path, options);
    const { includeMetadata = false, maxPageSize } = options;
    this.includeMetadata = includeMetadata;
    this.maxPageSize = maxPageSize;
  }

  private filterKeyPrefix?: string;
  private filters: readonly string[] = [];
  private expandFields: readonly string[] = [];
  private queryFields: readonly string[] = [];
  private sortOrders: ReadonlyArray<{
    readonly field: string;
    readonly direction: "asc" | "desc";
  }> = [];

  /**
   * Whether full metadata should be included in the resource.
   */
  public readonly includeMetadata: boolean;

  /**
   * The maximum page size to request from the API for collection resources.
   * Note: cannot exceed the API's max.
   */
  public readonly maxPageSize: number | undefined;

  /**
   * Add arguments for a function resource.
   * @param args - The arguments as a key-value object.
   * @param aliases - Which arguments to add as query parameter aliases.
   */
  public addFunctionArguments<T extends object>(
    args: T,
    { aliases = [] }: { aliases?: ReadonlyArray<keyof T> } = {},
  ): this {
    const aliasedValues = new Map<string, string>();

    const argString = Object.entries(args)
      .filter(([, value]) => isExistent(value))
      .map(([key, value]) => {
        if (aliases.includes(key)) {
          const alias = `@${key}`;
          aliasedValues.set(alias, formatValue(value));
          return `${key}=${alias}`;
        } else {
          return `${key}=${formatValue(value)}`;
        }
      })
      .join(",");

    let currentPath = this.setPath(`${this.path}(${argString})`);

    // Adds query params if any
    for (const [alias, value] of aliasedValues.entries()) {
      currentPath = currentPath.setParam(alias, value);
    }

    return currentPath;
  }

  /**
   * Set the `$filter` query parameter to exactly the given value, overriding
   * any existing value, to provide a filter to the resource.
   * @param value The filter string to set.
   */
  public setFilterParam(value: string | null | undefined): this {
    this.filters = [];
    return value ? this.addRawFilter(value) : this.setParam("$filter", null);
  }

  /**
   * Add a field to the `$expand` query parameter to tell the resource to
   * provide the full object for the given property.
   *
   * **Note:** This requires a "Navigation Property" set on the resource for the
   * given field name in order to work.
   * @param field The field to expand.
   * @param callback A callback for manipulating the selected navigation
   * property before adding it to the query. This allows for doing nested
   * expands, filters, etc.
   */
  public addExpandField<
    Field extends NavigationPropertyField<ResourceModel>,
    ResultModel = NavigationPropertyType<ResourceModel, Field>,
  >(
    field: Field,
    callback?: (
      resource: ODataResourceUrl<
        NavigationPropertyType<ResourceModel, Field>,
        Field
      >,
    ) => ODataResourceUrl<ResultModel>,
  ): ODataResourceUrl<
    ExtendODataModel<Resource, Record<Field, ResultModel>>,
    ResourceName
  > {
    const fieldResource = new ODataResourceUrl<
      NavigationPropertyType<ResourceModel, Field>,
      Field
    >(field);

    const nestedResource = callback?.(fieldResource) ?? null;
    const value = nestedResource?.getNestingUrl() ?? field;
    const fields = [...this.expandFields, value];

    const resource = this.setParam("$expand", fields.join(","));
    resource.expandFields = fields;

    // Kind of hacky, but we need to pass on the metadata state from any child
    // resources that requires it or it'll never get set on the parent request.
    // This does potentially explode the amount of metadata we're including.
    return nestedResource?.includeMetadata
      ? resource.clone({ includeMetadata: true }).convertModelType()
      : resource.convertModelType();
  }

  public addExpandFieldAsCount<
    Field extends NavigationPropertyField<ResourceModel>,
  >(
    field: Field,
    callback?: (
      resource: ODataResourceUrl<
        NavigationPropertyType<ResourceModel, Field>,
        Field
      >,
    ) => ODataResourceUrl,
  ): ODataResourceUrl<
    ExtendODataModel<
      Resource,
      NavigationPropertyCountModel<ResourceModel, Field>
    >,
    ResourceName
  > {
    return this.addExpandField(field, (resource) => {
      const result = callback?.(resource) ?? resource;
      return result.withCount().top(0);
    }).convertModelType();
  }

  /**
   * Add a new filter to the resource `$filter` query parameter, joined with
   * `and` if a filter already exists.
   *
   * **Note:** this method doesn't provide any validation on the filter
   * provided. As such, prefer using the other filter methods instead wherever
   * possible.
   * @param value The exact filter string to add.
   */
  public addRawFilter(value: string): this {
    const filters = [...this.filters, value];
    const resource = this.setParam(
      "$filter",
      // Avoid wrapping a single filter in extra parentheses.
      filters.length === 1
        ? filters[0]
        : filters.map((filter) => `(${filter})`).join(" and "),
    );
    resource.filters = filters;
    return resource;
  }

  /**
   * Add a new logical operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists.
   * @param key - The key of the property to compare against.
   * @param operator - The logical operator (will be converted to OData operators).
   * @param value - The value to compare against the property with the operator.
   */
  public addFilter<K extends keyof ResourceModel>(
    key: K,
    operator: Operator,
    // We need to allow DateTime so it can be parsed added without the quotes
    // that we would get for a plain string. Ideally we could differentiate
    // the types on the ResourceModel between plain strings and Edm.DateTimes.
    value: string extends ResourceModel[K]
      ? ResourceModel[K] | DateTime | UUID
      : ResourceModel[K],
    options?: { ignoreCase?: boolean },
  ): this;

  /**
   * Add a new logical operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists.
   * @param key - The nested key of the property to compare against. The first
   * string is the navigation property to join on and the second is the property
   * key within it.
   * @param operator - The logical operator (will be converted to OData operators).
   * @param value - The value to compare against the property with the operator.
   */
  public addFilter<
    Field extends NavigationPropertyField<ResourceModel>,
    FieldType extends NonNullable<NavigationPropertyType<ResourceModel, Field>>,
    K extends StringKeys<FieldType>,
  >(
    key: [Field, K],
    operator: Operator,
    value: string extends FieldType[K]
      ? FieldType[K] | DateTime | UUID
      : FieldType[K],
    options?: { ignoreCase?: boolean },
  ): this;

  /**
   * @deprecated
   * If possible, use the `key: [NavigationProp, Key]` overload instead for
   * accessing nested properties, or make sure that the key actually exists on
   * the API model.
   */
  public addFilter(
    key: string,
    operator: Operator,
    value: unknown,
    options?: { ignoreCase?: boolean },
  ): this;

  public addFilter(
    key: string | string[],
    operator: Operator,
    value: unknown,
    { ignoreCase }: { ignoreCase?: boolean } = {},
  ): this {
    const normalizedKey = this.getNormalizedKey(key);
    const odataOperator = getODataOperator(operator);
    const formattedValue = formatValue(value);
    return this.addRawFilter(
      `${
        ignoreCase ? `tolower(${normalizedKey})` : normalizedKey
      } ${odataOperator} ${
        ignoreCase && typeof value === "string"
          ? formattedValue.toLowerCase()
          : formattedValue
      }`,
    );
  }

  /**
   * Add a filter, but only if the provided value exists (is not `null` or
   * `undefined`).
   * @param value The value to check the existence of.
   * @param filter The callback that will apply the filter if the value exists.
   * Passes through the resource and (type narrowed) value as arguments.
   */
  public addFilterIfExists<T>(
    value: T | null | undefined,
    filter: (resource: this, value: T) => this,
  ): this {
    return isExistent(value) ? filter(this, value) : this;
  }

  /**
   * Add a new filter, which is composed of several other filters joined by an
   * `or`, to the resource `$filter` query parameter, itself joined with to any
   * other existing filters with `and`.
   * @param getFiltered - A callback that accepts the current resource and
   * returns a set of filters applied to the resource that should be combined.
   */
  public addOrFilters(
    getFiltered: (resource: this) => ReadonlyArray<this | null>,
  ): this {
    const cloneResource = this.clone();
    // Clear the existing filters so we only include the newly added ones.
    cloneResource.setFilterParam(null);

    const filters = getFiltered(cloneResource)
      .map((resource) => resource?.workingUrl.searchParams.get("$filter"))
      .filter(isExistent);

    return filters.length > 0
      ? this.addRawFilter(
          // Avoid wrapping a single filter in extra parentheses.
          filters.length === 1
            ? filters[0]
            : filters.map((filter) => `(${filter})`).join(" or "),
        )
      : this;
  }

  /**
   * Add a filter that matches on **any** item within a navigation property
   * collection.
   *
   * @param field - The navigation property field to filter within.
   * @param getFiltered - A callback that accepts the current resource and
   * returns a resource with the navigation property filter applied.
   */
  public addNavigationPropertyAnyFilter<
    Field extends NavigationPropertyField<ResourceModel>,
    NestedResource extends ODataResourceUrl<
      NavigationPropertyType<ResourceModel, Field>,
      Field
    >,
  >(
    field: Field,
    getFiltered: (
      resource: Filterable<NestedResource>,
    ) => Filterable<NestedResource>,
  ): this {
    const prefix = "x";
    // Get the resource within the navigation property to get the right types.
    const cloneResource = this.clone({
      filterKeyPrefix: prefix,
    }).appendNavigationPropertyPath(field);
    // Clear the existing filters so we only include the newly added ones.
    cloneResource.setFilterParam(null);

    // We need to cast here because the `Filterable` types don't quite align.
    // This is likely just a very subtle incompatibility that would be pretty
    // hard to fully convince TS of the safety of. The "real" properties on the
    // cloned orders should match their "filterable" equivalents since we're
    // cloning the resource from the current one with the same nav prop type.
    const filteredResource = getFiltered(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cloneResource as any as Filterable<NestedResource>,
    );
    if (!(filteredResource instanceof ODataResourceUrl)) {
      // This would only happen if someone did something silly such as try to
      // pass back a manually created object that had the shape of the
      // "filterable" resource but wasn't actually one.
      throw new Error("Unexpected filtered resource type.");
    }
    const filter = filteredResource.workingUrl.searchParams.get("$filter");

    return filter
      ? this.addRawFilter(`${field}/any(${prefix}:${filter})`)
      : this;
  }

  /**
   * Add a new `contains` operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists. This will match
   * the `value` against the `key` property for a partial match.
   * @param key The key of the property to compare against.
   * @param value The value to find a partial match with in the property.
   */
  public addContainsFilter<
    K extends StringKeys<PickByValueType<ResourceModel, string>>,
  >(key: K, value: string): this {
    return this.addRawFilter(
      `contains(${this.getNormalizedKey(key)},${formatValue(value)})`,
    );
  }

  /**
   * Add a new `startsWith` operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists. This will match
   * the `value` against the `key` property for a partial match.
   * @param key The key of the property to compare against.
   * @param value The value to find a partial match with in the property.
   */
  public addStartsWithFilter<
    K extends StringKeys<PickByValueType<ResourceModel, string>>,
  >(key: K, value: string): this {
    return this.addRawFilter(
      getODataRawStartsWithFilter(this.getNormalizedKey(key), value),
    );
  }

  /**
   * Add a new `in` operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists. This will match
   * the `key` property against every `value` for a match.
   * @param key - The key of the property to compare against.
   * @param value - The array of values to compare against the property.
   */
  public addInFilter<K extends StringKeys<ResourceModel>>(
    key: K,
    value: ReadonlyArray<ResourceModel[K]>,
  ): this;

  /**
   * Add a new `in` operator filter to the resource `$filter` query
   * parameter, joined with `and` if a filter already exists. This will match
   * the `key` property against every `value` for a match.
   * @param key - The key of the property to compare against.
   * @param value - The array of values to compare against the property.
   * @param options.ignoreCase - Enables ignoring the case for string values.
   */
  public addInFilter<K extends StringKeys<ResourceModel>>(
    key: string extends ResourceModel[K] ? K : never,
    value: readonly string[],
    options: { ignoreCase: true },
  ): this;

  /**
   * Add a new `in` operator filter to the resource `$filter` query parameter,
   * joined with `and` if a filter already exists. This will match the nested
   * `key` property against every `value` for a match.
   * @param key - The nested key of the property to compare against. The first
   * string is the navigation property to join on and the second is the property
   * key within it.
   * @param value - The array of values to compare against the property.
   */
  public addInFilter<
    Field extends NavigationPropertyField<ResourceModel>,
    FieldType extends Exclude<
      NavigationPropertyType<ResourceModel, Field>,
      null | undefined
    >,
    K extends StringKeys<FieldType>,
  >(key: [Field, K], value: ReadonlyArray<FieldType[K]>): this;

  public addInFilter(
    key: string | string[],
    values: readonly unknown[],
    { ignoreCase }: { ignoreCase?: boolean } = {},
  ): this {
    const normalizedKey = this.getNormalizedKey(key);
    const formattedKey = ignoreCase
      ? `tolower(${normalizedKey})`
      : normalizedKey;
    const formattedValue = values
      .map((value) =>
        ignoreCase && typeof value === "string" ? value.toLowerCase() : value,
      )
      .map(formatValue)
      .join(",");
    return this.addRawFilter(`${formattedKey} in (${formattedValue})`);
  }

  /**
   * Add a new logical NOT operator filter to the resource `$filter` query
   * parameter, surrounding the provided filters with `not()`.
   * @param getFiltered - Callback that accepts the current resource and
   * returns a filtered resource with the filters to negate.
   */
  public addNotFilter(getFiltered: (resource: this) => this): this {
    const cloneResource = this.clone();
    // Clear the existing filters so we only include the newly added ones.
    cloneResource.setFilterParam(null);

    const filter =
      getFiltered(cloneResource).workingUrl.searchParams.get("$filter");

    return filter ? this.addRawFilter(`not(${filter})`) : this;
  }

  /**
   * Append a resource ID to the resource path, selecting a specific instance
   * of the collection resource.
   * @param id The resource ID to append.
   */
  public appendId(
    id: number | string,
  ): ODataResourceUrl<ResourceModel, ResourceName> {
    return this.appendPath(id).convertModelType();
  }

  /**
   * Add a path to select a function or action navigation property under a resource.
   * @param field The field name to append.
   */
  public appendFunctionPropertyPath<
    Field extends FunctionPropertyField<ResourceModel>,
  >(
    field: Field,
  ): ODataResourceUrl<FunctionPropertyType<ResourceModel, Field>, Field> {
    return this.appendPath(field).convertModelType();
  }

  /**
   * Add a path to select a navigation property under a resource.
   * @param field The field name to append.
   */
  public appendNavigationPropertyPath<
    Field extends NavigationPropertyField<ResourceModel>,
  >(
    field: Field,
  ): ODataResourceUrl<NavigationPropertyType<ResourceModel, Field>, Field> {
    return this.appendPath(field).convertModelType();
  }

  /**
   * Add a nested resource to the end of the current resource path. The API
   * model type will be changed to reflect the new nested resource.
   * @param resource The resource to take the path from and add to the end of
   * the current resource path.
   */
  public override appendPath<NewResourceName, NewResource>(
    resource: ODataResourceUrl<NewResource, NewResourceName>,
  ): ODataResourceUrl<NewResource, NewResourceName>;
  /**
   * @param path The path to add to the end.
   */
  public override appendPath(path: Path): this;
  public override appendPath<NewResource, NewResourceName>(
    resource: ODataResourceUrl<NewResource, NewResourceName> | Path,
  ): ODataResourceUrl<NewResource, NewResourceName> | this {
    return resource instanceof ODataResourceUrl
      ? super
          .appendPath(resource.path)
          .convertModelType<NewResource, NewResourceName>()
      : super.appendPath(resource);
  }

  public asReference(): ODataResourceUrl<ODataReference, ResourceName> {
    return this.appendPath("$ref").convertModelType();
  }

  /**
   * Set a filter to only include the resources with the given `active` state.
   * @param value Whether to filter only active resources (default: `true`).
   */
  public isActive(value = true): this {
    return this.addRawFilter(`active eq ${String(value)}`);
  }

  /**
   * Request to order the resources of a collection resource by the given field
   * in the given sort direction. This will stack with previously applied
   * orders, with the first applied being the primary order.
   * @param field The resource field to order by.
   * @param direction The direction to sort by; `asc` for ascending or `desc`
   * for descending (default: `asc`).
   */
  public orderBy(
    field: StringKeys<ResourceModel>,
    direction: "asc" | "desc" = "asc",
  ): this {
    const sortOrders = [...this.sortOrders, { field, direction }];
    const resource = this.setParam(
      "$orderby",
      sortOrders.map((order) => `${order.field} ${order.direction}`).join(","),
    );
    resource.sortOrders = sortOrders;
    return resource;
  }

  /**
   * Request to include only the specified fields from the resource.
   * @param fields The resource fields to select.
   */
  public select<Field extends StringKeys<ResourceModel>>(
    ...fields: [Field, ...Field[]]
  ): ODataResourceUrl<
    ReplaceODataModel<Resource, FilterODataModel<ResourceModel, Field>>,
    ResourceName
  > {
    // The count fields aren't valid in select filters, but in order to keep the
    // types easier/consistent, we have to include them in calls to `select` to
    // include those properties in the resultant model type. So filter them out
    // and no-op if there are no other selects to add. This could be eliminated
    // with a smarter (more complex) return type from this method and/or
    // `addExpandFieldAsCount`.
    const validFields = fields.filter(
      (field) => !field.endsWith("@odata.count"),
    );
    if (validFields.length === 0) {
      return this.convertModelType();
    }

    return this.setParam("$select", validFields.join(",")).convertModelType();
  }
  /**
   *
   * @param fields : strings[] where each field is a column whose data is to be fetched from the database
   * @returns OData Resource
   */
  // TODO: remove this method and use the `select` method defined above.
  public selectArr<Field extends StringKeys<ResourceModel>>(
    fields: string[],
  ): ODataResourceUrl<
    ReplaceODataModel<Resource, FilterODataModel<ResourceModel, Field>>,
    ResourceName
  > {
    // The count fields aren't valid in select filters, but in order to keep the
    // types easier/consistent, we have to include them in calls to `select` to
    // include those properties in the resultant model type. So filter them out
    // and no-op if there are no other selects to add. This could be eliminated
    // with a smarter (more complex) return type from this method and/or
    // `addExpandFieldAsCount`.
    const validFields = fields
      .filter((field) => !field.endsWith("@odata.count"))
      .filter(Boolean);
    if (validFields.length === 0) {
      return this.convertModelType();
    }
    return this.setParam("$select", validFields.join(",")).convertModelType();
  }

  /**
   * Request to receive at most the provided number of resources from a
   * collection resource.
   * @param count The number of resources to return.
   */
  public top(count: number): this {
    return this.setParam("$top", count.toString());
  }

  public withCount(): ODataResourceUrl<
    ExtendODataModel<Resource, { "@odata.count": number }>,
    ResourceName
  > {
    return this.setParam("$count", "true").convertModelType();
  }

  public asCount(): ODataResourceUrl<number, ResourceName> {
    return this.appendPath("$count").convertModelType();
  }

  /**
   * Add a filter to select only resources with any one of the given fields that
   * match the given query anywhere within the field value.
   * @param fields The fields to compare the query against.
   * @param query The query to compare to each field.
   */
  public withFieldsContaining(
    fields: FilterFields<ResourceModel>,
    query: string,
  ): this {
    const queryFields: string[] = [];
    for (const field of fields) {
      if (typeof field === "string") {
        queryFields.push(this.getNormalizedKey(field));
      } else {
        queryFields.push(
          ...field.fields.map((nestedField) =>
            this.getNormalizedKey([field.name, String(nestedField)]),
          ),
        );
      }
    }
    this.queryFields = queryFields;

    return this.addRawFilter(
      queryFields
        .map(
          (field) =>
            `contains(cast(${field},Edm.String),${formatValue(query)})`,
        )
        .join(" or "),
    );
  }

  /**
   * Include in the resource model the ID needed to reference the model for
   * updating Navigation Properties on some resources.
   */
  public withReferenceId(): ODataResourceUrl<
    ExtendODataModel<Resource, ODataReference>,
    ResourceName
  > {
    return this.clone({ includeMetadata: true }).convertModelType();
  }

  /**
   * Sets the maximum page size for a given collection request.
   * @param maxPageSize The page size.
   */
  public withMaxPageSize(maxPageSize: number): this {
    return this.clone({ maxPageSize });
  }

  protected override clone(
    options?: Partial<ODataOptions> & { readonly filterKeyPrefix?: string },
  ): this {
    const clone = super.clone(options);
    clone.filterKeyPrefix = options?.filterKeyPrefix ?? this.filterKeyPrefix;
    clone.filters = [...this.filters];
    clone.expandFields = [...this.expandFields];
    clone.queryFields = [...this.queryFields];
    clone.sortOrders = [...this.sortOrders];
    return clone;
  }

  private getNormalizedKey(key: string | string[]): string {
    const normalizedKey = Array.isArray(key) ? key.join("/") : key;
    return this.filterKeyPrefix
      ? `${this.filterKeyPrefix}/${normalizedKey}`
      : normalizedKey;
  }

  /**
   * Get the relative URL with query parameters in their "nested" format.
   * @example
   * const url = new ODataResourceUrl("docks").addFilter("id eq 1").top(10);
   * url.getNestingUrl() === "docks($filter=id eq 1;$top=10)";
   */
  private getNestingUrl(): string {
    const query = joinNestedParams(this.workingUrl.searchParams);
    return this.path + (query ? `(${query})` : "");
  }

  /**
   * Force a new base API model type so that other transformations can
   * return the expected type.
   */
  private convertModelType<NewResource, NewResourceName>(): ODataResourceUrl<
    NewResource,
    NewResourceName
  > {
    return this as unknown as ODataResourceUrl<NewResource, NewResourceName>;
  }
}

type FilterFields<Model> = [FilterField<Model>, ...Array<FilterField<Model>>];
type FilterField<Model> = StringKeys<Model> | NestedFilterField<Model>;
interface NestedFilterField<Model, PropName = NavigationPropertyField<Model>> {
  name: PropName;
  fields: FilterFields<NavigationPropertyType<Model, PropName>>;
}

function joinNestedParams(params: URLSearchParams): string {
  return Array.from(params)
    .map(([name, value]) => `${name}=${value}`)
    .join(";");
}

/**
 * Formats a value for use in an OData resource URL query such as a filter.
 *
 * **Note:** This is likely only required when adding a "raw" filter. Most
 * methods on `ODataResourceUrl` that accept a value will do this for you.
 * Prefer using those instead wherever possible.
 * @param value The value to format.
 */
function formatValue(value: unknown): string {
  // See here for example literal formats:
  // https://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part2-url-conventions.html#sec_PrimitiveLiterals
  if (DateTime.isDateTime(value)) {
    return formatDateTime(value);
  } else if (value instanceof Day) {
    return value.serialize();
  } else if (value instanceof TimeOfDay) {
    return value.serialize();
  } else if (value instanceof UUID) {
    return value.toString();
  } else if (typeof value === "string") {
    return `'${value.replace(/'/g, "''")}'`;
  } else {
    return JSON.stringify(value);
  }
}

type ODataOperator = "eq" | "ne" | "gt" | "ge" | "lt" | "le";
type Operator = "==" | "!=" | ">" | ">=" | "<" | "<=" | ODataOperator;

function getODataOperator(operator: Operator): ODataOperator {
  switch (operator) {
    case "==":
      return "eq";
    case "!=":
      return "ne";
    case ">":
      return "gt";
    case ">=":
      return "ge";
    case "<":
      return "lt";
    case "<=":
      return "le";
    default:
      return operator;
  }
}

/**
 * Creates a filter query string segment for matching from the start of a text
 * value.
 *
 * @private
 * This generally should not be used outside of the `resource-url` functions.
 *
 * @param property - The record property to match on.
 * @param value - The substring to match against the property.
 */
function getODataRawStartsWithFilter(property: string, value: string): string {
  // Because of SQL query and indexing efficiency issues, we can't use the
  // existing `startswith` OData function because it seems to ignore indexes in
  // the same way that `contains` does. Using `substring` seems much more
  // efficient.
  return `substring(${property},0,${value.length}) eq ${formatValue(value)}`;
}

type NestedFilterableMethod =
  | "addContainsFilter"
  | "addFilter"
  | "addInFilter"
  | "addOrFilters"
  | "addStartsWithFilter"
  | "withFieldsContaining";

type Filterable<Resource extends ODataResourceUrl> = {
  // We need to transform the return type of the filter methods so that they
  // don't _appear_ to return a full resource object. This keeps the invalid
  // methods (that don't filter properly or aren't supported when filtering with
  // a nested value) hidden even when chaining.
  //
  // To support this transformation, we need to handle every overload
  // individually by inferring each overload's return type and handling it one
  // at a time. Otherwise, TypeScript only picks the last overload type.
  //
  // So check for methods that are overloaded four times, then three, etc. and
  // process their return types until we've handled all possible overloads.
  //
  // Aside: if not for this TS limitation, this would be as simple as:
  // ```
  // type Filterable<Resource extends ODataResourceUrl> = {
  //   [K in NestedFilterableMethod]: (
  //     ...args: Parameters<Resource[K]>
  //   ) => Filterable<ReturnType<Resource[K]>>;
  // };
  // ```
  [K in NestedFilterableMethod]: Resource[K] extends {
    (...args: infer A1): infer R1;
    (...args: infer A2): infer R2;
    (...args: infer A3): infer R3;
    (...args: infer A4): infer R4;
  }
    ? R1 extends Resource
      ? R2 extends Resource
        ? R3 extends Resource
          ? R4 extends Resource
            ? {
                (...args: A1): Filterable<R1>;
                (...args: A2): Filterable<R2>;
                (...args: A3): Filterable<R3>;
                (...args: A4): Filterable<R4>;
              }
            : never
          : never
        : never
      : never
    : Resource[K] extends {
        (...args: infer A1): infer R1;
        (...args: infer A2): infer R2;
        (...args: infer A3): infer R3;
      }
    ? R1 extends Resource
      ? R2 extends Resource
        ? R3 extends Resource
          ? {
              (...args: A1): Filterable<R1>;
              (...args: A2): Filterable<R2>;
              (...args: A3): Filterable<R3>;
            }
          : never
        : never
      : never
    : Resource[K] extends {
        (...args: infer A1): infer R1;
        (...args: infer A2): infer R2;
      }
    ? R1 extends Resource
      ? R2 extends Resource
        ? {
            (...args: A1): Filterable<R1>;
            (...args: A2): Filterable<R2>;
          }
        : never
      : never
    : Resource[K] extends (...args: infer A1) => infer R1
    ? R1 extends Resource
      ? (...args: A1) => Filterable<R1>
      : never
    : never;
};
