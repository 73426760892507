interface FlaggedRunner {
  <T = void>(action: () => Promise<T> | T): Promise<T>;
  isRunning: boolean;
}

/**
 * Creates a function that keeps track of whether the provided `action` function
 * is running using a flag property (`isRunning`) on the function itself.
 *
 * @example
 * const run = getFlaggedRunner();
 * const slowAction = () => new Promise(resolve => setTimeout(resolve, 5000));
 * run(slowAction);
 * // `run.isRunning` will be `true` for 5 seconds, then return to `false`.
 */
export function getFlaggedRunner(): FlaggedRunner {
  const run = Object.assign(
    async <T = void>(action: () => Promise<T> | T) => {
      run.isRunning = true;
      try {
        return await action();
      } finally {
        run.isRunning = false;
      }
    },
    { isRunning: false },
  );

  return run;
}
