import {
  Component,
  HostBinding,
  Injectable,
  Input,
  Optional,
  Self,
  TemplateRef,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  NgControl,
  ValidationErrors,
} from "@angular/forms";
import { BooleanAttribute, parseBooleanAttribute } from "src/utils";

let counter = 0;
const defaultErrorLabel = "This field";

@Injectable()
export class FieldConfiguration {
  public isEditingExisting = false;
}

@Injectable()
export class FieldContainerParentService {
  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl | null,
  ) {}

  public get control(): AbstractControl | null {
    return this.ngControl?.control ?? null;
  }
}

@Component({
  selector: "mr-field-container",
  templateUrl: "field-container.component.html",
  styleUrls: ["field-container.component.scss"],
})
export class FieldContainerComponent {
  public constructor(
    private readonly parent: FieldContainerParentService | null,
    @Optional() private readonly config: FieldConfiguration | null,
  ) {}

  private readonly index = counter++;

  @Input() public label?: string;
  @Input() public description?: string;
  @Input() @HostBinding("class") public kind: FieldContainerVariant = "form";

  @Input()
  public additionalErrorMessageTemplate?: TemplateRef<AdditionalErrorMessagesContext>;

  @Input() public set labelHidden(value: BooleanAttribute) {
    this.isLabelHidden = parseBooleanAttribute(value);
  }
  public isLabelHidden = false;

  @Input() public set errorsHidden(value: BooleanAttribute) {
    this.areErrorsHidden = parseBooleanAttribute(value);
  }
  public areErrorsHidden = false;

  @Input()
  public set customFieldId(value: string | undefined) {
    this.#fieldId = value || this.defaultFieldId;
  }
  public get fieldId(): string {
    return this.#fieldId;
  }
  private readonly defaultFieldId = `mr-field-${this.index}`;
  #fieldId = this.defaultFieldId;

  public readonly labelId = `mr-field-label-${this.index}`;
  public readonly descriptionId = `mr-field-description-${this.index}`;

  public get errorLabel(): string {
    return this.label || defaultErrorLabel;
  }

  public getErrors(): ValidationErrors | null {
    if (!this.parent?.control) {
      // Nothing to validate if there's no form control attached to this field.
      return null;
    }

    if (!this.config?.isEditingExisting && this.parent.control.untouched) {
      // Don't show errors for untouched fields on a "create" form.
      return null;
    }

    return this.parent?.control.errors;
  }

  public isRequired(): boolean {
    // Try validating with an empty control value and see if the required error
    // result appears.
    if (
      this.parent?.control?.validator?.(new FormControl(null))?.["required"]
    ) {
      return true;
    }

    return false;
  }
}

export type FieldContainerVariant =
  | "form"
  | "inline-important"
  | "inline"
  | "tiled";

export interface AdditionalErrorMessagesContext {
  $implicit: ValidationErrors | null;
  errorLabel: string;
}
