import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { RequestService } from "src/app/core/request.service";
import { SitesService } from "src/app/core/sites";
import { HelpAssistEventCreation } from "./help-assist-event-creation.model";
import {
  expandedHelpTicketEventResource,
  HelpAssistEvent,
} from "./help-assist-event.model";

@Injectable({ providedIn: "root" })
export class HelpAssistEventsService {
  public constructor(
    private readonly request: RequestService,
    private readonly sites: SitesService,
  ) {}

  private readonly itemResourceChanges = this.sites
    .getResourceInSelected(expandedHelpTicketEventResource)
    .pipe(shareReplay(1));

  private readonly modifiableResourceChanges = this.itemResourceChanges;

  public async post(
    update: HelpAssistEventCreation,
  ): Promise<HelpAssistEvent["id"]> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { id } = await firstValueFrom(this.request.post(resource, update));
    return id;
  }
}
