import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "mr-notice-page[message]",
  template: `
    <section>
      <p>{{ message }}</p>
      <ng-container *ngIf="buttonText">
        <a *ngIf="link; else action" [href]="link">{{ buttonText }}</a>
        <ng-template #action>
          <mr-button size="large" (click)="buttonClick.emit()">
            {{ buttonText }}
          </mr-button>
        </ng-template>
      </ng-container>
    </section>
    <mr-logout-button></mr-logout-button>
  `,
  styleUrls: ["./notice-page.component.scss"],
})
export class NoticePageComponent {
  @Input() public message!: string;
  @Input() public link?: string;
  @Input() public buttonText?: string;

  @Output() public readonly buttonClick = new EventEmitter<void>();
}
