import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";

let counter = 0;

// TODO: Handle missing label property. Move label out of ng-content.
@Component({
  selector: "mr-checkbox",
  template: `
    <div>
      <input
        #checkbox
        type="checkbox"
        [disabled]="isDisabled"
        [attr.aria-label]="isLabelHidden ? label : null"
        [attr.aria-labelledby]="isLabelHidden ? null : labelId"
        [ngModel]="checked"
        (ngModelChange)="propagateChange($event)"
        (change)="$event.stopPropagation()"
        (blur)="onBlur()"
      />
      <label
        [attr.title]="isLabelHidden ? label : null"
        (click)="checkbox.click()"
        [id]="labelId"
      >
        <ng-container *ngIf="!isLabelHidden">
          <ng-container *ngIf="label; else labelContent">{{
            label
          }}</ng-container>
          <ng-template #labelContent><ng-content></ng-content></ng-template>
          <span class="description" *ngIf="description">{{ description }}</span>
        </ng-container>
      </label>
    </div>
  `,
  styleUrls: ["checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent extends BaseFieldComponent<boolean> {
  @Input() public get checked(): boolean {
    return this.value ?? false;
  }
  public set checked(value: boolean) {
    this.value = value;
  }

  private readonly index = counter++;

  public readonly labelId = `mr-checkbox-field-label-${this.index}`;
}
