export enum HelpAssistBookmarkAction {
  Add = "Add",
  Remove = "Remove",
}

export enum HelpAssistEventType {
  AppointmentApproved = "AppointmentApproved",
  AppointmentApprovedAdminOverride = "AppointmentApprovedAdminOverride",
  AppointmentDeclinedAdminOverride = "AppointmentDeclinedAdminOverride",
  AppointmentCreateApprovalRequested = "AppointmentCreateApprovalRequested",
  AppointmentDeclined = "AppointmentDeclined",
  AppointmentModifyApprovalRequested = "AppointmentModifyApprovalRequested",
  AppointmentRequested = "AppointmentRequested",
  Assigned = "Assigned",
  AwaitingReply = "AwaitingReply",
  Categorized = "Categorized",
  Closed = "Closed",
  Commented = "Commented",
  Created = "Created",
  EmailSent = "EmailSent",
  Expired = "Expired",
  InProgress = "InProgress",
  Modified = "Modified",
  Open = "Open",
  ReOpened = "ReOpened",
  Unassigned = "Unassigned",
  Uncategorized = "Uncategorized",
}

export enum HelpAssistTicketApprovalStatus {
  Approved = "Approved",
  AwaitingAdminApproval = "AwaitingAdminApproval",
  AwaitingCarrierApproval = "AwaitingCarrierApproval",
  Declined = "Declined",
}

export enum HelpAssistTicketStatus {
  AwaitingReply = "AwaitingReply",
  Closed = "Closed",
  Expired = "Expired",
  InProgress = "InProgress",
  Open = "Open",
}

export enum HelpAssistTicketType {
  General = "General",
  Scheduling = "Scheduling",
}
