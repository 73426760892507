import { Routes } from "@angular/router";
import { joinPath } from "src/utils";
import {
  multiplePermissionDataKey,
  permissionDataKey,
} from "./has-permission-guard.service";
import { isPermission, isPermissionArray, Permission } from "./permissions";

export type RoutePermission = [path: string, permission: Permission];

export function getDefaultRoutePermissions(
  routes: Routes,
  parentPath = "",
): readonly RoutePermission[] {
  let permissionsByPath: RoutePermission[] = [];

  for (const { path = "", data, children } of routes) {
    const fullPath = joinPath(parentPath, path);

    if (data && data[allowedAsDefaultDataKey] === true) {
      if (isPermission(data[permissionDataKey])) {
        permissionsByPath.push([fullPath, data[permissionDataKey]]);
      } else if (isPermissionArray(data[multiplePermissionDataKey])) {
        for (const permission of data[multiplePermissionDataKey]) {
          permissionsByPath.push([fullPath, permission]);
        }
      }
    }

    if (children) {
      permissionsByPath = [
        ...permissionsByPath,
        ...getDefaultRoutePermissions(children, fullPath),
      ];
    }
  }

  return permissionsByPath;
}

const allowedAsDefaultDataKey = "isAllowedAsDefault";
