import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";
import { Dock } from "../docks/dock.model";
import { DoorGroup } from "../door-groups/door-group.model";

const api = getApiDetailsDecorator<Api.DoorUpdate>();

export abstract class BaseDoor {
  protected constructor(args: ClassProperties<BaseDoor>) {
    this.displaySequence = args.displaySequence;
    this.dock = args.dock;
    this.doorGroup = args.doorGroup;
    this.isActive = args.isActive;
    this.maxUnitCount = args.maxUnitCount;
    this.minUnitCount = args.minUnitCount;
    this.name = args.name;
    this.physicalSequence = args.physicalSequence;
    this.priority = args.priority;
  }

  @api({ key: "sortOrder" }) public readonly displaySequence: number;
  @api({ key: "dockID" }) public readonly dock: Dock;
  @api({ key: "doorGroupID" }) public readonly doorGroup: DoorGroup;
  @api({ key: "active" }) public readonly isActive: boolean;
  @api({ key: "maxUnitCount" }) public readonly maxUnitCount: number;
  @api({ key: "minUnitCount" }) public readonly minUnitCount: number;
  @api() public readonly name: string;
  @api({ key: "sequence" }) public readonly physicalSequence: number;
  @api() public readonly priority: number;
}
