import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { getApiDetailsDecorator } from "src/utils";
import { DoorGroup } from "./door-group.model";

const api = getApiDetailsDecorator<Api.DoorGroupUpdate>();

export abstract class BaseDoorGroup {
  protected constructor(
    args: Override<
      ClassProperties<BaseDoorGroup>,
      { overflowDoorGroup(this: void): DoorGroup | null }
    >,
  ) {
    this.isActive = args.isActive;
    this.name = args.name;
    this.site = args.site;

    this.getOverflowDoorGroup = args.overflowDoorGroup;
  }

  @api({ key: "active" }) public readonly isActive: boolean;
  @api() public readonly name: string;
  @api({ key: "siteID" }) public readonly site: Site;

  @api({ key: "overflowDoorGroupID" })
  public get overflowDoorGroup(): DoorGroup | null {
    return this.getOverflowDoorGroup();
  }
  protected readonly getOverflowDoorGroup: () => DoorGroup | null;
}
