import { Component, Input } from "@angular/core";
import { BooleanAttribute, parseBooleanAttribute } from "src/utils";

@Component({
  selector: "mr-dropdown-action[buttonLabel]",
  templateUrl: "dropdown-action.component.html",
  styleUrls: ["dropdown-action.component.scss"],
})
export class DropdownActionComponent {
  @Input() public buttonLabel!: string;
  @Input() public set disabled(value: BooleanAttribute) {
    this.isDisabled = parseBooleanAttribute(value);
  }
  public isDisabled = false;
  public toggleMenu = false;
}
