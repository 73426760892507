import { UnknownRecord } from "io-ts";
import { isObject, pick } from "lodash-es";
import { DateTime } from "luxon";
import { Day } from "src/app/core/day.model";
import { getApiDetails, isApiIgnored, isApiPropertyClass } from "./decorators";
import { hasProperty } from "./miscellaneous";

/**
 * Get the list of updated properties between a base and an updated object.
 * @param base The object to use as the base of the comparison.
 * @param updates The object to check against the base for updated properties.
 */
function getUpdatedProperties<T extends object>(
  base: T,
  updates: T,
): Array<StringKeys<T>> {
  const updatedProperties: Array<StringKeys<T>> = [];
  for (const [key, value] of Object.entries(updates)) {
    if (key in base && isPropertyUpdated(value, base[key])) {
      updatedProperties.push(key);
    }
  }
  return updatedProperties;
}

function isPropertyUpdated<T>(a: T, b: T): boolean {
  if (hasId(a) && hasId(b)) {
    return a.id !== b.id;
  } else if (DateTime.isDateTime(a) && DateTime.isDateTime(b)) {
    // Compare milliseconds (since epoch) to ignore time zones.
    return a.toMillis() !== b.toMillis();
  } else if (a instanceof Day && b instanceof Day) {
    return !a.isSameDay(b);
  } else {
    return a !== b;
  }
}

function hasId<T>(value: T): value is T & { readonly id: number } {
  return (
    isObject(value) && hasProperty(value, "id") && typeof value.id === "number"
  );
}

export function getUpdatedPropertiesFilter<
  Model extends object,
  ApiModel extends object,
>(model: Model, base: Model | null, updates: Model): FilterPartial<ApiModel> {
  // Exclude any excess properties that may have been included so that they
  // don't raise missing API Details errors for irrelevant properties.
  const filteredUpdates = pick(updates, Object.keys(model));

  const updatedApiProps = base
    ? getUpdatedProperties(base, filteredUpdates)
        .filter((prop) => !isApiIgnored(base, prop))
        .flatMap((prop) => {
          const baseValue = base[prop];
          const updatesValue = filteredUpdates[prop];
          if (isApiPropertyClass(baseValue)) {
            return getUpdatedNestedProperties(prop, baseValue, updatesValue);
          }
          return [getApiDetails(model, prop).key];
        })
    : null;

  return (apiModel) =>
    updatedApiProps ? pick(apiModel, updatedApiProps) : apiModel;
}

function getUpdatedNestedProperties<T>(
  prop: string,
  baseValue: T & object,
  updateValue: T,
): string[] {
  if (!UnknownRecord.is(updateValue)) {
    throw new Error(
      `Update value for ${prop} is not a valid object type: ${String(
        updateValue,
      )}`,
    );
  }
  return getUpdatedProperties(baseValue, updateValue)
    .filter((subProp) => !isApiIgnored(baseValue, subProp))
    .map((subProp) => getApiDetails(baseValue, subProp).key);
}

/**
 * A change to an existing model as opposed to a brand new model to be created.
 * @template T - The model update type to coerce into being a change model.
 */
export type ModelChange<T extends { base: unknown | null }> = T & {
  base: NonNullable<T["base"]>;
};
/**
 * Whether the given model update represents a change from an existing model as
 * opposed to a brand new model to be created.
 * @param model - The model to check.
 */
export function isModelChange<T extends { base: unknown | null }>(
  model: T,
): model is ModelChange<T> {
  return model.base !== null;
}
