import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { NgCircleProgressModule } from "ng-circle-progress";
import { InlineSVGModule } from "ng-inline-svg-2";
import { WarningStatusLineComponent } from "src/app/core/elements/warning-status-line.component";
import { AuthModule } from "../auth/auth.module";
import { PipesModule } from "../pipes/pipes.module";
import { ActionsCardComponent } from "./actions-card/actions-card.component";
import { AddButtonReferenceComponent } from "./add-button-reference.component";
import { AddNewButtonComponent } from "./add-new-button.component";
import { AppointmentInformationIconsComponent } from "./appointment-information-icons.component";
import { ButtonComponent } from "./button.component";
import { CircleGraphComponent } from "./circle-graph.component";
import { CommentComponent } from "./comment/comment.component";
import { ContentChangeComponent } from "./content-change/content-change.component";
import { DetailsItemComponent } from "./details-item.component";
import { DetailsListComponent } from "./details-list.component";
import { EmptyStateComponent } from "./empty-state.component";
import { GoBackLinkComponent } from "./go-back-link.component";
import { IconButtonComponent } from "./icon-button.component";
import { IconComponent } from "./icon.component";
import { LoadingBackdropComponent } from "./loading-backdrop.service";
import { LoadingComponent } from "./loading.component";
import { LogoutButtonComponent } from "./logout-button.component";
import {
  ModalActionsDirective,
  ModalComponent,
  ModalCustomContentDirective,
} from "./modal.component";
import { NoticeBoxComponent } from "./notice-box.component";
import { NotificationCountComponent } from "./notification-count/notification-count.component";
import { PopoverComponent } from "./popover/popover.component";
import { PopoverService } from "./popover/popover.service";
import { StatusLineComponent } from "./status-line.component";
import { StyledListItemComponent } from "./styled-list/styled-list-item.component";
import { StyledListComponent } from "./styled-list/styled-list.component";
import { TooltipDirective } from "./tooltip/tooltip.directive";
import { UserAvatarComponent } from "./user-avatar/user-avatar.component";

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    InlineSVGModule.forRoot(),
    MatIconModule,
    NgCircleProgressModule.forRoot({}),
    OverlayModule,
    PipesModule,
    PortalModule,
    RouterModule,
  ],
  declarations: [
    ActionsCardComponent,
    AddButtonReferenceComponent,
    AddNewButtonComponent,
    AppointmentInformationIconsComponent,
    ButtonComponent,
    CircleGraphComponent,
    CommentComponent,
    ContentChangeComponent,
    DetailsItemComponent,
    DetailsListComponent,
    EmptyStateComponent,
    GoBackLinkComponent,
    IconButtonComponent,
    IconComponent,
    LoadingBackdropComponent,
    LoadingComponent,
    LogoutButtonComponent,
    ModalActionsDirective,
    ModalComponent,
    ModalCustomContentDirective,
    NoticeBoxComponent,
    NotificationCountComponent,
    PopoverComponent,
    StatusLineComponent,
    StyledListComponent,
    StyledListItemComponent,
    TooltipDirective,
    UserAvatarComponent,
    WarningStatusLineComponent,
  ],
  exports: [
    ActionsCardComponent,
    AddButtonReferenceComponent,
    AddNewButtonComponent,
    AppointmentInformationIconsComponent,
    ButtonComponent,
    CircleGraphComponent,
    CommentComponent,
    ContentChangeComponent,
    DetailsItemComponent,
    DetailsListComponent,
    EmptyStateComponent,
    GoBackLinkComponent,
    IconButtonComponent,
    IconComponent,
    InlineSVGModule,
    LoadingComponent,
    LogoutButtonComponent,
    ModalActionsDirective,
    ModalComponent,
    ModalCustomContentDirective,
    NoticeBoxComponent,
    NotificationCountComponent,
    PopoverComponent,
    StatusLineComponent,
    StyledListComponent,
    StyledListItemComponent,
    TooltipDirective,
    UserAvatarComponent,
    WarningStatusLineComponent,
  ],
  providers: [PopoverService],
})
export class ElementsModule {}
