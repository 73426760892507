import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { pickBy } from "lodash-es";
import { DateTime } from "luxon";
import { Site } from "src/app/core/sites";
import { Dock } from "src/app/partner/settings/docks/dock.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import { Door } from "src/app/partner/settings/doors/door.model";
import { parseDateTime } from "src/utils";
import { AppointmentReservationSlot } from "./appointment-reservation-slot.model";

type AppointmentSlotArguments = Omit<
  ClassProperties<AppointmentSlot>,
  "dock" | "doorGroup"
>;

interface DeserializeArgs {
  doors: readonly Door[];
  site: Site;
}

export class AppointmentSlot {
  private constructor(args: AppointmentSlotArguments) {
    this.doors = args.doors;
    this.startTime = args.startTime;

    // Per business rules, all doors in a given slot will have the same dock and
    // door group so we can arbitrarily pick the dock from the first door.
    const firstDoor = this.doors.at(0);
    if (!firstDoor) {
      throw new Error("No doors included in slot.");
    }
    this.dock = firstDoor.dock;
    this.doorGroup = firstDoor.doorGroup;

    this.hash = [
      this.constructor.name,
      this.startTime.toUTC().toISO(),
      this.doors
        .map((door) => door.id)
        .sort()
        .join(","),
    ].join("|");
  }

  public readonly dock: Dock;
  public readonly doorGroup: DoorGroup;
  public readonly doors: readonly Door[];
  public readonly startTime: DateTime;

  private readonly hash: string;

  public static create(
    args: Omit<AppointmentSlotArguments, "ruleViolations">,
  ): AppointmentSlot {
    return new AppointmentSlot(args);
  }

  public static deserialize(
    data: Api.AppointmentSlot,
    { doors, site }: DeserializeArgs,
  ): AppointmentSlot {
    const slotDoors = data.doorIDs.map((doorId) => {
      const door = doors.find(({ id }) => id === doorId);
      if (!door) {
        throw new Error(`Could not find door with ID "${doorId}".`);
      }
      return door;
    });

    return new AppointmentSlot({
      doors: slotDoors,
      startTime: parseDateTime(data.startTime, site),
    });
  }

  public static deserializeList(
    data: readonly Api.AppointmentSlot[],
    args: DeserializeArgs,
  ): readonly AppointmentSlot[] {
    return data.map((x) => AppointmentSlot.deserialize(x, args));
  }

  public isEqual(other: AppointmentSlot | AppointmentReservationSlot): boolean {
    return other instanceof AppointmentSlot && this.hash === other.hash;
  }

  public move(
    args: Partial<Pick<AppointmentSlot, "doors" | "startTime">>,
  ): AppointmentSlot {
    return AppointmentSlot.create({
      ...this,
      // Ignore `undefined` so it doesn't overwrite the base value.
      ...pickBy(args, (value) => value !== undefined),
    });
  }
}
