<ng-container
  *ngIf="{
    currentValue: currentValueChanges | async,
    changeValue: changeValueChanges | async,
    isChanged: isChangedChanges | async
  } as data"
>
  <ng-container
    *ngIf="
      isExistent(data.currentValue) || isExistent(data.changeValue);
      else notEntered
    "
  >
    <ng-container *ngIf="data.isChanged; else currentValueDisplay">
      <mr-content-change kind="previous" *ngIf="isExistent(data.currentValue)">
        <ng-container *ngTemplateOutlet="currentValueDisplay"></ng-container>
      </mr-content-change>
      <mr-content-change #newContentRef kind="new">
        <ng-container
          *ngTemplateOutlet="valueDisplay; context: { value: data.changeValue }"
        ></ng-container>
      </mr-content-change>
    </ng-container>
  </ng-container>

  <ng-template #currentValueDisplay>
    <ng-container
      *ngTemplateOutlet="valueDisplay; context: { value: data.currentValue }"
    ></ng-container>
  </ng-template>
</ng-container>

<ng-template #notEntered>
  <span class="text-italic">Not entered</span>
</ng-template>

<ng-template #valueDisplay let-value="value">
  <ng-template
    *ngIf="valueTemplate; else valueDefault"
    [ngTemplateOutlet]="valueTemplate"
    [ngTemplateOutletContext]="{ value: value }"
  >
  </ng-template>

  <ng-template #valueDefault>
    {{ value }}
  </ng-template>
</ng-template>
