import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  TemplateRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { parseNumber } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";
import {
  AdditionalErrorMessagesContext,
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

type InputType = "date" | "email" | "number" | "tel" | "text" | "time";
export type TextboxValue = string | number | null;

@Component({
  selector: "mr-textbox[label]",
  templateUrl: "textbox.component.html",
  styleUrls: ["textbox.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextboxComponent),
    },
  ],
})
export class TextboxComponent extends BaseFieldComponent<TextboxValue> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input()
  public additionalErrorMessageTemplate?: TemplateRef<AdditionalErrorMessagesContext>;
  @Input() public placeholder = "";
  @Input() public type: InputType = "text";
  @Input() public updateOn: "blur" | "input" = "input";
  @Input() public min: number | string | null = null;
  @Input() public max: number | string | null = null;
  @Input() public step: number | string | null = null;
  @Input() public minlength: number | string | null = null;
  @Input() public maxlength: number | string | null = null;
  @Input() public originalValue: TextboxValue = null;
  @Input() public canHighlight = false;
  @Input() public units?: string;
  @Input() public showAsterisk = false;
  @Input()
  @HostBinding("class")
  public kind: "form" | "inline" | "tiled" = "form";

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  public onInputChange(value: string): void {
    if (this.updateOn === "input") {
      this.onChange(value);
    }
  }

  public onBlurChange(value: string): void {
    if (this.updateOn === "blur") {
      this.onChange(value);
    }
  }

  public override onChange(value: string): void {
    super.onChange(this.type === "number" ? parseNumber(value) : value.trim());
  }
}
