import { Component, Input } from "@angular/core";

@Component({
  selector: "mr-details-item",
  template: `
    <dt *ngIf="title">{{ title }}</dt>
    <dd><ng-content></ng-content></dd>
  `,
  styleUrls: ["./details-item.component.scss"],
})
export class DetailsItemComponent {
  @Input() public title = "";
}
