<mr-icon-button
  [label]="label"
  [disabled]="isDisabled"
  (click)="openSelectIconWindow($event)"
  [class.has-selected-icon]="(selectedIconChanges | async) !== null"
>
  <mr-icon
    aria-hidden="true"
    [name]="(selectedIconChanges | async) || 'material/add'"
  ></mr-icon>
</mr-icon-button>
