import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EventTypePipe } from "src/app/core/pipes/event-type.pipe";
import { TimeSincePipe } from "src/app/core/pipes/time-since.pipe";
import { AmountPipe } from "./amount.pipe";
import { AppointmentFieldTitlePipe } from "./appointment-field-title.pipe";
import { AppointmentOrderFieldTitlePipe } from "./appointment-order-field-title.pipe";
import { BooleanPipe } from "./boolean.pipe";
import { DateTimeInSiteZonePipe } from "./date-time-in-site-zone.pipe";
import { DateTimePipe } from "./date-time.pipe";
import { DayPipe } from "./day.pipe";
import { DropLoadAvailabilityPipe } from "./drop-load-availability.pipe";
import { DurationPipe } from "./duration.pipe";
import { EmailStatusPipe } from "./email-status.pipe";
import { EncryptEmailPipe } from "./encrypt-email.pipe";
import { HelpAssistTicketApprovalStatusPipe } from "./help-assist-ticket-status.pipe";
import { ManagedTypePipe } from "./managed-type.pipe";
import { OrderStatusPipe } from "./order-status.pipe";
import { PartnerAvailabilityStatusPipe } from "./partner-availability-status.pipe";
import { PhoneNumber } from "./phone-number.pipe";
import { StatusColorPipe } from "./status-color.pipe";
import { TicketStatusPipe } from "./ticket-status.pipe";
import { TimeOfDayPipe } from "./time-of-day.pipe";
import { UnitTypePipe } from "./unit-type.pipe";
import { UnloaderPipe } from "./unloader.pipe";
import { WeekdaysPipe } from "./weekdays.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    AmountPipe,
    AppointmentFieldTitlePipe,
    AppointmentOrderFieldTitlePipe,
    BooleanPipe,
    DateTimeInSiteZonePipe,
    DateTimePipe,
    DayPipe,
    DropLoadAvailabilityPipe,
    DurationPipe,
    EmailStatusPipe,
    EventTypePipe,
    HelpAssistTicketApprovalStatusPipe,
    ManagedTypePipe,
    OrderStatusPipe,
    PartnerAvailabilityStatusPipe,
    PhoneNumber,
    StatusColorPipe,
    TicketStatusPipe,
    TimeOfDayPipe,
    TimeSincePipe,
    UnitTypePipe,
    UnloaderPipe,
    WeekdaysPipe,
    EncryptEmailPipe,
  ],
  providers: [
    AmountPipe,
    DateTimePipe,
    DateTimeInSiteZonePipe,
    DayPipe,
    PhoneNumber,
  ],
  exports: [
    AmountPipe,
    AppointmentFieldTitlePipe,
    AppointmentOrderFieldTitlePipe,
    BooleanPipe,
    DateTimeInSiteZonePipe,
    DateTimePipe,
    DayPipe,
    DropLoadAvailabilityPipe,
    DurationPipe,
    EmailStatusPipe,
    EventTypePipe,
    HelpAssistTicketApprovalStatusPipe,
    ManagedTypePipe,
    OrderStatusPipe,
    PartnerAvailabilityStatusPipe,
    PhoneNumber,
    StatusColorPipe,
    TicketStatusPipe,
    TimeOfDayPipe,
    TimeSincePipe,
    UnitTypePipe,
    UnloaderPipe,
    WeekdaysPipe,
    EncryptEmailPipe,
  ],
})
export class PipesModule {}
