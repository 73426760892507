import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime, Interval } from "luxon";
import { DropLoadAvailability, ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { ChangesHistory } from "src/app/core/history.model";
import { formatWeekdays } from "src/app/core/pipes/weekdays.pipe";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { AppointmentUpdatePurchaseOrder } from "src/app/partner/appointments/appointment-update.model";
import { AppointmentReservationSlot } from "src/app/partner/appointments/fields/appointment-reservation-slot.model";
import { AppointmentSlot } from "src/app/partner/appointments/fields/appointment-slot.model";
import {
  Carrier,
  CarrierReference,
  deserializeSimpleCarrier,
} from "src/app/partner/global/carriers";
import { Door } from "src/app/partner/settings/doors/door.model";
import { getVendorRouteUrl } from "src/app/partner/vendors/base-vendor.model";
import {
  deserializeVendorDisplayName,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import {
  createPartnerResourceUrl,
  formatHistoryIdChangeValue,
  getApiDetailsDecorator,
  ODataModel,
  parseDateTime,
  PartnerResourceModel,
  throwUnhandledCaseError,
} from "src/utils";
import {
  BaseReservation,
  ReservationCarrier,
  ReservationVendor,
} from "./base-reservation.model";

export const reservationsResource = createPartnerResourceUrl("reservations");

export const reservationOccurrencesResource = reservationsResource.modify(
  (resource) => resource.appendFunctionPropertyPath("getOccurrences"),
);

export const expandedReservationsResource = reservationsResource.modify(
  (resource) =>
    resource
      .addExpandField("carriers", (carrierResource) =>
        carrierResource.select("id", "name").withReferenceId(),
      )
      .addExpandField("doors", (doorsResource) => doorsResource.select("id"))
      .addExpandField("site")
      .addExpandField("vendors", (vendorResource) =>
        vendorResource
          .select("doorCount", "id", "name", "vendorNumber")
          .withReferenceId(),
      ),
);

type ApiModelReservationList = PartnerResourceModel<
  typeof expandedReservationsResource
>;
export interface ApiExpandedReservation
  extends ODataModel<ApiModelReservationList> {}

type ApiModelReservationOccurrenceList = PartnerResourceModel<
  typeof reservationOccurrencesResource
>;
export interface ApiReservationOccurrence
  extends ODataModel<ApiModelReservationOccurrenceList> {}

const api = getApiDetailsDecorator<ApiExpandedReservation>();

interface DeserializeArgs {
  doors: readonly Door[];
  site: Site;
  getCarrier(id: number): Promise<Carrier | null>;
  getVendor(id: number): Promise<Vendor | null>;
}

export enum ReservationType {
  Carrier = "Carrier",
  Vendor = "Vendor",
}

export class Reservation extends BaseReservation {
  private constructor(
    args: Omit<
      ClassProperties<Reservation>,
      "displayName" | "endTime" | "type" | "isExpired"
    >,
  ) {
    super(args);
    this.carriers = args.carriers;
    this.createdDate = args.createdDate;
    this.doors = args.doors;
    this.history = args.history;
    this.id = args.id;
    this.isExpiringSoon = args.isExpiringSoon;
    this.modifiedDate = args.modifiedDate;
    this.site = args.site;
    this.vendors = args.vendors;

    // Computed Properties
    this.effectiveDayRange = this.startDay
      .toDateTime(Day.startOfDay, this.site.timeZone)
      .until(
        this.endDay
          // Make inclusive by offsetting to the start of the next day.
          .plus({ days: 1 })
          .toDateTime(Day.startOfDay, this.site.timeZone),
      );

    this.endTime = this.startTime.addDuration(this.duration);
    this.isExpired = this.endDay.isBefore(Day.getTodayIn(this.site.timeZone));

    if (this.vendors.length > 0) {
      this.type = ReservationType.Vendor;
      this.displayName =
        this.name || this.vendors.map((vendor) => vendor.name).join(", ");
    } else if (this.carriers.length > 0) {
      this.type = ReservationType.Carrier;
      this.displayName =
        this.name || this.carriers.map((carrier) => carrier.name).join(", ");
    } else {
      throw new Error("No carrier or vendor set on reservation.");
    }
  }

  public readonly carriers: readonly ReservationCarrier[];
  @api() public readonly createdDate: DateTime;
  @api() public readonly doors: readonly Door[];
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: number;
  @api({ key: "expiringSoon" }) public readonly isExpiringSoon: boolean;
  @api() public readonly modifiedDate: DateTime;
  @api({ key: "siteID", navigationProperty: "site", uiModel: Site })
  public override readonly site: Site;
  public readonly vendors: readonly ReservationVendor[];

  /** A display name such as for the schedule reservation bar. */
  public readonly displayName: string;
  public readonly endTime: TimeOfDay;
  /** Whether the reservation has exceeded its end date. */
  public readonly isExpired: boolean;
  public readonly type: ReservationType;
  private readonly effectiveDayRange: Interval;

  public static deserialize(
    data: ApiExpandedReservation | MappedApiReservationOccurrence,
    { doors, getCarrier, getVendor, site }: DeserializeArgs,
  ): Reservation {
    // As per business, reservation will only contain one door.
    const apiDoor = data.doors[0];
    const selectedDoor = doors.find((door) => door.id === apiDoor.id);
    if (!selectedDoor) {
      throw new Error(
        `Could not find reservation door with ID "${apiDoor.id}".`,
      );
    }

    const history =
      "history" in data
        ? ChangesHistory.deserializeList<HistoryFieldName>(data.history, {
            fieldDefinitions: {
              carrier: {
                formatChangeValue: formatHistoryIdChangeValue,
                label: "Carriers",
              },
              // TODO: this check can be removed once all records from before
              // feature #25216 was released to prod (~2022-01-04, v2.55.0) are
              // removed from the DB.
              carrierID: {
                formatChangeValue: (value) =>
                  // TODO: remove fallback once old records (before ~2021-01-01) are removed from the DB.
                  formatHistoryIdChangeValue.withAsyncFallback(
                    value,
                    async (id) => (await getCarrier(id))?.name ?? null,
                  ),
                label: "Carrier",
              },
              dayOfWeek: {
                label: "Days",
                formatChangeValue: (value) =>
                  value
                    ? formatWeekdays(Weekdays.deserialize(value), "short")
                    : null,
              },
              door: {
                formatChangeValue: (value) =>
                  // TODO: remove fallback once old records (before ~2021-01-01) are removed from the DB.
                  formatHistoryIdChangeValue.withFallback(
                    value,
                    (id) => doors.find((door) => door.id === id)?.name ?? null,
                  ),
              },
              exceptions: {
                formatChangeValue: (value) =>
                  value
                    ?.split(",")
                    .map((date) => Day.deserialize(date, null)) ?? null,
              },
              vendor: {
                formatChangeValue: formatHistoryIdChangeValue,
                label: "Vendors",
              },
              // TODO: this check can be removed once all records from before
              // feature #23886 was released to prod (~2021-11-04, v2.52.0) are
              // removed from the DB.
              vendorID: {
                formatChangeValue: (value) =>
                  // TODO: remove fallback once old records (before ~2021-01-01) are removed from the DB.
                  formatHistoryIdChangeValue.withAsyncFallback(
                    value,
                    async (id) => (await getVendor(id))?.displayName ?? null,
                  ),
                label: "Vendor",
              },
            },
          })
        : [];

    return new Reservation({
      ...this.deserializeBase(data, { site }),
      carriers: data.carriers.map((carrier) =>
        deserializeSimpleCarrier(carrier),
      ),
      createdDate: parseDateTime(data.createdDate, site),
      doors: [selectedDoor],
      history,
      id: data.id,
      isExpiringSoon: data.expiringSoon,
      modifiedDate: parseDateTime(data.modifiedDate, site),
      vendors: data.vendors.map((vendor) =>
        deserializeReservationVendor(vendor, { site }),
      ),
    });
  }

  public static deserializeList(
    { value }: ApiModelReservationList | ApiModelReservationOccurrenceList,
    args: DeserializeArgs,
  ): readonly Reservation[] {
    return value.map((reservation) => {
      // Deserialize full reservation
      if ("history" in reservation) {
        return Reservation.deserialize(reservation, args);
      }

      // Deserialize reservation occurrence (simple reservation to be displayed on the schedule view)
      return Reservation.deserialize(
        mapApiReservationOccurrencePropertyNames(reservation),
        args,
      );
    });
  }

  public isInEffectOn(day: Day): boolean {
    return (
      this.effectiveDayRange.contains(
        day.toDateTime(Day.startOfDay, this.site.timeZone),
      ) &&
      !this.exceptionDays
        .map((exceptionDay) => exceptionDay.toString())
        .includes(day.toString())
    );
  }

  public includesDay(day: Day): boolean {
    return this.weekdays.isSelected(day) && this.isInEffectOn(day);
  }

  public getInstanceOn(day: Day): Interval | null {
    if (!this.includesDay(day)) {
      return null;
    }

    const start = day.toDateTime(this.startTime, this.site.timeZone);
    const endDay = this.endTime.isBefore(this.startTime)
      ? day.plus({ days: 1 })
      : day;
    const end = endDay.toDateTime(this.endTime, this.site.timeZone);
    return start.until(end);
  }

  public getInstanceContaining(time: DateTime): Interval | null {
    const day = new Day(time);
    const sameDayInstance = this.getInstanceOn(day);
    const previousDayInstance = this.getInstanceOn(day.minus({ days: 1 }));

    // Either the time is on the same day as (the start of) the reservation, or
    // the reservation spans over into the next day and the time is in the
    // second half of the reservation in the following day. These shouldn't
    // overlap so check each in turn to find the one that contains it. If it's
    // not in either, then it's not in a reservation from this series.
    if (sameDayInstance?.contains(time) === true) {
      return sameDayInstance;
    } else if (previousDayInstance?.contains(time) === true) {
      return previousDayInstance;
    } else {
      return null;
    }
  }

  public includesSlot(
    slot: AppointmentSlot | AppointmentReservationSlot,
  ): boolean {
    return (
      slot.doors.some((door) => this.doors.includes(door)) &&
      this.getInstanceContaining(slot.startTime) !== null
    );
  }

  public isFor({
    carrierOfRecord,
    orders,
  }: {
    readonly carrierOfRecord: CarrierReference;
    readonly orders: readonly AppointmentUpdatePurchaseOrder[];
  }): boolean {
    switch (this.type) {
      case ReservationType.Carrier: {
        return this.carriers.some(
          (carrier) => carrier.id === carrierOfRecord.id,
        );
      }
      case ReservationType.Vendor: {
        const hasMatchingCarrier = this.carriers.some(
          (carrier) => carrier.id === carrierOfRecord.id,
        );
        const hasVendorInOrders = this.vendors.some((vendor) =>
          isVendorInOrders(vendor, orders),
        );
        return (
          (this.carriers.length === 0 || hasMatchingCarrier) &&
          hasVendorInOrders
        );
      }
      default: {
        throwUnhandledCaseError("reservation type", this.type);
      }
    }
  }

  /**
   * Whether this reservation can be used with orders and/or appointments with
   * the given values associated with them.
   *
   * @param options - The values to check against the reservation.
   */
  public isCompatibleWith({
    isDropLoad,
    managedType,
  }: {
    /** The drop load state to check against the reservation. */
    readonly isDropLoad?: boolean;
    /** The managed type to check against the reservation. */
    readonly managedType?: ManagedType;
  }): boolean {
    return (
      (isDropLoad === undefined || this.isCompatibleWithDropLoad(isDropLoad)) &&
      (managedType === undefined ||
        this.isCompatibleWithManagedType(managedType))
    );
  }

  private isCompatibleWithDropLoad(isDropLoad: boolean): boolean {
    switch (this.dropLoadAvailability) {
      case DropLoadAvailability.Optional:
        return true;
      case DropLoadAvailability.Mandatory:
        return isDropLoad;
      case DropLoadAvailability.NotAllowed:
        return !isDropLoad;
      default:
        throwUnhandledCaseError(
          "drop load availability",
          this.dropLoadAvailability,
        );
    }
  }

  private isCompatibleWithManagedType(managedType: ManagedType): boolean {
    // No managed type on the slot means it can be used with any orders.
    return this.managedType === null || managedType === this.managedType;
  }

  public getRouteUrl(...childPaths: string[]): string {
    return this.site.getRouteUrl(
      "settings/reservations",
      String(this.id),
      ...childPaths,
    );
  }
}

export function deserializeReservationVendor(
  data: Pick<Api.Vendor, "id" | "name" | "vendorNumber"> &
    Partial<Pick<Api.Vendor, "doorCount">>,
  { site }: { readonly site: Site },
): ReservationVendor {
  return {
    ...deserializeVendorDisplayName({
      name: data.name,
      vendorNumber: data.vendorNumber,
    }),
    getRouteUrl: (...childPaths) =>
      getVendorRouteUrl({ id: data.id, site }, ...childPaths),
    id: data.id,
    maxAppointmentDoorCount: "doorCount" in data ? data.doorCount : undefined,
    name: data.name,
    number: data.vendorNumber,
  };
}

type HistoryFieldName =
  | StringKeys<ApiExpandedReservation>
  | "carrier"
  | "carrierID"
  | "door"
  | "vendor"
  | "vendorID";

function isVendorInOrders(
  vendor: ReservationVendor,
  orders: readonly AppointmentUpdatePurchaseOrder[],
): boolean {
  for (const order of orders) {
    if (order.vendor.id === vendor.id) {
      return true;
    }

    const base = "base" in order ? order.base : order;

    if (base) {
      for (const details of base.orderDetails) {
        if (details.vendor.id === vendor.id) {
          return true;
        }
      }
    }
  }

  return false;
}

interface MappedApiReservationOccurrence
  extends Omit<ApiReservationOccurrence, "doors" | "carriers" | "vendors"> {
  doors: Array<Pick<Api.Door, "id" | "name">>;
  carriers: Array<Pick<Api.Carrier, "id" | "name">>;
  vendors: Array<Pick<Api.Vendor, "id" | "name" | "vendorNumber">>;
}

function mapApiReservationOccurrencePropertyNames(
  reservation: Api.ReservationOccurrence,
): MappedApiReservationOccurrence {
  return {
    ...reservation,
    doors: reservation.doors.map((door) => ({
      id: door.doorId,
      name: door.doorName,
    })),
    carriers: reservation.carriers.map((carrier) => ({
      id: carrier.carrierId,
      name: carrier.carrierName,
    })),
    vendors: reservation.vendors.map((vendor) => ({
      ...vendor,
      id: vendor.vendorId,
      name: vendor.vendorName,
    })),
  };
}
