import { Injectable } from "@angular/core";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { Pagination } from "src/app/core/pagination";
import { RequestService } from "src/app/core/request.service";
import { ColumnFilter } from "src/app/core/table";
import {
  ColumnSort,
  deserializeApiModelList,
  firstExistentValueFrom,
  isExistent,
  notFoundErrorToNull,
  RequestCache,
  setFiltersOnResource,
  setSortOnResource,
} from "src/utils";
import { CarrierType, carrierTypesResource } from "./carrier-type.model";
import { CarrierUpdate } from "./carrier-update.model";
import { ApiCarrier, Carrier, carriersResource } from "./carrier.model";

@Injectable({ providedIn: "root" })
export class CarriersService {
  public constructor(
    private readonly request: RequestService,
    private readonly user: UserService,
  ) {}

  public readonly typesChanges = this.request.getAll(carrierTypesResource).pipe(
    map((result) => result && CarrierType.deserializeList(result)),
    shareReplay(1),
  );

  private readonly deserializeArgs = this.typesChanges.pipe(
    map((types) => types && { types }),
  );

  private readonly cache = new RequestCache<readonly Carrier[] | null>();

  private readonly updates = new Subject<ApiCarrier>();

  public getListChanges({
    query,
    isActive,
    carrierKey,
    carrierTypeName,
    nameQuery,
    selectedCarrierId,
    columnFilters,
    sort = "name",
    pagination,
    maximum,
    cached = false,
  }: {
    query?: string;
    isActive?: boolean;
    carrierKey?: string;
    carrierTypeName?: string;
    nameQuery?: string;
    selectedCarrierId?: number;
    columnFilters?: readonly ColumnFilter[];
    sort?: ColumnSort | StringKeys<Carrier>;
    pagination?: Pagination;
    maximum?: number | null;
    cached?: boolean;
  } = {}): Observable<readonly Carrier[] | null> {
    const filteredResource = carriersResource
      .addFilterIfExists(query || null, (resource, value) =>
        resource.withFieldsContaining(
          ["name", "carrierKey", "comments"],
          value,
        ),
      )
      .addFilterIfExists(nameQuery || null, (resource, value) =>
        resource.addContainsFilter("name", value),
      )
      .addFilterIfExists(isActive, (resource, value) =>
        resource.isActive(value),
      )
      .addFilterIfExists(carrierKey, (resource, value) =>
        resource.addFilter("carrierKey", "==", value),
      )
      .addFilterIfExists(carrierTypeName || null, (resource, value) =>
        resource.addFilter(["carrierType", "name"], "==", value),
      )
      .addFilterIfExists(columnFilters, (resource, value) =>
        setFiltersOnResource(resource, Carrier, value, {
          timeZone: Carrier.timeZone,
          // Carriers aren't associated with a site, so there's no specific
          // time zone to tie dates to.
          timeZonePath: null,
        }),
      )
      .addFilterIfExists(sort, (resource, value) =>
        setSortOnResource(resource, Carrier, value),
      );

    let listChanges = deserializeApiModelList({
      deserialize: Carrier.deserializeList,
      list: this.request.getMany(filteredResource, { pagination, maximum }),
      arguments: this.deserializeArgs,
      updates: this.updates,
    });

    if (isExistent(selectedCarrierId) && !nameQuery) {
      listChanges = listChanges.pipe(
        switchMap(async (carriers) => {
          if (!carriers) {
            return null;
          }
          if (carriers.some((carrier) => carrier.id === selectedCarrierId)) {
            return carriers;
          }
          const selectedCarrier = await this.getSelected(selectedCarrierId);
          return selectedCarrier ? [...carriers, selectedCarrier] : carriers;
        }),
      );
    }

    const key = filteredResource.toString();
    return cached ? listChanges.pipe(this.cache.load(key)) : listChanges;
  }

  public async getUserCarrier(): Promise<Carrier | null> {
    if (!this.user.isCarrier()) {
      return null;
    }

    const carriers = await firstExistentValueFrom(
      this.getListChanges({ carrierKey: this.user.details.carrierKey }),
    );
    return carriers[0] ?? null;
  }

  public async getSelected(id: number): Promise<Carrier | null> {
    const cache = await this.cache.getValue();
    return (
      cache?.find((carrier) => carrier.id === id) ??
      this.get(id).catch(notFoundErrorToNull)
    );
  }

  public async update(update: CarrierUpdate): Promise<Carrier> {
    const id = update.base
      ? await this.patch(update.base.id, update)
      : await this.post(update);

    return await this.get(id);
  }

  private async get(id: number): Promise<Carrier> {
    const apiModel = await firstExistentValueFrom(
      this.request.get(carriersResource.appendId(id)),
    );

    this.updates.next(apiModel);

    const args = await firstExistentValueFrom(this.deserializeArgs);
    return Carrier.deserialize(apiModel, args);
  }

  private async patch(id: number, update: CarrierUpdate): Promise<number> {
    return firstValueFrom(
      this.request
        .patch(carriersResource.appendId(id), update)
        .pipe(map((result) => result.id)),
    );
  }

  private async post(update: CarrierUpdate): Promise<number> {
    return firstValueFrom(
      this.request
        .post(carriersResource, update)
        .pipe(map((result) => result.id)),
    );
  }
}
