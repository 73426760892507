<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-fields-wrapper">
    <div class="mr-layout-flex">
      <div class="mr-layout-flex__row">
        <div
          class="appointment-details-field-wrapper mr-layout-flex__col mr-layout-flex__col-sm--6"
        >
          <mr-date-picker
            formControlName="idealAppointmentDate"
            label="Ideal Appointment Date"
            [minDay]="minimumDayChanges | async"
          ></mr-date-picker>
        </div>
        <div
          class="appointment-details-field-wrapper mr-layout-flex__col mr-layout-flex__col-sm--6"
          *ngIf="idealStartTimeMinuteStepChanges | async as startTimeStep"
        >
          <mr-timepicker
            label="Ideal Start Time"
            formControlName="idealStartTime"
            [minuteStep]="startTimeStep"
            [hideSpinners]="true"
          ></mr-timepicker>
        </div>
      </div>
    </div>
    <mr-textbox
      label="Email Address"
      description="Separate email addresses with a semi-colon"
      type="email"
      formControlName="emailAddress"
    ></mr-textbox>
    <mr-phone-number-input
      label="Phone Number"
      formControlName="phoneNumber"
    ></mr-phone-number-input>
    <mr-textarea
      label="Comments"
      formControlName="additionalComments"
      rows="3"
      placeholder="Additional Comments"
      maxlength="1000"
    ></mr-textarea>
    <div class="form-fields-wrapper__button">
      <mr-button type="submit" [disabled]="!form.valid"
        >Open Scheduling Ticket</mr-button
      >
      <p>
        Tickets are typically resolved within
        {{
          appointment!.site.helpAssist.ticketResolutionTimeDuration.as("hours")
        }}
        hours
      </p>
    </div>
  </div>
</form>
