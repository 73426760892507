import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Day } from "src/app/core/day.model";
import { formatDateTime, getUpdatedPropertiesFilter } from "src/utils";
import { HelpAssistTicketAppointmentOrderUpdate } from "../ticket-order/help-assist-ticket-appointment-order-update.model";
import { BaseHelpAssistAppointmentDraftTicket } from "./base-help-assist-ticket-appointment-draft.model";

export class HelpAssistTicketAppointmentDraftUpdate extends BaseHelpAssistAppointmentDraftTicket {
  public constructor(
    args: Omit<
      ClassProperties<HelpAssistTicketAppointmentDraftUpdate>,
      "totalCaseCount" | "totalWarehousePalletCount"
    >,
  ) {
    super(args);

    this.orders = args.orders;

    this.totalCaseCount =
      this.orders?.reduce((total, order) => total + order.caseCount, 0) ?? null;

    this.totalWarehousePalletCount = this.getEffectiveTotalWarehousePalletCount(
      this.orders,
    );

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, null, args);
  }

  public readonly orders: HelpAssistTicketAppointmentOrderUpdate[] | null;
  public readonly totalCaseCount: number | null;
  public readonly totalWarehousePalletCount: number | null;

  private readonly filterUpdatedProperties: FilterPartial<Api.HelpAssistTicketAppointmentDraftUpdate>;

  public serialize(): Partial<
    Api.HelpAssistTicketAppointmentDraftUpdate & {
      orders?: Partial<Api.TicketAppointmentOrderUpdate[]>;
    }
  > {
    const filteredProperties = this.filterUpdatedProperties({
      appointmentPalletOverride: this.totalWarehousePalletCountOverride,
      carrierId: this.carrier?.id ?? null,
      deliveryCarrier: this.unknownDeliveryCarrierName,
      deliveryCarrierRecordID: this.deliveryCarrier?.id ?? null,
      doorGroupId: this.doorGroup?.id ?? null,
      doorId: this.door?.id ?? null,
      duration: this.duration,
      idealAppointmentDate:
        this.idealAppointmentDate?.serialize(
          Day.startOfDay,
          this.site.timeZone,
        ) ?? null,
      idealStartTime: this.idealStartTime?.serialize() ?? null,
      isDropload: this.isDropLoad,
      isIntermodal: this.isIntermodal,
      loadWeight: this.loadWeight,
      mainOrderNumber: this.mainOrderNumber,
      notificationList: this.notificationList,
      schedule:
        this.scheduledArrivalDay?.serialize(
          Day.startOfDay,
          this.site.timeZone,
        ) ?? null,
      slotStartTime: formatDateTime(this.slotStartTime),
      unloader: this.unloader,
    });

    return {
      ...filteredProperties,
      orders: this.orders?.map((order) => order.serialize(this.site)) ?? [],
    };
  }
}
