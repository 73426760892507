import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "./core/auth/user.service";
import { SitesService } from "./core/sites";
import { PartnersService } from "./partner/global/partners.service";

@Component({
  selector: "mr-account-pending",
  template: `
    <mr-notice-page
      message="Thank you for signing up for your Managed Receiving account. An administrator will review and verify your account shortly."
    ></mr-notice-page>
  `,
})
export class AccountPendingComponent implements OnInit {
  public constructor(
    private readonly router: Router,
    private readonly user: UserService,
    private readonly partners: PartnersService,
    private readonly sites: SitesService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const [isPartnerLoaded, isSiteLoaded] = await Promise.all([
      this.partners.load(),
      this.sites.load(),
      this.user.isLoaded,
    ]);
    if (isPartnerLoaded && isSiteLoaded) {
      await this.router.navigateByUrl("/");
    }
  }

  public logout(): void {
    this.user.logout();
  }
}
