import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PopoverConfig } from "./popover-config";
import { PopoverRef } from "./popover-ref";
import { PopoverComponent } from "./popover.component";

const popoverPositions: ConnectionPositionPair[] = [
  {
    originX: "start",
    originY: "top",
    overlayX: "end",
    overlayY: "bottom",
  },
  {
    originX: "start",
    originY: "bottom",
    overlayX: "center",
    overlayY: "top",
  },
];
@Injectable()
export class PopoverService {
  public constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {}

  public overlayRef?: OverlayRef;

  private isDisabled = false;

  private readonly closedNotifier = new Subject<void>();

  public open<T>(options: PopoverConfig<T>): void {
    if (this.isDisabled) {
      return;
    }
    this.close();
    const { content, data, shouldCloseOnOutsideClick } = options;
    this.overlayRef = this.overlay.create(this.getOverlayConfig<T>(options));
    const popoverRef = new PopoverRef<T>(this.overlayRef, content, data);
    const injector = this.createInjector(popoverRef, this.injector);
    this.overlayRef.attach(
      new ComponentPortal(PopoverComponent, null, injector),
    );

    if (shouldCloseOnOutsideClick) {
      this.overlayRef
        .outsidePointerEvents()
        .pipe(takeUntil(this.closedNotifier))
        .subscribe(() => {
          // Close when clicking outside of the referenced overlay element
          this.close();
        });
    }
  }

  public close(): void {
    if (this.overlayRef) {
      this.closedNotifier.next();
      this.overlayRef.dispose();
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
  }

  public disable(disable = true): void {
    this.isDisabled = disable;
  }

  private createInjector<T>(
    popoverRef: PopoverRef<T>,
    injector: Injector,
  ): Injector {
    return Injector.create({
      parent: injector,
      providers: [{ provide: PopoverRef, useValue: popoverRef }],
    });
  }

  private getOverlayConfig<T>(options: PopoverConfig<T>): OverlayConfig {
    const { width, height, origin } = options;
    return new OverlayConfig({
      width,
      height,
      hasBackdrop: false,
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(
    origin: FlexibleConnectedPositionStrategyOrigin,
  ): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(popoverPositions)
      .withFlexibleDimensions(false)
      .withPush(false);
  }
}
