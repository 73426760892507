import { maxBy, partition } from "lodash-es";
import { Duration } from "luxon";
import { ManagedType, ValidationConstants } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { Site, UnitType } from "src/app/core/sites";
import { AppointmentPurchaseOrderCreation } from "src/app/partner/appointments/appointment-purchase-order-update.model";
import { AppointmentUpdatePurchaseOrder } from "src/app/partner/appointments/appointment-update.model";
import { SimpleAppointmentPurchaseOrder } from "src/app/partner/appointments/base-appointment.model";
import { HelpAssistTicketAppointmentOrder } from "src/app/partner/help-assist/models/ticket-order/help-assist-ticket-appointment-order.model";
import { ChangedOrderType } from "src/app/partner/help-assist/ticket-details/po-table/changed-order-type.pipe";
import {
  clampDuration,
  snapDuration,
  throwUnhandledCaseError,
} from "src/utils";
import { AppointmentDuration } from "../appointments/appointment.model";
import { PurchaseOrder } from "./purchase-order.model";

export function getMainPurchaseOrder<
  T extends
    | readonly SimpleAppointmentPurchaseOrder[]
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[],
>(orders: T): T[number] | null {
  const mainOrder = maxBy<
    | SimpleAppointmentPurchaseOrder
    | PurchaseOrder
    | AppointmentUpdatePurchaseOrder
  >(orders, (order) => order.warehousePalletCount);
  return mainOrder ?? null;
}

export function pickAppointmentDurationSettings(
  site: Site,
  dock?: AppointmentDuration["dock"],
): AppointmentDurationSettings {
  if (dock?.isDockLevelSettingEnabled) {
    // Asserting to duration, as duration details must not be nullable when dock level setting is enabled
    return {
      appointmentInterval: dock.appointmentInterval as Duration,
      maximumAppointmentDuration: dock.maximumAppointmentDuration as Duration,
      minimumAppointmentDuration: dock.minimumAppointmentDuration as Duration,
    };
  } else
    return {
      appointmentInterval: site.appointmentInterval,
      maximumAppointmentDuration: site.maximumAppointmentDuration,
      minimumAppointmentDuration: site.minimumAppointmentDuration,
    };
}

export function calculateEstimatedUnloadDuration(
  orders: readonly PurchaseOrder[] | readonly AppointmentUpdatePurchaseOrder[],
  totalWarehousePalletCountOverride: number | null,
): Duration {
  const firstOrder = orders.at(0);
  if (!firstOrder) {
    throw new Error("Cannot calculate estimated unload duration of no orders.");
  }
  // All orders should be in the same site, so just take the first order's.
  const { site } = firstOrder;

  let duration: Duration;

  // Use "Appointment Pallet Override" value, if it exists, to calculate the duration when the site unit is "Pallets".
  if (totalWarehousePalletCountOverride && site.unitType === UnitType.Pallet) {
    duration = getEstimatedUnloadDurationForTotalWarehousePalletOverride(
      orders,
      totalWarehousePalletCountOverride,
    );
  } else {
    // Calculate the total appointment average from each order's per-unit average.
    duration = orders
      .map(getEstimatedUnloadDuration)
      .reduce((previous, current) => current.plus(previous));
  }

  const snappedDuration = snapDuration(duration, site.appointmentInterval, {
    round: "up",
  }).shiftTo("minutes");

  return clampDuration(snappedDuration, {
    min: site.minimumAppointmentDuration ?? undefined,
    max: site.maximumAppointmentDuration ?? undefined,
  });
}

/**
 * Gets the subset of the orders which are Truck Load managed type that aren't
 * allowed to be on a drop load appointment, or `null` if no orders are in
 * violation.
 *
 * @param orders - The orders to check.
 */
export function getTruckLoadOrdersViolatingDropLoadRestriction<
  T extends
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[],
>(orders: T): T | null {
  const firstOrder = orders.at(0);
  if (!firstOrder) {
    return null;
  }

  // All orders should be in the same site, so just take the first order's.
  const { site } = firstOrder;

  if (site.isDropLoadDisallowedForTruckLoadManagedType) {
    const truckLoadOrders = filterUnionOfArrays(
      orders,
      (order) => order.managedType === ManagedType.TruckLoad,
    );
    return truckLoadOrders.length > 0 ? truckLoadOrders : null;
  }

  return null;
}

/**
 * Filters down a list of orders to only those that are not allowed to be
 * scheduled on the "same day" (i.e. today).
 *
 * @param orders - The orders to filter down.
 * @returns The filtered orders or `null` if there are none that are disallowed.
 */
function excludeOrdersAllowedOnSameDay<
  T extends
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[],
>(orders: T | null): T | null {
  if (!orders) {
    return null;
  }

  const restrictedOrders = filterUnionOfArrays(
    orders,
    (order) => !order.vendor.isSameDayAppointmentAllowed,
  );
  return restrictedOrders.length > 0 ? restrictedOrders : null;
}

/**
 * Whether the given orders should allow selecting the "same day" (i.e. today)
 * as an option when creating or modifying an appointment.
 *
 * @param orders - The orders to check.
 */
export function canSelectSameDayForOrders(
  orders:
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[]
    | null,
): boolean {
  const restrictedOrders = excludeOrdersAllowedOnSameDay(orders);
  // If any order is allowed same day (and thus is not "restricted"), then the
  // user is allowed to pick today. This gives them the chance to see the error
  // message and which POs aren't allowed same day, if any.
  return restrictedOrders?.length !== orders?.length;
}

/**
 * Gets a list of orders that violate the "same day" restriction imposed by the
 * order's vendor when the given date is "same day" (today). Otherwise, returns
 * null (such as when no orders are in violation or the date isn't today).
 *
 * @param date - The date to check for "same day".
 * @param orders - The orders to check for violations of the "same day" rule.
 */
export function getInvalidSameDayOrders<
  T extends
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[],
>(date: Day, orders: T | null): T | null {
  // All orders should be in the same site, so just pick the first if it exists.
  const firstOrder = orders?.at(0);
  if (!firstOrder) {
    // There are no orders so there are none that are invalid.
    return null;
  }

  const today = Day.getTodayIn(firstOrder.site.timeZone);
  return date.isSameDay(today) ? excludeOrdersAllowedOnSameDay(orders) : null;
}

export function getUniqueInvalidSameDayVendorNames<
  T extends
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[],
>(date: Day | null, orders: T | null): string[] | null {
  if (!date) {
    return null;
  }
  const invalidSameDayOrders = getInvalidSameDayOrders(date, orders);
  if (!invalidSameDayOrders) {
    return null;
  }
  const invalidSameDayVendorNames = invalidSameDayOrders.map(
    ({ vendor }) => vendor.name,
  );
  // Filter duplicates
  return [...new Set(invalidSameDayVendorNames)];
}

export function getUniqueReservationOnlyVendorNames(
  orders: readonly AppointmentUpdatePurchaseOrder[],
): readonly string[] | null {
  const ordersOnlyAllowedInReservationSlots = orders.filter(
    (order) => !order.vendor.areUnreservedSlotsOffered,
  );
  if (!ordersOnlyAllowedInReservationSlots.length) {
    return null;
  }
  const reservationOnlyVendorNames = ordersOnlyAllowedInReservationSlots.map(
    ({ vendor }) => vendor.name,
  );
  // Filter duplicates
  return [...new Set(reservationOnlyVendorNames)];
}

/**
 * Wether the user should be prompted about any of the selected orders being
 * past their due date.
 */
export async function shouldPromptWhenOrdersPastDue({
  selectedDay,
  selectedOrders,
  getOrderCountByNumber,
}: {
  /** The day to check if the orders' due date is earlier than. */
  selectedDay: Day;
  /** The orders to check the due dates on. */
  selectedOrders:
    | readonly PurchaseOrder[]
    | readonly AppointmentUpdatePurchaseOrder[];
  /** A callback for checking how many orders exist with the given PO number. */
  getOrderCountByNumber(number: string): Promise<number>;
}): Promise<boolean> {
  const firstOrder = selectedOrders.at(0);
  if (!firstOrder) {
    throw new Error("Orders cannot be empty.");
  }

  // All orders should be in the same site, so just use the first order's.
  const { site } = firstOrder;

  // Only prompt if the feature is enabled at the site.
  if (!site.isLateDueDatePromptRequired) {
    return false;
  }

  const pastDueOrders = filterUnionOfArrays(
    selectedOrders,
    (order) => order.dueDate?.isBefore(selectedDay) ?? false,
  );
  // Only prompt when there's at least one past-due order.
  if (pastDueOrders.length === 0) {
    return false;
  }

  // Additional "escape hatches" exist when the prompts are limited at the site.
  if (site.isPastDueDateNotificationLimited) {
    if (selectedOrders.length > 1) {
      // Prompt will not display with multiple purchase orders on the appointment.
      return false;
    }

    // An order is cloned if any other order exists in the system with
    // the cloning feature enabled that has the same order number.
    const orderCount = await getOrderCountByNumber(firstOrder.number);
    const hasExistingOrder =
      firstOrder.id === null ? orderCount > 0 : orderCount > 1;
    if (hasExistingOrder) {
      // Prompt will not display when purchase order is reused.
      return false;
    }
  }

  // All attempts to avoid the prompt have failed, so prompt.
  return true;
}

/**
 * Gets the managed type that applies for the entire set of orders based on
 * their respective managed types.
 *
 * @param orders - The orders to find the effective managed type of.
 */
export function getEffectiveManagedType(
  orders:
    | readonly AppointmentUpdatePurchaseOrder[]
    | readonly SimpleAppointmentPurchaseOrder[],
): ManagedType {
  if (orders.length === 0) {
    throw new Error("Cannot calculate effective managed type of no orders.");
  }
  const uniqueManagedTypes = Array.from(
    new Set(orders.map((order) => order.managedType ?? ManagedType.TruckLoad)),
  );
  const firstUniqueManagedType = uniqueManagedTypes.at(0);
  // If all orders have the same managed type, us it. Otherwise, "mixed" managed
  // types must be treated as truck loads.
  return firstUniqueManagedType && uniqueManagedTypes.length === 1
    ? firstUniqueManagedType
    : ManagedType.TruckLoad;
}

export type AppointmentUpdatePurchaseOrderWithChangeType =
  AppointmentUpdatePurchaseOrder & {
    changeType: ChangedOrderType;
  };

/**
 * Apply the Help Assist ticket updates to a set of orders, including generating
 * any new orders from the ticket.
 *
 * @param orders - The set of orders to update with the changes from the ticket.
 * @param orderUpdates - The updates to the orders from the ticket.
 */
export function applyOrderUpdatesFromTicket(
  orders: readonly AppointmentUpdatePurchaseOrder[] | null,
  orderUpdates: readonly HelpAssistTicketAppointmentOrder[] | null,
): readonly AppointmentUpdatePurchaseOrderWithChangeType[] | null {
  if (!orderUpdates) {
    return (
      orders?.map((order) =>
        Object.assign(order, { changeType: ChangedOrderType.Unchanged }),
      ) ?? null
    );
  }

  const [newOrderUpdates, existingOrderUpdates] = partition(
    orderUpdates,
    ({ orderId }) => orderId === null,
  );

  const updatedExistingOrders =
    orders?.map((order) => {
      const update = existingOrderUpdates.find(
        ({ orderId }) => orderId === order.id,
      );
      if (!update) {
        return Object.assign(order, { changeType: ChangedOrderType.Removed });
      }

      const updatedOrder = order.update({
        ...update,
        loadWeight:
          update.loadWeight ?? ValidationConstants.loadWeightDefaultValue,
      });

      const comparisonKeys: SelectedOrderPropertiesToCompare[] = [
        "warehousePalletCount",
        "caseCount",
        "billOfLadingNumber",
        "proNumber",
        "pointOfOrigin",
      ];

      const hasChangedProperties = !comparisonKeys.every(
        (key) =>
          JSON.stringify(order[key]) === JSON.stringify(updatedOrder[key]),
      );

      return Object.assign(updatedOrder, {
        changeType: hasChangedProperties
          ? ChangedOrderType.Modified
          : ChangedOrderType.Unchanged,
      });
    }) ?? [];

  const newOrders = newOrderUpdates.map((update) => {
    const newOrder = AppointmentPurchaseOrderCreation.create({
      ...update,
      loadWeight:
        update.loadWeight ?? ValidationConstants.loadWeightDefaultValue,
    });

    return Object.assign(newOrder, { changeType: ChangedOrderType.Added });
  });

  const updatedOrders = [...updatedExistingOrders, ...newOrders];

  return updatedOrders.length > 0 ? updatedOrders : null;
}

/**
 * Gets the unit count depending on the site's configured unit type.
 */
function getUnitCount(
  order: PurchaseOrder | AppointmentUpdatePurchaseOrder,
): number {
  const { unitType } = order.site;
  switch (unitType) {
    case UnitType.Case:
      return order.caseCount;
    case UnitType.Pallet:
      return order.warehousePalletCount;
    default:
      throwUnhandledCaseError("site unit type", unitType);
  }
}

function getEffectiveUnloadDurationPerUnit(
  order: PurchaseOrder | AppointmentUpdatePurchaseOrder,
): Duration {
  const customUnloadDurationPerUnit = order.site
    .customAppointmentDurationCalculation.isEnabled
    ? order.vendor.customUnloadDurationPerUnit
    : null;
  const effectiveUnloadDurationPerUnit =
    customUnloadDurationPerUnit ??
    order.vendor.unloadDurationPerUnit ??
    order.site.defaultUnloadDurationPerUnit;

  return effectiveUnloadDurationPerUnit;
}

function getEstimatedUnloadDuration(
  order: PurchaseOrder | AppointmentUpdatePurchaseOrder,
): Duration {
  const effectiveUnloadDurationPerUnit =
    getEffectiveUnloadDurationPerUnit(order);

  return Duration.fromMillis(
    effectiveUnloadDurationPerUnit.as("milliseconds") * getUnitCount(order),
  ).shiftTo("minutes");
}

function getEstimatedUnloadDurationForTotalWarehousePalletOverride(
  orders: readonly PurchaseOrder[] | readonly AppointmentUpdatePurchaseOrder[],
  totalWarehousePalletCountOverride: number,
): Duration {
  const averageUnloadDurationPerPallet =
    orders
      .map(getEffectiveUnloadDurationPerUnit)
      .reduce((previous, current) => current.plus(previous))
      .as("milliseconds") / orders.length;

  return Duration.fromMillis(
    averageUnloadDurationPerPallet * totalWarehousePalletCountOverride,
  ).shiftTo("minutes");
}

// TypeScript currently doesn't support `.filter` on unions of arrays properly
// so we have to assert that it's safe instead.
// See: https://github.com/microsoft/TypeScript/issues/44373
function filterUnionOfArrays<A extends readonly unknown[]>(
  array: A,
  predicate: (value: A[number]) => unknown,
): A {
  return array.filter(predicate) as unknown as A;
}

type SelectedOrderPropertiesToCompare = keyof Pick<
  PurchaseOrder,
  | "warehousePalletCount"
  | "caseCount"
  | "billOfLadingNumber"
  | "proNumber"
  | "pointOfOrigin"
>;

export type AppointmentDurationSettings = Pick<
  Site,
  | "appointmentInterval"
  | "minimumAppointmentDuration"
  | "maximumAppointmentDuration"
>;
