import { Component, HostBinding, Input } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { isString } from "lodash-es";
import { Permission } from "../auth";

@Component({
  selector: "mr-link",
  template: `
    <ng-container *ngIf="permission; else linkedContent">
      <ng-container
        *mrIfHasPermission="permission; then linkedContent; else content"
      ></ng-container>
    </ng-container>

    <ng-template #linkedContent>
      <a
        [routerLink]="link"
        [queryParams]="params"
        [state]="{ returnTo: returnTo }"
        ><ng-container [ngTemplateOutlet]="content"></ng-container
      ></a>
    </ng-template>

    <ng-template #content><ng-content></ng-content></ng-template>
  `,
  styles: [
    `
      :host.important a {
        color: var(--mr-color-blue);
      }
    `,
  ],
})
export class LinkComponent {
  @Input() public link: RouterLink["routerLink"] = ".";
  @Input() public params: RouterLink["queryParams"] = {};
  @Input() public permission?: Permission;
  @Input() public returnTo = window.location.href.replace(
    window.location.origin,
    "",
  );
  @Input() @HostBinding("class") public kind?: "important";
}

export function getReturnLink(
  router: Router,
  { defaultLink }: { defaultLink?: string } = {},
): string {
  const state = router.getCurrentNavigation()?.extras.state;
  return state && isString(state["returnTo"]) && state["returnTo"].length > 0
    ? state["returnTo"]
    : defaultLink ?? "..";
}
