import { isLeft } from "fp-ts/es6/Either";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/es6/NonEmptyString";
import { PathReporter } from "io-ts/es6/PathReporter";

/**
 * Appends a path component to an existing URL, ensuring no existing path is
 * overwritten, even if an absolute path component is provided.
 *
 * @param baseUrl - The full URL to append to.
 * @param path - The path to add to the URL.
 */
export function appendUrlPath(baseUrl: URL | string, path: string): URL {
  return new URL(path.replace(/^\/+/, ""), normalizeUrl(baseUrl));
}

/**
 * Normalizes the URL with a trailing slash to ensure appending paths to it
 * don't override the existing path.
 *
 * @param url - The URL string or object to normalize.
 */
export function normalizeUrl(url: URL | string): URL {
  const href = url instanceof URL ? url.href : url;
  return new URL(href.replace(/\/+$/, "") + "/");
}

const urlCodec = new t.Type<URL, string, string>(
  "URL",
  (value): value is URL => value instanceof URL,
  (value, context) => {
    try {
      // Ensure there's always a trailing slash so that the paths join properly.
      return t.success(normalizeUrl(value));
    } catch {
      return t.failure(value, context);
    }
  },
  ({ href }) => href,
);
export const urlFromStringCodec = t.string.pipe(urlCodec, "UrlFromString");

const environmentTypeCodec = t.keyof({
  dev: null,
  local: null,
  preprod: null,
  prod: null,
  qa: null,
  "qa-di": null,
  perf: null,
  rc: null,
  sandbox: null,
  test: null,
});

const apiUrlsCodec = t.type(
  {
    attachments: urlFromStringCodec,
    mr: urlFromStringCodec,
    sites: urlFromStringCodec,
  },
  "ApiUrls",
);

const azureADCodec = t.type(
  {
    appIds: t.type({
      mr: NonEmptyString,
      sites: NonEmptyString,
    }),
    clientId: NonEmptyString,
    loginAuthorityPolicyName: NonEmptyString,
    passwordResetPolicyName: NonEmptyString,
    tenantName: NonEmptyString,
  },
  "AzureAD",
);

const powerBICodec = t.type({
  dockOrderStatusId: NonEmptyString,
  workspaceId: NonEmptyString,
});

const appInsightsCodec = t.type({
  instrumentationKey: NonEmptyString,
  roleInstance: NonEmptyString,
  roleName: NonEmptyString,
});

const ymsUrlsCodec = t.type({
  app: urlFromStringCodec,
  // UI Routes
  moveRequests: NonEmptyString,
  // API Endpoints
  apiInitiateAppointmentMoveRequest: NonEmptyString,
  apiActiveMoveRequestForAppointment: NonEmptyString,
});

const configCodec = t.type(
  {
    apiUrls: apiUrlsCodec,
    applicationInsights: appInsightsCodec,
    azureAD: azureADCodec,
    environment: environmentTypeCodec,
    fileExchangeAPIManagementKey: NonEmptyString,
    googleMapsApiKey: NonEmptyString,
    powerBI: powerBICodec,
    ymsUrls: ymsUrlsCodec,
    autoExtendReservationLimitInDays: t.Int,
    noOfDaysForExtendingReservation: t.Int,
  },
  "Config",
);

const windowCodec = t.type({ MANAGED_RECEIVING: configCodec }, "Window");

export type GlobalEnvironmentConfig = t.TypeOf<typeof configCodec>;

export function decodeConfig(config: unknown): GlobalEnvironmentConfig {
  return decode(configCodec, config);
}

export function getConfig(): GlobalEnvironmentConfig {
  return decode(windowCodec, window).MANAGED_RECEIVING;
}

function decode<C extends t.Any>(codec: C, value: unknown): t.TypeOf<C> {
  const result = codec.decode(value);
  // The "left" of the "either" result is set if there are errors.
  if (isLeft(result)) {
    throw new Error(
      "Invalid global config:\n" + PathReporter.report(result).join("\n"),
    );
  }

  return result.right as unknown;
}
