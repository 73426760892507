import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({ name: "dateTime" })
export class DateTimePipe implements PipeTransform {
  public transform(
    value: DateTime | null | undefined,
    format: DateTimeFormat = "medium",
  ): string | null {
    return value?.toLocaleString(formats[format] ?? formats.medium) ?? null;
  }
}

type Format = Intl.DateTimeFormatOptions;
const hour: Format = { hour: "numeric" };
const time: Format = { ...DateTime.TIME_SIMPLE, timeZoneName: "short" };
const short: Format = { ...DateTime.DATE_SHORT, ...time };
const medium: Format = { ...DateTime.DATETIME_MED, ...time };
const fullTime: Format = { ...time, timeZoneName: "long" };

const formats = {
  short,
  medium,
  long: DateTime.DATETIME_FULL,
  full: DateTime.DATETIME_HUGE,

  shortDate: DateTime.DATE_SHORT,
  mediumDate: DateTime.DATE_MED,
  longDate: DateTime.DATE_FULL,
  fullDate: DateTime.DATE_HUGE,

  time,
  hour,
  fullTime,

  // We don't ever include seconds and always include time zone in times so
  // these are all just aliases:
  shortTime: time,
  mediumTime: time,
  longTime: time,
} as const;

/**
 * Date formats for the `dateTime` pipe. Very closely based on the `date` pipe
 * formats from Angular, though tuned for our specific format needs such as
 * always including the time zone with all times.
 *
 * @see https://angular.io/api/common/DatePipe#pre-defined-format-options
 *
 * @example
 * short -> "1/1/2049, 12:00 AM CST"
 * medium -> "Jan 1, 2049, 12:00 AM CST"
 * long -> "January 1, 2049, 12:00 AM CST"
 * full -> "Friday, January 1, 2049, 12:00 AM Central Standard Time"
 *
 * shortDate -> "1/1/2049"
 * mediumDate -> "Jan 1, 2049"
 * longDate -> "January 1, 2049"
 * fullDate -> "Friday, January 1, 2049"
 *
 * time -> "12:00 AM CST"
 * fullTime -> "12:00 AM Central Standard Time"
 *
 * // Aliases for "time" to fully support the `DatePipe` formats:
 * shortTime -> "12:00 AM CST"
 * mediumTime -> "12:00 AM CST"
 * longTime -> "12:00 AM CST"
 */
export type DateTimeFormat = keyof typeof formats;
