import { Injectable } from "@angular/core";
import { sortBy } from "lodash-es";
import { combineLatest, firstValueFrom, Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { RequestService } from "src/app/core/request.service";
import { UserPreferencesService } from "src/app/core/user-preferences.service";
import {
  addOrUpdateBy,
  firstExistentValueFrom,
  getDefaultUpdater,
  isExistent,
  isInstanceOf,
  mapArrayUpdates,
  ODataResourceUrl,
  PartnerResource,
} from "src/utils";
import {
  expandedPartnersResource,
  Partner,
  PartnerAdd,
  partnersResource,
  PartnerUpdate,
} from "./partner.model";

@Injectable({ providedIn: "root" })
export class PartnersService {
  public constructor(
    private readonly request: RequestService,
    private readonly userPreferences: UserPreferencesService,
    private readonly user: UserService,
  ) {}

  private readonly updates = new Subject<Partner>();

  public readonly changes: Observable<readonly Partner[]> = this.request
    .getAll(expandedPartnersResource)
    .pipe(
      // Ignore the `null` loading indicator as this should only load once
      // and we don't want it to refresh the default partner selection.
      filter(isExistent),
      map(Partner.deserializeList),
      mapArrayUpdates(this.updates, (all, update) =>
        addOrUpdateBy(all, update, (partner) => partner.key.isSame(update.key)),
      ),
      map((partners) =>
        sortBy(partners, (partner) => partner.name.toLowerCase()),
      ),
      shareReplay(1),
    );

  private readonly maybeSelectedForPartnerUserChanges = this.changes.pipe(
    map((partners) => {
      if (!this.user.isPartner()) {
        throw new Error(
          'Partner user "selected partner" observable used for wrong user type.',
        );
      }
      const { partnerKey } = this.user.details;
      return (
        partners.find((partner) => partner.key.isSame(partnerKey)) ??
        "not-found"
      );
    }),
    shareReplay(1),
  );

  private readonly maybeSelectedForGlobalUserChanges = combineLatest([
    this.userPreferences.partnerKeyChanges,
    this.changes,
  ]).pipe(
    map(
      ([key, partners]) =>
        (key && partners.find((partner) => partner.key.isSame(key))) ||
        "not-found",
    ),
    shareReplay(1),
  );

  private readonly maybeSelectedChanges = this.user.isPartner()
    ? this.maybeSelectedForPartnerUserChanges
    : this.maybeSelectedForGlobalUserChanges;

  public readonly selectedChanges = this.maybeSelectedChanges.pipe(
    filter(isInstanceOf(Partner)),
    distinctUntilChanged(),
  );

  public readonly isSelectedLoadedChanges = this.maybeSelectedChanges.pipe(
    map((partner) => partner !== "not-found"),
  );

  private readonly defaultSelectionChanges = this.changes.pipe(
    map((partners) => (partners[0] ?? null) as Partner | null),
  );

  private isSelectedLoadedForGlobalUserNotifier?: Observable<boolean>;

  private readonly unloadNotifier = new Subject<void>();

  public async load(): Promise<boolean> {
    if (this.user.isPartner()) {
      const partner = await firstValueFrom(
        this.maybeSelectedForPartnerUserChanges,
      );

      if (partner !== "not-found") {
        await this.setSelected(partner);
        return true;
      } else {
        return false;
      }
    }

    if (!this.isSelectedLoadedForGlobalUserNotifier) {
      // Load the cache first so the default selection doesn't get applied on
      // initial load, and only runs on subsequent changes.
      await firstExistentValueFrom(this.changes);

      const [updater, notifier] = getDefaultUpdater(
        this.maybeSelectedForGlobalUserChanges,
        this.defaultSelectionChanges,
      );
      this.isSelectedLoadedForGlobalUserNotifier = notifier;

      // Set the default partner (for global/admin users only)
      // if one isn't selected or the selected wasn't found.
      updater
        .pipe(
          switchMap((partner) => this.setSelected(partner)),
          takeUntil(this.unloadNotifier),
        )
        .subscribe();
    }

    return firstValueFrom(this.isSelectedLoadedForGlobalUserNotifier);
  }

  public async unload(): Promise<void> {
    this.unloadNotifier.next();
    this.isSelectedLoadedForGlobalUserNotifier = undefined;
    await this.userPreferences.setPartnerKey(null);
  }

  public async update(update: PartnerAdd | PartnerUpdate): Promise<Partner> {
    const partner =
      update instanceof PartnerAdd
        ? await this.post(update)
        : await this.patch(update);

    this.updates.next(partner);
    return partner;
  }

  private async post(update: PartnerAdd): Promise<Partner> {
    const response = await firstValueFrom(
      this.request.post(partnersResource, update),
    );
    return Partner.deserialize(response);
  }

  private async patch(update: PartnerUpdate): Promise<Partner> {
    const response = await firstValueFrom(
      this.request.patch(
        partnersResource.appendId(update.key.toString()),
        update,
      ),
    );
    return Partner.deserialize(response);
  }

  /**
   * Gets the concrete `ODataResourceUrl` for a `PartnerResource` that is nested
   * within the appropriate, currently selected partner.
   *
   * **Example result URL:**
   *
   * `https://dev-mr.capstonelogistics.com/api/partner/1234/docks`
   *
   * @param resource The partner resource to add the partner to.
   */
  public getResourceInSelected<Resource, ResourceName>(
    resource: PartnerResource<Resource, ResourceName>,
  ): Observable<ODataResourceUrl<Resource, ResourceName>> {
    return this.selectedChanges.pipe(map((partner) => resource.get(partner)));
  }

  public async setSelected(partner: Pick<Partner, "key">): Promise<void> {
    if (
      this.user.isPartner() &&
      !this.user.details.partnerKey.isSame(partner.key)
    ) {
      // Don't allow partners to change their selected partner.
      // TODO: probably should log this with App Insights or such.
      // We can't provide a user alert or throw as the app root where the alerts
      // are injected into isn't loaded yet generally.
      return;
    }
    await this.userPreferences.setPartnerKey(partner.key, {
      sessionOnly: this.user.isCarrier(),
    });
  }
}
