import { Chain, getOrElse } from "fp-ts/es6/Either";
import { pipe } from "fp-ts/es6/function";
import * as t from "io-ts";
import { withFallback } from "io-ts-types/es6/withFallback";
import { DateTime, Duration, Interval } from "luxon";
import { Day } from "src/app/core/day.model";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import {
  formatDateTime,
  parseDateTime,
} from "./converters/date-time-converters";
import { getEnumMember, isInstanceOf } from "./miscellaneous";

interface NonNumberStringBrand {
  readonly NonNumberString: unique symbol;
}
type NonNumberString = t.Branded<string, NonNumberStringBrand>;
const nonNumberStringCodec = t.brand(
  t.string,
  (value): value is NonNumberString => Number.isNaN(Number(value)),
  "NonNumberString",
);

export const dateTimeCodec = new t.Type<DateTime>(
  "DateTime",
  DateTime.isDateTime,
  (value, context) =>
    DateTime.isDateTime(value) ? t.success(value) : t.failure(value, context),
  t.identity,
);

// Require NonNumberString for inputs to exclude bare number. `fromISO` will
// parse some 2-digit and 4-digit number strings as the hour and the year,
// respectively, but we are always expecting full date-time strings.
export const dateTimeFromIsoStringCodec = new t.Type<
  DateTime,
  NonNumberString,
  unknown
>(
  "DateTimeFromIsoString",
  DateTime.isDateTime,
  (value, context) => {
    return Chain.chain(
      nonNumberStringCodec.validate(value, context),
      (stringValue) => {
        try {
          const dateTime = parseDateTime(stringValue);
          return dateTime.isValid
            ? t.success(dateTime)
            : t.failure(value, context);
        } catch {
          return t.failure(value, context);
        }
      },
    );
  },
  // `toISO` will always return the full ISO date string, never just a number.
  (value) => formatDateTime(value) as NonNumberString,
);

export const dayCodec = instanceOfCodec(Day);

export const dayFromStringCodec = new t.Type<Day, string, unknown>(
  "DayFromString",
  dayCodec.is,
  (value, context) => {
    return Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        return t.success(Day.deserialize(stringValue, null));
      } catch {
        return t.failure(stringValue, context);
      }
    });
  },
  (value) => value.serialize(),
);

export const durationCodec = new t.Type<Duration>(
  "Duration",
  Duration.isDuration,
  (input, context) =>
    Duration.isDuration(input) ? t.success(input) : t.failure(input, context),
  t.identity,
);

export const durationFromStringCodec = new t.Type<Duration, string, unknown>(
  "DurationFromString",
  durationCodec.is,
  (value, context) => {
    return Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        const duration = Duration.fromISO(stringValue);
        return duration.isValid
          ? t.success(duration)
          : t.failure(stringValue, context);
      } catch {
        return t.failure(stringValue, context);
      }
    });
  },
  (value) => value.toISO(),
);

export function enumFromStringCodec<T extends string>(
  enumType: Readonly<Record<string, T>>,
): t.Type<T, string, unknown> {
  return new t.Type<T, string, unknown>(
    "EnumFromString",
    (value): value is T =>
      typeof value === "string" &&
      getEnumMember(enumType, value, null) !== null,
    (value, context) => {
      return Chain.chain(t.string.validate(value, context), (stringValue) => {
        try {
          return t.success(getEnumMember(enumType, stringValue));
        } catch {
          return t.failure(stringValue, context);
        }
      });
    },
    (value) => value,
  );
}

export function instanceOfCodec<T>(constructor: ConstructorLike<T>): t.Type<T> {
  const isInstanceOfConstructor = isInstanceOf(constructor);
  return new t.Type<T, T>(
    constructor.name,
    isInstanceOfConstructor,
    (value, context) =>
      isInstanceOfConstructor(value)
        ? t.success(value)
        : t.failure(value, context),
    t.identity,
  );
}

export const intervalFromIsoStringCodec = new t.Type<Interval, string, unknown>(
  "IntervalFromIsoString",
  Interval.isInterval,
  (value, context) => {
    return Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        const interval = Interval.fromISO(stringValue);
        return interval.isValid
          ? t.success(interval)
          : t.failure(value, context);
      } catch {
        return t.failure(value, context);
      }
    });
  },
  (value) => value.toISO(),
);

export const jsonFromStringCodec = new t.Type<unknown, string, string>(
  "JSONFromString",
  (_value): _value is unknown => true,
  (value, context) => {
    try {
      return t.success(JSON.parse(value));
    } catch {
      return t.failure(value, context);
    }
  },
  (value) => JSON.stringify(value),
);

export const timeOfDayCodec = instanceOfCodec(TimeOfDay);

export const timeOfDayFromStringCodec = new t.Type<TimeOfDay, string, unknown>(
  "TimeOfDayFromString",
  timeOfDayCodec.is,
  (value, context) => {
    return Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        return t.success(TimeOfDay.deserialize(stringValue));
      } catch {
        return t.failure(stringValue, context);
      }
    });
  },
  (value) => value.serialize(),
);

const uuidRegExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export class UUID {
  public constructor(value: string) {
    if (uuidRegExp.test(value) === false) {
      throw new Error(`Invalid UUID: ${value}`);
    }
    this.value = value.toLowerCase();
  }

  private readonly value: string;

  public isSame(other: UUID): boolean {
    return this.value === other.value;
  }

  public toString(): string {
    return this.value;
  }
}

export const uuidStringCodec = new t.Type<UUID, string, unknown>(
  "UUIDString",
  isInstanceOf(UUID),
  (value, context) =>
    Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        return t.success(new UUID(stringValue));
      } catch {
        return t.failure(stringValue, context);
      }
    }),
  String,
);

export const weekdaysCodec = instanceOfCodec(Weekdays);

export const weekdaysFromStringCodec = new t.Type<Weekdays, string, unknown>(
  "WeekdaysFromString",
  weekdaysCodec.is,
  (value, context) => {
    return Chain.chain(t.string.validate(value, context), (stringValue) => {
      try {
        return t.success(Weekdays.deserialize(stringValue));
      } catch {
        return t.failure(stringValue, context);
      }
    });
  },
  (value) => value.serialize() || "",
);

export function maybe<C extends t.Any>(codec: C): t.UnionC<[C, t.UndefinedC]> {
  return withFallback(t.union([codec, t.undefined]), undefined);
}

/**
 * Gets a decoder function from a codec that returns null on error.
 * @param codec The codec to decode with.
 */
export function decodeOrElseNull<T, I>(
  codec: t.Type<T, unknown, I>,
): (value: I) => T | null {
  return (value) =>
    pipe(
      codec.decode(value),
      getOrElse(() => null as T | null),
    );
}
