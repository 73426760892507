import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { AppointmentStatus } from "src/app/partner/appointments/appointment-status.model";

import {
  FilterODataModel,
  getApiDetailsDecorator,
  OmitMetaProperties,
} from "src/utils";
import {
  ApiTicketAppointment,
  HelpAssistTicketAppointment,
} from "../help-assist-ticket-appointment.model";
import {
  GlobalApiTicketAppointmentDraft,
  GlobalHelpAssistTicketAppointmentDraft,
  PartnerApiTicketAppointmentDraft,
  PartnerHelpAssistTicketAppointmentDraft,
} from "../ticket-appointment-draft/help-assist-ticket-appointment-draft.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketSchedule>();

abstract class SharedScopeHelpAssistTicketSchedule {
  protected constructor(
    args: ClassProperties<SharedScopeHelpAssistTicketSchedule>,
  ) {
    this.appointment = args.appointment;
    this.isAppointmentApprovalRequired = args.isAppointmentApprovalRequired;
    this.isAppointmentOwnerApprovalRequired =
      args.isAppointmentOwnerApprovalRequired;
  }

  @api({
    key: "appointmentId",
    navigationProperty: "appointment",
    uiModel: HelpAssistTicketAppointment,
  })
  public readonly appointment: HelpAssistTicketAppointment | null;
  @api({ key: "appointmentApprovalRequired" })
  public readonly isAppointmentApprovalRequired: boolean;
  @api({ key: "appointmentOwnerApprovalRequired" })
  public readonly isAppointmentOwnerApprovalRequired: boolean;

  protected static deserializeBase(
    apiModel: GlobalApiTicketSchedule | PartnerApiTicketSchedule,
    { site, statuses }: DeserializeArguments,
  ): SharedScopeHelpAssistTicketSchedule {
    return {
      appointment: apiModel.appointment
        ? HelpAssistTicketAppointment.deserialize(apiModel.appointment, {
            site,
            statuses,
          })
        : null,
      isAppointmentApprovalRequired: apiModel.appointmentApprovalRequired,
      isAppointmentOwnerApprovalRequired:
        apiModel.appointmentOwnerApprovalRequired,
    };
  }
}

export class GlobalHelpAssistTicketSchedule extends SharedScopeHelpAssistTicketSchedule {
  private constructor(args: ClassProperties<GlobalHelpAssistTicketSchedule>) {
    super(args);
    this.ticketAppointment = args.ticketAppointment;
  }

  @api({
    key: "ticketAppointmentId",
    navigationProperty: "ticketAppointment",
    uiModel: GlobalHelpAssistTicketAppointmentDraft,
  })
  public readonly ticketAppointment: GlobalHelpAssistTicketAppointmentDraft | null;

  public static deserialize(
    apiModel: OmitMetaProperties<GlobalApiTicketSchedule>,
    { site, statuses }: DeserializeArguments,
  ): GlobalHelpAssistTicketSchedule {
    return new GlobalHelpAssistTicketSchedule({
      ...this.deserializeBase(apiModel, { site, statuses }),
      ticketAppointment: apiModel.ticketAppointment
        ? GlobalHelpAssistTicketAppointmentDraft.deserialize(
            apiModel.ticketAppointment,
            { site },
          )
        : null,
    });
  }
}

export class PartnerHelpAssistTicketSchedule extends SharedScopeHelpAssistTicketSchedule {
  private constructor(args: ClassProperties<PartnerHelpAssistTicketSchedule>) {
    super(args);
    this.ticketAppointment = args.ticketAppointment;
  }

  @api({
    key: "ticketAppointmentId",
    uiModel: PartnerHelpAssistTicketAppointmentDraft,
    navigationProperty: "ticketAppointment",
  })
  public readonly ticketAppointment: PartnerHelpAssistTicketAppointmentDraft | null;

  public static deserialize(
    apiModel: OmitMetaProperties<PartnerApiTicketSchedule>,
    { site, statuses }: DeserializeArguments,
  ): PartnerHelpAssistTicketSchedule {
    return new PartnerHelpAssistTicketSchedule({
      ...this.deserializeBase(apiModel, { site, statuses }),
      ticketAppointment: apiModel.ticketAppointment
        ? PartnerHelpAssistTicketAppointmentDraft.deserialize(
            apiModel.ticketAppointment,
            { site },
          )
        : null,
    });
  }
}

export type HelpAssistTicketSchedule =
  | GlobalHelpAssistTicketSchedule
  | PartnerHelpAssistTicketSchedule;

type GlobalApiTicketSchedule = FilterODataModel<
  Api.HelpAssistTicketSchedule,
  "appointmentApprovalRequired" | "appointmentOwnerApprovalRequired"
> & {
  appointment?: ApiTicketAppointment | null;
  ticketAppointment?: GlobalApiTicketAppointmentDraft | null;
};

type PartnerApiTicketSchedule = FilterODataModel<
  Api.HelpAssistTicketSchedule,
  "appointmentApprovalRequired" | "appointmentOwnerApprovalRequired"
> & {
  appointment?: ApiTicketAppointment | null;
  ticketAppointment?: PartnerApiTicketAppointmentDraft | null;
};

interface DeserializeArguments {
  readonly site: Site;
  readonly statuses: readonly AppointmentStatus[];
}
