import { Injectable } from "@angular/core";
import { NgbTimeAdapter, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { TimeOfDay } from "../time-of-day.model";

@Injectable()
export class TimepickerAdapter extends NgbTimeAdapter<TimeOfDay | null> {
  public fromModel(time: TimeOfDay | null): NgbTimeStruct {
    if (!time) {
      // The base class doesn't support the null return value case despite the
      // docs suggesting it works, so we have to cast it.
      // See: https://ng-bootstrap.github.io/#/components/timepicker/examples#adapter
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
      return null as any;
    }
    return {
      hour: time.hour,
      minute: time.minute,
      second: 0,
    };
  }

  public toModel(time: NgbTimeStruct): TimeOfDay | null {
    return time ? new TimeOfDay(time) : null;
  }
}
