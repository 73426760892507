import { get, isNil } from "lodash-es";
import { Duration, Interval } from "luxon";
import { QuillToolbarConfig } from "ngx-quill";
import { parseDelta } from "./converters/core-converters";

export { appendUrlPath } from "../environments/get-global-config";

/**
 * Replaces an existing value in an array with an updated value, leaving it at
 * the same index, or appends the new value to the end if it's not in the array.
 * If the updated value is `null`, the element is removed from the array.
 * This does **not** mutate the array. It returns a new array.
 * @param array The array to replace the value in.
 * @param value The value to update in place.
 * @param predicate A function to match which value in the array to update.
 */
export function addOrUpdateBy<T>(
  array: readonly T[],
  value: T | null,
  predicate: (value: T) => boolean,
): readonly T[] {
  const updatedModelSet = isNil(value) ? [] : [value];

  const index = array.findIndex(predicate);
  if (index === -1) {
    return [...array, ...updatedModelSet];
  }

  const frontHalf = array.slice(0, index);
  const backHalf = array.slice(index + 1);

  return [...frontHalf, ...updatedModelSet, ...backHalf];
}

/**
 * Replaces an existing model in an array with an updated value, leaving it at
 * the same index, or appends the new value to the end if it's not in the array.
 * Optionally, it can remove the item instead.
 * This does **not** mutate the array. It returns a new array.
 * @param array The array to replace the model in.
 * @param value The model to update in place.
 * @param options.remove Whether to remove instead of updating the item, if found.
 */
export function addOrUpdateModel<T extends { readonly id: number }>(
  array: readonly T[],
  value: T,
  { remove = false } = {},
): readonly T[] {
  return addOrUpdateBy(
    array,
    remove ? null : value,
    ({ id }) => id === value.id,
  );
}

/**
 * Replaces an existing model in an OData collection with an updated value,
 * leaving it at the same index, or appends the new value to the end if it's not
 * in the collection. Optionally, it can remove the item instead.
 *
 * This does **not** mutate the array. It returns a new array.
 * @param collection - The collection to replace the model in.
 * @param item - The model to update in place.
 * @param options.remove - Whether to remove instead of updating the item, if
 * found.
 */
export function addOrUpdateApiModel<
  Item extends { readonly id: number },
  Collection extends { readonly value: readonly Item[] },
>(collection: Collection, item: Item, { remove = false } = {}): Collection {
  return {
    ...collection,
    value: addOrUpdateBy(
      collection.value,
      remove ? null : item,
      ({ id }) => id === item.id,
    ),
  };
}

/**
 * Whether two date/duration-like objects are equal, including possibly null
 * values.
 * @param left - The "left side" item to compare in the equality check.
 * @param right - The "right side" item to compare in the equality check.
 */
export function areLuxonModelsEqual<T extends { equals(other: T): boolean }>(
  left: T | null,
  right: T | null,
): boolean {
  return left === right || (!!left && !!right && left.equals(right));
}

/**
 * Limits a duration within an inclusive lower and upper bound.
 * @param duration - The duration to clamp.
 * @param options.min - The duration lower bound. (Default: 0)
 * @param options.max - The duration upper bound. (Default: none)
 */
export function clampDuration(
  duration: Duration,
  {
    min = Duration.fromMillis(0),
    max = duration,
  }: { min?: Duration; max?: Duration },
): Duration {
  if (duration < min) {
    return min;
  } else if (duration > max) {
    return max;
  } else {
    return duration;
  }
}

/**
 * Limits the duration of an interval within an inclusive lower and upper bound
 * while maintaining the start time.
 * @param interval - The interval to clamp.
 * @param options.min - The duration lower bound. (Default: 0)
 * @param options.max - The duration upper bound. (Default: none)
 */
export function clampIntervalDuration(
  interval: Interval,
  options: { min?: Duration; max?: Duration },
): Interval {
  const clampedDuration = clampDuration(interval.toDuration(), options);
  return Interval.after(interval.start, clampedDuration);
}

/**
 * Throws an error for an unhandled switch case.
 * @param valueType - The name of the value type to include in the error message.
 * @param value - The value that violated the expectation.
 */
export function throwUnhandledCaseError(
  valueType: string,
  value: never,
): never {
  throw new Error(`Unhandled switch case for ${valueType}: ${String(value)}`);
}

/**
 * Checks whether the value exists, i.e. is not `null` or `undefined`. this is
 * **not** a truthy check, so `isExistent(0) === true`, for instance.
 * @param value The value to check.
 */
export function isExistent<T>(value: T): value is NonNullable<T> {
  return !isNil(value);
}

/**
 * Creates a function that checks its argument against the given class
 * constructor to see if the argument is an instance of the class.
 * @param classConstructor The constructor of the class to check the value against.
 */
export const isInstanceOf =
  <T>(classConstructor: ConstructorLike<T>) =>
  (value: unknown): value is T =>
    value instanceof classConstructor;

/**
 * Creates a function which determines whether its argument **does not** exactly
 * equal the provided value. Useful for use in `filter` functions.
 * @param notValue The value the function will check its values against.
 */
export function isNot<Value, ExcludedValue extends Value>(
  notValue: ExcludedValue,
): (value: Value) => value is Exclude<Value, ExcludedValue> {
  return (value: Value): value is Exclude<Value, ExcludedValue> =>
    value !== notValue;
}

/**
 * Checks whether a Quill Delta represents an empty value.
 * @param deltaJson The JSON representation of the Delta to check.
 */
export function isEmptyDelta(deltaJson: string | null | undefined): boolean {
  const delta = parseDelta(deltaJson);
  if (!delta) {
    return true;
  }
  const nonEmptyOperations = delta.filter(
    ({ insert }) => typeof insert === "string" && /[^\n\s]/.test(insert),
  );
  return nonEmptyOperations.length === 0;
}

/**
 * Creates a function that checks its argument value against the given array
 * to see if the value exists in (and thus is of a type from) the array.
 * @param array The array or tuple to check the value against.
 */
export const isOneOfCreator =
  <T>(array: readonly T[]) =>
  (value: unknown): value is T => {
    // It should always be safe to pass any value to `includes` regardless
    // of the types of the array or the value and have it return whether
    // the value is included.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return array.includes(value as any);
  };

/**
 * Gets the enum member type for the given value or throws if none is found.
 * @param enumType The enum object to get the members from.
 * @param value The value to check for in the enum.
 */
export function getEnumMember<T>(
  enumType: T,
  value: number | string,
): T[keyof T];
/**
 * Gets the enum member type for the given value. If the value is not found,
 * return the provided default member from the enum.
 * @param enumType The enum object to get the members from.
 * @param value The value to check for in the enum.
 * @param defaultMember The value to return if the provided value doesn't exist.
 */
export function getEnumMember<T>(
  enumType: T,
  value: number | string | null | undefined,
  defaultMember: T[keyof T],
): T[keyof T];
/**
 * Gets the enum member type for the given value. If the value is not found,
 * return null.
 * @param enumType The enum object to get the members from.
 * @param value The value to check for in the enum.
 * @param defaultMember The value to return if the provided value doesn't exist.
 */
export function getEnumMember<T extends Record<string, unknown>>(
  enumType: T,
  value: number | string | null | undefined,
  defaultMember: null,
): T[keyof T] | null;
export function getEnumMember<T extends Record<string, unknown>>(
  enumType: T,
  value: number | string | null | undefined,
  defaultMember?: T[keyof T] | null,
): T[keyof T] | null {
  if (isExistent(value) && isEnumMember(enumType, value)) {
    return value;
  } else if (defaultMember === undefined) {
    throw new Error(
      `Missing enum member for value "${String(
        value,
      )}" and no default was provided.`,
    );
  } else {
    return defaultMember;
  }
}

/**
 * Gets a list of allowed Quill formats (e.g. for pasted in text) from a toolbar
 * configuration.
 *
 * This allows for limiting the allowed formats to only those supported by the
 * editor itself.
 *
 * @param toolbar - The Quill toolbar configuration to pull the allowed formats
 * from.
 */
export function getQuillFormatsFromToolbar(
  toolbar: QuillToolbarConfig,
): string[] {
  return toolbar
    .flat()
    .flatMap((option) =>
      typeof option === "string" ? option : Object.keys(option),
    )
    .filter((format) => format !== "clean");
}

function isEnumMember<T extends Record<string, unknown>>(
  enumType: T,
  value: unknown,
): value is T[keyof T] {
  return Object.values(enumType).includes(value);
}

export function hasProperty<T extends object, P extends string>(
  value: T,
  property: P,
): value is T & Readonly<Record<P, unknown>> {
  return property in value;
}

/**
 * Joins one or more path segments together, resolving any relative parent
 * segments (`..`) and removes empty segments.
 */
export function joinPath(first: string, ...rest: string[]): string {
  // Keep leading slash in first segment if present to represent the root path.
  // Remove it from everything else.
  const path = [first, ...rest.map((segment) => segment.replace(/^\/+/, ""))]
    // Remove trailing slashes from all segments so there aren't extra slashes
    // when they're all joined together again.
    .map((segment) => segment.replace(/\/+$/, ""))
    // Remove any empty segments since it's likely just a typo or trailing slash.
    .filter((segment) => segment)
    .join("/");
  // Use URL with a throw-away "base" URL to resolve any `..` segments. Also
  // remove any trailing slashes that might result, for instance:
  // `/foo/bar/..` -> `/foo/` -> `/foo`
  return new URL(path, "http://example.com").pathname.replace(/\/+$/, "");
}

/**
 * Confirms that the given object has no falsy enumerable properties and returns
 * that object or null if there is any one falsy property.
 *
 * @param object - The object to check.
 */
export function mustHaveEvery<T extends Record<string, unknown>>(
  object: T,
): { readonly [P in keyof T]: NonNullable<T[P]> } | null {
  return Object.values(object).every((value) => value)
    ? (object as { [P in keyof T]: NonNullable<T[P]> })
    : null;
}

/**
 * Confirms that the given object has at least one truthy enumerable property
 * and returns that object or null if all properties are falsy.
 *
 * @param object - The object to check.
 */
export function mustHaveOne<T extends Record<string, unknown>>(
  object: T,
): Readonly<T> | null {
  return Object.values(object).some((value) => value) ? object : null;
}

/**
 * Catches HTTP 404 errors and returns null instead; rethrows otherwise.
 */
export function notFoundErrorToNull(error: unknown): null {
  // Any error with a `status` property, e.g. HttpErrorResponse or our ApiError.
  if (get(error, "status") === 404) {
    return null;
  }
  throw error;
}

/**
 * Snap the duration to the closest step.
 * @param value The duration value to snap.
 * @param step The interval step to snap to.
 * @param options.round How to round the duration to the closest step.
 */
export function snapDuration(
  value: Duration,
  step: Duration,
  options?: SnapOptions,
): Duration {
  return Duration.fromMillis(
    snapToStep(value.as("milliseconds"), step.as("milliseconds"), options),
  );
}

/**
 * Snap the value to the closest step.
 * @param value The value to snap.
 * @param step The interval step to snap to.
 * @param options.round How to round the value to the closest step.
 *
 * @example
 * snapToStep(2.4, 5) === 0
 * snapToStep(1, 5, { round: "up" }) === 5
 * snapToStep(2.6, 5) === 5
 * snapToStep(-3, 5) === -5
 * snapToStep(-3, 5, { round: "up" }) === 0
 */
export function snapToStep(
  value: number,
  step: number,
  { round = "nearest" }: SnapOptions = {},
): number {
  switch (round) {
    case "up":
      return Math.ceil(value / step) * step;
    case "down":
      return Math.floor(value / step) * step;
    case "nearest":
      return Math.round(value / step) * step;
    default:
      throwUnhandledCaseError("snapToStep rounding option", round);
  }
}

interface SnapOptions {
  round?: "up" | "down" | "nearest";
}

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}
