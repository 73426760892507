import { Component, Input } from "@angular/core";

@Component({
  selector: "mr-content-change",
  templateUrl: "./content-change.component.html",
  styleUrls: ["./content-change.component.scss"],
})
export class ContentChangeComponent {
  @Input() public kind!: "previous" | "new";
}
