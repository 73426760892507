import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import {
  DockCapacities,
  DockCapacitiesBreakdown,
  DockCapacityCounts,
  DockHourlyCapacitiesBreakdown,
} from "src/app/core/dock-capacity-totals.service";
import { UnitType } from "src/app/core/sites";
import { Dock } from "src/app/partner/settings/docks/dock.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import {
  getEnumMember,
  isExistent,
  parseDateTime,
  throwUnhandledCaseError,
} from "src/utils";
import { AppointmentSlotDoorGroupCapacities } from "./appointment-slot-door-group-capacities.model";

export class AppointmentSlotDock {
  public constructor(args: ClassProperties<AppointmentSlotDock>) {
    this.dockCapacities = args.dockCapacities;
    this.dock = args.dock;
    this.doorGroupsCapacities = args.doorGroupsCapacities;
    this.dockHourlyCapacities = args.dockHourlyCapacities;
    this.messages = args.messages;
  }

  public readonly dockCapacities: {
    readonly proposedBreakdown: DockCapacitiesBreakdown;
    readonly scheduledBreakdown: DockCapacitiesBreakdown;
  };
  public readonly dockHourlyCapacities: DockHourlyCapacitiesBreakdown[] | null;
  public readonly dock: Dock;
  public readonly doorGroupsCapacities: readonly AppointmentSlotDoorGroupCapacities[];
  public readonly messages: readonly string[] | null;

  public static deserialize(
    data: Api.AppointmentSlotDock,
    { docks, doorGroups }: DeserializeArguments,
  ): AppointmentSlotDock {
    const dock = docks.find(({ id }) => id === data.dockID);
    if (!dock) {
      throw new Error(`Could not find dock with ID "${data.dockID}".`);
    }

    const { pastDockCutOffTimeMessages } = deserializeSlotDockMessages(data);

    const scheduledBreakdown: DockCapacitiesBreakdown =
      calculateCapacitiesBreakdown(data, dock);

    const change = {
      appointment: data.apptChange,
      case: data.caseChange,
      pallet: data.palletChange,
    };

    const proposedBreakdown: DockCapacitiesBreakdown = {
      held: addChanges(dock, scheduledBreakdown.held, change),
      reserved: addChanges(dock, scheduledBreakdown.reserved, change),
      scheduledTotal: addChanges(
        dock,
        scheduledBreakdown.scheduledTotal,
        change,
      ),
      total: addChanges(dock, scheduledBreakdown.total, change),
      unreserved: addChanges(dock, scheduledBreakdown.unreserved, change),
    };

    const dockHourlyCapacities =
      "hourlyCapacities" in data && data.hourlyCapacities
        ? data.hourlyCapacities.map((apiHourlyCapacity) =>
            calculateDockHourlyCapacitiesBreakdown(apiHourlyCapacity, dock),
          )
        : null;

    return new AppointmentSlotDock({
      dock,
      dockCapacities: {
        proposedBreakdown,
        scheduledBreakdown,
      },
      dockHourlyCapacities,
      doorGroupsCapacities: AppointmentSlotDoorGroupCapacities.deserializeList(
        data.doorGroups,
        { doorGroups },
      ),
      messages: [...(pastDockCutOffTimeMessages || [])],
    });
  }

  public static deserializeList(
    data: Api.AppointmentSlotDock[],
    args: DeserializeArguments,
  ): readonly AppointmentSlotDock[] {
    return data.map((slotDock) =>
      AppointmentSlotDock.deserialize(slotDock, args),
    );
  }
}

interface DeserializeArguments {
  readonly docks: readonly Dock[];
  readonly doorGroups: readonly DoorGroup[];
}

function getTotalCounts(
  unreserved: DockCapacityCounts,
  reserved: DockCapacityCounts,
): DockCapacityCounts {
  // Only the unreserved limit determines whether the total is unlimited (null).
  // (See: #23538.)
  const limit =
    unreserved.limit === null ? null : unreserved.limit + (reserved.limit ?? 0);
  const scheduled = unreserved.scheduled + reserved.scheduled;
  return getCounts(limit, scheduled);
}

function getCounts(
  limit: number | null,
  scheduled: number,
): DockCapacityCounts {
  return {
    hasCapacity: isExistent(limit) ? limit - scheduled > 1 : true,
    hasZeroCapacity: isExistent(limit) ? limit - scheduled <= 0 : false,
    willFillRemainingCapacity: isExistent(limit)
      ? limit - scheduled === 1
      : false,
    limit,
    over: limit === null ? null : scheduled - limit,
    scheduled,
  };
}

function addChanges(
  dock: Dock,
  scheduled: DockCapacities,
  change: { appointment: number; case: number; pallet: number },
): DockCapacities {
  return getCapacities(
    dock,
    getCounts(
      scheduled.appointment.limit,
      scheduled.appointment.scheduled + change.appointment,
    ),
    getCounts(scheduled.case.limit, scheduled.case.scheduled + change.case),
    getCounts(
      scheduled.pallet.limit,
      scheduled.pallet.scheduled + change.pallet,
    ),
  );
}

function getCapacities(
  dock: Dock,
  appointmentCounts: DockCapacityCounts,
  caseCounts: DockCapacityCounts,
  palletCounts: DockCapacityCounts,
): DockCapacities {
  let siteUnit: DockCapacityCounts;
  switch (dock.site.unitType) {
    case UnitType.Case:
      siteUnit = caseCounts;
      break;
    case UnitType.Pallet:
      siteUnit = palletCounts;
      break;
    default:
      throwUnhandledCaseError("site unit type", dock.site.unitType);
  }

  return {
    appointment: appointmentCounts,
    case: caseCounts,
    pallet: palletCounts,
    siteUnit,
  };
}

function calculateCapacitiesBreakdown(
  data: Api.CapacityBreakdown,
  dock: Dock,
): DockCapacitiesBreakdown {
  const unreserved = getCapacities(
    dock,
    getCounts(data.unreservedApptsLimit, data.unreservedApptsScheduled),
    getCounts(data.unreservedCasesLimit, data.unreservedCasesScheduled),
    getCounts(data.unreservedPalletsLimit, data.unreservedPalletsScheduled),
  );

  const reserved = getCapacities(
    dock,
    getCounts(data.reservedApptsLimit, data.reservedApptsScheduled),
    getCounts(data.reservedCasesLimit, data.reservedCasesScheduled),
    getCounts(data.reservedPalletsLimit, data.reservedPalletsScheduled),
  );

  const heldCounts = {
    appointment: data.reservedApptsUnscheduled,
    case: data.reservedCasesUnscheduled,
    pallet: data.reservedPalletsUnscheduled,
  };

  const held = getCapacities(
    dock,
    getCounts(null, heldCounts.appointment),
    getCounts(null, heldCounts.case),
    getCounts(null, heldCounts.pallet),
  );

  const scheduledTotal = getCapacities(
    dock,
    getTotalCounts(unreserved.appointment, reserved.appointment),
    getTotalCounts(unreserved.case, reserved.case),
    getTotalCounts(unreserved.pallet, reserved.pallet),
  );

  const total = addChanges(dock, scheduledTotal, heldCounts);

  return {
    held,
    reserved,
    scheduledTotal,
    total,
    unreserved,
  };
}

function calculateDockHourlyCapacitiesBreakdown(
  data: Api.AppointmentSlotDockHourlyCapacity,
  dock: Dock,
): DockHourlyCapacitiesBreakdown {
  const capacitiesBreakdown = calculateCapacitiesBreakdown(data, dock);

  return {
    ...capacitiesBreakdown,
    startTime: parseDateTime(data.startTimeUtc, dock.site),
    endTime: parseDateTime(data.endTimeUtc, dock.site),
    hasCapacity: data.hasCapacity,
  };
}

export enum SlotDockInformationMessageCode {
  PastDockCutOffTime = "PastDockCutOff",
}

export function deserializeSlotDockMessages(data: Api.AppointmentSlotDock): {
  messages: Array<{
    code: SlotDockInformationMessageCode;
    text: string;
  }>;
  pastDockCutOffTimeMessages: string[] | null;
} {
  const messages = data.messages.map((message) => ({
    ...message,
    // Ensures that all API message codes are handled
    code: getEnumMember(SlotDockInformationMessageCode, message.code),
  }));

  const pastDockCutOffTimeMessages = getTextsFromMessages(
    messages,
    SlotDockInformationMessageCode.PastDockCutOffTime,
  );

  return {
    messages,
    pastDockCutOffTimeMessages,
  };
}

function getTextsFromMessages(
  messages: Array<{
    code: SlotDockInformationMessageCode;
    text: string;
  }>,
  messageCode: string,
): string[] | null {
  const texts = messages
    .filter(({ code }) => code === messageCode)
    .map(({ text }) => text);
  return texts.length ? texts : null;
}
