import { Pipe, PipeTransform } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DateTime } from "luxon";
import { combineLatest, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { SitesService } from "src/app/core/sites";
import { DateTimeFormat, DateTimePipe } from "./date-time.pipe";

@UntilDestroy()
@Pipe({ name: "dateTimeInSiteZone", pure: false })
export class DateTimeInSiteZonePipe implements PipeTransform {
  public constructor(
    private readonly sites: SitesService,
    private readonly basePipe: DateTimePipe,
  ) {
    this.formattedDateChanges
      .pipe(untilDestroyed(this))
      .subscribe((formatted) => {
        this.formattedDate = formatted;
      });
  }

  private readonly argumentsSubject = new ReplaySubject<Arguments>(1);

  private readonly uniqueValueChanges = this.argumentsSubject.pipe(
    map((args) => args.value),
    distinctUntilChanged((current, next) => {
      if (current === next) {
        return true;
      }
      if (DateTime.isDateTime(current) && DateTime.isDateTime(next)) {
        return current.hasSame(next, "millisecond");
      }
      return false;
    }),
  );

  private readonly uniqueDateTimeFormatChanges = this.argumentsSubject.pipe(
    map((args) => args.format),
    distinctUntilChanged(),
  );

  private readonly formattedDateChanges = combineLatest([
    this.sites.selectedChanges,
    this.uniqueValueChanges,
    this.uniqueDateTimeFormatChanges,
  ]).pipe(
    map(([{ timeZone }, value, format]) =>
      this.basePipe.transform(value?.setZone(timeZone), format),
    ),
  );

  private formattedDate: string | null = null;

  public transform(
    value: DateTime | null | undefined,
    format?: DateTimeFormat,
  ): string | null {
    this.argumentsSubject.next({ value, format });
    return this.formattedDate;
  }
}

interface Arguments {
  value: DateTime | null | undefined;
  format?: DateTimeFormat;
}
