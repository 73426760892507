import { DateTime } from "luxon";
import { ChangesHistory } from "src/app/core/history.model";
import { ODataReference } from "src/app/core/odata-reference.model";
import { Site, SiteReference } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { EquipmentType } from "src/app/partner/global/equipment-type.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import {
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  isExistent,
  ODataModel,
  parseDateTime,
  parseDuration,
  PartnerResourceModel,
} from "src/utils";
import {
  BaseVendor,
  getVendorRouteUrl,
  VendorReference,
} from "./base-vendor.model";

export const vendorsResource = createPartnerResourceUrl("vendors");

export const expandedVendorsResource = vendorsResource.modify((resource) =>
  resource.addExpandFieldAsCount("reservations").withReferenceId(),
);

type ApiModelList = PartnerResourceModel<typeof expandedVendorsResource>;
export interface ApiVendor extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiVendor>();

interface DeserializeArgs {
  equipmentTypes: readonly EquipmentType[];
  doorGroups: readonly DoorGroup[];
  site: Site;
}

export class Vendor extends BaseVendor implements VendorReference {
  private constructor(args: Omit<ClassProperties<Vendor>, "displayName">) {
    super(args);

    this.createdDate = args.createdDate;
    this.history = args.history;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.name = args.name;
    this.number = args.number;
    this.ownerCode = args.ownerCode;
    this.reference = args.reference;
    this.reservationCount = args.reservationCount;
    this.site = args.site;

    this.displayName = getDisplayName(this.name, this.number);
  }

  @api() public readonly createdDate: DateTime;
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: VendorReference["id"];
  @api() public readonly modifiedDate: DateTime;
  @api() public readonly name: string;
  @api({ key: "vendorNumber" }) public readonly number: string;
  @api() public readonly ownerCode: string;
  @api({ key: "@odata.id" }) public readonly reference: ODataReference;
  @api({ key: "reservations@odata.count" })
  public readonly reservationCount: number;
  public readonly site: Site;

  public readonly displayName: string;

  public static deserialize(
    data: ApiVendor,
    { equipmentTypes, doorGroups, site }: DeserializeArgs,
  ): Vendor {
    const { equipmentGroupID, doorGroupID } = data;

    let equipmentGroup: EquipmentType | null = null;
    if (isExistent(equipmentGroupID)) {
      equipmentGroup =
        equipmentTypes.find(({ id }) => id === equipmentGroupID) ?? null;
      if (!equipmentGroup) {
        throw new Error(
          `Could not find equipment group with ID "${equipmentGroupID}".`,
        );
      }
    }

    let doorGroupOverride: DoorGroup | null = null;
    if (isExistent(doorGroupID)) {
      doorGroupOverride =
        doorGroups.find(({ id }) => id === doorGroupID) ?? null;
      if (!doorGroupOverride) {
        throw new Error(`Could not find door group with ID "${doorGroupID}".`);
      }
    }

    return new Vendor({
      ...deserializePurchaseOrderVendor(data, { site }),
      createdDate: parseDateTime(data.createdDate, site),
      doorGroupOverride,
      endTime: data.endTime ? TimeOfDay.deserialize(data.endTime) : null,
      equipmentGroup,
      history: ChangesHistory.deserializeList<HistoryFieldName>(data.history, {
        fieldDefinitions: { maxLoadCount: { label: "Maximum Load Count" } },
      }),
      isActive: data.active,
      maxAppointmentDoorCount: data.doorCount,
      maximumCustomUnloadDurationPerUnit: parseDuration(
        data.maxCalcCustomMinutesPerUnit,
        "minutes",
      ),
      modifiedDate: parseDateTime(data.modifiedDate, site),
      ownerCode: data.ownerCode,
      reference: new ODataReference(data["@odata.id"]),
      reservationCount: data["reservations@odata.count"],
      site,
      startTime: data.startTime ? TimeOfDay.deserialize(data.startTime) : null,
      weekdays: Weekdays.deserialize(data.daysOfWeek),
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    args: DeserializeArgs,
  ): readonly Vendor[] {
    return value.map((x) => Vendor.deserialize(x, args));
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getVendorRouteUrl(this, ...childPaths);
  }
}

type HistoryFieldName = StringKeys<ApiVendor>;

export function deserializePurchaseOrderVendor(
  vendor: Pick<
    ApiVendor,
    | "allowSameDayAppointment"
    | "appointmentNotificationEmails"
    | "customMinutesPerUnit"
    | "id"
    | "maxCalcMinutesPerUnit"
    | "maxLoadCount"
    | "minutesPerUnit"
    | "name"
    | "offerUnreservedSlots"
    | "vendorNumber"
  >,
  { site }: { readonly site: SiteReference },
): PurchaseOrderVendor {
  const id = vendor.id;
  return {
    ...deserializeVendorDisplayName(vendor),
    areUnreservedSlotsOffered: vendor.offerUnreservedSlots,
    contactEmails: vendor.appointmentNotificationEmails || null,
    customUnloadDurationPerUnit: parseDuration(
      vendor.customMinutesPerUnit,
      "minutes",
    ),
    getRouteUrl: (...childPaths) =>
      getVendorRouteUrl({ id, site }, ...childPaths),
    id,
    isSameDayAppointmentAllowed: vendor.allowSameDayAppointment,
    maximumLoadCount: vendor.maxLoadCount ?? null,
    maximumUnloadDurationPerUnit: parseDuration(
      vendor.maxCalcMinutesPerUnit,
      "minutes",
    ),
    name: vendor.name,
    number: vendor.vendorNumber,
    unloadDurationPerUnit: parseDuration(vendor.minutesPerUnit, "minutes"),
  };
}

export interface PurchaseOrderVendor
  extends Pick<
    Vendor,
    | "areUnreservedSlotsOffered"
    | "contactEmails"
    | "customUnloadDurationPerUnit"
    | "displayName"
    | "id"
    | "isSameDayAppointmentAllowed"
    | "maximumLoadCount"
    | "maximumUnloadDurationPerUnit"
    | "name"
    | "number"
    | "unloadDurationPerUnit"
  > {
  getRouteUrl(...childPaths: string[]): string;
}

export function deserializeVendorDisplayName(
  vendor: Pick<ApiVendor, "name" | "vendorNumber">,
): Pick<Vendor, "displayName"> {
  return { displayName: getDisplayName(vendor.name, vendor.vendorNumber) };
}

function getDisplayName(name: string, number: string): string {
  return `${name} (${number})`;
}
