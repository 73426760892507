import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { pickBy } from "lodash-es";
import { DateTime, Duration } from "luxon";
import { DropLoadAvailability, ManagedType } from "src/app/core/constants";
import { getEffectiveManagedType } from "src/app/partner/purchase-orders/purchase-order-list.model";
import { Door } from "src/app/partner/settings/doors/door.model";
import { Reservation } from "src/app/partner/settings/reservations/reservation.model";
import {
  clampDuration,
  mustHaveOne,
  parseDateTime,
  throwUnhandledCaseError,
} from "src/utils";
import { Dock } from "../../settings/docks/dock.model";
import { DoorGroup } from "../../settings/door-groups/door-group.model";
import { AppointmentUpdatePurchaseOrder } from "../appointment-update.model";
import { SimpleAppointmentPurchaseOrder } from "../base-appointment.model";
import { deserializeAppointmentSlotMessages } from "./appointment-slot-information.model";
import { AppointmentReservationSlotRequest } from "./appointment-slot-request.model";
import { AppointmentSlot } from "./appointment-slot.model";

export class AppointmentReservationSlot {
  private constructor(
    args: Omit<
      ClassProperties<AppointmentReservationSlot>,
      "ruleViolations" | "dock" | "doorGroup"
    > & { readonly request: RequestArguments },
  ) {
    this.doors = args.doors;

    // Per business rules, all doors in a given slot will have the same dock and
    // door group so we can arbitrarily pick the dock from the first door.
    const firstDoor = this.doors.at(0);
    if (!firstDoor) {
      throw new Error("No doors included in slot.");
    }
    this.dock = firstDoor.dock;
    this.doorGroup = firstDoor.doorGroup;
    this.reservation = args.reservation;
    this.reservationSlotCarriersDeliveryWindowMessages =
      args.reservationSlotCarriersDeliveryWindowMessages;
    this.reservationSlotDeliveryCarrierDeliveryWindowMessages =
      args.reservationSlotDeliveryCarrierDeliveryWindowMessages;
    this.reservationSlotVendorsDeliveryWindowMessages =
      args.reservationSlotVendorsDeliveryWindowMessages;
    this.startTime = args.startTime;

    this.hash = [
      this.constructor.name,
      this.startTime.toISODate(),
      this.reservation.id,
    ].join("|");

    this.request = {
      isDropLoad: args.request.isDropLoad,
      orders: args.request.orders,
    };

    this.ruleViolations = mustHaveOne({
      dropLoadAvailability: getIncompatibleDropLoadAvailability(
        this.request,
        this.reservation,
      ),
      ordersManagedType: getIncompatibleOrdersManagedType(
        this.request,
        this.reservation,
      ),
      reservationSlotCarriersDeliveryWindowMessages:
        args.reservationSlotCarriersDeliveryWindowMessages,
      reservationSlotDeliveryCarrierDeliveryWindowMessages:
        args.reservationSlotDeliveryCarrierDeliveryWindowMessages,
      reservationSlotVendorsDeliveryWindowMessages:
        args.reservationSlotVendorsDeliveryWindowMessages,
    });
  }

  public readonly reservationSlotCarriersDeliveryWindowMessages:
    | string[]
    | null;
  public readonly reservationSlotDeliveryCarrierDeliveryWindowMessages:
    | string[]
    | null;
  public readonly dock: Dock;
  public readonly doorGroup: DoorGroup;
  public readonly doors: readonly Door[];
  public readonly reservation: Reservation;
  public readonly ruleViolations: SlotRuleViolations | null;
  public readonly startTime: DateTime;
  public readonly reservationSlotVendorsDeliveryWindowMessages: string[] | null;

  private readonly hash: string;
  private readonly request: RequestArguments;

  public static create({
    doors,
    request,
    reservation,
    startTime,
  }: CreateArguments): AppointmentReservationSlot {
    return new AppointmentReservationSlot({
      doors: doors ?? reservation.doors,
      request,
      reservation,
      startTime,
      reservationSlotCarriersDeliveryWindowMessages: null,
      reservationSlotDeliveryCarrierDeliveryWindowMessages: null,
      reservationSlotVendorsDeliveryWindowMessages: null,
    });
  }

  public static deserialize(
    data: Api.AppointmentReservationSlot,
    { request, reservations }: DeserializeArguments,
  ): AppointmentReservationSlot {
    const reservation = reservations.find(
      ({ id }) => id === data.reservationID,
    );
    if (!reservation) {
      throw new Error(
        `Could not find reservation with ID "${data.reservationID}".`,
      );
    }

    const { groupedMessages } = deserializeAppointmentSlotMessages(data);

    return new AppointmentReservationSlot({
      reservationSlotCarriersDeliveryWindowMessages:
        groupedMessages.carriersDeliveryWindowMessages,
      reservationSlotDeliveryCarrierDeliveryWindowMessages:
        groupedMessages.deliveryCarrierDeliveryWindowMessages,
      doors: reservation.doors,
      request,
      reservation,
      startTime: parseDateTime(data.startTime, reservation.site),
      reservationSlotVendorsDeliveryWindowMessages:
        groupedMessages.vendorsDeliveryWindowMessages,
    });
  }

  public static deserializeList(
    data: readonly Api.AppointmentReservationSlot[],
    args: DeserializeArguments,
  ): readonly AppointmentReservationSlot[] {
    return data.map((x) => AppointmentReservationSlot.deserialize(x, args));
  }

  public isEqual(other: AppointmentSlot | AppointmentReservationSlot): boolean {
    return (
      other instanceof AppointmentReservationSlot && this.hash === other.hash
    );
  }

  public move(
    args: Partial<Pick<AppointmentReservationSlot, "doors" | "startTime">>,
  ): AppointmentReservationSlot {
    return AppointmentReservationSlot.create({
      ...this,
      request: this.request,
      // Ignore `undefined` so it doesn't overwrite the base value.
      ...pickBy(args, (value) => value !== undefined),
    });
  }

  public fitDurationToSlot(duration: Duration): Duration {
    return clampDuration(duration, { max: this.reservation.duration });
  }
}

function getIncompatibleOrdersManagedType(
  { orders }: RequestArguments,
  reservation: Reservation,
): ManagedType | null {
  if (orders === null || orders.length === 0) {
    return null;
  }

  const ordersManagedType = getEffectiveManagedType(orders);
  const isManagedTypeCompatible = reservation.isCompatibleWith({
    managedType: ordersManagedType,
  });

  return !isManagedTypeCompatible ? ordersManagedType : null;
}

function getIncompatibleDropLoadAvailability(
  { isDropLoad }: RequestArguments,
  reservation: Reservation,
): DropLoadAvailability.Mandatory | DropLoadAvailability.NotAllowed | null {
  if (isDropLoad === null || reservation.isCompatibleWith({ isDropLoad })) {
    return null;
  }

  switch (reservation.dropLoadAvailability) {
    case DropLoadAvailability.Mandatory:
    case DropLoadAvailability.NotAllowed:
      return reservation.dropLoadAvailability;
    case DropLoadAvailability.Optional:
      throw new Error(
        "Unexpected optional drop load availability. This reservation should always be compatible.",
      );
    default:
      throwUnhandledCaseError(
        "drop load availability",
        reservation.dropLoadAvailability,
      );
  }
}

interface RequestArguments {
  readonly isDropLoad: boolean | null;
  readonly orders:
    | readonly AppointmentUpdatePurchaseOrder[]
    | readonly SimpleAppointmentPurchaseOrder[]
    | null;
}

interface CreateArguments
  extends Pick<AppointmentReservationSlot, "reservation" | "startTime">,
    Partial<Pick<AppointmentReservationSlot, "doors">> {
  readonly request: RequestArguments;
}

interface DeserializeArguments {
  readonly request: AppointmentReservationSlotRequest;
  readonly reservations: readonly Reservation[];
}

export interface SlotRuleViolations {
  readonly dropLoadAvailability:
    | DropLoadAvailability.Mandatory
    | DropLoadAvailability.NotAllowed
    | null;
  readonly ordersManagedType: ManagedType | null;
  readonly reservationSlotCarriersDeliveryWindowMessages: string[] | null;
  readonly reservationSlotDeliveryCarrierDeliveryWindowMessages:
    | string[]
    | null;
  readonly reservationSlotVendorsDeliveryWindowMessages: string[] | null;
}
