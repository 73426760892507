import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { map, retry, switchMap, take, withLatestFrom } from "rxjs/operators";
import { RequestService } from "src/app/core/request.service";
import { SitesService } from "src/app/core/sites";
import { environment } from "src/environments";
import {
  Attachment,
  attachmentsResource,
  NewAttachment,
} from "./attachment.model";
import {
  HelpAssistTicket,
  helpTicketsResourcePartner,
} from "./models/ticket/help-assist-ticket.model";

const headers = new HttpHeaders({
  "Ocp-Apim-Subscription-Key": environment.fileExchangeAPIManagementKey,
});

@Injectable({ providedIn: "root" })
export class AttachmentsService {
  public constructor(
    private readonly request: RequestService,
    private readonly sites: SitesService,
    private readonly httpClient: HttpClient,
  ) {}

  private readonly attachmentsResourceChanges =
    this.sites.getResourceInSelectedPartner(attachmentsResource);

  private readonly ticketsResourceChanges =
    this.sites.getResourceInSelectedPartner(helpTicketsResourcePartner);

  private async post(attachment: NewAttachment): Promise<Attachment["id"]> {
    const resource = await firstValueFrom(this.attachmentsResourceChanges);
    const { id } = await firstValueFrom(
      this.request.post(resource, attachment),
    );
    return id;
  }

  private async sendFileAndGetUrl(
    ticket: HelpAssistTicket,
    file: File,
  ): Promise<string> {
    return firstValueFrom(
      this.getToken(ticket.id).pipe(
        switchMap((token) => {
          const formData = new FormData();
          formData.append("file", file, file.name);

          return this.httpClient.post(
            getAttachmentRequestUrl(token),
            formData,
            { headers, responseType: "text" },
          );
        }),
        retry(3),
      ),
    );
  }

  public async update(
    ticket: HelpAssistTicket,
    file: File,
  ): Promise<Attachment["id"]> {
    const blobUrl = await this.sendFileAndGetUrl(ticket, file);
    return this.post(
      new NewAttachment({
        blobUrl,
        fileName: file.name,
        ticket,
      }),
    );
  }

  public async getFile(attachment: Attachment): Promise<Blob> {
    return firstValueFrom(
      this.getToken(attachment.ticket.id).pipe(
        switchMap((token) =>
          this.httpClient.get(getAttachmentRequestUrl(token, attachment), {
            headers,
            responseType: "blob",
          }),
        ),
        retry(3),
      ),
    );
  }

  public async delete(attachment: Attachment): Promise<void> {
    return firstValueFrom(
      this.getToken(attachment.ticket.id).pipe(
        switchMap((token) =>
          this.httpClient.delete(getAttachmentRequestUrl(token, attachment), {
            headers,
          }),
        ),
        withLatestFrom(this.attachmentsResourceChanges),
        switchMap(([, resource]) =>
          this.request.delete(resource.appendId(attachment.id)),
        ),
        retry(3),
      ),
    );
  }

  private getToken(ticketId: HelpAssistTicket["id"]): Observable<string> {
    return combineLatest([
      this.ticketsResourceChanges,
      this.sites.selectedChanges,
    ]).pipe(
      take(1),
      switchMap(([resource, site]) =>
        this.request.post(
          resource.appendId(ticketId).appendFunctionPropertyPath("GetJWT"),
          { serialize: () => ({ siteId: site.id }) },
        ),
      ),
      map(({ value }) => value),
    );
  }
}

function getAttachmentRequestUrl(
  token: string,
  attachment?: Attachment,
): string {
  // Clone the URL so we can update it.
  const url = new URL(environment.apiUrls.attachments.href);
  url.searchParams.append("jwt", token);
  if (attachment) {
    url.searchParams.append("fileUrl", attachment.blobUrl);
  }
  return url.href;
}
