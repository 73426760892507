import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Duration } from "luxon";
import { DropLoadAvailability, ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { SimpleCarrier } from "src/app/partner/global/carriers";
import { Vendor } from "src/app/partner/vendors/vendor.model";
import {
  getApiDetailsDecorator,
  getEnumMember,
  isExistent,
  parseDuration,
} from "src/utils";

const api = getApiDetailsDecorator<Api.Reservation>();

export abstract class BaseReservation {
  protected constructor(args: ClassProperties<BaseReservation>) {
    this.dropLoadAvailability = args.dropLoadAvailability;
    this.duration = args.duration;
    this.endDay = args.endDay;
    this.exceptionDays = args.exceptionDays;
    this.isActive = args.isActive;
    this.isAutoExtended = args.isAutoExtended;
    this.managedType = args.managedType;
    this.maximumCaseCount = args.maximumCaseCount;
    this.maximumPalletCount = args.maximumPalletCount;
    this.minimumCaseCount = args.minimumCaseCount;
    this.minimumPalletCount = args.minimumPalletCount;
    this.name = args.name;
    this.site = args.site;
    this.startDay = args.startDay;
    this.startTime = args.startTime;
    this.timeoutOccurrence = args.timeoutOccurrence;
    this.weekdays = args.weekdays;
  }

  @api({ key: "dropLoad" })
  public readonly dropLoadAvailability: DropLoadAvailability;
  @api({ key: "length" }) public readonly duration: Duration;
  @api({ key: "effectiveEndDate" }) public readonly endDay: Day;
  @api({ key: "exceptions" }) public readonly exceptionDays: Day[];
  @api({ key: "active" }) public readonly isActive: boolean;
  @api({ key: "autoExtend" }) public readonly isAutoExtended: boolean;
  @api() public readonly managedType: ManagedType | null;
  @api({ key: "maxCases" }) public readonly maximumCaseCount: number | null;
  @api({ key: "maxPallets" }) public readonly maximumPalletCount: number | null;
  @api({ key: "minCases" }) public readonly minimumCaseCount: number | null;
  @api({ key: "minPallets" }) public readonly minimumPalletCount: number | null;
  @api() public readonly name: string | null;
  @api({ key: "siteID", navigationProperty: "site", uiModel: Site })
  public readonly site: Site;
  @api({ key: "effectiveStartDate" }) public readonly startDay: Day;
  @api() public readonly startTime: TimeOfDay;
  @api({ key: "timeout" }) public readonly timeoutOccurrence: Duration | null;
  @api({ key: "dayOfWeek" }) public readonly weekdays: Weekdays;

  protected static deserializeBase(
    data: Api.ReservationBase,
    { site }: { site: Site },
  ): ClassProperties<BaseReservation> {
    if (!data.effectiveStartDate) {
      throw new Error("Missing effective start date.");
    }

    if (!data.effectiveEndDate) {
      throw new Error("Missing effective end date.");
    }

    return {
      dropLoadAvailability: getEnumMember(DropLoadAvailability, data.dropLoad),
      duration: parseDuration(data.length, "minutes"),
      endDay: Day.deserialize(data.effectiveEndDate, site.timeZone),
      exceptionDays: data.exceptions.map((exception) =>
        Day.deserialize(exception, site.timeZone),
      ),
      isActive: data.active,
      isAutoExtended: data.autoExtend,
      managedType: data.managedType
        ? getEnumMember(ManagedType, data.managedType)
        : null,
      maximumCaseCount: data.maxCases ?? null,
      maximumPalletCount: data.maxPallets ?? null,
      minimumCaseCount: data.minCases ?? null,
      minimumPalletCount: data.minPallets ?? null,
      name: data.name ?? null,
      site,
      startDay: Day.deserialize(data.effectiveStartDate, site.timeZone),
      startTime: TimeOfDay.deserialize(data.startTime),
      timeoutOccurrence: isExistent(data.timeout)
        ? parseDuration(data.timeout, "hours")
        : null,
      weekdays: Weekdays.deserialize(data.dayOfWeek),
    };
  }
}

export interface ReservationVendor
  extends Pick<Vendor, "displayName" | "id" | "name" | "number">,
    Partial<Pick<Vendor, "maxAppointmentDoorCount" | "reference">> {
  getRouteUrl(...childPaths: string[]): string;
}

export interface ReservationCarrier extends SimpleCarrier {}
