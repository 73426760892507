import { Injectable } from "@angular/core";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { range } from "lodash-es";
import { DateTime, Info } from "luxon";
import { areLuxonModelsEqual } from "src/utils";

export const CUSTOM_DATE_TIME_FORMATS = {
  fullPickerInput: {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  datePickerInput: { month: "long", day: "numeric", year: "numeric" },
  timePickerInput: { hour: "numeric", minute: "numeric" },
  monthYearLabel: { month: "long", year: "numeric" },
};

@Injectable()
export class LuxonDateTimeAdapter extends DateTimeAdapter<DateTime> {
  public constructor() {
    super();
    // Hard code the locale for now since we don't otherwise support others.
    super.setLocale("en-US");
  }

  public getYear(date: DateTime): number {
    return date.year;
  }

  public getMonth(date: DateTime): number {
    // The adapter expects a zero-based month (like native Date).
    return date.month - 1;
  }

  public getDay(date: DateTime): number {
    // DateTime has Sunday as 7 but the adapter expects 0 (like native Date).
    return date.weekday === 7 ? 0 : date.weekday;
  }

  public getDate(date: DateTime): number {
    return date.day;
  }

  public getHours(date: DateTime): number {
    return date.hour;
  }

  public getMinutes(date: DateTime): number {
    return date.minute;
  }

  public getSeconds(date: DateTime): number {
    return date.second;
  }

  public getTime(date: DateTime): number {
    return date.toMillis();
  }

  public getNumDaysInMonth(date: DateTime): number {
    return date.daysInMonth;
  }

  public differenceInCalendarDays(
    dateLeft: DateTime,
    dateRight: DateTime,
  ): number {
    return Math.floor(dateLeft.diff(dateRight, "days").days);
  }

  public getYearName(date: DateTime): string {
    return date.toFormat("y");
  }

  public getMonthNames(style: "long" | "short" | "narrow"): string[] {
    return Info.months(style);
  }

  public getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    const weekdays = [...Info.weekdays(style)];
    // There should always be the 7 elements for the weekdays here, Sunday being
    // the last element. However, it's required to be first by the adapter.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sunday = weekdays.pop()!;
    return [sunday, ...weekdays];
  }

  public getDateNames(): string[] {
    return range(1, 32).map(String);
  }

  public toIso8601(date: DateTime): string {
    return date.toISO();
  }

  public isEqual(
    dateLeft: DateTime | null,
    dateRight: DateTime | null,
  ): boolean {
    return areLuxonModelsEqual(dateLeft, dateRight);
  }

  public isSameDay(
    dateLeft: DateTime | null,
    dateRight: DateTime | null,
  ): boolean {
    return dateLeft && dateRight
      ? dateLeft.hasSame(dateRight, "day")
      : dateLeft === dateRight;
  }

  public isValid(date: DateTime): boolean {
    return date.isValid;
  }

  public invalid(): DateTime {
    return DateTime.invalid("Invalid date in luxon-date-time-adapter");
  }

  public isDateInstance(obj: unknown): boolean {
    return DateTime.isDateTime(obj);
  }

  public addCalendarYears(date: DateTime, years: number): DateTime {
    return date.plus({ years });
  }

  public addCalendarMonths(date: DateTime, months: number): DateTime {
    return date.plus({ months });
  }

  public addCalendarDays(date: DateTime, days: number): DateTime {
    return date.plus({ days });
  }

  public setHours(date: DateTime, hour: number): DateTime {
    return date.set({ hour });
  }

  public setMinutes(date: DateTime, minute: number): DateTime {
    return date.set({ minute });
  }

  public setSeconds(date: DateTime, second: number): DateTime {
    return date.set({ second });
  }

  public createDate(
    year: number,
    month: number,
    date: number,
    hours = 0,
    minutes = 0,
    seconds = 0,
  ): DateTime {
    const dateTime = DateTime.local(
      year,
      // The adapter uses zero-based months (like native Date).
      month + 1,
      date,
      hours,
      minutes,
      seconds,
    );
    if (dateTime.invalidExplanation !== null) {
      throw new Error(`Invalid date: ${dateTime.invalidExplanation}`);
    }
    return dateTime;
  }

  public clone(date: DateTime): DateTime {
    return date;
  }

  public now(): DateTime {
    return DateTime.local();
  }

  public format(
    date: DateTime,
    displayFormat: Intl.DateTimeFormatOptions,
  ): string {
    return date.toLocaleString(displayFormat);
  }

  public parse(value: unknown, parseFormat: string): DateTime | null {
    if (!value || typeof value !== "string" || !parseFormat) {
      return null;
    }
    return DateTime.fromFormat(value, parseFormat);
  }
}
