import { Component, Input } from "@angular/core";
import { BooleanAttribute, parseBooleanAttribute } from "src/utils";

@Component({
  selector: "mr-icon-button[label]",
  styleUrls: ["./icon-button.component.scss"],
  template: `
    <button
      type="button"
      [attr.aria-label]="label"
      [title]="label"
      [disabled]="isDisabled"
    >
      <ng-content></ng-content>
    </button>
  `,
})
export class IconButtonComponent {
  @Input() public label!: string;
  @Input() public set disabled(value: BooleanAttribute) {
    this.isDisabled = parseBooleanAttribute(value);
  }
  public isDisabled = false;
}
