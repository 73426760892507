<mr-field-container
  #container
  [customFieldId]="fieldId"
  [label]="label"
  [description]="description"
  [labelHidden]="isLabelHidden"
  [errorsHidden]="areErrorsHidden"
  [additionalErrorMessageTemplate]="errorMessages"
>
  <ngb-timepicker
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [meridian]="true"
    [minuteStep]="minuteStepInMinutes"
    [hourStep]="1"
    [spinners]="!hideSpinners"
  ></ngb-timepicker>
</mr-field-container>

<ng-template #errorMessages let-errors let-errorLabel="errorLabel">
  <li *ngIf="errors.emptyHour">
    {{ errorLabel }} must specify hour and minute together
  </li>
  <li *ngIf="errors.earliestTimeOfDay as error">
    <ng-container *ngIf="error.exact; else nonExactError">
      {{ errorLabel }} must be {{ error.earliest | timeOfDay }}
    </ng-container>
    <ng-template #nonExactError>
      {{ errorLabel }} must be equal to or later than
      {{ error.earliest | timeOfDay }}
    </ng-template>
  </li>
</ng-template>
