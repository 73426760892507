import { Directive, HostBinding, Input } from "@angular/core";

@Directive({
  selector: "[mrHighlighted]",
})
export class HighlightedDirective {
  @Input() public highlightStyle: "background" | "border" = "background";
  @Input("mrHighlighted") public isHighlighted = false;
  @Input() public color = "var(--mr-color-ha-updated-content-background)";

  @HostBinding("style") public get style(): string | undefined {
    if (this.isHighlighted) {
      return this.highlightStyle === "border"
        ? "border: 2px solid var(--mr-color-yellow)"
        : `background-color: ${this.color}`;
    }
    return;
  }
}
