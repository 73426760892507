/**
 * Group a collection by a specific key, and optionally reduce the keys to
 * a single value with a key comparer function.
 * @param values The item values to group.
 * @param keySelector A function for pulling out the key from each value.
 * @param keyComparer A function for comparing the keys. The first key value
 * encountered in the input array will be used as the group key.
 */
export function groupBy<K, V>(
  values: readonly V[],
  keySelector: (value: V) => K,
  keyComparer?: (leftKey: K, rightKey: K) => boolean,
): Map<K, [V, ...V[]]> {
  const groups = new Map<K, [V, ...V[]]>();

  for (const value of values) {
    let key = keySelector(value);
    if (keyComparer) {
      const keys = Array.from(groups.keys());
      key = keys.find((existingKey) => keyComparer(existingKey, key)) || key;
    }

    let valuesGroup = groups.get(key);
    if (!valuesGroup) {
      valuesGroup = [value];
      groups.set(key, valuesGroup);
    } else {
      valuesGroup.push(value);
    }
  }

  return groups;
}
