import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime, Duration } from "luxon";
import { GlobalSite } from "src/app/carrier/global-site.model";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { ChangesHistory } from "src/app/core/history.model";
import { Site } from "src/app/core/sites";
import {
  AppointmentActivity,
  deserializeAppointmentActivity,
} from "src/app/partner/appointments/activities/appointment-activity.model";
import { AppointmentStatus } from "src/app/partner/appointments/appointment-status.model";
import {
  AppointmentReference,
  AppointmentVendor,
  deserializeAppointmentStatus,
  deserializeAppointmentVendor,
  getAppointmentRouteUrl,
  UnloaderType,
} from "src/app/partner/appointments/base-appointment.model";
import {
  Carrier,
  deserializeSimpleCarrier,
  SimpleCarrier,
} from "src/app/partner/global/carriers";
import { BaseHelpAssistTicketAppointmentOrder } from "src/app/partner/help-assist/models/ticket-order/base-help-assist-ticket-appointment-order.model";
import { PointOfOrigin } from "src/app/partner/purchase-orders/base-purchase-order.model";
import { Door } from "src/app/partner/settings/doors/door.model";
import {
  deserializePurchaseOrderVendor,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import {
  ExpandedODataModel,
  getApiDetailsDecorator,
  getEnumMember,
  parseDateTime,
  parseDuration,
} from "src/utils";
import { AppointmentApproval } from "./ticket-appointment-approval.model";

const api = getApiDetailsDecorator<ApiTicketAppointment>();

export class HelpAssistTicketAppointment implements AppointmentReference {
  public constructor(args: ClassProperties<HelpAssistTicketAppointment>) {
    this.appointmentApproval = args.appointmentApproval;
    this.carrier = args.carrier;
    this.confirmationNumber = args.confirmationNumber;
    this.deliveryCarrier = args.deliveryCarrier;
    this.door = args.door;
    this.dueDate = args.dueDate;
    this.history = args.history;
    this.id = args.id;
    this.isDropLoad = args.isDropLoad;
    this.isIntermodal = args.isIntermodal;
    this.lastActivity = args.lastActivity;
    this.loadNumber = args.loadNumber;
    this.loadType = args.loadType;
    this.loadWeight = args.loadWeight;
    this.notificationList = args.notificationList;
    this.orders = args.orders;
    this.scheduledArrivalDay = args.scheduledArrivalDay;
    this.slotStartTime = args.slotStartTime;
    this.scheduledDuration = args.scheduledDuration;
    this.site = args.site;
    this.status = args.status;
    this.totalCaseCount = args.totalCaseCount;
    this.totalWarehousePalletCount = args.totalWarehousePalletCount;
    this.totalWarehousePalletCountOverride =
      args.totalWarehousePalletCountOverride;
    this.unknownDeliveryCarrierName = args.unknownDeliveryCarrierName;
    this.unloader = args.unloader;
    this.vendor = args.vendor;
  }

  @api({ key: "appointmentApproval", uiModel: AppointmentApproval })
  public readonly appointmentApproval: AppointmentApproval | null;
  @api({ key: "carrier", uiModel: Carrier })
  public readonly carrier: SimpleCarrier;
  @api() public readonly confirmationNumber: string;
  @api({ key: "deliveryCarrierRecord" })
  public readonly deliveryCarrier: SimpleCarrier | null;
  @api({ key: "doors", uiModel: Door })
  public readonly door: AppointmentDoor;
  @api() public readonly dueDate: DateTime | null;
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: AppointmentReference["id"];
  @api({ key: "dropLoad" }) public readonly isDropLoad: boolean;
  @api({ key: "intermodal" }) public readonly isIntermodal: boolean;
  public readonly lastActivity: AppointmentActivity | null;
  @api({ key: "clientAppointmentNumber" })
  public readonly loadNumber: string | null;
  @api() public readonly loadType: string | null;
  @api({ key: "totalLoadWeight" }) public readonly loadWeight: number | null;
  @api() public readonly notificationList: string | null;
  @api() public readonly orders: BaseHelpAssistTicketAppointmentOrder[];
  public readonly scheduledArrivalDay: Day;
  @api({ key: "startTime" }) public readonly slotStartTime: DateTime;
  @api() public readonly scheduledDuration: Duration;
  public readonly site: Site | GlobalSite;
  @api({
    key: "appointmentStatusID",
  })
  public readonly status: AppointmentStatus;
  @api() public readonly totalCaseCount: number;
  @api({ key: "totalPalletCount" })
  public readonly totalWarehousePalletCount: number;
  @api({ key: "appointmentPalletOverride" })
  public readonly totalWarehousePalletCountOverride: number | null;
  @api({ key: "deliveryCarrier" })
  public readonly unknownDeliveryCarrierName: string | null;
  @api() public readonly unloader: UnloaderType;
  @api({ key: "vendor", uiModel: Vendor })
  public readonly vendor: AppointmentVendor | null;

  public static deserialize(
    data: ApiTicketAppointment,
    { site, statuses }: DeserializeArguments,
  ): HelpAssistTicketAppointment {
    const scheduledArrivalTime = parseDateTime(data.startTime, site);

    return new HelpAssistTicketAppointment({
      appointmentApproval: data.appointmentApproval
        ? AppointmentApproval.deserialize(data.appointmentApproval)
        : null,
      carrier: deserializeSimpleCarrier(data.carrier),
      confirmationNumber: data.confirmationNumber,
      deliveryCarrier: data.deliveryCarrierRecord
        ? deserializeSimpleCarrier(data.deliveryCarrierRecord)
        : null,
      door: data.doors[0],
      dueDate: parseDateTime(data.dueDate, site),
      history: ChangesHistory.deserializeList(data.history),
      id: data.id,
      isDropLoad: data.dropLoad,
      isIntermodal: data.intermodal,
      lastActivity: data.lastActivity
        ? deserializeAppointmentActivity(data.lastActivity, { site })
        : null,
      loadNumber: data.clientAppointmentNumber || null,
      loadType: data.loadType || null,
      notificationList: data.notificationList || null,
      orders: data.orders.map((apiOrder) =>
        deserializeBaseHelpAssistAppointmentOrder(apiOrder, {
          site,
        }),
      ),
      scheduledArrivalDay: new Day(scheduledArrivalTime),
      slotStartTime: scheduledArrivalTime,
      scheduledDuration: parseDuration(data.scheduledDuration, "minutes"),
      site,
      status: deserializeAppointmentStatus(data, { statuses }),
      totalCaseCount: data.totalCaseCount,
      loadWeight: data.totalLoadWeight ?? null,
      totalWarehousePalletCount: data.totalPalletCount,
      totalWarehousePalletCountOverride: data.appointmentPalletOverride ?? null,
      unknownDeliveryCarrierName: data.deliveryCarrier ?? null,
      unloader: getEnumMember(UnloaderType, data.unloader),
      vendor: data.vendor
        ? deserializeAppointmentVendor(data.vendor, { site })
        : null,
    });
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getAppointmentRouteUrl(this, ...childPaths);
  }
}

type VendorSelectedApiProperties =
  | "allowSameDayAppointment"
  | "appointmentNotificationEmails"
  | "customMinutesPerUnit"
  | "id"
  | "maxCalcMinutesPerUnit"
  | "maxLoadCount"
  | "minutesPerUnit"
  | "name"
  | "offerUnreservedSlots"
  | "vendorNumber";

export type ApiTicketAppointment = Pick<
  ExpandedODataModel<
    Api.Appointment,
    {
      vendor: "id" | "name" | "vendorNumber";
      carrier: "id" | "name";
      deliveryCarrierRecord: "id" | "name";
      doors: "id" | "name";
      appointmentApproval: keyof Api.AppointmentApproval;
      lastActivity: keyof Api.LastAppointmentActivity;
    }
  >,
  | "appointmentApproval"
  | "appointmentPalletOverride"
  | "appointmentStatusID"
  | "carrier"
  | "clientAppointmentNumber"
  | "confirmationNumber"
  | "deliveryCarrierRecord"
  | "dropLoad"
  | "doors"
  | "dueDate"
  | "history"
  | "id"
  | "intermodal"
  | "lastActivity"
  | "loadType"
  | "notificationList"
  | "scheduledDuration"
  | "startTime"
  | "totalCaseCount"
  | "totalLoadWeight"
  | "totalPalletCount"
  | "deliveryCarrier"
  | "unloader"
  | "vendor"
> & {
  orders: readonly ApiTicketAppointmentPurchaseOrder[];
};

interface DeserializeArguments {
  readonly site: Site;
  readonly statuses: readonly AppointmentStatus[];
}

type ApiTicketAppointmentPurchaseOrder = Pick<
  ExpandedODataModel<
    Api.PurchaseOrder,
    {
      vendor: VendorSelectedApiProperties;
      doorGroup: "id" | "name";
    }
  >,
  | "asnbolNumber"
  | "asnproNumber"
  | "backhaulPickupConfNumber"
  | "bolNumber"
  | "caseCount"
  | "comments"
  | "consigneeCode"
  | "doorGroup"
  | "dueDate"
  | "entryDate"
  | "estReceivedPallets"
  | "id"
  | "loadWeight"
  | "managedType"
  | "originCity"
  | "originLatitude"
  | "originLongitude"
  | "originPostalCode"
  | "originState"
  | "palletCount"
  | "pickupDate"
  | "poNumber"
  | "proNumber"
  | "vendor"
>;

interface BaseHelpAssistAppointmentOrderDeserializeArguments {
  readonly site: Site;
}

export function deserializeBaseHelpAssistAppointmentOrder(
  data: ApiTicketAppointmentPurchaseOrder,
  { site }: BaseHelpAssistAppointmentOrderDeserializeArguments,
): BaseHelpAssistTicketAppointmentOrder {
  return {
    asnBillOfLadingNumber: data.asnbolNumber ?? null,
    asnProNumber: data.asnproNumber ?? null,
    backhaulPickupConfirmationNumber: data.backhaulPickupConfNumber ?? null,
    billOfLadingNumber: data.bolNumber ?? null,
    caseCount: data.caseCount,
    comments: data.comments ?? null,
    consigneeCode: data.consigneeCode ?? null,
    doorGroup: data.doorGroup ?? null,
    dueDate: data.dueDate ? Day.deserialize(data.dueDate, site.timeZone) : null,
    entryDate: data.entryDate
      ? Day.deserialize(data.entryDate, site.timeZone)
      : null,
    inboundPalletCount: data.estReceivedPallets,
    orderId: data.id,
    loadWeight: data.loadWeight ?? null,
    managedType: getEnumMember(ManagedType, data.managedType, null),
    number: data.poNumber,
    pickupDate: data.pickupDate
      ? Day.deserialize(data.pickupDate, site.timeZone)
      : null,
    pointOfOrigin: PointOfOrigin.deserialize(data) ?? null,
    proNumber: data.proNumber ?? null,
    vendor: deserializePurchaseOrderVendor(data.vendor, { site }),
    warehousePalletCount: data.palletCount,
  };
}

type AppointmentDoor = Pick<Door, "id" | "name">;
