import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  AccountInfo,
  AuthError,
  BrowserAuthError,
  BrowserAuthErrorMessage,
  BrowserUtils,
  EventError,
  EventType,
  InteractionStatus,
  Logger,
  LogLevel,
} from "@azure/msal-browser";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
} from "rxjs/operators";
import { isExistent, isNavigationStateChange } from "src/utils";
import { ApplicationInsightsService } from "./core/application-insights.service";
import { passwordResetAuthority, UserService } from "./core/auth";
import { usersResource } from "./core/b2c-user.model";
import { RequestService } from "./core/request.service";
import { SitesService } from "./core/sites";
import { UserPreferencesService } from "./core/user-preferences.service";

@UntilDestroy()
@Component({
  selector: "mr-root",
  template: `
    <mr-loading *ngIf="isNavigatingChanges | async"></mr-loading>
    <router-outlet *ngIf="!isInIframe"></router-outlet>
    <mr-alerts></mr-alerts>
  `,
  styles: [
    `
      mr-loading {
        height: 100%;
        align-self: center;
      }
    `,
  ],
})
export class RootComponent implements OnInit {
  public constructor(
    private readonly applicationInsights: ApplicationInsightsService,
    private readonly msal: MsalService,
    private readonly msalBroadcast: MsalBroadcastService,
    private readonly request: RequestService,
    private readonly router: Router,
    private readonly sites: SitesService,
    private readonly user: UserService,
    private readonly userPreferences: UserPreferencesService,
  ) {}

  public readonly isInIframe = BrowserUtils.isInIframe();

  public readonly isNavigatingChanges = this.router.events.pipe(
    isNavigationStateChange(),
    // Mark as initially navigating in case we missed the "start" event.
    startWith(true),
    // Skip any duplicates in case we did see the "start" event.
    distinctUntilChanged(),
    // Get the start and then the stop. We don't want the whole page to show
    // loading on every navigation, only on the first page load.
    take(2),
    shareReplay(1),
  );

  public ngOnInit(): void {
    // Listen to account changes from other tabs.
    this.msal.instance.enableAccountStorageEvents();

    this.msalBroadcast.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        map(
          () =>
            this.msal.instance.getActiveAccount() ??
            this.msal.instance.getAllAccounts().at(0),
        ),
        // After login, this event will fire but there won't be any accounts
        // stored yet. Instead, we'll handle loading the user details in the
        // `msalSubject$` handler below.
        filter(isExistent),
        switchMap(async (account) => this.loadUserAccount(account)),
        untilDestroyed(this),
      )
      .subscribe();

    this.msalBroadcast.msalSubject$
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        switch (event.eventType) {
          case EventType.ACCOUNT_REMOVED: {
            if (this.msal.instance.getAllAccounts().length === 0) {
              // Logout of all tabs if any tab is logged out in.
              this.user.logout();
            }
            break;
          }
          case EventType.LOGIN_SUCCESS: {
            if (!event.payload.account) {
              throw new Error("Missing account info in login payload.");
            }
            this.loadUserAccount(event.payload.account)
              .then(async () => {
                // Notify the server on login for analytic purposes.
                await firstValueFrom(
                  this.sites.getResourceInSelectedPartner(usersResource).pipe(
                    switchMap((resource) =>
                      this.request.post(resource, {
                        serialize: () => null,
                      }),
                    ),
                  ),
                );
              })
              .catch((error) => {
                this.applicationInsights.logException(error);
                // If something has failed during login, there's not much we can
                // do to recover so just ensure we're fully logged out and the
                // user can try again.
                this.user.logout();
              });
            break;
          }
        }

        // Handle password reset requests.
        // Error message: "The user has forgotten their password."
        if (isAuthErrorWithCode("AADB2C90118", event.error)) {
          this.msal.loginRedirect({
            authority: passwordResetAuthority,
            scopes: ["openid"],
          });
          return;
        }

        // Handle "cancel" click on sign up page.
        // Error message: "The user has cancelled entering self-asserted information."
        if (isAuthErrorWithCode("AADB2C90091", event.error)) {
          this.msal.loginRedirect();
          return;
        }

        // Handle case where user uses old login URL (e.g. from a bookmark) in
        // the middle of a valid login session for some reason.
        // Error message: "No cached authority found."
        // See also the `LoginComponent` for additional handling of stale login
        // page sessions.
        if (isBrowserAuthErrorWithCode("noCachedAuthorityError", event.error)) {
          this.msal.loginRedirect();
          return;
        }
      });

    this.applicationInsights.load();

    // Set a custom logger to track MSAL errors via App Insights
    this.msal.setLogger(
      new Logger({
        logLevel: LogLevel.Info,
        loggerCallback: (logLevel, message) => {
          switch (logLevel) {
            case LogLevel.Error:
              this.applicationInsights.logException(message);
              return;
            case LogLevel.Info:
              this.applicationInsights.logCustomEvent("MSAL Info", {
                message,
              });
              return;
            case LogLevel.Warning:
              this.applicationInsights.logCustomEvent("MSAL Warning", {
                message,
              });
              // eslint-disable-next-line no-console
              console.warn(message);
              return;
          }
        },
      }),
    );
  }

  private async loadUserAccount(account: AccountInfo): Promise<void> {
    this.msal.instance.setActiveAccount(account);

    if (this.user.isValid) {
      return;
    }

    this.user.load(account);
    if (!this.user.isValid) {
      await this.router.navigateByUrl("/account-pending");
      return;
    }
    this.applicationInsights.setUserId(this.user.details.id);

    if (this.user.isVendor()) {
      await this.router.navigateByUrl("/vendor-portal");
      return;
    }

    await this.userPreferences.load();
  }
}

function isAuthErrorWithCode(
  code: string,
  value: EventError,
): value is AuthError {
  return (
    value instanceof AuthError &&
    (value.errorCode === code || value.errorMessage.indexOf(code) > -1)
  );
}

function isBrowserAuthErrorWithCode(
  code: keyof typeof BrowserAuthErrorMessage,
  value: EventError,
): value is BrowserAuthError {
  return (
    value instanceof BrowserAuthError &&
    value.errorCode === BrowserAuthErrorMessage[code].code
  );
}
