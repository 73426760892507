import { isNil, memoize } from "lodash-es";
import { DateTime } from "luxon";
import { ChangesHistory } from "src/app/core/history.model";
import { ODataReference } from "src/app/core/odata-reference.model";
import { Site, SiteModelReference } from "src/app/core/sites";
import { EquipmentType } from "src/app/partner/global/equipment-type.model";
import {
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  ODataModel,
  parseDateTime,
  PartnerResourceModel,
} from "src/utils";
import {
  deserializeSimpleEquipment,
  SimpleEquipment,
} from "../equipment/equipment.model";
import { BaseDoorGroup } from "./base-door-group.model";

export const doorGroupsResource = createPartnerResourceUrl("doorGroups");
export const expandedDoorGroupsResource = doorGroupsResource.modify(
  (resource) =>
    resource
      .addExpandFieldAsCount("doors", (doorsResource) =>
        doorsResource.isActive(),
      )
      .addExpandField("equipment")
      .withReferenceId(),
);

type ApiModelList = PartnerResourceModel<typeof expandedDoorGroupsResource>;
export interface ApiExpandedDoorGroup extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiExpandedDoorGroup>();

interface DeserializeArguments {
  readonly equipmentTypes: readonly EquipmentType[];
  readonly groups: readonly DoorGroup[];
  readonly site: Site;
}

interface ListDeserializeArguments {
  readonly equipmentTypes: readonly EquipmentType[];
  readonly sites: readonly Site[];
}

export class DoorGroup extends BaseDoorGroup implements DoorGroupReference {
  private constructor(
    args: Override<
      Omit<ClassProperties<DoorGroup>, "isDefault" | "totalEquipmentCount">,
      { overflowDoorGroup(): DoorGroup | null }
    >,
  ) {
    super(args);
    this.createdDate = args.createdDate;
    this.doorCount = args.doorCount;
    this.equipment = args.equipment;
    this.history = args.history;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.reference = args.reference;

    // Computed Values
    this.isDefault = this.id === this.site.defaultDoorGroupId;
    this.totalEquipmentCount = this.equipment.reduce(
      (total, { quantity }) => total + quantity,
      0,
    );
  }

  @api() public readonly createdDate: DateTime;
  @api({ key: "doors@odata.count" }) public readonly doorCount: number;
  @api() public readonly equipment: readonly SimpleEquipment[];
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: DoorGroupReference["id"];
  @api() public readonly modifiedDate: DateTime;
  @api({ key: "@odata.id" }) public readonly reference: ODataReference;

  public readonly isDefault: boolean;
  public readonly totalEquipmentCount: number;

  public static deserialize(
    data: ApiExpandedDoorGroup,
    { equipmentTypes, groups, site }: DeserializeArguments,
  ): DoorGroup {
    const { overflowDoorGroupID } = data;

    // We have to lazily load since the groups list isn't fully populated yet.
    const overflowDoorGroup = memoize(() => {
      if (isNil(overflowDoorGroupID)) {
        return null;
      }
      const group = groups.find(({ id }) => id === overflowDoorGroupID);
      if (!group) {
        throw new Error(
          `Could not find door group with ID ${overflowDoorGroupID}.`,
        );
      }
      return group;
    });

    const equipment = data.equipment.map((piece) =>
      deserializeSimpleEquipment(piece, { site, types: equipmentTypes }),
    );

    return new DoorGroup({
      createdDate: parseDateTime(data.createdDate),
      doorCount: data["doors@odata.count"],
      equipment,
      history: ChangesHistory.deserializeList(data.history),
      id: data.id,
      isActive: data.active,
      modifiedDate: parseDateTime(data.modifiedDate),
      name: data.name,
      overflowDoorGroup,
      reference: new ODataReference(data["@odata.id"]),
      site,
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    { equipmentTypes, sites }: ListDeserializeArguments,
  ): readonly DoorGroup[] {
    const groups: DoorGroup[] = [];
    // Use mutation to populate the groups list so that we can pass the
    // reference through to lazy load the overflow group once it's populated.
    for (const group of value) {
      const site = sites.find(({ id }) => id === group.siteID);
      if (!site) {
        throw new Error(`Could not find site with ID "${group.siteID}".`);
      }
      groups.push(
        DoorGroup.deserialize(group, { equipmentTypes, groups, site }),
      );
    }
    // Resolve all the overflow groups to get the errors now, if any.
    for (const group of groups) {
      group.getOverflowDoorGroup();
    }
    return groups;
  }

  public getRouteUrl(...childPaths: string[]): string {
    return this.site.getRouteUrl(
      "settings/door-groups",
      String(this.id),
      ...childPaths,
    );
  }
}

export interface DoorGroupReference extends SiteModelReference<"doorGroup"> {}
