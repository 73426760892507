import { differenceBy, inRange } from "lodash-es";

interface Model {
  id: number;
  // The model should never have a reference to a base since it's not an update.
  // We need the property here, though, so that we can narrow the type
  // definition when differentiating between the model and the update.
  base?: never;
}
interface Update<M extends Model> {
  // The update may have a reference to the base ID property.
  id?: number | null;
  base: M | null;
}
type ModelOrUpdate<M extends Model> = M | Update<M>;
type UpdateOnly<U> = U extends Update<Model> ? U : never;
type ModifyUpdate<U> = UpdateOnly<U> & {
  base: Exclude<UpdateOnly<U>["base"], null>;
};

export class ArrayUpdate<M extends Model, U extends ModelOrUpdate<M> = M> {
  public constructor(
    /** The full list of items, including unchanged items, in the updated collection. */
    public readonly updated: readonly U[],
    /** The full list of items in the original collection. */
    public readonly original: readonly M[] | null | undefined,
  ) {
    this.added = original ? differenceBy(updated, original, getId) : updated;
    this.removed = original ? differenceBy(original, updated, getId) : [];
    this.modified = differenceBy(
      updated.filter(isModifyUpdate),
      this.added,
      getId,
    );
  }
  /** The items added in the update. */
  public readonly added: readonly U[];
  /** The items removed from original in the updated collection. */
  public readonly removed: readonly M[];
  /** The items modified from the original in the updated collection. */
  public readonly modified: ReadonlyArray<ModifyUpdate<U>>;
}

function getId<U extends ModelOrUpdate<Model>>(value: U): number | null {
  if (isUpdate(value)) {
    return value.base?.id ?? null;
  } else if (isModel(value)) {
    return value.id;
  } else {
    throw new Error("Unexpected model format.");
  }
}

function isModel<U extends ModelOrUpdate<Model>>(
  value: U,
): value is Exclude<U, Update<Model>> {
  return value.base === undefined;
}

function isUpdate<U extends ModelOrUpdate<Model>>(
  value: U,
): value is UpdateOnly<U> {
  return value.base !== undefined;
}

function isModifyUpdate<U extends ModelOrUpdate<Model>>(
  value: U,
): value is ModifyUpdate<U> {
  return isUpdate(value) && value.base !== null;
}

export function swapItems(
  array: unknown[],
  firstIndex: number,
  secondIndex: number,
): void {
  if (
    !inRange(firstIndex, 0, array.length) ||
    !inRange(secondIndex, 0, array.length)
  ) {
    return;
  }

  [array[secondIndex], array[firstIndex]] = [
    array[firstIndex],
    array[secondIndex],
  ];
}
