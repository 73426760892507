import { Component, forwardRef, Input, TemplateRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { SortableOption } from "src/app/core/forms/sortable-list.component";
import { ChoiceBehavior, ModalService } from "src/app/core/modal.service";
import { BooleanAttribute, isExistent, parseBooleanAttribute } from "src/utils";
import { DisplayFieldsSelectorModalComponent } from "./display-fields-selector-modal.component";
import { FieldContainerParentService } from "./field-container.component";

@Component({
  selector:
    "mr-display-fields-selector[allDisplayFields][defaults][description][label][promptHeader][fieldDisplayTemplate]",
  templateUrl: "./display-fields-selector.component.html",
  styleUrls: ["./display-fields-selector.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DisplayFieldsSelectorComponent),
    },
  ],
})
export class DisplayFieldsSelectorComponent<T extends string>
  extends BaseFieldComponent<readonly T[] | null>
  implements ControlValueAccessor
{
  public constructor(private readonly modal: ModalService) {
    super();
  }

  @Input() public allDisplayFields!: readonly T[];
  @Input() public defaults!: readonly T[];
  @Input() public fieldDisplayTemplate!: TemplateRef<{ $implicit: T }>;
  @Input() public limit?: number;
  @Input() public permanentDefaults: readonly T[] = [];
  @Input() public promptHeader!: string;

  @Input() public set isCustom(value: BooleanAttribute) {
    this.isCustomInput.next(parseBooleanAttribute(value));
  }
  private readonly isCustomInput = new BehaviorSubject(false);
  public readonly isCustomChanges = this.isCustomInput.asObservable();

  // Redeclare as non-optional since it must be specified because of the selector.
  public declare description: string;

  public get displayedFields(): readonly T[] {
    return this.value ?? this.defaults;
  }

  public async onManageSelectionClick(): Promise<void> {
    // Get all available options to display for selection
    const availableOptions = this.allDisplayFields.map<SortableOption<T>>(
      (key) => ({
        isPermanent: this.permanentDefaults.includes(key),
        key,
        value: this.fieldDisplayTemplate,
      }),
    );

    // Pick out the selected options from all available while maintaining the
    // same order as in the current selected fields array.
    const selectedOptions =
      this.displayedFields
        .map((field) => availableOptions.find((option) => option.key === field))
        .filter(isExistent) ?? [];

    const fields = await this.modal.open<
      DisplayFieldsSelectorModalComponent<T>
    >(
      DisplayFieldsSelectorModalComponent,
      {
        header: this.promptHeader,
        limit: this.limit ?? null,
        options: availableOptions,
        selectedOptions,
      },
      { choice: ChoiceBehavior.Optional },
    ).result;

    // User canceled the change
    if (fields === undefined) {
      return;
    }

    this.onChange(fields);
  }
}
