import { Component, Input } from "@angular/core";
import { Permission } from "src/app/core/auth";
import { ChoiceBehavior, ModalService } from "src/app/core/modal.service";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import { HelpAssistTicketType } from "../help-assist-enums";
import { HelpAssistTicketAppointmentDraftUpdate } from "../models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { HelpAssistModalComponent } from "./help-assist-modal.component";

@Component({
  selector: "mr-help-button",
  templateUrl: "help-button.component.html",
  styleUrls: ["help-button.component.scss"],
})
export class HelpButtonComponent {
  public constructor(private readonly modal: ModalService) {}

  @Input()
  public appointment: HelpAssistTicketAppointmentDraftUpdate | null = null;
  @Input() public appointmentId: AppointmentReference["id"] | null = null;
  @Input() public areSchedulingTicketsEnabled: boolean | null = null;
  @Input() public areGeneralTicketsEnabled: boolean | null = null;
  @Input() public ticketType: HelpAssistTicketType | null = null;
  @Input() public appointmentTicketCallback?: () => void | Promise<void>;
  @Input() public isAppointmentCreation = false;
  @Input() public orderSearchTerms: readonly string[] | null = null;

  public readonly Permission = Permission;
  public isModalOpen = false;

  public async openTicketModal(): Promise<void> {
    this.isModalOpen = true;

    try {
      const modal = this.modal.open(
        HelpAssistModalComponent,
        {
          appointment: this.appointment ?? undefined,
          appointmentId: this.appointmentId ?? undefined,
          areGeneralTicketsEnabled: this.areGeneralTicketsEnabled ?? undefined,
          areSchedulingTicketsEnabled:
            this.areSchedulingTicketsEnabled ?? undefined,
          isAppointmentCreation: this.isAppointmentCreation,
          orderSearchTerms: this.orderSearchTerms ?? undefined,
          view:
            !this.ticketType || this.ticketType === HelpAssistTicketType.General
              ? "generalTicketView"
              : "mainView",
        },
        { choice: ChoiceBehavior.PreventClose },
      );

      if ((await modal.result) === "createdAppointmentTicket") {
        await this.appointmentTicketCallback?.();
      }

      modal.close();
    } finally {
      this.isModalOpen = false;
    }
  }
}
