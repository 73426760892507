/**
 * A process queue with a mutual-exclusion lock to synchronize the execution of
 * the processes.
 *
 * This ensure that one asynchronous process doesn't access shared resources
 * during the execution of another, and prevents stale data from being used.
 */
export class MutexQueue {
  private activeLock = Promise.resolve();

  private addLock(): Promise<() => void> {
    const previousLock = this.activeLock;

    // Add the new lock to the queue with the unlock function set to resolve it.
    let unlock: () => void;
    this.activeLock = new Promise((resolve) => {
      unlock = () => resolve();
    });

    // Wait for the previous lock to be unlocked, then return the unlock
    // function for the current lock so that it can be unlocked on completion.
    return previousLock.then(() => unlock);
  }

  /**
   * Adds a process callback to the queue to be run after all previous processes
   * have completed.
   * @param callback The process to run.
   */
  public async add<T>(callback: () => Promise<T>): Promise<T> {
    const unlock = await this.addLock();
    try {
      return await callback();
    } finally {
      unlock();
    }
  }
}
