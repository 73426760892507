<h3 *ngIf="title" class="heading4">{{ title }}</h3>

<mr-details-list split="fifths">
  <mr-details-item title="PO's:">
    <ng-container *ngIf="orders?.length; else notEntered">
      <div *ngFor="let order of orders">
        <div class="po-details">
          <div class="po-details__number">{{ order.number }}</div>
          <div *ngIf="order.dueDate" class="po-details__date">
            due {{ order.dueDate | day }}
          </div>
        </div>
      </div>
    </ng-container>
  </mr-details-item>
</mr-details-list>

<ng-template #notEntered>
  <span class="text-italic">Not entered</span>
</ng-template>
