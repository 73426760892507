import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms";
import { SelectMode } from "@danielmoncada/angular-datetime-picker";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { isArray, isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { BehaviorSubject } from "rxjs";
import { DateTimePipe } from "src/app/core/pipes/date-time.pipe";
import { isExistent, isInstanceOf, makeValidatorOptional } from "src/utils";
import { Day } from "../day.model";
import { TimeOfDay } from "../time-of-day.model";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

type Value = Day | Day[] | null;
type DateTimeValue = DateTime | DateTime[] | null;

@UntilDestroy()
@Component({
  selector: "mr-date-picker[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [description]="description"
      [labelHidden]="isLabelHidden"
      [kind]="kind"
      [additionalErrorMessageTemplate]="errorMessages"
    >
      <mr-date-time-picker-base
        [fieldId]="container.fieldId"
        [descriptionId]="container.descriptionId"
        [label]="isLabelHidden ? label : ''"
        [timeZone]="timeZone"
        [fixedTime]="fixedTime"
        [minDateTime]="minimumDateTime"
        [maxDateTime]="maximumDateTime"
        [disableClear]="disableClear"
        [disabled]="isDisabled"
        [readonly]="isReadonly"
        [isHighlighted]="isHighlighted"
        [highlightStyle]="highlightStyle"
        [selectMode]="selectMode"
        [value]="dateTimeChanges | async"
        [forceWeeklyRange]="forceWeeklyRange"
        (valueChange)="setFromDateTime($event)"
        (unfocus)="onBlur()"
      ></mr-date-time-picker-base>

      <div
        *ngIf="isHighlighted && originalValueDateTime"
        class="original-value"
        [ngClass]="
          highlightStyle === 'border' ? 'border-style' : 'background-style'
        "
      >
        {{ getOriginalDateTimeDisplayText(originalValueDateTime) }}
      </div>
    </mr-field-container>

    <ng-template #errorMessages let-errors let-errorLabel="errorLabel">
      <li *ngIf="errors.minDay as error">
        {{ errorLabel }} must be on or after {{ error.min | day }}
      </li>
      <li *ngIf="errors.maxDay as error">
        {{ errorLabel }} must be on or before {{ error.max | day }}
      </li>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .original-value {
        --date-time-picker-base-left-padding: 2.625rem;
        color: var(--mr-text-color);
        text-decoration: 2px solid var(--mr-color-yellow) line-through;

        &.border-style {
          margin-top: var(--mr-form-field-label-margin-top);
          padding-left: calc(2px + var(--date-time-picker-base-left-padding));
        }

        &.background-style {
          padding-block: 0.3rem 0;
          padding-left: var(--date-time-picker-base-left-padding);
        }
      }
    `,
  ],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
})
export class DatePickerComponent
  extends BaseFieldComponent<Value>
  implements OnInit
{
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
    private readonly dateTimePipe: DateTimePipe,
  ) {
    super();
  }

  @Input() public canHighlight = false;
  @Input() public disableClear = false;
  @Input() public forceWeeklyRange = false;
  @Input() public highlightStyle: "background" | "border" = "background";
  @Input() public kind: "form" | "tiled" = "form";
  @Input() public selectMode: SelectMode = "single";

  public isHighlighted = false;

  @Input() public set minDay(value: Day | null) {
    this.minimumDateTime = value ? getAdjustedDateTime(value) : null;
  }
  public minimumDateTime: DateTime | null = null;

  @Input() public set maxDay(value: Day | null) {
    this.maximumDateTime = value ? getAdjustedDateTime(value) : null;
  }
  public maximumDateTime: DateTime | null = null;

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  public readonly timeZone = datePickerTimeZone;
  public readonly fixedTime = datePickerFixedTime;

  public override get value(): Value {
    return this._value;
  }
  public override set value(value: Value) {
    if (value instanceof Array) {
      if (!value[0] || !value[1]) {
        throw Error("Date Picker value should contain a start and end dates.");
      }

      this.dateTimeInput.next(
        value.map((val) => getAdjustedDateTime(val) ?? null),
      );
    } else {
      const adjustedDateTimeValue = value ? getAdjustedDateTime(value) : null;
      this.dateTimeInput.next(adjustedDateTimeValue);
    }
    this._value = value;
  }
  private _value: Value = null;
  private readonly dateTimeInput = new BehaviorSubject<DateTimeValue>(null);
  public readonly dateTimeChanges = this.dateTimeInput.asObservable();

  public get originalValue(): Value | null {
    return this._originalValue;
  }
  @Input() public set originalValue(value: Value | null) {
    this._originalValue = value;

    if (value instanceof Array) {
      this.originalValueDateTime = value.map((day) => getAdjustedDateTime(day));
    } else {
      this.originalValueDateTime =
        value?.toDateTime(this.fixedTime, this.timeZone) ?? null;
    }
  }
  private _originalValue: Value | null = null;
  public originalValueDateTime: DateTimeValue | null = null;

  public setFromDateTime(value: DateTimeValue): void {
    if (value instanceof Array) {
      this.onChange(value.map((value) => new Day(value)));
    } else {
      this.onChange(value && new Day(value));
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.dateTimeChanges
      .pipe(untilDestroyed(this))
      .subscribe((currentDateTime) => {
        if (
          this.originalValueDateTime instanceof Array &&
          currentDateTime instanceof Array
        ) {
          this.isHighlighted =
            this.canHighlight &&
            !isEqual(this.originalValueDateTime.sort(), currentDateTime.sort());
        } else if (
          this.originalValueDateTime instanceof DateTime &&
          currentDateTime instanceof DateTime
        ) {
          this.isHighlighted =
            this.canHighlight &&
            isExistent(currentDateTime) &&
            !this.originalValueDateTime.equals(currentDateTime);
        } else {
          this.isHighlighted = false;
        }
      });
  }

  public getOriginalDateTimeDisplayText(value: DateTimeValue): string | null {
    if (!value) return null;

    const getFormattedDate = (value: DateTime): string | null =>
      this.dateTimePipe.transform(value, "longDate");

    if (isArray(value)) {
      const originalStartDateTime = getFormattedDate(value[0]);
      const originalEndDateTime = getFormattedDate(value[1]);

      if (!originalStartDateTime || !originalEndDateTime) {
        return null;
      }

      return `${originalStartDateTime} -
      ${originalEndDateTime}`;
    }

    return getFormattedDate(value);
  }
}

type MinDayError = ["minDay", { min: Day; actual: Day }];
export function minDayValidator(min: Day): ValidatorFn {
  return makeValidatorOptional<MinDayError>(isInstanceOf(Day), (actual) =>
    actual.isBefore(min) ? { minDay: { min, actual } } : null,
  );
}

type MaxDayError = ["maxDay", { max: Day; actual: Day }];
export function maxDayValidator(max: Day): ValidatorFn {
  return makeValidatorOptional<MaxDayError>(isInstanceOf(Day), (actual) =>
    actual.isAfter(max) ? { maxDay: { max, actual } } : null,
  );
}

export const datePickerTimeZone = Day.localZone;
export const datePickerFixedTime = TimeOfDay.midnight;

export function getAdjustedDateTime(value: Day): DateTime {
  return value.toDateTime(datePickerFixedTime, datePickerTimeZone);
}
