import {
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  TemplateRef,
} from "@angular/core";
import { ChoiceBehavior, Modal } from "../modal.service";

@Directive({ selector: "[mrModalActions]" })
export class ModalActionsDirective {}

@Directive({ selector: "[mrModalCustomContent]" })
export class ModalCustomContentDirective {}

@Component({
  selector: "mr-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
  public constructor(public readonly modal: Modal<string, boolean>) {}

  @HostBinding("attr.role") public readonly role = "dialog";

  @Input() public header?: string;
  @Input() public warningMessage?: string | null;

  @ContentChild(ModalActionsDirective, { read: TemplateRef })
  public readonly actionsTemplate?: TemplateRef<void>;

  @ContentChild(ModalCustomContentDirective, { read: TemplateRef })
  public readonly customContentTemplate?: TemplateRef<void>;

  public get isCloseButtonVisible(): boolean {
    return (
      this.modal.options.choice === ChoiceBehavior.Optional ||
      !this.actionsTemplate
    );
  }
}
