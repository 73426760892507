import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CapstoneSitesApi } from "@capstone/mock-api";
import { sortBy, uniqBy } from "lodash-es";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { environment } from "src/environments";
import { appendUrlPath } from "src/utils";
import { CapstonePartner, CapstoneSite } from "./capstone-site.model";

const sitesUrl = appendUrlPath(environment.apiUrls.sites, "sites");
sitesUrl.searchParams.append("attributeName", "MR New");

@Injectable({ providedIn: "root" })
export class CapstoneSitesService {
  public constructor(private readonly httpClient: HttpClient) {}

  public readonly listChanges = this.httpClient
    .get<CapstoneSitesApi.Site[]>(sitesUrl.href)
    .pipe(map(CapstoneSite.deserializeList), shareReplay(1));

  public readonly partnerListChanges: Observable<readonly CapstonePartner[]> =
    this.listChanges.pipe(
      map((sites) =>
        uniqBy(
          sortBy(
            sites.map((site) => site.partner),
            (partner) => partner.name.toLowerCase(),
          ),
          (partner) => partner.key.toString(),
        ),
      ),
      shareReplay(1),
    );
}
