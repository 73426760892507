import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { getSiteRouteUrl } from "src/app/core/sites";
import { isExistent, parseNumber, parseUUID } from "src/utils";

@Injectable()
export class RedirectGuard implements CanActivate {
  public constructor(private readonly router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): UrlTree {
    const partnerKey = parseUUID(route.queryParamMap.get("partner"));
    const siteId = parseNumber(route.queryParamMap.get("site"));
    const appointmentId = parseNumber(route.queryParamMap.get("appointment"));
    const ticketId = parseNumber(route.queryParamMap.get("ticket"));

    if (isExistent(partnerKey) && isExistent(siteId)) {
      const site = { id: siteId, partner: { key: partnerKey } };

      if (isExistent(appointmentId)) {
        return this.router.parseUrl(
          getSiteRouteUrl(site, "appointments", String(appointmentId)),
        );
      }

      if (isExistent(ticketId)) {
        return this.router.parseUrl(
          getSiteRouteUrl(site, "help-assist", String(ticketId)),
        );
      }
    }

    // Reaching this point represents accessing an undefined or invalid redirect
    // URL. There's not much we can do about this other than navigate them to
    // the root as the alerts service wouldn't yet be available on the page.
    // TODO: Add App Insights logging here.
    return this.router.parseUrl("/");
  }
}
