import { Component, Input } from "@angular/core";
import { BooleanAttribute, parseBooleanAttribute } from "src/utils";

@Component({
  selector: "mr-button",
  templateUrl: "button.component.html",
  styleUrls: ["button.component.scss"],
})
export class ButtonComponent {
  @Input() public type: "button" | "submit" = "button";
  @Input() public kind:
    | "primary"
    | "link"
    | "link-secondary"
    | "cancel"
    | "cancel-important"
    | "secondary" = "primary";
  @Input() public size: "normal" | "large" | "extra-large" = "normal";
  @Input() public buttonStyle: "normal" | "danger" = "normal";
  @Input() public form?: string;
  @Input() public isLoading = false;

  @Input() public set disabled(value: BooleanAttribute) {
    this.isDisabled = parseBooleanAttribute(value);
  }

  public isDisabled = false;
}
