import { chunk } from "lodash-es";
import { defer, Observable, zip } from "rxjs";
import { map, startWith } from "rxjs/operators";

/**
 * Gets the display name for a history change value representing a collection of
 * records with ID references.
 * @param value - The value with the collection of IDs like "1|Door A,2|Door B".
 */
export function formatHistoryIdChangeValue(
  value: string | null,
): string | null {
  if (value === null) {
    return null;
  }
  const items = parseIdChangeValue(value);
  return items ? formatHistoryIdChangeValueItems(items) : `[ID: ${value}]`;
}

/**
 * Gets the display name for a history change value representing a collection of
 * records with ID references, with a fallback to look up the name from an
 * external source if it's not found in the value itself.
 * @param value - The value with the collection of IDs like "1|Door A,2|Door B".
 * @param getNameFallback - The fallback function for getting the name if it's
 * not included in the value itself.
 * @deprecated Use the function without a fallback instead for new usages.
 */
formatHistoryIdChangeValue.withFallback = (
  value: string | null,
  getNameFallback: (id: number) => string | null,
): string | null => {
  if (value === null) {
    return null;
  }

  const items = parseIdChangeValue(value);
  if (!items) {
    return `[ID: ${value}]`;
  } else if (items.every(({ name }) => name)) {
    return formatHistoryIdChangeValueItems(items);
  } else {
    return formatHistoryIdChangeValueItems(
      items.map(({ id }) => ({ id, name: getNameFallback(id) })),
    );
  }
};

/**
 * Gets the display name for a history change value representing a collection of
 * records with ID references, with a fallback to asynchronously look up the
 * name from an external source if it's not found in the value itself.
 * @param value - The value with the collection of IDs like "1|Door A,2|Door B".
 * @param getNameFallback - The fallback function for getting the name if it's
 * not included in the value itself.
 * @deprecated Use the function without a fallback instead for new usages.
 */
formatHistoryIdChangeValue.withAsyncFallback = (
  value: string | null,
  getNameFallback: (
    id: number,
  ) => Observable<string | null> | Promise<string | null>,
): string | Observable<string> | null => {
  if (value === null) {
    return null;
  }

  const items = parseIdChangeValue(value);
  if (!items) {
    return `[ID: ${value}]`;
  } else if (items.every(({ name }) => name)) {
    return formatHistoryIdChangeValueItems(items);
  } else {
    return zip(
      ...items.map(({ id, name: originalName }) =>
        // The name might exist already so we don't need to look it up even if
        // there is at least one item without a name that needs to be looked up.
        defer(() => (originalName ? [originalName] : getNameFallback(id))).pipe(
          startWith(null),
          map((name): HistoryIdChangeValueItem => ({ id, name })),
        ),
      ),
    ).pipe(map(formatHistoryIdChangeValueItems));
  }
};

function formatHistoryIdChangeValueItems(
  value: readonly HistoryIdChangeValueItem[],
): string {
  return value.map(({ id, name }) => name || `[ID: ${id}]`).join(", ");
}

/**
 * Process the "collection" of history change ID values.
 * @param value - The value with the collection of IDs, like "1|Door A,2|Door B".
 */
function parseIdChangeValue(
  value: string,
): readonly HistoryIdChangeValueItem[] | null {
  // Because the "name" part of the items in the collection could have ","
  // in them, we can't just split on ",". Instead, this splits on a
  // delimiter that looks something like ",123|". Some details:
  // 1. To simplify the regular expression, a leading comma is appended to
  //    match the first element as a delimiter also. The new, fake first
  //    item is then thrown away with the slice at the end.
  // 2. The IDs are included in the split output before its respective name
  //    part because of the capture group around the `\d+`. See:
  //    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#Description
  // 3. Lastly, the "|" is left optional so that the old pattern without the
  //    name part will still match.
  //
  // Examples:
  // "1,2,3" -> ["1","",2,"",3,""]
  // "1|a,b,2|c" -> ["1","a,b","2","c"]
  const parts = `,${value}`.split(/,(\d+)\|?/).slice(1);

  if (parts.length === 0) {
    // If it didn't even match once, we can't parse it.
    return null;
  }

  // This groups the ID with its respective name (if present).
  //
  // Examples:
  // ["1","",2,"",3,""] -> [["1",""],[2,""],[3,""]]
  // ["1","a,b","2","c"] -> [["1","a,b"],["2","c"]]
  const idNamePairs = chunk(parts, 2);

  // And lastly, map the pairs to objects and clean up the values a bit.
  //
  // Examples:
  // [["1",""],[2,""],[3,""]] -> [{id:1,name:null},{id:2,name:null},{id:3,name:null}]
  // [["1","a,b"],["2","c"]] -> [{id:1,name:"a,b"},{id:2,name:"c"}]
  return idNamePairs.map(([id, name]) => ({
    id: Number(id),
    name: name || null,
  }));
}

interface HistoryIdChangeValueItem {
  id: number;
  name: string | null;
}
