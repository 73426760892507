import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { EMPTY, firstValueFrom, Observable, of } from "rxjs";
import {
  expand,
  map,
  startWith,
  switchMap,
  take,
  // See usages for explanation.
  // eslint-disable-next-line rxjs/no-tap
  tap,
} from "rxjs/operators";
import {
  isExistent,
  ODataModel,
  ODataResourceUrl,
  ResourceUrl,
} from "src/utils";
import { ODataReference } from "./odata-reference.model";
import { Pagination } from "./pagination";
import {
  BatchOperation,
  BatchResult,
  createBatchChangesetRequest,
  RequestModel,
} from "./request-batching";

const headers = new HttpHeaders({
  Accept: "application/json",
  "Content-Type": "application/json",
});

@Injectable({ providedIn: "root" })
export class RequestService {
  public constructor(private readonly httpClient: HttpClient) {}

  public apiBuildNumber: string | null = null;

  /**
   * Makes an HTTP GET request for a single item or the first page of a
   * collection, initiated with a null to signal loading/reloading.
   * @param resource The resource being requested.
   */
  public get<T>(resource: ODataResourceUrl<T>): Observable<T | null> {
    return this.httpClient
      .get<T>(resource.url.href, {
        headers: getHeaders(resource),
        observe: "response",
      })
      .pipe(
        // There's probably a better way to do this with interceptors or
        // something, but for now we can pull the API build number off each
        // request to keep it updated and available for display in the footer.
        tap((response) => {
          this.apiBuildNumber = response.headers.get("API-Build-Number");
        }),
        map((response) => response.body),
        startWith<T | null>(null),
      );
  }

  /**
   * Makes an HTTP GET request for a Blob, initiated with a null to signal
   * loading/reloading.
   * @param resource The resource being requested.
   */
  public getBlob<T>(resource: ODataResourceUrl<T>): Observable<Blob | null> {
    const { url } = resource;

    return this.httpClient
      .get(url.href, { headers, responseType: "blob" })
      .pipe(startWith(null));
  }

  /**
   * Makes HTTP GET requests to get a collection of resources, initiated with a
   * null to signal loading/reloading.
   *
   * If the `pagination` handler is provided, gets the first page of a
   * collection. Subsequent pages will load based on the load requests from the
   * pagination controller. Otherwise, returns all results potentially resulting
   * in one or more GET requests.
   * @param resource The resource being requested.
   * @param options Options for controlling paging.
   */
  public getMany<T>(
    resource: ODataResourceUrl<Api.ODataList<T>>,
    {
      pagination,
      maximum = 50,
    }: {
      /** The pagination controller for managing paging. */
      pagination?: Pagination;
      /**
       * The maximum number of results to return per page (or overall if no
       * pagination handler is provided). Use `null` to request all results.
       */
      maximum?: number | null;
    } = {},
  ): Observable<Api.ODataList<T> | null> {
    return pagination
      ? this.getPaged(
          isExistent(maximum) ? resource.withMaxPageSize(maximum) : resource,
          pagination,
        )
      : this.getAll(isExistent(maximum) ? resource.top(maximum) : resource);
  }

  /**
   * Makes an HTTP GET requests for the first page of a collection, initiated
   * with a null to signal loading/reloading. Subsequent pages will load based
   * on the load requests from the pagination controller.
   * @param resource The resource being requested.
   * @param pagination The pagination controller for managing paging.
   */
  private getPaged<T>(
    resource: ODataResourceUrl<Api.ODataList<T>>,
    pagination: Pagination,
  ): Observable<Api.ODataList<T> | null> {
    return this.get(resource).pipe(
      // Since we don't control the pagination here, we have to update it as a
      // side-effect of our request in order to keep it in sync. Resetting it
      // here ensures the page info always represents the current resource URL's
      // pages (e.g. if filters or sorting change).
      tap((result) => {
        if (result) {
          pagination.reset(resource, result);
        }
      }),
      // `expand` will emit the initial result but then also listen for new
      // request from the pagination control and emit those new pages as they're
      // requested.
      expand((result) => {
        if (!result) {
          // Ignore the loading indicator values (null).
          return EMPTY;
        }
        return pagination.requestNotifier.pipe(
          take(1),
          switchMap((nextLink) =>
            this.getByLink(resource, nextLink).pipe(
              // Again, we have to update our page link info as a side-effect in
              // order to keep it in sync with the results.
              switchMap(async (nextResult) => {
                if (nextResult) {
                  await pagination.loadPage(nextLink, nextResult);
                }
                return nextResult;
              }),
            ),
          ),
        );
      }),
    );
  }

  private getByLink<T>(
    resource: ODataResourceUrl<T>,
    link: string,
  ): Observable<T | null> {
    return this.httpClient
      .get<T>(link, { headers: getHeaders(resource) })
      .pipe(startWith(null));
  }

  /**
   * Makes one or more HTTP GET requests for the full collection of a resource,
   * initiated with a null to signal loading/reloading.
   * @param resource The resource being requested.
   */
  public getAll<T>(
    resource: ODataResourceUrl<Api.ODataList<T>>,
  ): Observable<Api.ODataList<T> | null> {
    return this.get(resource).pipe(
      switchMap((result) =>
        result ? this.getRemainingList(resource, result) : of(null),
      ),
    );
  }

  private async getRemainingList<T>(
    originalResource: ODataResourceUrl<Api.ODataList<T>>,
    { "@odata.nextLink": nextLink, ...currentList }: Api.ODataList<T>,
  ): Promise<Api.ODataList<T>> {
    if (!nextLink) {
      return currentList;
    }

    const nextList = await firstValueFrom(
      this.httpClient.get<Api.ODataList<T>>(nextLink, {
        headers: getHeaders(originalResource),
      }),
    );

    const remainingList = await this.getRemainingList<T>(
      originalResource,
      nextList,
    );

    return {
      ...currentList,
      value: [...currentList.value, ...remainingList.value],
    };
  }

  /**
   * Makes an HTTP POST request to create a new association to a resource via
   * reference.
   * @param resource The base resource's reference property.
   * @param data The reference to associate with the base resource.
   */
  public post(
    resource: ODataResourceUrl<Api.ODataReference>,
    data: ODataReference,
  ): Observable<void>;
  /**
   * Makes an HTTP POST request to create a new resource.
   * @param resource The resource being created.
   * @param data The data to add to the request.
   */
  public post<T>(
    resource: ODataResourceUrl<T>,
    data: RequestModel,
  ): Observable<ODataModel<T>>;
  public post<T>(
    { url }: ODataResourceUrl<T>,
    data: RequestModel,
  ): Observable<ODataModel<T> | void> {
    const body = data.serialize();

    let requestHeaders =
      body instanceof FormData ? headers.delete("Content-Type") : headers;
    requestHeaders = data.version
      ? requestHeaders.set("If-Match", data.version)
      : requestHeaders;

    return this.httpClient.post<ODataModel<T> | void>(url.href, body, {
      headers: requestHeaders,
    });
  }

  /**
   * Makes an HTTP PUT request to update a resource in full.
   * @param resource The resource being updated.
   * @param data The data to add to the request.
   */
  public put<T>(
    { url }: ODataResourceUrl<T>,
    data: RequestModel,
  ): Observable<T> {
    return this.httpClient.put<T>(url.href, data.serialize(), {
      headers: data.version ? headers.set("If-Match", data.version) : headers,
    });
  }

  /**
   * Makes an HTTP PATCH request to partially update a resource.
   * @param resource The resource being updated.
   * @param data The data to add to the request.
   */
  public patch<T>(
    { url }: ODataResourceUrl<T>,
    data: RequestModel,
  ): Observable<T> {
    return this.httpClient.patch<T>(url.href, data.serialize(), {
      headers: data.version ? headers.set("If-Match", data.version) : headers,
    });
  }

  /**
   * Makes an HTTP DELETE request to delete a resource.
   * @param resource The resource being deleted.
   */
  public delete<T>({ url }: ODataResourceUrl<T>): Observable<void> {
    return this.httpClient.delete<void>(url.href, { headers });
  }

  public async batchChangeset<T extends readonly BatchOperation[]>(
    serviceRootResource: ResourceUrl,
    operations: T,
  ): Promise<BatchResult<T>> {
    const { batch, parseResponse } = createBatchChangesetRequest<T>(
      serviceRootResource,
      operations,
    );

    const response = await firstValueFrom(
      this.httpClient.post(batch.url, batch.body, {
        headers: batch.headers,
        observe: "response",
        responseType: "text",
      }),
    );

    return parseResponse(response);
  }
}

function getHeaders(resource: ODataResourceUrl): HttpHeaders {
  const { includeMetadata, maxPageSize } = resource;
  let modified = headers;
  if (includeMetadata) {
    modified = modified.set("Accept", "application/json;odata.metadata=full");
  }
  if (isExistent(maxPageSize)) {
    modified = modified.set("Prefer", `odata.maxpagesize=${maxPageSize}`);
  }
  return modified;
}
