<ng-container [ngSwitch]="currentView">
  <mr-modal *ngSwitchCase="modalView.MainView" header="We are here to help">
    <p>Please select one of the following:</p>

    <div class="button-set">
      <mr-button
        *ngIf="appointment?.site.helpAssist.areSchedulingTicketsAllowed"
        (click)="openTicket(modalView.AppointmentView)"
        [disabled]="!areSchedulingTicketsEnabled"
      >
        I need help with the current appointment
      </mr-button>

      <mr-button
        kind="secondary"
        (click)="openTicket(modalView.GeneralView)"
        [disabled]="!areGeneralTicketsEnabled"
      >
        I need help with a general question
      </mr-button>
    </div>

    <p
      *ngIf="!areGeneralTicketsEnabled && hasOrders"
      class="navigation-description"
    >
      <span
        *ngIf="
          !areSchedulingTicketsEnabled;
          else allowedSchedulingTicketCreationMessage
        "
      >
        There is a pending request on this appointment. For questions regarding
        PO information, please contact your Buyer, Vendor, or Carrier.
      </span>
      <ng-template #allowedSchedulingTicketCreationMessage>
        You must enter Purchase Order(s) to proceed with creating a Help Assist
        ticket related to an appointment. For questions regarding PO
        information, please contact your Buyer, Vendor, or Carrier.
      </ng-template>

      For general assistance with logging into the MR System or other How To
      questions, please call the Help Desk at 1-800-838-4549 Option 6
    </p>

    <p *ngIf="!hasOrders" class="navigation-description">
      You must enter Purchase Order(s) to proceed with creating a Help Assist
      ticket related to an appointment. For questions regarding PO information,
      please contact your Buyer, Vendor, or Carrier. For general assistance with
      logging into the MR System or other How To questions, please call the Help
      Desk at 1-800-838-4549 Option 6.
    </p>

    <p
      *ngIf="
        areGeneralTicketsEnabled && !areSchedulingTicketsEnabled && hasOrders
      "
    >
      There is a pending request on this appointment. Please contact the help
      assist team via the general question form
    </p>
  </mr-modal>

  <mr-modal *ngSwitchCase="modalView.GeneralView" header="Ask Us Anything!">
    <p>Be as descriptive as possible when explaining your issue.</p>
    <mr-general-ticket-form
      [isAppointmentCreation]="isAppointmentCreation"
      [orderSearchTerms]="orderSearchTerms"
      (submitSuccess)="handleGenericSubmitSuccess($event)"
      (submitError)="handleSubmitError($event)"
    ></mr-general-ticket-form>
  </mr-modal>

  <mr-modal
    *ngSwitchCase="modalView.AppointmentView"
    header="Scheduling Assistance"
  >
    <div class="mr-layout-flex">
      <div class="mr-layout-flex__row mr-layout-flex__row--centered">
        <div class="mr-layout-flex__col mr-layout-flex__col-sm--8">
          <p>
            Can’t find a slot? Open a ticket with our scheduling specialists and
            we’ll do our best to accommodate your needs.
          </p>
        </div>
      </div>
      <div class="mr-layout-flex__row appointment-details-wrapper">
        <div class="mr-layout-flex__col mr-layout-flex__col-sm--6">
          <mr-ticket-appointment-details
            alignTitle="right"
            *ngIf="appointment"
            [valuesWithNoHighlight]="appointment"
          ></mr-ticket-appointment-details>
        </div>
        <div class="mr-layout-flex__col mr-layout-flex__col-sm--6">
          <mr-ticket-po-list
            title="PO details"
            *ngIf="appointment"
            [appointment]="appointment"
          ></mr-ticket-po-list>
        </div>
      </div>
      <div class="mr-layout-flex__row mr-layout-flex__row--centered">
        <div class="mr-layout-flex__col mr-layout-flex__col-sm--8">
          <mr-scheduling-ticket-form
            [appointment]="appointment"
            [appointmentId]="appointmentId"
            (submitSuccess)="handleAppointmentSubmitSuccess($event)"
            (submitError)="handleSubmitError($event)"
          ></mr-scheduling-ticket-form>
        </div>
      </div>
    </div>
  </mr-modal>
</ng-container>
