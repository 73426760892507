import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  FormsModule as AngularFormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { OwlDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { QuillModule } from "ngx-quill";
import { SortablejsModule } from "ngx-sortablejs";
import { DateRangeStepperComponent } from "src/app/core/forms/date-range-stepper.component";
import { HighlightedDirective } from "src/app/core/forms/highlighted.directive";
import { MultiSelectComponent } from "src/app/core/forms/multi-select.component";
import { ElementsModule } from "../elements/elements.module";
import { PipesModule } from "../pipes/pipes.module";
import { CheckboxComponent } from "./checkbox.component";
import { ClickOutsideDirective } from "./click-outside.directive";
import { DatePickerComponent } from "./date-picker.component";
import { DateStepperComponent } from "./date-stepper.component";
import { DateTimePickerBaseComponent } from "./date-time-picker-base.component";
import { DateTimePickerComponent } from "./date-time-picker.component";
import { DisplayFieldsSelectorModalComponent } from "./display-fields-selector-modal.component";
import { DisplayFieldsSelectorComponent } from "./display-fields-selector.component";
import { DropdownActionItemComponent } from "./dropdown-action-item.component";
import { DropdownActionComponent } from "./dropdown-action.component";
import { DropdownItemComponent } from "./dropdown-item.component";
import { DropdownComponent } from "./dropdown.component";
import { DurationSelectComponent } from "./duration-select.component";
import { FieldContainerComponent } from "./field-container.component";
import { FieldsetComponent } from "./fieldset.component";
import { FormRowComponent } from "./form-row.component";
import { GooglePlaceDirective } from "./google-place.directive";
import { IconSelectorPopupComponent } from "./icon-selector-popup.component";
import { IconSelectorComponent } from "./icon-selector.component";
import { PhoneNumberInputComponent } from "./phone-number-input.component";
import { RadioGroupFieldContainerComponent } from "./radio-group-field-container.component";
import { RadioGroupItemComponent } from "./radio-group-item.component";
import { RadioGroupComponent } from "./radio-group.component";
import { RichTextareaComponent } from "./rich-textarea.component";
import { SearchBoxComponent } from "./search-box.component";
import { SortableListComponent } from "./sortable-list.component";
import { TextareaComponent } from "./textarea.component";
import { TextboxComponent } from "./textbox.component";
import { TimepickerComponent } from "./timepicker.component";
import { WeekdaySelectComponent } from "./weekday-select.component";

@NgModule({
  imports: [
    AngularFormsModule,
    CommonModule,
    ElementsModule,
    NgbTimepickerModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    OwlDateTimeModule,
    PipesModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    SortablejsModule,
  ],
  declarations: [
    CheckboxComponent,
    ClickOutsideDirective,
    DatePickerComponent,
    DateStepperComponent,
    DateRangeStepperComponent,
    DateTimePickerBaseComponent,
    DateTimePickerComponent,
    DisplayFieldsSelectorComponent,
    DisplayFieldsSelectorModalComponent,
    DropdownActionComponent,
    DropdownActionItemComponent,
    DropdownComponent,
    DropdownItemComponent,
    DurationSelectComponent,
    FieldContainerComponent,
    FieldsetComponent,
    FormRowComponent,
    GooglePlaceDirective,
    HighlightedDirective,
    IconSelectorComponent,
    IconSelectorPopupComponent,
    MultiSelectComponent,
    PhoneNumberInputComponent,
    RadioGroupComponent,
    RadioGroupFieldContainerComponent,
    RadioGroupItemComponent,
    RichTextareaComponent,
    SearchBoxComponent,
    SortableListComponent,
    TextareaComponent,
    TextboxComponent,
    TimepickerComponent,
    WeekdaySelectComponent,
  ],
  exports: [
    AngularFormsModule,
    CheckboxComponent,
    ClickOutsideDirective,
    DatePickerComponent,
    DateStepperComponent,
    DateRangeStepperComponent,
    DateTimePickerComponent,
    DisplayFieldsSelectorComponent,
    DisplayFieldsSelectorModalComponent,
    DropdownActionComponent,
    DropdownActionItemComponent,
    DropdownComponent,
    DropdownItemComponent,
    DurationSelectComponent,
    FieldContainerComponent,
    FieldsetComponent,
    FormRowComponent,
    GooglePlaceDirective,
    HighlightedDirective,
    IconSelectorComponent,
    MultiSelectComponent,
    NgxDaterangepickerMd,
    PhoneNumberInputComponent,
    QuillModule,
    RadioGroupComponent,
    RadioGroupItemComponent,
    ReactiveFormsModule,
    RichTextareaComponent,
    SearchBoxComponent,
    SortableListComponent,
    TextareaComponent,
    TextboxComponent,
    TimepickerComponent,
    WeekdaySelectComponent,
  ],
})
export class FormsModule {}
