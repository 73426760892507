import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Inject, Injectable, InjectionToken, Injector } from "@angular/core";
import { firstValueFrom, ReplaySubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ModalService {
  public constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
  ) {}

  // We will extract the input and output types from T so we don't care what
  // they are here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public open<T extends ModalComponent<any, any>>(
    component: ComponentType<T>,
    context: ModalInput<T>,
    options?: Partial<ModalOptions>,
  ): Modal<ModalInput<T>, ModalOutput<T>> {
    const overlay = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "mr-modal-backdrop",
      panelClass: "mr-modal-container",
      minHeight: 200,
      maxHeight: "calc(100vh - 4rem)",
      minWidth: 400,
      maxWidth: options?.maxWidth ?? 700,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    const modalConfig: ModalConfig<ModalInput<T>> = {
      overlay,
      context,
      options,
    };

    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: MODAL_CONFIG, useValue: modalConfig }],
    });

    const { instance } = overlay.attach(
      new ComponentPortal(component, undefined, injector),
    );

    // Type can be asserted since the inputs and outputs were extracted from the
    // component that this modal instance came from.
    return instance.modal as Modal<ModalInput<T>, ModalOutput<T>>;
  }
}

interface ModalConfig<Input> {
  context: Input;
  options?: Partial<ModalOptions>;
  overlay: OverlayRef;
}
const MODAL_CONFIG = new InjectionToken<ModalConfig<unknown>>("ModalConfig");

@Injectable()
export class Modal<Input = void, Output = void> {
  public constructor(
    @Inject(MODAL_CONFIG)
    {
      context,
      overlay,
      options: { choice = ChoiceBehavior.Required, maxWidth } = {},
    }: ModalConfig<Input>,
  ) {
    this.context = context;
    this.options = { choice, maxWidth };
    this.overlay = overlay;

    if (choice === ChoiceBehavior.Optional) {
      overlay.keydownEvents().subscribe((event) => {
        if (event.code === "Escape") {
          this.close();
        }
      });
      overlay.backdropClick().subscribe(() => {
        this.close();
      });
    }
  }

  public readonly context: Input;
  public readonly options: ModalOptions;
  private readonly overlay: OverlayRef;

  private readonly resultSubject = new ReplaySubject<Output | undefined>(1);
  public readonly result = firstValueFrom(this.resultSubject);

  private readonly closedNotifier = new Subject<void>();

  public async choose(result: Output): Promise<void> {
    this.resultSubject.next(result);
    const closed = firstValueFrom(this.closedNotifier);
    if (this.options.choice !== ChoiceBehavior.PreventClose) {
      this.close();
    }
    await closed;
  }

  public close(): void {
    this.resultSubject.next(undefined);
    this.resultSubject.complete();
    this.overlay.dispose();
    this.closedNotifier.next();
  }
}

export enum ChoiceBehavior {
  /**
   * A choice is required and that choice will automatically close the modal.
   */
  Required,
  /**
   * An explicit choice isn't required, allowing the modal to be closed without
   * one such as by clicking the backdrop or pressing the `Escape` key. Picking
   * a choice will automatically close the modal.
   */
  Optional,
  /**
   * A choice will not close the modal. The modal can be programmatically
   * closed.
   */
  PreventClose,
}

type ModalInputOutput<T> = T extends ModalComponent<infer Input, infer Output>
  ? [Input, Output]
  : never;
type ModalInput<T extends ModalComponent<unknown, unknown>> =
  ModalInputOutput<T>[0];
type ModalOutput<T extends ModalComponent<unknown, unknown>> =
  ModalInputOutput<T>[1];

interface ModalComponent<Input = void, Output = void> {
  modal: Modal<Input, Output>;
}

interface ModalOptions {
  /**
   * How the modal should behave to a choice being selected. [Default:
   * `ChoiceBehavior.Required`]
   */
  choice: ChoiceBehavior;
  maxWidth?: string | number;
}
