import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { HistoryItem } from "src/app/core/history.model";
import { Site } from "src/app/core/sites";
import { ActivityAction } from "src/app/partner/settings/activities/base-activity.model";
import {
  apiPropertyClass,
  getApiDetailsDecorator,
  parseDateTime,
  UUID,
} from "src/utils";
import { BaseAppointmentActivity } from "./base-appointment-activity.model";

export type ApiAppointmentActivity =
  | Api.AppointmentActivity
  | Api.LastAppointmentActivity;
const api = getApiDetailsDecorator<ApiAppointmentActivity>();

interface DeserializeArguments {
  site: Site;
}

export class AppointmentActivity
  extends BaseAppointmentActivity
  implements HistoryItem
{
  public constructor(
    args: Omit<ClassProperties<AppointmentActivity>, "timestamp">,
  ) {
    super(args);

    this.createdDate = args.createdDate;
    this.email = args.email;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.username = args.username;

    this.timestamp = args.activityDate;
  }

  @api() public readonly createdDate: DateTime;
  public readonly email: ActivityEmailProperty | null;
  @api() public readonly id: number;
  @api() public readonly modifiedDate: DateTime;
  @api() public readonly username: string;

  // Clone of activityDate to match HistoryItem.
  public readonly timestamp: DateTime;
}

export function deserializeAppointmentActivity(
  data: ApiAppointmentActivity,
  { site }: DeserializeArguments,
): AppointmentActivity {
  return new AppointmentActivity({
    ...deserializeSimpleAppointmentActivity(data),
    action: ActivityAction[data.action],
    activityDate: parseDateTime(data.activityDate, site),
    comments: data.comments || null,
    createdDate: parseDateTime(data.createdDate, site),
    email: ActivityEmailProperty.deserialize(data, { site }),
    id: data.id,
    modifiedDate: parseDateTime(data.modifiedDate, site),
    username: data.username || "unknown",
  });
}

export function deserializeAppointmentActivityList(
  value: readonly ApiAppointmentActivity[],
  deserializeArguments: DeserializeArguments,
): readonly AppointmentActivity[] {
  return value.map((x) =>
    deserializeAppointmentActivity(x, deserializeArguments),
  );
}

export function deserializeSimpleAppointmentActivity(
  data: Pick<ApiAppointmentActivity, "activity" | "iconName" | "reason">,
): SimpleAppointmentActivity {
  return {
    activity: data.activity,
    iconName: data.iconName || null,
    reasonCodeTitle: data.reason,
  };
}

export interface SimpleAppointmentActivity
  extends Pick<
    AppointmentActivity,
    "activity" | "iconName" | "reasonCodeTitle"
  > {}

@apiPropertyClass
class ActivityEmailProperty {
  private constructor(args: ClassProperties<ActivityEmailProperty>) {
    this.emailId = args.emailId;
    this.name = args.name;
    this.recipients = args.recipients;
    this.sentDate = args.sentDate;
    this.subject = args.subject;
  }

  @api() public readonly emailId: UUID;
  @api({ key: "emailName" }) public readonly name: string;
  @api({ key: "emailSentTo" }) public readonly recipients: readonly string[];
  @api({ key: "emailSentDate" }) public readonly sentDate: DateTime;
  @api({ key: "emailSubjectLine" }) public readonly subject: string;

  public static deserialize(
    data: ApiAppointmentActivity,
    { site }: DeserializeArguments,
  ): ActivityEmailProperty | null {
    if (
      !data.emailId ||
      !data.emailName ||
      !data.emailSentDate ||
      !data.emailSentTo ||
      !data.emailSubjectLine
    ) {
      return null;
    }

    return new ActivityEmailProperty({
      emailId: new UUID(data.emailId),
      name: data.emailName,
      recipients: data.emailSentTo.split(","),
      sentDate: parseDateTime(data.emailSentDate, site),
      subject: data.emailSubjectLine,
    });
  }
}
