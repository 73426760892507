import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { startCase } from "lodash-es";
import { Weekday, WeekdayNames } from "src/app/core/base-week.model";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { Weekdays } from "src/app/core/weekdays.model";

@Component({
  selector: "mr-days-filter[label]",
  template: `
    <mr-dropdown
      [label]="label"
      [labelHidden]="isLabelHidden"
      [errorsHidden]="areErrorsHidden"
      [items]="weekdayItems"
      [value]="dropdownValue"
      (valueChange)="selectDays($event)"
      displayProperty="name"
      valueProperty="id"
      [multiple]="true"
      placeholder="Select Days"
    ></mr-dropdown>
  `,
  styleUrls: ["./days-filter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DaysFilterComponent),
    },
  ],
})
export class DaysFilterComponent extends BaseFieldComponent<Weekdays | null> {
  public readonly weekdayItems = weekdayItems;

  @Input() public override set value(value: Weekdays | null) {
    this._value = value;
    this.dropdownValue = value
      ? [...value.getSelected()].map((weekday) => weekdayItemRecord[weekday])
      : null;
  }
  public override get value(): Weekdays | null {
    return this._value;
  }

  private _value: Weekdays | null = null;
  public dropdownValue: readonly WeekdayItem[] | null = null;

  public selectDays(value: Weekday[]): void {
    this.onChange(value.length ? new Weekdays(value) : null);
  }
}

interface WeekdayItem {
  readonly id: Weekday;
  readonly name: string;
}

function getWeekdayItemRecord(): Readonly<Record<Weekday, WeekdayItem>> {
  const record: Partial<Record<Weekday, WeekdayItem>> = {};
  for (const day of WeekdayNames) {
    record[day] = {
      id: day,
      name: startCase(day.substring(0, 3)),
    };
  }
  // WEEKDAY_NAMES has every element so we can guarantee
  // that this record is complete.
  return record as Record<Weekday, WeekdayItem>;
}

const weekdayItemRecord = getWeekdayItemRecord();
const weekdayItems: readonly WeekdayItem[] = Object.values(weekdayItemRecord);
