import { Duration } from "luxon";
import { DropdownColumnFilterValue } from "src/app/core/table";
import type { Site } from "./sites";

export enum AppointmentInterval {
  Invalid = 0,
  QuarterHour = 15,
  HalfHour = 30,
  Hour = 60,
}

export const AppointmentIntervalDurations = {
  [AppointmentInterval.Invalid]: null,
  [AppointmentInterval.QuarterHour]: Duration.fromObject({ minutes: 15 }),
  [AppointmentInterval.HalfHour]: Duration.fromObject({ minutes: 30 }),
  [AppointmentInterval.Hour]: Duration.fromObject({ minutes: 60 }),
} as const;

/** The managed type on a purchase order. */
export enum ManagedType {
  /** Type T - Over the Road/Carrier or Vendor Managed */
  TruckLoad = "T",
  /** Type B - Partner Managed */
  Backhaul = "B",
  /** Type L - Partner Managed/Contract */
  Logistics = "L",
  /** Type C - Contract or Consolidated */
  Contract = "C",
}

export interface ManagedTypeOption extends DropdownColumnFilterValue {
  readonly id: ManagedType;
  readonly name: string;
}

export const ManagedTypeOptions: readonly ManagedTypeOption[] = [
  { id: ManagedType.TruckLoad, name: "Truck Load (T)" },
  { id: ManagedType.Backhaul, name: "Backhaul (B)" },
  { id: ManagedType.Logistics, name: "Logistics (L)" },
  { id: ManagedType.Contract, name: "Contract (C)" },
];

export function getManagedTypeOptions({
  isUsingContractManagedType,
}: Site): readonly ManagedTypeOption[] {
  return isUsingContractManagedType
    ? contractManagedTypeOptions
    : logisticsManagedTypeOptions;
}
// Contract managed type replaces Logistics when enabled.
const contractManagedTypeOptions = ManagedTypeOptions.filter(
  (option) => option.id !== ManagedType.Logistics,
);
const logisticsManagedTypeOptions = ManagedTypeOptions.filter(
  (option) => option.id !== ManagedType.Contract,
);

/** The type of drop load appointments allowed by a reservation. */
export enum DropLoadAvailability {
  Mandatory = "Required",
  NotAllowed = "Disallowed",
  Optional = "Optional",
}

/** The order status on a purchase order */
export enum OrderStatus {
  Active = "Active",
  Cancelled = "Cancelled",
}

export interface OrderStatusOption extends DropdownColumnFilterValue {
  readonly id: OrderStatus;
  readonly name: string;
}

export const orderStatusDisplayLookup: Readonly<Record<OrderStatus, string>> = {
  [OrderStatus.Active]: "Active",
  [OrderStatus.Cancelled]: "Cancelled",
};

export const OrderStatusOptions: readonly OrderStatusOption[] = Object.entries(
  orderStatusDisplayLookup,
).map(([id, name]) => ({ id, name }));

export const ValidationConstants = {
  autoApproveRequestDurationStep: Duration.fromObject({ hours: 1 }),
  autoApproveRequestMax: Duration.fromObject({ hours: 99999 }),
  autoApproveRequestMin: Duration.fromObject({ hours: 0 }),
  carrierETAMinuteStep: 15,
  caseCountDefaultValue: 0,
  caseCountMax: 999999,
  caseCountMin: 0,
  closureMinuteStep: 15,
  hourlyCapacityMinuteStep: 60,
  contactEmailMaxLength: 50,
  contactNameMaxLength: 50,
  driverCDLMaxLength: 25,
  driverNameMaxLength: 51,
  helpAssistTicketResolutionDurationMax: Duration.fromObject({ hours: 99999 }),
  helpAssistTicketResolutionDurationMin: Duration.fromObject({ hours: 1 }),
  helpAssistTicketResolutionDurationStep: Duration.fromObject({ hours: 1 }),
  inboundPalletCountDefaultValue: 0,
  inboundPalletCountMax: 9999,
  inboundPalletCountMin: 0,
  inboundPalletCountStep: 1,
  loadWeightDefaultValue: 0,
  loadWeightMin: 0,
  partnerNameLengthMax: 50,
  siteInformationMaxLength: 15000,
  trailerMaxLength: 50,
  trailerTemperatureMax: 999,
  trailerTemperatureMin: -999,
  trailerTemperatureStep: 1,
  unloadDurationPerUnitMin: Duration.fromObject({ minutes: 0 }),
  warehousePalletCountDefaultValue: 0,
  warehousePalletCountMax: 9999,
  warehousePalletCountMin: 0,
  warehousePalletCountRequiredMin: 1,
  warehousePalletCountStep: 1,
} as const;

export enum PartnerAvailabilityStatus {
  Live = "Active",
  Pending = "Pending",
  Decommissioned = "Inactive",
}

export const PartnerStatusOptions: readonly DropdownColumnFilterValue[] = [
  { id: PartnerAvailabilityStatus.Live, name: "Live" },
  { id: PartnerAvailabilityStatus.Pending, name: "Pending" },
  { id: PartnerAvailabilityStatus.Decommissioned, name: "Decommissioned" },
];

export enum SiteAvailabilityStatus {
  Live = "Active",
  LivePartnerOnly = "LivePartnerOnly",
  Pending = "Pending",
  Decommissioned = "Inactive",
}

export const SiteStatusOptions: readonly DropdownColumnFilterValue[] = [
  { id: SiteAvailabilityStatus.Live, name: "Live" },
  { id: SiteAvailabilityStatus.LivePartnerOnly, name: "Live – Partner Only" },
  { id: SiteAvailabilityStatus.Pending, name: "Pending" },
  { id: SiteAvailabilityStatus.Decommissioned, name: "Decommissioned" },
];
