<mr-field-container
  #container
  [customFieldId]="fieldId"
  [label]="label"
  [labelHidden]="isLabelHidden"
  [description]="description"
  [errorsHidden]="areErrorsHidden"
  [kind]="labelKind"
>
  <ng-select
    *ngIf="hasItems; else staticOptions"
    role="listbox"
    [labelForId]="container.fieldId"
    [attr.aria-labelledby]="isLabelHidden ? null : container.labelId"
    [attr.title]="isLabelHidden ? label : null"
    [attr.aria-label]="isLabelHidden ? label : null"
    [attr.aria-describedby]="description ? container.descriptionId : null"
    [disabled]="isReadonly || isDisabled"
    [multiple]="isMultiple"
    [maxSelectedItems]="maxSelectedItems"
    [clearable]="!disableClearing"
    [closeOnSelect]="!isMultiple"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [bindValue]="valueProperty"
    [bindLabel]="displayProperty || valueProperty"
    [compareWith]="compareWith"
    [items]="passthroughItems"
    [loading]="!passthroughItems"
    [typeahead]="searchable ? searchTermChanges : undefined"
    [ngModel]="value"
    (ngModelChange)="propagateChange($event)"
    (blur)="onBlur()"
  ></ng-select>

  <!--
    TODO: Figure out how to exclude or programmatically set ng-select's item
    property so we don't have to duplicate this for the static, ng-option case.
  -->
  <ng-template #staticOptions>
    <ng-select
      *ngIf="selectedDisplayValue; else staticLabel"
      role="listbox"
      [labelForId]="container.fieldId"
      [disabled]="isReadonly || isDisabled"
      [attr.aria-labelledby]="isLabelHidden ? null : container.labelId"
      [attr.title]="isLabelHidden ? label : null"
      [attr.aria-label]="isLabelHidden ? label : null"
      [attr.aria-describedby]="description ? container.descriptionId : null"
      [multiple]="isMultiple"
      [maxSelectedItems]="maxSelectedItems"
      [clearable]="!disableClearing"
      [closeOnSelect]="!isMultiple"
      [searchable]="searchable"
      [placeholder]="placeholder"
      [compareWith]="compareWith"
      [typeahead]="searchable ? searchTermChanges : undefined"
      [ngModel]="value"
      (ngModelChange)="propagateChange($event)"
      (blur)="onBlur()"
    >
      <ng-template ng-label-tmp>
        {{ selectedDisplayValue }}
      </ng-template>
      <ng-option
        *ngFor="let option of options"
        [value]="option.value"
        [disabled]="option.disabled"
      >
        <span [class.primary]="option.primary">
          {{ option.content }}
        </span>
      </ng-option>
    </ng-select>
  </ng-template>

  <ng-template #staticLabel>
    <ng-select
      role="listbox"
      [labelForId]="container.fieldId"
      [disabled]="isReadonly || isDisabled"
      [attr.aria-labelledby]="isLabelHidden ? null : container.labelId"
      [attr.title]="isLabelHidden ? label : null"
      [attr.aria-label]="isLabelHidden ? label : null"
      [attr.aria-describedby]="description ? container.descriptionId : null"
      [multiple]="isMultiple"
      [maxSelectedItems]="maxSelectedItems"
      [clearable]="!disableClearing"
      [closeOnSelect]="!isMultiple"
      [searchable]="searchable"
      [placeholder]="placeholder"
      [compareWith]="compareWith"
      [typeahead]="searchable ? searchTermChanges : undefined"
      [ngModel]="value"
      (ngModelChange)="propagateChange($event)"
      (blur)="onBlur()"
    >
      <ng-option
        *ngFor="let option of options"
        [value]="option.value"
        [disabled]="option.disabled"
      >
        <span [class.primary]="option.primary">
          {{ option.content }}
        </span>
      </ng-option>
    </ng-select>
  </ng-template>
</mr-field-container>
