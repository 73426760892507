<ul>
  <li @modalSlideInOut *ngFor="let alert of alerts" role="alert">
    <ng-container [ngSwitch]="alert.status">
      <img
        *ngSwitchCase="'success'"
        src="/assets/images/alert-success.svg"
        alt="Success icon"
      />
      <img
        *ngSwitchCase="'error'"
        src="/assets/images/alert-error.svg"
        alt="Error icon"
      />
      <img
        *ngSwitchCase="'warn'"
        src="/assets/images/alert-warning.svg"
        alt="Warning icon"
      />
      <img
        *ngSwitchCase="'info'"
        src="/assets/images/alert-info.svg"
        alt="Info icon"
      />
    </ng-container>

    <section class="alert-text">
      <h1 class="alert-title">{{ alert.title }}</h1>
      <p *ngIf="alert.message" class="alert-message">{{ alert.message }}</p>
    </section>

    <button (click)="remove(alert)">Close</button>
  </li>
</ul>
