import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Carrier>();

export class HelpAssistTicketCarrier {
  private constructor(
    args: Omit<ClassProperties<HelpAssistTicketCarrier>, "carrierId">,
  ) {
    this.id = args.id;
    this.name = args.name;
    this.carrierId = args.id;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;
  public readonly carrierId: number;

  public static deserialize(
    apiModel: ApiTicketCarrier,
  ): HelpAssistTicketCarrier {
    return new HelpAssistTicketCarrier({
      id: apiModel.id,
      name: apiModel.name,
    });
  }
}

export type ApiTicketCarrier = Pick<Api.Carrier, "id" | "name">;
