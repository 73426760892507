import { Duration } from "luxon";
import { Day } from "src/app/core/day.model";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { UUID } from "src/utils";

export interface ClearColumnFilter extends Omit<BaseColumnFilter, "type"> {
  readonly type: TableColumnFilterType | undefined;
  readonly isCleared: true;
  readonly value: null;
}

export type ColumnFilter =
  | BooleanColumnFilter
  | DateRangeColumnFilter
  | DayRangeColumnFilter
  | DaysColumnFilter
  | DropdownColumnFilter
  | DurationRangeColumnFilter
  | MultiSelectColumnFilter
  | NumberColumnFilter
  | NumberIdentifierColumnFilter
  | NumberRangeColumnFilter
  | TextColumnFilter
  | TimeColumnFilter;

interface BaseColumnFilter {
  readonly type: TableColumnFilterType;
  readonly key: string;
  readonly isCleared: boolean;
}

interface TimeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Time;
  readonly value: TimeOfDay;
}

interface TextColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Text;
  readonly value: string;
}

interface NumberRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.NumberRange;
  readonly value: {
    readonly min: number | null;
    readonly max: number | null;
  };
}

interface BooleanColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Boolean;
  readonly value: boolean;
}

interface DateRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.DateRange;
  readonly value: {
    readonly start: Day;
    readonly end: Day;
  };
}
interface DayRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.DayRange;
  readonly value: {
    readonly start: Day;
    readonly end: Day;
  };
}
interface NumberColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Number;
  readonly value: number;
}

interface NumberIdentifierColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.NumberIdentifier;
  readonly value: string;
}

interface DropdownColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Dropdown;
  readonly value: DropdownColumnFilterValue["id"];
}

interface MultiSelectColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.MultiSelect;
  readonly value: ReadonlyArray<DropdownColumnFilterValue["id"]>;
}

interface DaysColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Days;
  readonly value: Weekdays;
}

interface DurationRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.DurationRange;
  readonly value: {
    readonly min: Duration | null;
    readonly max: Duration | null;
  };
}

export interface DropdownColumnFilterValue {
  readonly id: number | string | UUID | null;
  readonly name: string;
}

export enum TableColumnFilterType {
  Boolean = "boolean",
  /** A start and end date with a time component from midnight to midnight. */
  DateRange = "date-range",
  /** A start and end date with no time component. */
  DayRange = "day-range",
  /** A set of weekdays (Monday, Tuesday, etc). */
  Days = "days",
  Dropdown = "dropdown",
  DurationRange = "duration-range",
  MultiSelect = "multi-select",
  Number = "number",
  NumberIdentifier = "number-identifier",
  NumberRange = "number-range",
  Text = "text",
  Time = "time",
}
