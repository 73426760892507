import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertsService } from "src/app/core/alerts.service";
import { ApiError } from "src/app/core/api-error";
import { UserService } from "src/app/core/auth";
import { environment } from "src/environments";

@Injectable()
export class NetworkResponseErrorInterceptor implements HttpInterceptor {
  public constructor(
    private readonly user: UserService,
    private readonly alerts: AlertsService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          return throwError(() => error);
        }

        const apiError = ApiError.create(error);

        if (apiError.status === 401) {
          // Logout whenever the request is to one of the authenticated APIs
          // that is provided an auth token from the UI via MSAL.

          if (
            request.url.startsWith(environment.apiUrls.mr.href) ||
            request.url.startsWith(environment.apiUrls.sites.href)
          ) {
            this.user.logout();
            return EMPTY;
          }

          return throwError(() => apiError);
        }

        // Skip the alert for "Not Found" errors so that an appropriate splash
        // page can be shown to the user as needed.
        if (apiError.status === 404) {
          return throwError(() => apiError);
        }

        // Only alert on GETs so that other methods can handle their own errors
        // as needed.
        if (request.method === "GET") {
          this.alerts.error({
            title: `Request Error (${apiError.status})`,
            message:
              "The page failed to load correctly. Please refresh to try again.",
            error,
          });
        }

        return throwError(() => apiError);
      }),
    );
  }
}
