import { Component, Input } from "@angular/core";

@Component({
  selector: "mr-details-list",
  template: `
    <dl [class.align-right]="alignTitle === 'right'">
      <ng-content select="mr-details-item"></ng-content>
    </dl>
  `,
  styles: [
    `
      :host {
        display: block;
        min-width: 10rem;
        margin-bottom: 1rem;
      }
      dl {
        margin: 0;

        ::ng-deep > *:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    `,
  ],
})
export class DetailsListComponent {
  // These are used for styling in mr-details-item.
  @Input() public kind: "grid" | "list" = "list";
  @Input() public split: "equal" | "fifths" | "thirds" | "none" = "equal";
  @Input() public alignTitle?: string;
}
