import { getNestedApiDetails } from "src/utils/decorators";

export function getODataPathFromTableKey(
  model: ConstructorLike<unknown>,
  key: string,
): string {
  // Remove the collection delimiter since we only care about the individual
  // property names for lookup purposes.
  return getNestedApiDetails(model, key.replace("[]", "").split("."))
    .map((details, index, array) => {
      if (index === array.length - 1) {
        // The last key should always be a regular key, not a navigation property.
        return details.key;
      }
      return details.navigationProperty || details.key;
    })
    .join("/");
}
