import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { UnloaderType } from "src/app/partner/appointments/base-appointment.model";
import { getApiDetailsDecorator } from "src/utils";
import { HelpAssistTicketCarrier } from "../help-assist-ticket-carrier.model";
import { HelpAssistTicketDoorGroup } from "../help-assist-ticket-door-group.model";
import { HelpAssistTicketDoor } from "../help-assist-ticket-door.model";
import { BaseHelpAssistTicketAppointmentOrder } from "../ticket-order/base-help-assist-ticket-appointment-order.model";
import { GlobalHelpAssistTicketAppointmentOrder } from "./help-assist-ticket-appointment-draft.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketAppointmentDraft>();

export abstract class BaseHelpAssistAppointmentDraftTicket {
  protected constructor(
    args: ClassProperties<BaseHelpAssistAppointmentDraftTicket>,
  ) {
    this.carrier = args.carrier;
    this.deliveryCarrier = args.deliveryCarrier;
    this.door = args.door;
    this.doorGroup = args.doorGroup;
    this.duration = args.duration;
    this.idealAppointmentDate = args.idealAppointmentDate;
    this.idealStartTime = args.idealStartTime;
    this.isDropLoad = args.isDropLoad;
    this.isIntermodal = args.isIntermodal;
    this.loadWeight = args.loadWeight;
    this.mainOrderNumber = args.mainOrderNumber;
    this.mainTicketOrderId = args.mainTicketOrderId;
    this.notificationList = args.notificationList;
    this.scheduledArrivalDay = args.scheduledArrivalDay;
    this.site = args.site;
    this.slotStartTime = args.slotStartTime;
    this.totalWarehousePalletCountOverride =
      args.totalWarehousePalletCountOverride;
    this.unknownDeliveryCarrierName = args.unknownDeliveryCarrierName;
    this.unloader = args.unloader;
  }

  @api({
    key: "carrierId",
    navigationProperty: "carrier",
    uiModel: HelpAssistTicketCarrier,
  })
  public readonly carrier: HelpAssistTicketCarrier | null;
  @api({
    key: "deliveryCarrierRecordID",
    navigationProperty: "deliveryCarrierRecord",
    uiModel: HelpAssistTicketCarrier,
  })
  public readonly deliveryCarrier: HelpAssistTicketCarrier | null;
  @api({
    key: "doorGroupId",
    navigationProperty: "doorGroup",
    uiModel: HelpAssistTicketDoorGroup,
  })
  public readonly doorGroup: HelpAssistTicketDoorGroup | null;
  @api({
    key: "doorId",
    navigationProperty: "door",
    uiModel: HelpAssistTicketDoor,
  })
  public readonly door: HelpAssistTicketDoor | null;
  @api() public readonly duration: number | null;
  @api() public readonly idealAppointmentDate: Day | null;
  @api() public readonly idealStartTime: TimeOfDay | null;
  @api({ key: "isDropload" }) public readonly isDropLoad: boolean | null;
  @api() public readonly isIntermodal: boolean | null;
  @api() public readonly loadWeight: number | null;
  @api() public readonly mainOrderNumber: string | null;
  @api() public readonly mainTicketOrderId: number | null;
  @api() public readonly notificationList: string | null;
  @api({ key: "schedule" }) public readonly scheduledArrivalDay: Day | null;
  @api() public readonly slotStartTime: DateTime | null;
  @api({ key: "appointmentPalletOverride" })
  public readonly totalWarehousePalletCountOverride: number | null;
  @api({ key: "deliveryCarrier" })
  public readonly unknownDeliveryCarrierName: string | null;
  @api() public readonly unloader: UnloaderType | null;

  public readonly site: Site;

  protected getMainPurchaseOrderVendorDisplayName(
    orders: GlobalHelpAssistTicketAppointmentOrder[],
  ): string | null {
    return (
      orders.find(({ id }) => id === this.mainTicketOrderId)?.vendor
        ?.displayName ?? null
    );
  }

  protected getEffectiveTotalWarehousePalletCount(
    orders: BaseHelpAssistTicketAppointmentOrder[] | null,
  ): number | null {
    const totalWarehousePalletCount =
      orders?.reduce((total, order) => total + order.warehousePalletCount, 0) ??
      null;

    return this.site.isTotalWarehousePalletCountOverrideEnabled
      ? this.totalWarehousePalletCountOverride ?? totalWarehousePalletCount
      : totalWarehousePalletCount;
  }
}
