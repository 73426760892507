import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { parseDateTime } from "src/utils";

export class AssociatedTicketComment {
  public constructor(args: ClassProperties<AssociatedTicketComment>) {
    this.comment = args.comment;
    this.createdOn = args.createdOn;
    this.userDisplayName = args.userDisplayName;
  }

  public readonly comment: string;
  public readonly createdOn: DateTime;
  public readonly userDisplayName: string;

  public static deserialize(
    data: Api.AssociatedTicketComment,
  ): AssociatedTicketComment {
    return new AssociatedTicketComment({
      comment: data.comment,
      createdOn: parseDateTime(data.createdOn),
      userDisplayName: data.userDisplayName,
    });
  }

  public static deserializeList(
    data: readonly Api.AssociatedTicketComment[],
  ): AssociatedTicketComment[] {
    return data.map((comment) => AssociatedTicketComment.deserialize(comment));
  }
}
