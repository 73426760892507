import {
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  Output,
  QueryList,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { BooleanAttribute, parseBooleanAttribute } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";
import { DropdownItemComponent } from "./dropdown-item.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
  FieldContainerVariant,
} from "./field-container.component";

@UntilDestroy()
@Component({
  selector: "mr-dropdown[label]",
  templateUrl: "dropdown.component.html",
  styleUrls: ["dropdown.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
})
export class DropdownComponent<T>
  extends BaseFieldComponent<T | T[]>
  implements ControlValueAccessor
{
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
    this.searchTermChanges
      .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term) => {
        this.searchTermChange.emit(term?.trim());
      });
  }

  @Input() public set multiple(value: BooleanAttribute) {
    this.isMultiple = parseBooleanAttribute(value);
  }

  @Input() public set items(value: readonly T[] | null) {
    this.hasItems = true;
    this._passthroughItems = value ? [...value] : undefined;
  }

  @Input() public valueProperty?: string;
  @Input() public displayProperty?: string;
  @Input() public placeholder = "";
  @Input() public maxSelectedItems?: number;
  @Input() public compareWith = defaultCompareWith;
  @Input() public disableClearing = false;
  @Input() public searchable = false;
  @Input() public kind: "form" | "button" | "tiled" | "view-control" = "form";
  @Input() public selectedDisplayValue?: string;
  @Input() public isDisplayedInFullHeight = false;

  @Input() public set labelKind(value: FieldContainerVariant) {
    this.#labelKind = value;
  }
  public get labelKind(): FieldContainerVariant {
    return this.#labelKind ?? (this.kind === "tiled" ? "tiled" : "form");
  }
  #labelKind?: FieldContainerVariant;

  @Output() public readonly searchTermChange = new EventEmitter<string>();

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }
  @HostBinding("class.form") public get isFormKind(): boolean {
    return this.kind === "form";
  }
  @HostBinding("class.button") public get isButtonKind(): boolean {
    return this.kind === "button";
  }
  @HostBinding("class.view-control") public get isViewControlKind(): boolean {
    return this.kind === "view-control";
  }

  @HostBinding("class.show-full-height")
  public get isFullHeightShown(): boolean {
    return this.isDisplayedInFullHeight;
  }

  @ContentChildren(DropdownItemComponent)
  public readonly options!: QueryList<DropdownItemComponent<T>>;

  public isMultiple = false;
  public hasItems = false;

  // Subject required by base library component (ng-select).
  // eslint-disable-next-line rxjs/no-exposed-subjects
  public readonly searchTermChanges = new Subject<string>();

  /**
   * Used to pass through the provided `items`, if any, to the underlying
   * component library.
   *
   * This is needed because the ng-select library doesn't accept readonly arrays
   * but we need to accept them as many of our collections are readonly.
   */
  public get passthroughItems(): T[] | undefined {
    return this._passthroughItems;
  }
  private _passthroughItems?: T[];
}

function hasId(value: unknown): value is { id: unknown } {
  return typeof value === "object" && value !== null && "id" in value;
}

// TODO: figure out how to type this properly (this type is used as the
// interface for the `compareWith` property which has an ill-defined interface).
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function defaultCompareWith(option: any, selected: any): boolean {
  return (
    option === selected ||
    (hasId(option) && hasId(selected) && option.id === selected.id) ||
    (hasId(option) && option.id === selected)
  );
}
