import { Pipe, PipeTransform } from "@angular/core";
import {
  AppointmentLabelType,
  ScheduleAppointmentDisplayFieldKey,
} from "src/app/core/sites";

@Pipe({ name: "appointmentFieldTitle" })
export class AppointmentFieldTitlePipe implements PipeTransform {
  public transform(value: Key | AppointmentLabelType): string {
    const key = mapToAppointmentKey(value);
    return lookup[key];
  }
}

function mapToAppointmentKey(value: Key | AppointmentLabelType): Key {
  // Expand the map type to allow returning undefined for `Key` values.
  const map: Partial<Readonly<Record<AppointmentLabelType | Key, Key>>> =
    appointmentLabelTypeToKey;
  // The map is guaranteed to include all appointment label members, so if it's
  // not found, the value must be a `Key` type.
  return map[value] ?? (value as Exclude<typeof value, AppointmentLabelType>);
}

const appointmentLabelTypeToKey: Readonly<Record<AppointmentLabelType, Key>> = {
  [AppointmentLabelType.AppointmentNumber]: "confirmationNumber",
  [AppointmentLabelType.CarrierOfRecord]: "carrierOfRecord",
  [AppointmentLabelType.LoadNumber]: "loadNumber",
  [AppointmentLabelType.ManagedTypes]: "managedType",
  [AppointmentLabelType.PrimaryVendor]: "vendor",
  [AppointmentLabelType.PurchaseOrderNumber]: "mainPurchaseOrder",
};

const lookup: Readonly<Record<Key, string>> = {
  appointmentApprovalStatus: "Help Assist Status",
  carrierETA: "Carrier ETA",
  carrierOfRecord: "Carrier of Record",
  comments: "Comments",
  confirmationNumber: "Appointment",
  consigneeCode: "Consignee Code",
  contactEmail: "Contact Email",
  contactName: "Contact Name",
  contactPhone: "Contact Phone",
  cube: "Cube",
  date: "Arrival Date",
  deliveryCarrier: "Delivery Carrier",
  doors: "Doors",
  driverCDLNumber: "Driver's CDL",
  driverName: "Driver",
  driverPhoneNumber: "Driver's Phone Number",
  dueDate: "Due Date",
  effectiveDeliveryCarrierName: "Delivery Carrier",
  freightBasis: "Freight Basis",
  gateInTimestamp: "Gate In",
  gateOutTimestamp: "Gate Out",
  isBackhaul: "Backhaul",
  isCFM: "CFM Appt",
  isDropLoad: "Drop Load",
  isIntermodal: "Intermodal",
  isPimTagged: "PIM Tag",
  isUnscheduledSameDay: "Unscheduled Arrival",
  lastAppointmentActivity: "Last Appointment Activity",
  lateGateInFlag: "Late Gate In",
  lateGateOutFlag: "Late Gate Out",
  loadNumber: "Load Number",
  loadType: "Load Type",
  mainPurchaseOrder: "Main PO",
  mainPurchaseOrderSuggestedDoor: "Suggested Door",
  managedType: "Managed Types",
  notificationList: "Notification List",
  offComplexTimestamp: "Off Complex",
  onComplexTimestamp: "On Complex",
  orders: "Purchase Orders",
  originalAppointmentDate: "Original Date",
  priority: "Priority",
  scheduledArrivalTime: "Appointment Date/Time",
  scheduledDuration: "Duration",
  site: "Site",
  siteCustomName: "Site Display Name",
  siteDefaultName: "Site Name",
  slotWasReserved: "Reservation",
  status: "Status",
  suggestedDoor: "Suggested Door",
  tempZone: "Zone (Temp Zone)",
  time: "Time",
  totalCaseCount: "Cases",
  totalLoadWeight: "Load Weight",
  totalWarehousePalletCount: "Warehouse Pallets",
  totalWarehousePalletCountOverride: "Appointment Pallet Override",
  trailer: "Trailer #",
  trailerTemperatureActual: "Trailer's Actual Temp",
  trailerTemperatureSet: "Trailer's Set Temp",
  unknownDeliveryCarrierName: "Unknown Delivery Carrier",
  unloadEndTimestamp: "Unload End",
  unloader: "Unloader",
  unloadStartTimestamp: "Unload Start",
  vendor: "Vendor",
  vendorNumber: "Vendor #",
  yardLocation: "Yard Location",
};

type Key =
  | ScheduleAppointmentDisplayFieldKey
  | "appointmentApprovalStatus"
  | "comments"
  | "confirmationNumber"
  | "contactEmail"
  | "contactName"
  | "contactPhone"
  | "date"
  | "deliveryCarrier"
  | "doors"
  | "isBackhaul"
  | "isCFM"
  | "lastAppointmentActivity"
  | "mainPurchaseOrderSuggestedDoor"
  | "notificationList"
  | "orders"
  | "site"
  | "siteCustomName"
  | "siteDefaultName"
  | "status"
  | "time"
  | "totalWarehousePalletCountOverride"
  | "unknownDeliveryCarrierName"
  | "vendorNumber";
