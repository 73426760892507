import { Directive, Input, TemplateRef } from "@angular/core";
import { isNil } from "lodash-es";
import { Subject } from "rxjs";
import { DurationScale } from "src/app/core/pipes/duration.pipe";
import {
  ColumnFilter,
  DropdownColumnFilterValue,
  TableColumnFilterType,
} from "./table-column-filter.model";
import { TableComponent } from "./table.component";

@Directive({
  selector: "[mrTableItem]",
})
export class TableItemDirective<T> {
  public constructor(public readonly template: TemplateRef<Context<T>>) {}

  @Input() public set mrTableItemKey(value: string) {
    this.key = value;

    // Set the UI model key value initially to the filter key if it has no custom value
    if (!this.uiModelKey) {
      this.uiModelKey = this.key;
    }
  }
  public key!: string;

  @Input() public set mrTableItemUiModelKey(value: string) {
    this.uiModelKey = value;
  }
  public uiModelKey!: string;

  // Used as a reference for the template context type guard below to associate
  // the table's item type with this directive for better type safety.
  @Input() public mrTableItemTable?: TableComponent<T>;

  @Input() public set mrTableItemHeader(value: string) {
    this.header = value;
  }
  public header!: string;

  @Input() public set mrTableItemHeaderTemplate(value: TemplateRef<unknown>) {
    this.headerTemplate = value;
  }
  public headerTemplate?: TemplateRef<unknown>;

  @Input() public set mrTableItemHideHeaderText(value: boolean) {
    this.hideHeaderText = value;
  }
  public hideHeaderText!: boolean;

  @Input() public set mrTableItemFilterTrueLabel(value: string) {
    this.filterTrueLabel = value;
  }
  public filterTrueLabel = "Yes";

  @Input() public set mrTableItemFilterFalseLabel(value: string) {
    this.filterFalseLabel = value;
  }
  public filterFalseLabel = "No";

  @Input() public set mrTableItemFilterDropdownData(
    value: readonly DropdownColumnFilterValue[] | null,
  ) {
    this.filterDropdownData = value ?? undefined;
  }
  public filterDropdownData?: readonly DropdownColumnFilterValue[];

  @Input() public set mrTableItemFilterDurationScale(value: DurationScale) {
    this.filterDurationScale = value;
  }
  public filterDurationScale: DurationScale = "minutes";

  // Structural directives don't cleanly support `@Output` properties (see:
  // https://github.com/angular/angular/issues/12121), so we have to use a
  // `Subject` to signal an "output". Otherwise, we could use a callback
  // function as an input, but that would require pre-binding the callback
  // first. A public `Subject` is thus more convenient here.
  // eslint-disable-next-line rxjs/no-exposed-subjects
  @Input() public set mrTableItemFilterDropdownSearchNotifier(
    value: Subject<string>,
  ) {
    this.filterDropdownSearchNotifier = value;
  }
  // eslint-disable-next-line rxjs/no-exposed-subjects
  public filterDropdownSearchNotifier?: Subject<string>;

  @Input() public set mrTableItemFilterType(value: TableColumnFilterType) {
    this.filterType = value;
  }
  public filterType?: TableColumnFilterType;

  @Input() public set mrTableItemFilter(value: ColumnFilter) {
    this.filter = value;
  }
  public filter?: ColumnFilter;

  @Input() public set mrTableItemDefaultHidden(value: boolean) {
    this.hidden = value;
  }
  public hidden = false;

  @Input() public set mrTableItemIsPermanent(value: boolean) {
    this.isPermanent = value;
  }
  public isPermanent = false;

  @Input() public set mrTableItemIsFixed(value: boolean) {
    this.isFixed = value;
  }
  public isFixed = false;

  @Input() public set mrTableItemSortOn(value: string | false) {
    if (value === false) {
      this.sortingDisabled = true;
    } else {
      this._sortOn = value;
    }
  }
  public get sortOn(): string {
    return this._sortOn || this.key;
  }
  private _sortOn?: string;

  private sortingDisabled = false;

  public get isSortable(): boolean {
    return this.hasFilter && !this.sortingDisabled;
  }

  public get hasFilter(): boolean {
    return !isNil(this.filterType);
  }

  public static ngTemplateContextGuard<T>(
    _directive: TableItemDirective<T>,
    _context: unknown,
  ): _context is Context<T> {
    return true;
  }
}

interface Context<T> {
  $implicit: T;
  index: number;
}
