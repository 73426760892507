import { environment } from "src/environments";
import { getODataPathFromTableKey } from "./get-odata-path-from-table-key";
import { SortOrder } from "./miscellaneous";
import { ODataResourceUrl } from "./resource-url";

export interface ColumnSort {
  key: string;
  order: SortOrder;
}

export function setSortOnResource<Resource extends ODataResourceUrl>(
  resource: Resource,
  model: ConstructorLike<unknown>,
  sort: string | ColumnSort,
): Resource {
  const { key, order } = normalizeColumnSort(sort);
  let path: string;

  try {
    path = getODataPathFromTableKey(model, key);
  } catch (error) {
    if (!environment.isProductionBuild) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // Ignore unrecognized keys. See #23677
    return resource;
  }

  return resource.orderBy(path, order);
}

export function normalizeColumnSort(key: string | ColumnSort): ColumnSort {
  return typeof key === "string" ? { key, order: SortOrder.Ascending } : key;
}
