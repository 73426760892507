import {
  ManagedReceivingGlobalApi as GlobalApi,
  ManagedReceivingPartnerApi as PartnerApi,
} from "@capstone/mock-api";
import { CapstonePartner } from "src/app/core/sites";
import { Carrier } from "src/app/partner/global/carriers";
import { Partner } from "src/app/partner/global/partner.model";
import { ODataOptions, ODataResourceUrl } from "./odata-resource-url";

export function createGlobalResourceUrl<
  ResourceName extends StringKeys<GlobalApi.Resources>,
>(
  resourceName: ResourceName,
  options?: Partial<ODataOptions>,
): ODataResourceUrl<GlobalApi.Resources[ResourceName], ResourceName> {
  return new ODataResourceUrl<GlobalApi.Resources[ResourceName], ResourceName>(
    resourceName,
    options,
  ).prependPath("common");
}

/**
 * Gets the base type of the resource referenced by an ODataResourceUrl object.
 */
export type GlobalResourceModel<T> = T extends ODataResourceUrl<infer Resource>
  ? Resource
  : never;

/**
 * Creates a `GlobalCarrierResource` for representing the `ODataResourceUrl` that must
 * be bound to a particular carrier.
 * @param resourceName The name of the resource to get the URL of.
 * @param options Additional `ResourceUrl` options.
 */
export function createGlobalCarrierResourceUrl<
  ResourceName extends StringKeys<GlobalApi.CarrierResources>,
>(
  resourceName: ResourceName,
  options?: Partial<ODataOptions>,
): GlobalCarrierResource<
  GlobalApi.CarrierResources[ResourceName],
  ResourceName
> {
  return getGlobalCarrierResource(new ODataResourceUrl(resourceName, options));
}

function getGlobalCarrierResource<Resource, ResourceName>(
  resource: ODataResourceUrl<Resource, ResourceName>,
): GlobalCarrierResource<Resource, ResourceName> {
  return {
    get: (carrier) => resource.prependPath(`common/carriers/${carrier.id}`),
    modify: (modifier) => getGlobalCarrierResource(modifier(resource)),
  };
}

/**
 * An object representing a partially resolved `ODataResourceUrl` for a resource
 * which must be bound to a particular carrier.
 *
 * Generally, this binding will happen in a service _after_ the resource URL
 * reference is created in the model. This wrapper gives us the ability to delay
 * the return of the final URL until the `Carrier` is available while still
 * allowing the base resource to be modified, for instance for adding filters.
 */
export interface GlobalCarrierResource<Resource, ResourceName> {
  /**
   * Returns the final, concrete `ODataResourceUrl`, bound to the given carrier.
   * @param carrier The Carrier to select the resource from.
   */
  get(carrier: Carrier): ODataResourceUrl<Resource, ResourceName>;
  /**
   * Modifies the existing resource, for instance to add extra filters or expand
   * fields. This will return a new `GlobalCarrierResource` for the modified resource.
   * @param modifier A function for mapping between the original resource and
   * the new, modified version, returning the updated `GlobalCarrierResource`.
   */
  modify<NewResource>(
    modifier: GlobalCarrierResourceModifier<
      Resource,
      NewResource,
      ResourceName
    >,
  ): GlobalCarrierResource<NewResource, ResourceName>;
}

type GlobalCarrierResourceModifier<Resource, NewResource, ResourceName> = (
  resource: ODataResourceUrl<Resource, ResourceName>,
) => ODataResourceUrl<NewResource, ResourceName>;

/**
 * Gets the base type of the resource referenced by a GlobalCarrierResource object.
 */
export type GlobalCarrierResourceModel<T> = T extends GlobalCarrierResource<
  infer Resource,
  unknown
>
  ? Resource
  : never;

/**
 * Creates a `PartnerResource` for representing the `ODataResourceUrl` that must
 * be bound to a particular partner.
 * @param resourceName The name of the resource to get the URL of.
 * @param options Additional `ResourceUrl` options.
 */
export function createPartnerResourceUrl<
  ResourceName extends StringKeys<PartnerApi.Resources>,
>(
  resourceName: ResourceName,
  options?: Partial<ODataOptions>,
): PartnerResource<PartnerApi.Resources[ResourceName], ResourceName> {
  return getPartnerResource(new ODataResourceUrl(resourceName, options));
}

/**
 * Gets the OData root service resource URL for the currently selected
 * partner. This is useful for making partner-specific OData batch updates.
 *
 * **Example resource URL:**
 *
 * `https://dev-mr.capstonelogistics.com/partner/85a4317d-582a-e911-a960-000d3a37a767`
 */
export const partnerRootResourceUrl = getPartnerResource(
  new ODataResourceUrl<void>(""),
);

function getPartnerResource<Resource, ResourceName>(
  resource: ODataResourceUrl<Resource, ResourceName>,
): PartnerResource<Resource, ResourceName> {
  return {
    get: (partner) => resource.prependPath(`partner/${partner.key.toString()}`),
    modify: (modifier) => getPartnerResource(modifier(resource)),
  };
}

/**
 * An object representing a partially resolved `ODataResourceUrl` for a resource
 * which must be bound to a particular partner.
 *
 * Generally, this binding will happen in a service _after_ the resource URL
 * reference is created in the model. This wrapper gives us the ability to delay
 * the return of the final URL until the `Partner` is available while still
 * allowing the base resource to be modified, for instance for adding filters.
 */
export interface PartnerResource<Resource, ResourceName> {
  /**
   * Returns the final, concrete `ODataResourceUrl`, bound to the given partner.
   * @param partner The Partner to select the resource from.
   */
  get(
    partner: Partner | CapstonePartner,
  ): ODataResourceUrl<Resource, ResourceName>;
  /**
   * Modifies the existing resource, for instance to add extra filters or expand
   * fields. This will return a new `PartnerResource` for the modified resource.
   * @param modifier A function for mapping between the original resource and
   * the new, modified version, returning the updated `PartnerResource`.
   */
  modify<NewResource>(
    modifier: PartnerResourceModifier<Resource, NewResource, ResourceName>,
  ): PartnerResource<NewResource, ResourceName>;
}

type PartnerResourceModifier<Resource, NewResource, ResourceName> = (
  resource: ODataResourceUrl<Resource, ResourceName>,
) => ODataResourceUrl<NewResource, ResourceName>;

/**
 * Gets the base type of the resource referenced by a PartnerResource object.
 */
export type PartnerResourceModel<T> = T extends PartnerResource<
  infer Resource,
  unknown
>
  ? Resource
  : never;
