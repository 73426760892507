import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Dock>();

export class HelpAssistTicketDock {
  private constructor(args: ClassProperties<HelpAssistTicketDock>) {
    this.id = args.id;
    this.name = args.name;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;

  public static deserialize(apiModel: ApiTicketDock): HelpAssistTicketDock {
    return new HelpAssistTicketDock({
      id: apiModel.id,
      name: apiModel.name,
    });
  }
}

export type ApiTicketDock = Pick<Api.Dock, "id" | "name">;
