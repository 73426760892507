import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AlertsService } from "src/app/core/alerts.service";
import { FormComponent } from "src/app/core/form.component";
import { Modal } from "src/app/core/modal.service";

export interface Column {
  readonly header: string;
  readonly isFixed: boolean;
  readonly isPermanent: boolean;
  readonly key: string;
}

interface ColumnManagementContext {
  readonly allColumns: readonly Column[];
  readonly selectedColumns: readonly Column[];
}

@Component({
  selector: "mr-column-management-modal",
  template: `
    <mr-modal header="Manage Columns">
      <p>Drag and drop and click arrows to rearrange columns</p>

      <mr-sortable-list
        [options]="fixedColumns"
        [selected]="fixedColumns"
        disabled
      ></mr-sortable-list>

      <form [formGroup]="form">
        <mr-sortable-list
          formControlName="columns"
          [options]="options"
          [selected]="selected"
        ></mr-sortable-list>
      </form>

      <ng-template mrModalActions>
        <mr-button (click)="save()" [disabled]="!canSubmit"
          >Save Changes</mr-button
        >
        <mr-button kind="cancel" (click)="modal.choose(undefined)"
          >Cancel</mr-button
        >
      </ng-template>
    </mr-modal>
  `,
  providers: [Modal],
})
export class ColumnManagementModalComponent extends FormComponent<{
  columns: readonly string[];
}> {
  public constructor(
    public readonly modal: Modal<
      ColumnManagementContext,
      readonly string[] | undefined
    >,
    private readonly alerts: AlertsService,
    formBuilder: FormBuilder,
  ) {
    super(formBuilder, { columns: [null] });
  }

  public readonly options = this.modal.context.allColumns
    .filter((column) => !column.isFixed)
    .map(({ header, ...column }) => ({ ...column, value: header }));
  public readonly selected = this.modal.context.selectedColumns
    .filter((column) => !column.isFixed)
    .map(({ header, ...column }) => ({ ...column, value: header }));
  public readonly fixedColumns = this.modal.context.allColumns
    .filter((column) => column.isFixed)
    .map(({ header, ...column }) => ({ ...column, value: header }));

  public async save(): Promise<void> {
    await this.runSubmitWithAlerts(
      ({ columns }) =>
        this.modal.choose(
          this.fixedColumns.map(({ key }) => key).concat(columns),
        ),
      { alerts: this.alerts },
    );
  }
}
