import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { Day } from "../day.model";

@Pipe({ name: "day" })
export class DayPipe implements PipeTransform {
  public constructor(@Inject(LOCALE_ID) private readonly locale: string) {}

  public transform(value: Day, format?: DayFormat): string;
  public transform(value: Day | null, format?: DayFormat): string | null;
  public transform(
    value: Day | null,
    format: DayFormat = "medium",
  ): string | null {
    return value ? formatDay(value, this.locale, format) : null;
  }
}

export function formatDay(
  value: Day,
  locale: string,
  format: DayFormat = "medium",
): string {
  return formatDate(value.toString(), formats[format] || "mediumDate", locale);
}

const short = "M/d/yyyy";
const medium = "mediumDate";
const long = "longDate";
const full = "fullDate";

const formats = {
  short,
  medium,
  long,
  full,

  shortDate: short,
  mediumDate: medium,
  longDate: long,
  fullDate: full,
} as const;

/**
 * Date formats for the `day` pipe. Very closely based on the `date` pipe
 * formats from Angular, though tuned for our specific format needs.
 *
 * @see https://angular.io/api/common/DatePipe#pre-defined-format-options
 *
 * @example
 * short -> "1/1/2049"
 * medium -> "Jan 1, 2049"
 * long -> "January 1, 2049"
 * full -> "Friday, January 1, 2049"
 *
 * // Aliases for their equivalents above to support the `DatePipe` date formats:
 * shortDate -> "1/1/2049"
 * mediumDate -> "Jan 1, 2049"
 * longDate -> "January 1, 2049"
 * fullDate -> "Friday, January 1, 2049"
 */
type DayFormat = keyof typeof formats;
