<header>
  <h6 *ngIf="selectFor">Select an Icon for: {{ selectFor }}</h6>
  <button
    *ngIf="selectedIcon"
    type="button"
    class="clear-button"
    aria-label="Clear"
    (click)="clear()"
  >
    Clear
  </button>
  <mr-icon-button (click)="close()" label="Close">
    <mr-icon name="close"></mr-icon>
  </mr-icon-button>
</header>
<div class="icon-container">
  <mr-icon
    *ngFor="let iconName of availableIconNames"
    [name]="iconName"
    [class.selected]="iconName === selectedIcon"
    (click)="selectIcon(iconName)"
  ></mr-icon>
</div>
