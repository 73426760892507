import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthModule } from "../auth/auth.module";
import { ElementsModule } from "../elements/elements.module";
import {
  FlatLinkListComponent,
  FlatListComponent,
} from "./flat-link-list.component";
import { LinkComponent } from "./link.component";
import { TabListComponent } from "./tab-list.component";
import { TabComponent } from "./tab.component";
import { WizardPageStateDirective } from "./wizard-page-state.directive";
import { WizardPageComponent } from "./wizard-page.component";
import { WizardComponent } from "./wizard.component";

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    ElementsModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    FlatLinkListComponent,
    FlatListComponent,
    LinkComponent,
    TabComponent,
    TabListComponent,
    WizardComponent,
    WizardPageComponent,
    WizardPageStateDirective,
  ],
  exports: [
    FlatLinkListComponent,
    FlatListComponent,
    LinkComponent,
    RouterModule,
    TabComponent,
    TabListComponent,
    WizardComponent,
    WizardPageComponent,
    WizardPageStateDirective,
  ],
})
export class NavigationModule {}
