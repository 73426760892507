import { UnknownRecord } from "io-ts";
import { hasProperty } from "./miscellaneous";
import { NavigationPropertyField } from "./resource-url/odata-resource-types";

type PropertyDecorator<T extends string = string> = (
  target: object,
  propertyKey: T,
) => void;

const apiDetailsMetadataKey = Symbol("API Details");
const apiIgnoredMetadataKey = Symbol("API Ignored");

/** API metadata details for a model property. */
interface ApiDetailsArgs<ApiModel> {
  /** The API property key/name to associate with the model property. */
  readonly key: StringKeys<ApiModel>;
  /**
   * The model used to represent this property in the UI.
   * This will be used to look up nested API model properties.
   */
  readonly uiModel?: ConstructorLike<unknown> | null;
  /**
   * The related navigation property to use for nested properties if, for
   * instance, the main `key` binding is to an ID property for updates.
   */
  readonly navigationProperty?: NavigationPropertyField<ApiModel> | null;
}

interface ApiDetailsDecorator<ApiModel> {
  /**
   * Creates a property decorator with default (and inherited) API metadata for
   * an API property with matching name.
   */
  (): PropertyDecorator<StringKeys<ApiModel>>;
  /**
   * Creates a property decorator with the provided API metadata details.
   */
  (details: ApiDetailsArgs<ApiModel>): PropertyDecorator;
  /**
   * Creates a property decorator referencing an `apiPropertyClass` to specify
   * where to find additional API metadata details.
   */
  // Keep these separate so they can have their own documentation.
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  (propertyClass: ConstructorLike<unknown>): PropertyDecorator;
}

/** API metadata information related to model properties. */
export interface ApiDetails<ApiModel = { [property: string]: unknown }>
  extends Required<ApiDetailsArgs<ApiModel>> {}

interface InternalApiDetails<ApiModel = { [property: string]: unknown }>
  extends ApiDetails<ApiModel> {
  readonly isPropertyClass: boolean;
}

/**
 * Gets a decorator creator function for adding API metadata about a model property.
 * @example
 * const api = getApiDetailsDecorator<ApiModel>();
 * class Model {
 *   @api({ key: "val" }) public value: string;
 * }
 */
export function getApiDetailsDecorator<
  ApiModel,
>(): ApiDetailsDecorator<ApiModel> {
  return (detailsArgs?: ApiDetailsArgs<ApiModel> | ConstructorLike<unknown>) =>
    (target: object, propertyKey: string) => {
      let isPropertyClass: boolean;
      let args: ApiDetailsArgs<ApiModel> = {
        // This assertion is guaranteed by the ApiDetailsDecorator type
        // definition or will be ignored for property class details.
        key: propertyKey as StringKeys<ApiModel>,
      };

      if (detailsArgs && "prototype" in detailsArgs) {
        isPropertyClass = true;
        args = { ...args, uiModel: detailsArgs };
      } else {
        isPropertyClass = false;
        args = { ...args, ...detailsArgs };
      }

      const { key, navigationProperty = null, uiModel = null } = args;

      const details: InternalApiDetails<ApiModel> = {
        isPropertyClass,
        key,
        navigationProperty,
        uiModel,
      };

      Reflect.defineMetadata(
        apiDetailsMetadataKey,
        details,
        target,
        propertyKey,
      );
    };
}

/**
 * Gets the API metadata details associated with the given model property.
 * Throws if the property doesn't have any details configured.
 * @param target The model constructor to get details for.
 * @param propertyKey The property on the model to look up details for.
 */
export function getApiDetails<Model>(
  target: ConstructorLike<Model> | Model,
  propertyKey: ClassPropertyNames<Model>,
): ApiDetails<Model>;
export function getApiDetails(
  target: ConstructorLike<unknown> | object,
  propertyKey: string,
): ApiDetails;
export function getApiDetails<Model extends object>(
  target: ConstructorLike<Model> | Model,
  propertyKey: ClassPropertyNames<Model>,
): ApiDetails {
  const { isPropertyClass, ...details } = getInternalApiDetails(
    target,
    propertyKey,
  );

  if (isPropertyClass) {
    const modelName = getTargetPrototype(target).constructor.name;
    const propertyClassName = details.uiModel
      ? getTargetPrototype(details.uiModel).constructor.name
      : "null";
    throw new Error(
      `Cannot get details for property "${propertyKey}" on model "${modelName}" because it is a property class "${propertyClassName}". Use getNestedApiDetails to get its child properties.`,
    );
  }

  return details;
}

function getInternalApiDetails(
  target: ConstructorLike<unknown> | object,
  propertyKey: string,
): InternalApiDetails {
  const prototype = getTargetPrototype(target);

  const details = Reflect.getMetadata(
    apiDetailsMetadataKey,
    prototype,
    propertyKey,
  ) as InternalApiDetails | undefined;

  if (!details) {
    const modelName = prototype.constructor.name;
    throw new Error(
      `No API details found for model "${modelName}" property "${propertyKey}".`,
    );
  }

  return details;
}

export function getNestedApiDetails(
  target: ConstructorLike<unknown>,
  propertyKeys: readonly string[],
): readonly ApiDetails[] {
  const [mainKey, ...subKeys] = propertyKeys;
  const { isPropertyClass, ...details } = getInternalApiDetails(
    target,
    mainKey,
  );

  const detailsList: ApiDetails[] = [];

  if (!isPropertyClass) {
    // Only add the current details if it's not a property class. Property
    // classes are meant to encapsulate multiple API fields that don't have
    // nesting themselves.
    detailsList.push(details);
  }

  if (!subKeys.length) {
    if (isPropertyClass) {
      const modelName = getTargetPrototype(target).constructor.name;
      const propertyClassName = details.uiModel
        ? getTargetPrototype(details.uiModel).constructor.name
        : "null";
      throw new Error(
        `Missing sub-key under property "${mainKey}" on model "${modelName}" but it's required because it's a property class "${propertyClassName}".`,
      );
    }
    return detailsList;
  }

  if (!details.uiModel) {
    throw new Error(`Missing model for filter sub-key "${subKeys[0]}".`);
  }

  return detailsList.concat(getNestedApiDetails(details.uiModel, subKeys));
}

/**
 * Explicitly marks a property to be ignored when processing all the properties
 * on the model for retrieving API details.
 *
 * @param target - The model constructor that the property is on.
 * @param propertyKey - The property on the model to ignore.
 */
export function apiIgnored(target: object, propertyKey: string): void {
  Reflect.defineMetadata(apiIgnoredMetadataKey, true, target, propertyKey);
}

/**
 * Whether the given property on the given target model has been marked to be
 * ignored when collecting API property details.
 *
 * @param target - The model constructor that the property is on.
 * @param propertyKey - The property on the model to check.
 */
export function isApiIgnored(
  target: ConstructorLike<unknown> | object,
  propertyKey: string,
): boolean {
  const prototype = getTargetPrototype(target);
  if (!prototype) {
    throw new Error("Missing prototype on target.");
  }

  return (
    Reflect.getMetadata(apiIgnoredMetadataKey, prototype, propertyKey) === true
  );
}

/**
 * Marks a class as being a "nested", derived property value which itself
 * contains properties associated with the parent class's API equivalent. This
 * allows for separating properties out into more logical groupings rather than
 * being stuck with the structure imposed by the API.
 */
export function apiPropertyClass<T extends ConstructorLike<unknown>>(
  constructor: T,
): void {
  Reflect.defineMetadata(apiDetailsMetadataKey, "isApiProperty", constructor);
}

export function isApiPropertyClass(target: unknown): target is object {
  return (
    UnknownRecord.is(target) &&
    !!Reflect.getMetadata(apiDetailsMetadataKey, target.constructor)
  );
}

// `Object` is actually what we want to find here, since it has the common
// values to all objects that we need in this general case (e.g.
// `target.constructor.name`).
// eslint-disable-next-line @typescript-eslint/ban-types
function getTargetPrototype(target: ConstructorLike<unknown> | object): Object {
  const prototype: unknown = hasProperty(target, "prototype")
    ? target.prototype
    : Object.getPrototypeOf(target);
  if (typeof prototype !== "object" || !prototype) {
    throw new Error("Target is missing a reasonable prototype.");
  }
  return prototype;
}
