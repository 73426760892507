import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { combineLatest, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { isExistent } from "src/utils";
import {
  TicketAppointmentDetailsEntryDirective,
  TicketAppointmentDetailsEntryDirectiveContext,
} from "./ticket-appointment-details-entry.directive";

@Component({
  selector: "mr-appointment-details-entry",
  templateUrl: "./ticket-appointment-details-entry.component.html",
  styleUrls: ["./ticket-appointment-details-entry.component.scss"],
})
export class TicketAppointmentDetailsEntryComponent<T>
  implements AfterViewInit
{
  @Input() public set currentValue(value: T) {
    this.currentValueInput.next(value);
  }
  private readonly currentValueInput = new ReplaySubject<T>(1);
  public readonly currentValueChanges = this.currentValueInput.asObservable();

  @Input() public set changeValue(value: T | null) {
    this.changeValueInput.next(value);
  }

  @ViewChild("newContentRef")
  private readonly newContent!: ElementRef<HTMLElement>;

  private readonly changeValueInput = new ReplaySubject<T | null>(1);
  public readonly changeValueChanges = this.changeValueInput.asObservable();

  public readonly isChangedChanges = combineLatest([
    this.currentValueChanges,
    this.changeValueChanges,
  ]).pipe(
    map(
      ([currentValue, changeValue]) =>
        isExistent(changeValue) &&
        JSON.stringify(currentValue) !== JSON.stringify(changeValue),
    ),
  );

  public isExistent = isExistent;

  public isEntryOfKindNew = false;

  @ContentChild(TicketAppointmentDetailsEntryDirective, { read: TemplateRef })
  public readonly valueTemplate?: TemplateRef<
    TicketAppointmentDetailsEntryDirectiveContext<T>
  >;

  public ngAfterViewInit(): void {
    this.isEntryOfKindNew = !!this.newContent;
  }
}
