import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import {
  AppointmentReference,
  getAppointmentRouteUrl,
} from "src/app/partner/appointments/base-appointment.model";
import { HelpAssistTicket } from "./ticket/help-assist-ticket.model";

export class AssociatedAppointment {
  private constructor(args: ClassProperties<AssociatedAppointment>) {
    this.allPurchaseOrders = args.allPurchaseOrders;
    this.appointmentConfirmationNumber = args.appointmentConfirmationNumber;
    this.appointmentId = args.appointmentId;
    this.appointmentStatus = args.appointmentStatus;
    this.associatedTicket = args.associatedTicket;
    this.purchaseOrderMatches = args.purchaseOrderMatches;
  }

  public readonly allPurchaseOrders: string[];
  public readonly appointmentConfirmationNumber: number;
  public readonly appointmentId: AppointmentReference["id"];
  public readonly appointmentStatus: string;
  /** The ticket that this appointment is associated with. */
  public readonly associatedTicket: HelpAssistTicket;
  public readonly purchaseOrderMatches: string[];

  public static deserialize(
    data: Api.AssociatedAppointment,
    { associatedTicket }: DeserializeArguments,
  ): AssociatedAppointment {
    return new AssociatedAppointment({
      allPurchaseOrders: data.allPurchaseOrders,
      appointmentConfirmationNumber: data.appointmentConfirmationNumber,
      appointmentId: data.appointmentId,
      appointmentStatus: data.appointmentStatus,
      associatedTicket,
      purchaseOrderMatches: data.purchaseOrderMatches,
    });
  }

  public static deserializeList(
    data: readonly Api.AssociatedAppointment[],
    args: DeserializeArguments,
  ): readonly AssociatedAppointment[] {
    return data.map((appointment) =>
      AssociatedAppointment.deserialize(appointment, args),
    );
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getAppointmentRouteUrl(
      { id: this.appointmentId, site: this.associatedTicket.site },
      ...childPaths,
    );
  }
}

interface DeserializeArguments {
  readonly associatedTicket: HelpAssistTicket;
}
