import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Duration } from "luxon";
import { getSiteRouteUrl, SiteModelReference } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { EquipmentType } from "src/app/partner/global/equipment-type.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Vendor>();

export abstract class BaseVendor {
  protected constructor(args: ClassProperties<BaseVendor>) {
    this.areUnreservedSlotsOffered = args.areUnreservedSlotsOffered;
    this.contactEmails = args.contactEmails;
    this.customUnloadDurationPerUnit = args.customUnloadDurationPerUnit;
    this.doorGroupOverride = args.doorGroupOverride;
    this.endTime = args.endTime;
    this.equipmentGroup = args.equipmentGroup;
    this.isActive = args.isActive;
    this.isSameDayAppointmentAllowed = args.isSameDayAppointmentAllowed;
    this.maxAppointmentDoorCount = args.maxAppointmentDoorCount;
    this.maximumCustomUnloadDurationPerUnit =
      args.maximumCustomUnloadDurationPerUnit;
    this.maximumLoadCount = args.maximumLoadCount;
    this.maximumUnloadDurationPerUnit = args.maximumUnloadDurationPerUnit;
    this.startTime = args.startTime;
    this.unloadDurationPerUnit = args.unloadDurationPerUnit;
    this.weekdays = args.weekdays;
  }

  @api({ key: "offerUnreservedSlots" })
  public readonly areUnreservedSlotsOffered: boolean;
  @api({ key: "appointmentNotificationEmails" })
  public readonly contactEmails: string | null;
  @api({ key: "customMinutesPerUnit" })
  public readonly customUnloadDurationPerUnit: Duration | null;
  @api({
    key: "doorGroupID",
    navigationProperty: "doorGroup",
    uiModel: DoorGroup,
  })
  public readonly doorGroupOverride: DoorGroup | null;
  @api() public readonly endTime: TimeOfDay | null;
  @api({
    key: "equipmentGroupID",
    navigationProperty: "equipmentType",
    uiModel: EquipmentType,
  })
  public readonly equipmentGroup: EquipmentType | null;
  @api({ key: "active" }) public readonly isActive: boolean;
  @api({ key: "allowSameDayAppointment" })
  public readonly isSameDayAppointmentAllowed: boolean;
  @api({ key: "doorCount" }) public readonly maxAppointmentDoorCount: number;
  @api({ key: "maxCalcCustomMinutesPerUnit" })
  public readonly maximumCustomUnloadDurationPerUnit: Duration | null;
  @api({ key: "maxLoadCount" }) public readonly maximumLoadCount: number | null;
  @api({ key: "maxCalcMinutesPerUnit" })
  public readonly maximumUnloadDurationPerUnit: Duration | null;
  @api() public readonly startTime: TimeOfDay | null;
  @api({ key: "minutesPerUnit" })
  public readonly unloadDurationPerUnit: Duration | null;
  @api({ key: "daysOfWeek" }) public readonly weekdays: Weekdays;
}

export function getVendorRouteUrl(
  vendor: VendorReference,
  ...childPaths: string[]
): string {
  return getSiteRouteUrl(
    vendor.site,
    "vendors",
    String(vendor.id),
    ...childPaths,
  );
}

export interface VendorReference extends SiteModelReference<"vendor"> {}
