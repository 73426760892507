import { Pipe, PipeTransform } from "@angular/core";
import { isNil } from "lodash-es";

@Pipe({ name: "boolean" })
export class BooleanPipe implements PipeTransform {
  public transform(value: boolean | null | undefined, display = ""): string {
    const [trueDisplay = "Yes", falseDisplay = "No", nullDisplay = ""] = display
      .split(":")
      .map((part) => part || undefined);

    if (isNil(value)) {
      return nullDisplay;
    }
    return value ? trueDisplay : falseDisplay;
  }
}
