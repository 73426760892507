import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ApplicationInsightsService } from "./application-insights.service";

@Injectable({ providedIn: "root" })
export class AlertsService {
  public constructor(
    private readonly appInsights: ApplicationInsightsService,
  ) {}

  private readonly alertsSubject = new Subject<AlertMessage>();
  public readonly changes = this.alertsSubject.asObservable();

  public success({ title, message }: AlertMessageOptions): void {
    this.alertsSubject.next({ title, message, status: "success" });
  }

  public error({ title, message, error }: AlertMessageErrorOptions): void {
    this.alertsSubject.next({ title, message, status: "error" });
    if (error) {
      this.appInsights.logException(error);
    }
  }

  public warn({ title, message, error }: AlertMessageErrorOptions): void {
    this.alertsSubject.next({ title, message, status: "warn" });
    if (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  public info({ title, message }: AlertMessageOptions): void {
    this.alertsSubject.next({ title, message, status: "info" });
  }
}

interface AlertMessageOptions {
  title: string;
  message?: string;
}

interface AlertMessageErrorOptions extends AlertMessageOptions {
  error?: unknown;
}

export interface AlertMessage extends AlertMessageOptions {
  status: "success" | "error" | "warn" | "info";
}
