import { Injectable } from "@angular/core";
import { firstExistentValueFrom, UUID } from "src/utils";
import { RequestService } from "../request.service";
import {
  emailActivityResource,
  EmailInformation,
} from "./email-information.model";

@Injectable()
export class EmailInformationService {
  public constructor(private readonly request: RequestService) {}

  public async getInformationForRecipients(
    emailId: UUID,
  ): Promise<readonly EmailInformation[]> {
    const resource = emailActivityResource.addFunctionArguments({ emailId });
    const activity = await firstExistentValueFrom(this.request.get(resource));
    return activity.value
      ? EmailInformation.deserializeList(activity.value)
      : [];
  }
}
