export const WeekdayNames = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;
export type Weekday = typeof WeekdayNames[number];

export abstract class BaseWeek<T> implements Record<Weekday, T> {
  protected constructor(
    defaultValue: T,
    {
      sunday = defaultValue,
      monday = defaultValue,
      tuesday = defaultValue,
      wednesday = defaultValue,
      thursday = defaultValue,
      friday = defaultValue,
      saturday = defaultValue,
    }: Partial<Record<Weekday, T>> = {},
  ) {
    this.sunday = sunday;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
  }

  public readonly sunday: T;
  public readonly monday: T;
  public readonly tuesday: T;
  public readonly wednesday: T;
  public readonly thursday: T;
  public readonly friday: T;
  public readonly saturday: T;

  public *getWeekdays(): IterableIterator<[Weekday, T]> {
    for (const weekday of WeekdayNames) {
      yield [weekday, this[weekday]];
    }
  }
}
