import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { TimeOfDay } from "../time-of-day.model";

@Pipe({ name: "timeOfDay" })
export class TimeOfDayPipe implements PipeTransform {
  public transform(value: TimeOfDay, format?: TimeOfDayFormat): string;
  public transform(
    value: TimeOfDay | null | undefined,
    format?: TimeOfDayFormat,
  ): string | null;
  public transform(
    value: TimeOfDay | null | undefined,
    format: TimeOfDayFormat = "shortTime",
  ): string | null {
    return value ? formatTimeOfDay(value, format) : null;
  }
}

export function formatTimeOfDay(
  value: TimeOfDay,
  format: TimeOfDayFormat = "shortTime",
): string {
  return DateTime.local()
    .set(value)
    .toLocaleString(
      format === "hour" ? { hour: "numeric" } : DateTime.TIME_SIMPLE,
    );
}

type TimeOfDayFormat = "hour" | "shortTime";
