import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-multi-select[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [description]="description"
      [labelHidden]="isLabelHidden"
      [errorsHidden]="areErrorsHidden"
    >
      <ng-select
        [labelForId]="container.fieldId"
        [attr.aria-describedby]="container.descriptionId"
        [disabled]="isReadonly || isDisabled"
        [multiple]="true"
        [maxSelectedItems]="maxSelectedItems"
        [clearable]="false"
        [closeOnSelect]="false"
        [searchable]="false"
        [bindValue]="valueProperty"
        [bindLabel]="displayProperty || valueProperty"
        [compareWith]="compareWith"
        [items]="passthroughItems"
        [loading]="!passthroughItems"
        [class.loading]="!passthroughItems"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <mr-checkbox [checked]="item$.selected">
            {{ item$.label }}
          </mr-checkbox>
        </ng-template>
        <ng-template ng-multi-label-tmp></ng-template>
        <ng-template ng-header-tmp>
          <div class="panel-header">
            <mr-button
              [disabled]="!value || value.length === 0"
              kind="link-secondary"
              (click)="onChange([])"
            >
              Clear Filter
            </mr-button>
          </div>
        </ng-template>
      </ng-select>
    </mr-field-container>
  `,
  styleUrls: ["multi-select.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiSelectComponent),
    },
  ],
})
export class MultiSelectComponent<T>
  extends BaseFieldComponent<T[]>
  implements ControlValueAccessor
{
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public valueProperty?: string;
  @Input() public displayProperty?: string;
  @Input() public maxSelectedItems?: number;
  @Input() public compareWith = defaultCompareWith;

  @Input() public set items(value: readonly T[] | null) {
    this._passthroughItems = value ? [...value] : undefined;
  }

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  /**
   * Used to pass through the provided `items`, if any, to the underlying
   * component library.
   *
   * This is needed because the ng-select library doesn't accept readonly arrays
   * but we need to accept them as many of our collections are readonly.
   */
  public get passthroughItems(): T[] | undefined {
    return this._passthroughItems;
  }
  private _passthroughItems?: T[];
}

function hasId(value: unknown): value is { id: unknown } {
  return typeof value === "object" && value !== null && "id" in value;
}

// TODO: figure out how to type this properly (this type is used as the
// interface for the `compareWith` property which has an ill-defined interface).
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function defaultCompareWith(option: any, selected: any): boolean {
  return (
    option === selected ||
    (hasId(option) && hasId(selected) && option.id === selected.id)
  );
}
