<mr-field-container
  #container
  [label]="label"
  [description]="(isCustomChanges | async) ? description : undefined"
  [labelHidden]="isLabelHidden"
>
  <div *ngIf="isCustomChanges | async" class="custom-labels">
    <div class="label" *ngIf="limit">
      <span class="limit">{{ displayedFields.length }}/{{ limit }}</span>
    </div>
    <div class="label">
      <a
        class="manage-selection"
        [class.disabled]="isDisabled"
        (click)="onManageSelectionClick()"
      >
        Manage Selection
      </a>
    </div>
  </div>

  <div
    class="display-item-container"
    [class.custom]="isCustomChanges | async"
    [id]="container.fieldId"
    [attr.title]="isLabelHidden ? label : null"
    [attr.aria-label]="isLabelHidden ? label : null"
    [attr.aria-describedby]="container.descriptionId"
  >
    <div *ngFor="let field of displayedFields" class="display-item">
      <ng-container
        *ngTemplateOutlet="fieldDisplayTemplate; context: { $implicit: field }"
      ></ng-container>
    </div>
  </div>
</mr-field-container>
