import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { ActivityAction } from "src/app/partner/settings/activities/base-activity.model";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.AppointmentActivityUpdate>();

export abstract class BaseAppointmentActivity {
  protected constructor(args: ClassProperties<BaseAppointmentActivity>) {
    this.action = args.action;
    this.activity = args.activity;
    this.activityDate = args.activityDate;
    this.comments = args.comments;
    this.iconName = args.iconName;
    this.reasonCodeTitle = args.reasonCodeTitle;
  }

  @api() public readonly action: ActivityAction;
  @api() public readonly activity: string;
  @api() public readonly activityDate: DateTime;
  @api() public readonly comments: string | null;
  @api() public readonly iconName: string | null;
  @api({ key: "reason" }) public readonly reasonCodeTitle: string;
}
