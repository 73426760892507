import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TicketCreationModule } from "src/app/partner/help-assist/ticket-creation/ticket-creation.module";
import { AuthModule } from "../auth/auth.module";
import { ElementsModule } from "../elements/elements.module";
import { EmailInformationModule } from "../email-information/email-information.module";
import { NavigationModule } from "../navigation/navigation.module";
import { PipesModule } from "../pipes/pipes.module";
import { ActivityChangeComponent } from "./activity-change.component";
import { ApprovalModalComponent } from "./approval-modal.component";
import { EnvironmentVisualAidComponent } from "./environment-visual-aid.component";
import { HistoryChangeComponent } from "./history-change.component";
import { HistorySidebarSectionComponent } from "./history-sidebar-section.component";
import { HistoryComponent } from "./history.component";
import { NoticeModalComponent } from "./notice-modal.component";
import { NoticePageComponent } from "./notice-page.component";
import { PageComponent, PageHeaderDetailsDirective } from "./page.component";
import { SidebarClosureListComponent } from "./sidebar-closure-list.component";
import { SidebarReservationListComponent } from "./sidebar-reservation-list.component";
import { SidebarComponent, SidebarSectionComponent } from "./sidebar.component";

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    ElementsModule,
    EmailInformationModule,
    NavigationModule,
    PipesModule,
    TicketCreationModule,
  ],
  declarations: [
    ActivityChangeComponent,
    ApprovalModalComponent,
    EnvironmentVisualAidComponent,
    HistoryChangeComponent,
    HistoryComponent,
    HistorySidebarSectionComponent,
    NoticeModalComponent,
    NoticePageComponent,
    PageComponent,
    PageHeaderDetailsDirective,
    SidebarComponent,
    SidebarClosureListComponent,
    SidebarReservationListComponent,
    SidebarSectionComponent,
  ],
  exports: [
    EnvironmentVisualAidComponent,
    HistoryComponent,
    HistorySidebarSectionComponent,
    NoticePageComponent,
    PageComponent,
    PageHeaderDetailsDirective,
    SidebarComponent,
    SidebarClosureListComponent,
    SidebarReservationListComponent,
    SidebarSectionComponent,
  ],
})
export class LayoutModule {}
