import type {
  ManagedReceivingPartnerApi as Api,
  ManagedReceivingGlobalApi as GlobalApi,
} from "@capstone/mock-api";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { UnloaderType } from "src/app/partner/appointments/base-appointment.model";
import { Vendor } from "src/app/partner/vendors/vendor.model";
import {
  ExpandedODataModel,
  getApiDetailsDecorator,
  getEnumMember,
  OmitMetaProperties,
  parseDateTime,
} from "src/utils";
import {
  ApiTicketCarrier,
  HelpAssistTicketCarrier,
} from "../help-assist-ticket-carrier.model";
import {
  ApiTicketDoorGroup,
  HelpAssistTicketDoorGroup,
} from "../help-assist-ticket-door-group.model";
import {
  ApiTicketDoor,
  HelpAssistTicketDoor,
} from "../help-assist-ticket-door.model";
import {
  ApiTicketOrder,
  HelpAssistTicketAppointmentOrder,
} from "../ticket-order/help-assist-ticket-appointment-order.model";
import { BaseHelpAssistAppointmentDraftTicket } from "./base-help-assist-ticket-appointment-draft.model";
import { HelpAssistTicketAppointmentDraftOrder } from "./help-assist-ticket-appointment-draft-order.model";
import { TicketPreviousValues } from "./help-assist-ticket-appointment-previous.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketAppointmentDraft>();

interface DeserializeArguments {
  site: Site;
}

type ComputedProperty =
  | "carrierId"
  | "totalCaseCount"
  | "totalWarehousePalletCount";

type ScopeDependentProperty = "door" | "doorGroup" | "orders";

abstract class SharedScopeHelpAssistTicketAppointmentDraft extends BaseHelpAssistAppointmentDraftTicket {
  protected constructor(
    args: Omit<
      ClassProperties<SharedScopeHelpAssistTicketAppointmentDraft>,
      ComputedProperty
    >,
  ) {
    super(args);

    this.id = args.id;
    this.orders = args.orders;
    this.previous = args.previous;

    // Computed Properties

    this.carrierId = this.carrier?.id ?? null;
    this.totalCaseCount =
      this.orders?.reduce((total, order) => total + order.caseCount, 0) ?? null;

    this.totalWarehousePalletCount = this.getEffectiveTotalWarehousePalletCount(
      this.orders,
    );
  }

  @api() public readonly id: number;
  public readonly orders: HelpAssistTicketAppointmentOrder[] | null;
  public readonly previous: TicketPreviousValues | null;
  public readonly totalCaseCount: number | null;
  public readonly totalWarehousePalletCount: number | null;

  // Include redundant property for backward compatibility on the HA ticket list
  // view table. The carrier column is tied to this property but ideally it
  // would be using the `carrier` property. That will require a data migration
  // to maintain user column configuration, however.
  @api() public carrierId: number | null;

  protected static deserializeBase(
    apiModel: OmitMetaProperties<
      GlobalApiTicketAppointmentDraft | PartnerApiTicketAppointmentDraft
    >,
    { site }: DeserializeArguments,
  ): Omit<
    ClassProperties<SharedScopeHelpAssistTicketAppointmentDraft>,
    ComputedProperty | ScopeDependentProperty
  > {
    return {
      carrier: apiModel.carrier
        ? HelpAssistTicketCarrier.deserialize(apiModel.carrier)
        : null,
      deliveryCarrier: apiModel.deliveryCarrierRecord
        ? HelpAssistTicketCarrier.deserialize(apiModel.deliveryCarrierRecord)
        : null,
      duration: apiModel.duration ?? null,
      id: apiModel.id,
      idealAppointmentDate: apiModel.idealAppointmentDate
        ? Day.deserialize(apiModel.idealAppointmentDate, site.timeZone)
        : null,
      idealStartTime: apiModel.idealStartTime
        ? TimeOfDay.deserialize(apiModel.idealStartTime)
        : null,
      isDropLoad: apiModel.isDropload ?? null,
      isIntermodal: apiModel.isIntermodal ?? null,
      loadWeight: apiModel.loadWeight ?? null,
      mainOrderNumber: apiModel.mainOrderNumber ?? null,
      mainTicketOrderId: apiModel.mainTicketOrderId,
      notificationList: apiModel.notificationList || null,
      previous: apiModel.previous
        ? TicketPreviousValues.deserialize(apiModel.previous, { site })
        : null,
      scheduledArrivalDay: apiModel.schedule
        ? Day.deserialize(apiModel.schedule, site.timeZone)
        : null,
      site,
      slotStartTime: parseDateTime(apiModel.slotStartTime, site),
      totalWarehousePalletCountOverride:
        apiModel.appointmentPalletOverride ?? null,
      unknownDeliveryCarrierName: apiModel.deliveryCarrier ?? null,
      unloader: getEnumMember(UnloaderType, apiModel.unloader, null),
    };
  }
}

export class GlobalHelpAssistTicketAppointmentDraft extends SharedScopeHelpAssistTicketAppointmentDraft {
  private constructor(
    args: Omit<
      ClassProperties<GlobalHelpAssistTicketAppointmentDraft>,
      ComputedProperty
    >,
  ) {
    super(args);

    this.mainOrder = args.mainOrder;
  }

  // Preferably these didn't exist at all, but they're in the base class so we
  // have to null them out instead to clarify that they'll never be set (unless
  // the global query is updated).
  public declare readonly door: null;
  public declare readonly doorGroup: null;
  public declare readonly orders: null;
  @api({
    key: "mainTicketOrderId",
    navigationProperty: "helpAssistTicketOrder",
    uiModel: HelpAssistTicketAppointmentDraftOrder,
  })
  public readonly mainOrder: HelpAssistTicketAppointmentDraftOrder;

  public static deserialize(
    apiModel: OmitMetaProperties<GlobalApiTicketAppointmentDraft>,
    { site }: DeserializeArguments,
  ): GlobalHelpAssistTicketAppointmentDraft {
    return new GlobalHelpAssistTicketAppointmentDraft({
      ...this.deserializeBase(apiModel, { site }),
      door: null,
      doorGroup: null,
      orders: null,
      mainOrder:
        apiModel.helpAssistTicketOrder &&
        HelpAssistTicketAppointmentDraftOrder.deserialize(
          apiModel.helpAssistTicketOrder,
        ),
    });
  }
}

export class PartnerHelpAssistTicketAppointmentDraft extends SharedScopeHelpAssistTicketAppointmentDraft {
  private constructor(
    args: Omit<
      ClassProperties<PartnerHelpAssistTicketAppointmentDraft>,
      ComputedProperty
    >,
  ) {
    super(args);
  }

  public static deserialize(
    apiModel: OmitMetaProperties<PartnerApiTicketAppointmentDraft>,
    { site }: DeserializeArguments,
  ): PartnerHelpAssistTicketAppointmentDraft {
    return new PartnerHelpAssistTicketAppointmentDraft({
      ...this.deserializeBase(apiModel, { site }),
      door: apiModel.door
        ? HelpAssistTicketDoor.deserialize(apiModel.door)
        : null,
      doorGroup: apiModel.doorGroup
        ? HelpAssistTicketDoorGroup.deserialize(apiModel.doorGroup)
        : null,
      orders: HelpAssistTicketAppointmentOrder.deserializeList(
        apiModel.orders,
        { site },
      ),
    });
  }
}

export type HelpAssistTicketAppointmentDraft =
  | GlobalHelpAssistTicketAppointmentDraft
  | PartnerHelpAssistTicketAppointmentDraft;

export type GlobalApiTicketAppointmentDraft = ExpandedODataModel<
  GlobalApi.HelpAssistTicketAppointmentDraft,
  {
    carrier: keyof ApiTicketCarrier;
    deliveryCarrierRecord: keyof ApiTicketCarrier;
  }
> & {
  helpAssistTicketOrder: GlobalApiTicketOrder;
};

export type PartnerApiTicketAppointmentDraft = ExpandedODataModel<
  Api.HelpAssistTicketAppointmentDraft,
  {
    carrier: keyof ApiTicketCarrier;
    deliveryCarrierRecord: keyof ApiTicketCarrier;
    door: keyof ApiTicketDoor;
    doorGroup: keyof ApiTicketDoorGroup;
  }
> & {
  orders: ApiTicketOrder[];
};

export type GlobalApiTicketOrder = Pick<
  ExpandedODataModel<
    GlobalApi.HelpAssistTicketAppointmentOrder,
    {
      vendor: "id" | "name" | "vendorNumber";
    }
  >,
  "id" | "vendor"
>;

export type GlobalHelpAssistTicketAppointmentOrder = Override<
  Pick<HelpAssistTicketAppointmentOrder, "id" | "vendor">,
  { vendor: Pick<Vendor, "id" | "name" | "displayName"> | null }
>;
