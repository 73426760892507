import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { orderBy, startCase, words } from "lodash-es";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { parseDateTime, parseNumber, parseTimeOfDay } from "src/utils";
import { Day } from "./day.model";
import { TimeOfDay } from "./time-of-day.model";

type HistoryChangeValue =
  | Observable<HistoryChangeValue>
  | readonly HistoryChangeValue[]
  | DateTime
  | Day
  | TimeOfDay
  | string
  | null;

interface HistoryChange {
  label: string;
  originalValue: HistoryChangeValue;
  newValue: HistoryChangeValue;
  scope: "detailed" | "limited";
}

interface FieldDefinition {
  /**
   * How to display the field name. Defaults to the "start case" converted form,
   * e.g. "Carrier Of Record" for field `carrierOfRecord`.
   */
  readonly label?: string;
  /** Formats the given string value for display. */
  formatChangeValue?(value: string | null): HistoryChangeValue | null;
}
type FieldDefinitions<FieldName extends string> = Partial<
  Readonly<Record<FieldName, FieldDefinition>>
>;

interface DeserializeArguments<FieldName extends string> {
  fieldDefinitions?: FieldDefinitions<FieldName>;
}

export class ChangesHistory implements HistoryItem {
  private constructor(args: ClassProperties<ChangesHistory>) {
    this.timestamp = args.timestamp;
    this.notes = args.notes;
    this.username = args.username;
    this.changes = args.changes;
  }

  public readonly changes: readonly HistoryChange[];
  public readonly notes: string[];
  public readonly timestamp: DateTime;
  public readonly username: string;

  public static deserialize<FieldName extends string>(
    history: Api.HistoryEntry,
    { fieldDefinitions: definitions }: DeserializeArguments<FieldName> = {},
  ): ChangesHistory {
    return new ChangesHistory({
      changes: history.changes
        .map(({ fieldName, ...change }) => ({
          ...change,
          fieldName: normalizeFieldName<FieldName>(fieldName),
        }))
        .map(({ fieldName, newValue, originalValue, scope }) => ({
          label: definitions?.[fieldName]?.label || startCase(fieldName),
          newValue: parseChangeValue(newValue, fieldName, definitions),
          originalValue: parseChangeValue(
            originalValue,
            fieldName,
            definitions,
          ),
          scope: scope ?? "detailed",
        })),
      notes: history.notes ?? [],
      timestamp: parseDateTime(history.datetime),
      username: history.username,
    });
  }

  public static deserializeList<FieldName extends string>(
    value: Api.HistoryEntry[],
    args?: DeserializeArguments<FieldName>,
  ): readonly ChangesHistory[] {
    return orderBy(
      value.map((history) =>
        ChangesHistory.deserialize<FieldName>(history, args),
      ),
      [(history) => history.timestamp],
      ["desc"],
    );
  }
}

export interface HistoryItem {
  readonly timestamp: DateTime;
  readonly username: string;
}

function normalizeFieldName<FieldName extends string>(
  fieldName: string,
): FieldName {
  // While this cast is technically not safe, we always treat history change
  // values as effectively optional, so at worst, a value may never actually
  // appear in the history that we suggest might here.
  return fieldName
    .split(".")
    .map((field) => {
      const [firstWord, ...remainingWords] = words(field);
      // This seems to roughly match what the API is doing with its conversion
      // to the property names in the models which is what we match on.
      return [firstWord.toLowerCase(), ...remainingWords].join("");
    })
    .join(".") as FieldName;
}

function normalizeChangeValue(value: string | null): string | null {
  // The API still returns a "null" string for some values.
  return value === "null" || value === "" ? null : value;
}

function parseChangeValue<FieldName extends string>(
  value: string | null,
  fieldName: FieldName,
  { [fieldName]: definition }: FieldDefinitions<FieldName> = {},
): HistoryChangeValue {
  const normalizedValue = normalizeChangeValue(value);

  const parsedValue = definition?.formatChangeValue?.(normalizedValue);
  if (parsedValue) {
    return parsedValue;
  }

  if (normalizedValue === null) {
    return null;
  }

  if (parseNumber(normalizedValue) !== null) {
    return normalizedValue;
  }

  const timeOfDay = parseTimeOfDay(normalizedValue);
  if (timeOfDay !== null) {
    return timeOfDay;
  }

  const dateTime = parseDateTime(normalizedValue);
  if (dateTime.isValid) {
    return dateTime;
  }

  return normalizedValue;
}
