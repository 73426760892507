<div class="label-section">
  <label
    [id]="labelId"
    [for]="fieldId"
    *ngIf="!isLabelHidden && label"
    [class.no-description]="!description"
  >
    {{ label }}<span *ngIf="isRequired()">*</span>
  </label>

  <ng-content
    select="mr-link[mrFieldLabelAction],mr-button[mrFieldLabelAction]"
  ></ng-content>

  <span
    [id]="descriptionId"
    class="description"
    *ngIf="!isLabelHidden && description"
    >{{ description }}</span
  >
</div>

<ng-content></ng-content>

<ul *ngIf="!areErrorsHidden && getErrors() as errors">
  <li *ngIf="errors['email']">
    {{ errorLabel }} must be a valid email address
  </li>
  <li *ngIf="errors['digits']">
    {{ errorLabel }} must contain only the numerical digits 0&ndash;9
  </li>
  <li *ngIf="errors['max'] as error">
    {{ errorLabel }} must be less than or equal to {{ error.max }}
  </li>
  <li *ngIf="errors['maxlength'] as error">
    {{ errorLabel }} must be at most
    {{ error.requiredLength | amount: "character" }} long
  </li>
  <li *ngIf="errors['min'] as error">
    {{ errorLabel }} must be greater than or equal to {{ error.min }}
  </li>
  <li *ngIf="errors['minlength'] as error">
    {{ errorLabel }} must be at least
    {{ error.requiredLength | amount: "character" }} long
  </li>
  <li *ngIf="errors['minuteStep'] as error">
    {{ errorLabel }} must be an increment of {{ error.step | amount: "minute" }}
  </li>
  <li *ngIf="errors['required']">{{ errorLabel }} is required</li>
  <li *ngIf="errors['step'] as error">
    {{ errorLabel }} must be an increment of {{ error.step }}
  </li>
  <li *ngIf="errors['unique'] as error">
    {{ errorLabel }} of "{{ error.match }}" already exists
  </li>
  <li *ngIf="errors['maxArrayLength'] as error">
    {{ errorLabel }} must include no more than {{ error.max | amount: "item" }}
  </li>
  <li *ngIf="errors['isOccupied'] as error">{{ errorLabel }} is occupied</li>
  <ng-container
    [ngTemplateOutlet]="additionalErrorMessageTemplate || null"
    [ngTemplateOutletContext]="{ $implicit: errors, errorLabel: errorLabel }"
  ></ng-container>
</ul>
