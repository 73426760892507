import { Duration, Zone } from "luxon";
import { Day } from "src/app/core/day.model";
import {
  ColumnFilter,
  TableColumnFilterType,
} from "src/app/core/table/table-column-filter.model";
import { environment } from "src/environments";
import { getODataPathFromTableKey } from "./get-odata-path-from-table-key";
import { isExistent, throwUnhandledCaseError } from "./miscellaneous";
import { ODataResourceUrl } from "./resource-url";

/**
 * Set the table column filters on an OData resource.
 * @param resource The OData resource to add filters to.
 * @param columnFilters The column filters to apply.
 */
export function setFiltersOnResource<Resource extends ODataResourceUrl>(
  resource: Resource,
  model: ConstructorLike<unknown>,
  columnFilters: readonly ColumnFilter[],
  {
    timeZone,
    timeZonePath = "site/timeZone",
  }: {
    /** The time zone or zones to check filtered dates in. */
    timeZone: Zone | readonly Zone[];
    /** The API navigation path to the site time zone of the resource, or `null`
     * to not check the time zone when filtering on dates.
     *
     * Default: `"site/timeZone"`
     */
    timeZonePath?: string | null;
  },
): Resource {
  const timeZones = Array.isArray(timeZone) ? timeZone : [timeZone];
  return columnFilters.reduce(
    (filteredResource, columnFilter) =>
      addFilter(filteredResource, model, columnFilter, {
        timeZonePath,
        timeZones,
      }),
    resource,
  );
}

function addFilter<Resource extends ODataResourceUrl>(
  resource: Resource,
  model: ConstructorLike<unknown>,
  columnFilter: ColumnFilter,
  {
    timeZones,
    timeZonePath,
  }: {
    timeZones: readonly Zone[];
    timeZonePath: string | null;
  },
): Resource {
  const isCollectionFilter = columnFilter.key.includes("[]");

  if (isCollectionFilter && columnFilter.type !== TableColumnFilterType.Text) {
    throw new Error(
      "Collection filters are not yet implemented for non-text filter types. (Add them if needed!)",
    );
  }

  let key: string;
  let subKey: string | undefined;
  try {
    key = getODataPathFromTableKey(model, columnFilter.key);
    if (isCollectionFilter) {
      [key, subKey] = key.split("/");
    }
  } catch (error) {
    if (!environment.isProductionBuild) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // Ignore unrecognized keys. See #23677
    return resource;
  }

  if (isCollectionFilter && !subKey) {
    throw new Error(
      `Missing sub-key in collection filter key: ${columnFilter.key}`,
    );
  }

  switch (columnFilter.type) {
    case TableColumnFilterType.DurationRange: {
      let rangeFilteredResource = resource;
      if (isExistent(columnFilter.value.min)) {
        rangeFilteredResource = rangeFilteredResource.addFilter(
          key,
          ">=",
          getDurationInHighestUnit(columnFilter.value.min),
        );
      }
      if (isExistent(columnFilter.value.max)) {
        rangeFilteredResource = rangeFilteredResource.addFilter(
          key,
          "<=",
          getDurationInHighestUnit(columnFilter.value.max),
        );
      }
      return rangeFilteredResource;
    }
    case TableColumnFilterType.NumberRange: {
      let rangeFilteredResource = resource;
      if (isExistent(columnFilter.value.min)) {
        rangeFilteredResource = rangeFilteredResource.addFilter(
          key,
          ">=",
          columnFilter.value.min,
        );
      }
      if (isExistent(columnFilter.value.max)) {
        rangeFilteredResource = rangeFilteredResource.addFilter(
          key,
          "<=",
          columnFilter.value.max,
        );
      }
      return rangeFilteredResource;
    }
    case TableColumnFilterType.Boolean:
    case TableColumnFilterType.Number:
      return resource.addFilter(key, "==", columnFilter.value);
    case TableColumnFilterType.NumberIdentifier:
      return resource.addStartsWithFilter(
        `cast(${key},Edm.String)`,
        columnFilter.value,
      );
    case TableColumnFilterType.Time:
      return resource.addFilter(key, "==", columnFilter.value);
    case TableColumnFilterType.Text: {
      const capturedSubKey = subKey;
      return isCollectionFilter && capturedSubKey
        ? resource.addNavigationPropertyAnyFilter(key, (itemResource) =>
            itemResource.addStartsWithFilter(
              capturedSubKey,
              columnFilter.value,
            ),
          )
        : resource.addContainsFilter(key, columnFilter.value);
    }
    case TableColumnFilterType.Dropdown:
      return resource.addFilter(key, "==", columnFilter.value);
    case TableColumnFilterType.Days: {
      const weekdaysApiValue = columnFilter.value.serialize();
      if (!weekdaysApiValue) {
        return resource;
      }
      return resource.addRawFilter(
        weekdaysApiValue
          .split(",")
          .map((day) => `contains(${key}, '${day}')`)
          .join(" or "),
      );
    }
    case TableColumnFilterType.MultiSelect: {
      if (columnFilter.value.length === 0) {
        return resource;
      }
      return resource.addInFilter(key, columnFilter.value);
    }
    case TableColumnFilterType.DateRange: {
      const { start, end } = columnFilter.value;

      return resource.addOrFilters((orResource) =>
        timeZones.map((timeZone) =>
          orResource
            .addFilterIfExists(timeZonePath, (r, path) =>
              r.addFilter(path, "==", timeZone.name),
            )
            .addFilter(key, ">=", start.toDateTime(Day.startOfDay, timeZone))
            .addFilter(key, "<", end.toDateTime(Day.startOfDay, timeZone)),
        ),
      );
    }
    case TableColumnFilterType.DayRange: {
      const { start, end } = columnFilter.value;

      return resource.addOrFilters((orResource) =>
        timeZones.map((timeZone) =>
          orResource
            .addFilterIfExists(timeZonePath, (r, path) =>
              r.addFilter(path, "==", timeZone.name),
            )
            .addFilter(key, ">=", start)
            .addFilter(key, "<", end),
        ),
      );
    }
    default:
      throwUnhandledCaseError("column filter type", columnFilter);
  }
}

function getDurationInHighestUnit(value: Duration): number {
  return value.days !== 0
    ? value.as("days")
    : value.hours !== 0
    ? value.as("hours")
    : value.as("minutes");
}
