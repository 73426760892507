import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { EquipmentType } from "src/app/partner/global/equipment-type.model";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.EquipmentUpdate>();

export enum EquipmentLocationType {
  Site = "Site",
  Dock = "Dock",
  DoorGroup = "DoorGroup",
}

export abstract class BaseEquipment {
  protected constructor(args: ClassProperties<BaseEquipment>) {
    this.locationType = args.locationType;
    this.quantity = args.quantity;
    this.site = args.site;
    this.type = args.type;
  }

  @api({ key: "availability" })
  public readonly locationType: EquipmentLocationType;
  @api() public readonly quantity: number;
  @api({ key: "siteID" }) public readonly site: Site;
  @api({ key: "equipmentTypeID" }) public readonly type: EquipmentType;
}
