import { Injectable } from "@angular/core";
import { differenceBy, sortBy } from "lodash-es";
import { combineLatest, firstValueFrom, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { SiteAvailabilityStatus } from "src/app/core/constants";
import { RequestService } from "src/app/core/request.service";
import { UserPreferencesService } from "src/app/core/user-preferences.service";
import { Partner } from "src/app/partner/global/partner.model";
import { PartnersService } from "src/app/partner/global/partners.service";
import {
  deserializeApiModelList,
  firstExistentValueFrom,
  getDefaultUpdater,
  isExistent,
  isInstanceOf,
  isNot,
  mapNullable,
  ODataResourceUrl,
  PartnerResource,
  pauseable,
} from "src/utils";
import { CapstonePartner, CapstoneSite } from "./capstone-site.model";
import { SiteUpdate } from "./site-update.model";
import { ApiSite, Site, SiteReference, sitesResource } from "./site.model";

const baseListResource = sitesResource;

@Injectable({ providedIn: "root" })
export class SitesService {
  public constructor(
    private readonly partners: PartnersService,
    private readonly request: RequestService,
    private readonly user: UserService,
    private readonly userPreferences: UserPreferencesService,
  ) {}

  private readonly listResourceChanges = this.partners
    .getResourceInSelected(baseListResource)
    .pipe(shareReplay(1));

  private readonly itemBaseResourceChanges = this.listResourceChanges;

  private readonly modifiableResourceChanges = this.listResourceChanges;

  private readonly deserializeArgumentsChanges =
    this.partners.selectedChanges.pipe(map((partner) => ({ partner })));

  private readonly updates = new Subject<ApiSite>();

  readonly #listChanges = this.getListChanges().pipe(shareReplay(1));
  readonly #activeListChanges = this.#listChanges.pipe(
    // Filtering out of Pending/Decommissioned sites will be handled by the API
    // for most users, but we still need to filter out the Decommissioned sites
    // for global admins.
    mapNullable((sites) =>
      sites.filter(
        (site) => site.status !== SiteAvailabilityStatus.Decommissioned,
      ),
    ),
    shareReplay(1),
  );

  private readonly maybeSelectedChanges = combineLatest([
    this.#activeListChanges,
    this.userPreferences.siteIdChanges,
  ]).pipe(
    map(([sites, id]) =>
      sites === null
        ? "loading"
        : sites.length === 0
        ? "list-empty"
        : sites.find((site) => site.id === id) ?? "not-found",
    ),
    shareReplay(1),
  );

  public readonly selectedChanges = this.maybeSelectedChanges.pipe(
    filter(isInstanceOf(Site)),
  );

  public readonly selectedResourceChanges = this.selectedChanges.pipe(
    withLatestFrom(this.modifiableResourceChanges),
    map(([{ id }, resource]) => resource.appendId(id)),
  );

  public readonly isSelectedLoadedChanges = this.maybeSelectedChanges.pipe(
    map((site) => site !== "loading" && site !== "not-found"),
  );

  private readonly defaultSelectionChanges = this.#activeListChanges.pipe(
    filter(isExistent),
    // The list could be empty before initial partner set up or if the user
    // isn't configured with all their correct sites yet.
    map((sites) => (sites[0] ?? null) as Site | null),
  );

  readonly #isSelectedLoadingChanges = this.isSelectedLoadedChanges.pipe(
    map(isNot(true)),
  );

  // When switching partners, the list of sites and the selected site both
  // reload, but the `selectedChanges` will retain the previous site until it
  // finishes loading, so there may be a moment when the the old selected site
  // is still loaded once the new list of sites appears. This means that the
  // selected site doesn't exist in the site list which shouldn't ever happen.
  //
  // To avoid this, we can hold back the emission of the newly updated list
  // until after the new selected site has been loaded/selected as well.
  public readonly listChanges = combineLatest([
    this.#listChanges,
    this.#isSelectedLoadingChanges,
  ]).pipe(
    map(([list, isSelectedLoading]) => (isSelectedLoading ? null : list)),
    distinctUntilChanged(),
  );
  public readonly activeListChanges = combineLatest([
    this.#activeListChanges,
    this.#isSelectedLoadingChanges,
  ]).pipe(
    map(([list, isSelectedLoading]) => (isSelectedLoading ? null : list)),
    distinctUntilChanged(),
  );

  private isSelectedLoadedNotifier?: Observable<boolean>;

  private readonly unloadNotifier = new Subject<void>();

  public async load(): Promise<boolean> {
    if (!this.isSelectedLoadedNotifier) {
      // Skip emissions whenever the partner key has changed so that the
      // intermediate missing value (current site not available at new partner)
      // doesn't trigger a default selection update. See: #18940
      const readyMaybeSelectedChanges = this.maybeSelectedChanges.pipe(
        pauseable(
          this.userPreferences.partnerKeyChanges.pipe(skip(1)),
          this.#activeListChanges.pipe(filter(isExistent)),
        ),
      );
      const [updater, notifier] = getDefaultUpdater(
        readyMaybeSelectedChanges,
        this.defaultSelectionChanges,
      );
      this.isSelectedLoadedNotifier = notifier;

      // Set the default site if one isn't selected or the selected wasn't found.
      updater
        .pipe(
          switchMap((site) => this.setSelected(site)),
          takeUntil(this.unloadNotifier),
        )
        .subscribe();
    }

    return firstValueFrom(this.isSelectedLoadedNotifier);
  }

  public async unload(): Promise<void> {
    this.unloadNotifier.next();
    this.isSelectedLoadedNotifier = undefined;
    await this.userPreferences.setSiteId(null);
  }

  public getListChanges({
    partner,
    query,
  }: {
    partner?: Partner;
    query?: string;
  } = {}): Observable<readonly Site[] | null> {
    const listResourceChanges = partner
      ? of(baseListResource.get(partner))
      : this.listResourceChanges;
    const deserializeArgumentsChanges = partner
      ? of({ partner })
      : this.deserializeArgumentsChanges;

    const resourceChanges = listResourceChanges.pipe(
      map((resource) =>
        query
          ? resource.withFieldsContaining(
              ["displayName", "gatePassMessage", "name", "number"],
              query,
            )
          : resource,
      ),
    );

    return deserializeApiModelList({
      deserialize: Site.deserializeList,
      list: resourceChanges.pipe(
        switchMap((resource) => this.request.getAll(resource)),
      ),
      arguments: deserializeArgumentsChanges,
      updates: this.updates,
    }).pipe(
      map((sites) => sites && sortBy(sites, (site) => site.name.toLowerCase())),
    );
  }

  /**
   * Filters the provided list of sites down to only those that are not already
   * configured at the provided partner (i.e. those that are still available to
   * add).
   * @param partner - The partner to compare the sites at.
   * @param fullList - The list to filter down.
   */
  public filterAvailableAtPartner(
    partner: Partner | CapstonePartner,
    fullList: readonly CapstoneSite[],
  ): Promise<readonly CapstoneSite[]> {
    return firstValueFrom(
      this.request.getAll(baseListResource.get(partner)).pipe(
        filter(isExistent),
        map(({ value: mrSites }) =>
          differenceBy(fullList, mrSites, (site) => site.number),
        ),
      ),
    );
  }

  public async setSelected(site: SiteReference): Promise<void> {
    // Set the new partner first so that the default site doesn't reset the
    // partner back to the previous partner when it calls this. See: #18940
    // We also need to await at least the last one so that the notifier below
    // loads at the correct time.
    await this.partners.setSelected(site.partner);
    await this.userPreferences.setSiteId(site.id, {
      sessionOnly: this.user.isCarrier(),
    });

    // Wait for the selected notifier so we know the updated list and default
    // selection have all settled before the caller proceeds.
    if (this.isSelectedLoadedNotifier) {
      await firstValueFrom(
        this.isSelectedLoadedNotifier.pipe(filter((isLoaded) => isLoaded)),
      );
    }
  }

  public async update(update: SiteUpdate): Promise<Site> {
    const id = update.base
      ? await this.patch(update.base.id, update)
      : await this.post(update);

    return await this.get(id);
  }

  /**
   * Gets the concrete `ODataResourceUrl` for a `PartnerResource` that is nested
   * within the appropriate, currently selected partner as well as filtered by
   * the currently selected site.
   *
   * **Example result URL:**
   *
   * `https://dev-mr.capstonelogistics.com/api/partner/1234/docks?$filter=siteID eq 10`
   *
   * @param resource The partner resource to add the site filter to.
   * @param siteIdProperty The resource model property to match the site ID
   * against. Defaults to `siteID`.
   */
  public getResourceInSelected<Resource, ResourceName>(
    resource: PartnerResource<Resource, ResourceName>,
    siteIdProperty = "siteID",
  ): Observable<ODataResourceUrl<Resource, ResourceName>> {
    return this.selectedChanges.pipe(
      map(({ id }) => id),
      distinctUntilChanged(),
      withLatestFrom(this.getResourceInSelectedPartner(resource)),
      map(([siteId, resourceInPartner]) =>
        resourceInPartner.addRawFilter(`${siteIdProperty} eq ${siteId}`),
      ),
    );
  }

  /**
   * Gets the concrete `ODataResourceUrl` for a `PartnerResource` that is nested
   * within the appropriate, currently selected partner. This is a convenience
   * pass-through method for `PartnersService.getResourceInSelected`.
   *
   * You'll often want this one rather than `SitesService.getResourceInSelected`
   * when modifying resources (`POST`, `PATCH`, `DELETE`) or when selecting a
   * specific resource by ID as filtering by site on these is invalid.
   *
   * **Example result URL:**
   *
   * `https://dev-mr.capstonelogistics.com/api/partner/1234/docks`
   *
   * @param resource The partner resource to add the partner to.
   */
  public getResourceInSelectedPartner<Resource, ResourceName>(
    resource: PartnerResource<Resource, ResourceName>,
  ): Observable<ODataResourceUrl<Resource, ResourceName>> {
    return this.partners.getResourceInSelected(resource);
  }

  private async get(id: number): Promise<Site> {
    const resource = await firstValueFrom(this.itemBaseResourceChanges);
    const apiModel = await firstExistentValueFrom(
      this.request.get(resource.appendId(id)),
    );

    this.updates.next(apiModel);

    const args = await firstExistentValueFrom(this.deserializeArgumentsChanges);
    return Site.deserialize(apiModel, args);
  }

  private async patch(id: number, update: SiteUpdate): Promise<number> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    await firstValueFrom(this.request.patch(resource.appendId(id), update));
    return id;
  }

  private async post(update: SiteUpdate): Promise<number> {
    const resource = await firstValueFrom(this.modifiableResourceChanges);
    const { id } = await firstValueFrom(this.request.post(resource, update));
    return id;
  }
}
