import { HttpResponse } from "@angular/common/http";
import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import {
  ODataBatchChangeset,
  ODataBatchOperation,
  ODataBatchRequest,
} from "odata-batch-request";
import {
  ArrayUpdate,
  FunctionPropertyField,
  NavigationPropertyField,
  NavigationPropertyType,
  ODataModel,
  ODataResourceUrl,
  ResourceUrl,
} from "src/utils";
import { ApiError } from "./api-error";
import { ODataReference } from "./odata-reference.model";

export function createBatchChangesetRequest<
  T extends readonly BatchOperation[],
>(
  serviceRootResource: ResourceUrl,
  operations: T,
): {
  readonly batch: ODataBatchRequest<
    readonly [ODataBatchChangeset<readonly ODataBatchOperation[]>]
  >;
  parseResponse(this: void, response: HttpResponse<string>): BatchResult<T>;
} {
  const serviceRoot = serviceRootResource.url.href;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Referer: window.location.href,
  };

  const mappedOperations: ODataBatchOperation[] = [];
  for (const operation of operations) {
    let path: string | [ODataBatchOperation, string];
    if (Array.isArray(operation.resource)) {
      const [base, partialPath] = operation.resource;
      const baseIndex = operations.indexOf(base);
      const baseOperation = mappedOperations[baseIndex];
      if (!baseOperation) {
        throw new Error("Missing base operation for adding reference.");
      }
      path = [baseOperation, partialPath];
    } else {
      path = operation.resource.url.href;
    }

    let mappedOperation: ODataBatchOperation;
    switch (operation.method) {
      case BatchOperationMethod.Get:
      case BatchOperationMethod.Delete: {
        mappedOperation = new ODataBatchOperation(operation.method, path, {
          headers,
        });
        break;
      }
      case BatchOperationMethod.AddReference: {
        const data =
          "serialize" in operation.data
            ? JSON.stringify(operation.data.serialize())
            : mappedOperations[operations.indexOf(operation.data)];

        // This can happen if the operation isn't found in the array.
        if (data === undefined) {
          throw new Error("Missing data operation for adding reference.");
        }

        mappedOperation = new ODataBatchOperation(
          BatchOperationMethod.Post,
          path,
          {
            headers,
            body:
              typeof data === "string"
                ? data
                : (getReference) => {
                    if (getReference === undefined) {
                      // This should never happen since this is undefined when there
                      // are no references, but there will always be references in a
                      // changeset.
                      throw new Error(
                        "Could not get reference ID of added reference.",
                      );
                    }
                    const reference = new ODataReference(getReference(data));
                    return JSON.stringify(reference.serialize());
                  },
          },
        );
        break;
      }
      default: {
        mappedOperation = new ODataBatchOperation(operation.method, path, {
          headers: operation.data.version
            ? {
                ...headers,
                "If-Match": operation.data.version,
              }
            : headers,
          body: JSON.stringify(operation.data.serialize()),
        });
        break;
      }
    }
    mappedOperations.push(mappedOperation);
  }

  const changeset = new ODataBatchChangeset(mappedOperations);
  const batch = new ODataBatchRequest(serviceRoot, [changeset] as const);

  return {
    batch,
    parseResponse: (response) => parseResponse(batch, response),
  };
}

function parseResponse<T extends readonly BatchOperation[]>(
  batch: ODataBatchRequest<
    readonly [ODataBatchChangeset<readonly ODataBatchOperation[]>]
  >,
  { body, headers }: HttpResponse<string>,
): BatchResult<T> {
  if (!body) {
    throw new Error("Missing batch response body.");
  }

  const contentType = headers.get("Content-Type");
  if (!contentType) {
    throw new Error("Missing Content-Type header from batch response.");
  }

  // Since we sent a single changeset, either every request failed together
  // and the result is an error or it's the valid collection of responses to
  // all requests in the changeset.
  const [result] = batch.parseResponse(body, contentType).operations;
  if (!Array.isArray(result)) {
    throw ApiError.create(result);
  }

  // If the request succeeded, then the results should be a collection of the
  // API model objects or nothing for DELETE in the same order as the provided
  // operations. So this cast should be safe for returning a tuple matching
  // the expected results.
  const parsedResult = result.map((operationResponse) =>
    operationResponse.body && typeof operationResponse.body === "string"
      ? JSON.parse(operationResponse.body)
      : null,
  );
  return parsedResult as unknown as BatchResult<T>;
}

export interface RequestModel {
  version?: string;
  serialize(): unknown;
}

// Use `any` to support arbitrary matching in the method generic parameter.
/* eslint-disable @typescript-eslint/no-explicit-any */
export type BatchOperation =
  | BatchOperationGet<any>
  | BatchOperationDelete
  | BatchOperationPost<any>
  | BatchOperationPostToReference<any, string>
  | BatchOperationPatch<any>
  | BatchOperationAddReference<any, any>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export enum BatchOperationMethod {
  AddReference,
  Get = "get",
  Delete = "delete",
  Post = "post",
  Patch = "patch",
}

interface BatchOperationGet<T> {
  readonly method: BatchOperationMethod.Get;
  // For some reason, the default `ODataModel<T>` for the last parameter isn't
  // matching properly when getting a collection (`ODataList`) so we have to
  // "throw away" the third type parameter here with `any`.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly resource: ODataResourceUrl<T, unknown, any>;
}

interface BatchOperationDelete {
  readonly method: BatchOperationMethod.Delete;
  readonly resource: ODataResourceUrl;
}

export interface BatchOperationPost<T> {
  readonly method: BatchOperationMethod.Post;
  readonly resource: ODataResourceUrlByModel<T>;
  readonly data: RequestModel;
}

interface BatchOperationPostToReference<
  Resource,
  Field extends
    | NavigationPropertyField<Resource>
    | FunctionPropertyField<Resource>,
> {
  readonly method: BatchOperationMethod.Post;
  readonly resource: readonly [BatchOperationPost<Resource>, Field];
  readonly data: RequestModel;
}

export interface BatchOperationPatch<T> {
  readonly method: BatchOperationMethod.Patch;
  readonly resource: ODataResourceUrlByModel<T>;
  readonly data: RequestModel;
}

interface BatchOperationAddReference<T, U> {
  readonly method: BatchOperationMethod.AddReference;
  readonly resource:
    | readonly [BatchOperationPost<T>, string]
    | ODataResourceUrl<Api.ODataReference>;
  readonly data: BatchOperationPost<U> | ODataReference;
}

export type BatchResult<Operations> = {
  [K in keyof Operations]: Operations[K] extends BatchOperationDelete
    ? null
    : Operations[K] extends BatchOperationPostToReference<infer T, infer F>
    ? ODataModel<NavigationPropertyType<T, F>>
    : Operations[K] extends
        | BatchOperationGet<infer V>
        | BatchOperationPost<infer V>
        | BatchOperationPatch<infer V>
        | BatchOperationAddReference<unknown, infer V>
    ? V
    : unknown;
};

export function createBatchPostOperation<T>(
  resource: ODataResourceUrlByModel<T>,
  data: RequestModel,
): BatchOperationPost<T>;
export function createBatchPostOperation<
  T,
  F extends NavigationPropertyField<T> | FunctionPropertyField<T>,
>(
  resource: readonly [BatchOperationPost<T>, F],
  data: RequestModel,
): BatchOperationPostToReference<T, F>;
export function createBatchPostOperation<
  T,
  F extends NavigationPropertyField<T> | FunctionPropertyField<T>,
>(
  resource: ODataResourceUrlByModel<T> | readonly [BatchOperationPost<T>, F],
  data: RequestModel,
): BatchOperationPost<T> | BatchOperationPostToReference<T, F> {
  // The result is the same here, but in order to make the compiler happy, we
  // need to distinguish the array/reference form of `resource` from the regular
  // resource. The array check narrows the type in each branch appropriately.
  return Array.isArray(resource)
    ? { method: BatchOperationMethod.Post, resource, data }
    : { method: BatchOperationMethod.Post, resource, data };
}

export function createBatchPatchOperation<T>(
  resource: ODataResourceUrlByModel<T>,
  data: RequestModel,
): BatchOperationPatch<T> {
  return { method: BatchOperationMethod.Patch, resource, data };
}

export function createBatchDeleteOperation(
  resource: ODataResourceUrl,
  reference?: ODataReference,
): BatchOperationDelete {
  return {
    method: BatchOperationMethod.Delete,
    resource: reference ? resource.setParam("$id", reference.id) : resource,
  };
}

export function createBatchAddReferenceOperation<T, U>(
  base: BatchOperationPost<T> | BatchOperationPatch<T>,
  path: NavigationPropertyField<T>,
  data: BatchOperationPost<U> | ODataReference,
): BatchOperationAddReference<T, U> {
  return {
    method: BatchOperationMethod.AddReference,
    resource:
      base.method === BatchOperationMethod.Post
        ? [
            base,
            base.resource
              // Clear out any existing path component so we can get just the
              // part after the referenced resource in `base`.
              .setPath("")
              .appendNavigationPropertyPath(path)
              .asReference().path,
          ]
        : base.resource.appendNavigationPropertyPath(path).asReference(),
    data,
  };
}

export function createBatchRemoveReferenceOperation<T>(
  base: BatchOperationPatch<T>,
  path: NavigationPropertyField<T>,
  data: ODataReference,
): BatchOperationDelete {
  return {
    method: BatchOperationMethod.Delete,
    resource: base.resource
      .appendNavigationPropertyPath(path)
      .asReference()
      .setParam("$id", data.id),
  };
}

export function createArrayUpdateReferenceOperations<
  BaseApiModel,
  Field extends NavigationPropertyField<BaseApiModel>,
  Model extends { id: number; reference: ODataReference },
>(
  baseOperation:
    | BatchOperationPost<BaseApiModel>
    | BatchOperationPatch<BaseApiModel>,
  path: Field,
  update: ArrayUpdate<Model>,
): {
  readonly removed: readonly BatchOperationDelete[];
  readonly added: ReadonlyArray<
    BatchOperationAddReference<
      BaseApiModel,
      NavigationPropertyType<BaseApiModel, Field>
    >
  >;
} {
  return {
    added: update.added.map(({ reference }) =>
      createBatchAddReferenceOperation(baseOperation, path, reference),
    ),
    removed:
      baseOperation.method === BatchOperationMethod.Patch
        ? update.removed.map(({ reference }) =>
            createBatchRemoveReferenceOperation(baseOperation, path, reference),
          )
        : [],
  };
}

// We only want the model type, not the collection/list resource type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ODataResourceUrlByModel<T> = ODataResourceUrl<any, unknown, T>;
