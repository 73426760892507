import { Component, forwardRef, HostListener, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { SortOrder, throwUnhandledCaseError } from "src/utils";
import { TableItemDirective } from "./table-item.directive";
import { TableService } from "./table.service";

@Component({
  selector: "mr-table-column-sort[column]",
  templateUrl: "./table-column-sort.component.html",
  styleUrls: ["./table-column-sort.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableColumnSortComponent),
    },
  ],
})
export class TableColumnSortComponent<T> {
  public constructor(private readonly tableService: TableService) {}

  @Input() public column!: TableItemDirective<T>;

  public readonly SortOrder = SortOrder;

  public readonly sortOrderChanges = this.tableService.columnSortChanges.pipe(
    map((sort) =>
      sort?.key === this.column.sortOn && sort?.order ? sort.order : null,
    ),
  );

  @HostListener("click")
  public async sortColumn(): Promise<void> {
    const currentSort = await firstValueFrom(this.sortOrderChanges);
    const newSort = toggleSortOrder(currentSort);
    if (newSort) {
      await this.tableService.sortColumn(this.column.sortOn, newSort);
    } else {
      await this.tableService.removeSort();
    }
  }
}

function toggleSortOrder(value: SortOrder | null): SortOrder | null {
  switch (value) {
    case null:
      return SortOrder.Ascending;
    case SortOrder.Ascending:
      return SortOrder.Descending;
    case SortOrder.Descending:
      return null;
    default:
      throwUnhandledCaseError("sort order", value);
  }
}
