<mr-field-container
  #container
  [customFieldId]="fieldId"
  [label]="label"
  [description]="description"
  [additionalErrorMessageTemplate]="errorMessages"
  [kind]="kind"
>
  <div class="field" [class.field--small]="small">
    <mr-icon name="clock" aria-hidden="true"></mr-icon>
    <input
      type="number"
      [id]="container.fieldId"
      [placeholder]="placeholder"
      [attr.aria-describedby]="container.descriptionId"
      [disabled]="isDisabled"
      [readOnly]="isReadonly"
      [ngModel]="displayNumber"
      (ngModelChange)="onInputChange($event)"
      (blur)="onBlur()"
      [attr.min]="minNumber"
      [attr.max]="maxNumber"
      [attr.step]="stepNumber"
    />

    <span *ngIf="unitFormat !== 'short'; else shortUnits" class="scale">
      <ng-container [ngSwitch]="scale">
        <ng-container *ngSwitchCase="'days'">Day</ng-container>
        <ng-container *ngSwitchCase="'hours'">Hour</ng-container>
        <ng-container *ngSwitchCase="'minutes'"
          >Minute</ng-container
        > </ng-container
      >{{ displayNumber === 1 ? "" : "s" }}
    </span>
  </div>
</mr-field-container>

<ng-template #shortUnits>
  <span class="scale scale--short" [ngSwitch]="scale">
    <ng-container *ngSwitchCase="'days'">D.</ng-container>
    <ng-container *ngSwitchCase="'hours'">H.</ng-container>
    <ng-container *ngSwitchCase="'minutes'">Min.</ng-container>
  </span>
</ng-template>

<ng-template #errorMessages let-errors let-errorLabel="errorLabel">
  <li *ngIf="errors.durationStep as error">
    {{ errorLabel }} must be in an increment of
    {{ error.step | duration: "full":scale | lowercase }}
  </li>
  <li *ngIf="errors.maxDuration as error">
    {{ errorLabel }} must be less than or equal to
    {{ error.max | duration: "full":scale | lowercase }}
  </li>
  <li *ngIf="errors.minDuration as error">
    {{ errorLabel }} must be greater than or equal to
    {{ error.min | duration: "full":scale | lowercase }}
  </li>
</ng-template>
