import { Component } from "@angular/core";
import { PopoverRef } from "./popover-ref";

@Component({
  selector: "mr-popover",
  template: `<ng-container *ngComponentOutlet="content"></ng-container>`,
})
export class PopoverComponent {
  public constructor(private readonly popoverRef: PopoverRef<unknown>) {}

  public readonly content = this.popoverRef.content;
}
