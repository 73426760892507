import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { timer } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { AlertMessage, AlertsService } from "src/app/core/alerts.service";

@UntilDestroy()
@Component({
  selector: "mr-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
  animations: [
    trigger("modalSlideInOut", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-100%)" }),
        animate(
          "0.3s ease-in",
          style({
            opacity: 1,
            transform: "translateY(0)",
          }),
        ),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(0%)" }),
        animate(
          "0.3s ease-out",
          style({
            opacity: 0,
            transform: "translateY(-100%)",
          }),
        ),
      ]),
    ]),
  ],
})
export class AlertsComponent implements OnInit, OnDestroy {
  public constructor(public alertService: AlertsService) {}

  public readonly alerts: AlertMessage[] = [];

  public ngOnInit(): void {
    this.alertService.changes.pipe(untilDestroyed(this)).subscribe((alert) => {
      this.alerts.push(alert);
    });
    this.alertService.changes
      .pipe(
        filter((alert) => alert.status !== "error"),
        mergeMap((alert) => timer(5000).pipe(map(() => alert))),
        untilDestroyed(this),
      )
      .subscribe((alert) => {
        this.remove(alert);
      });
  }

  public ngOnDestroy(): void {
    // Clear unfinished alerts
    this.alerts.splice(0, this.alerts.length);
  }

  public remove(alert: AlertMessage): void {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
