import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.DoorGroup>();

export class HelpAssistTicketDoorGroup {
  private constructor(args: ClassProperties<HelpAssistTicketDoorGroup>) {
    this.id = args.id;
    this.name = args.name;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;

  public static deserialize(
    apiModel: ApiTicketDoorGroup,
  ): HelpAssistTicketDoorGroup {
    return new HelpAssistTicketDoorGroup({
      id: apiModel.id,
      name: apiModel.name,
    });
  }
}

export type ApiTicketDoorGroup = Pick<Api.DoorGroup, "id" | "name">;
