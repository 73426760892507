import { Injectable } from "@angular/core";
import { sortBy } from "lodash-es";
import { Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { RequestService } from "src/app/core/request.service";
import { isExistent, isOneOfCreator, mapNullable } from "src/utils";
import {
  AppointmentStatus,
  AppointmentStatusCode,
  appointmentStatusesGlobalResource,
} from "./appointment-status.model";

@Injectable({ providedIn: "root" })
export class AppointmentStatusesService {
  public constructor(private readonly request: RequestService) {}

  public readonly changes: Observable<readonly AppointmentStatus[] | null> =
    this.request.getAll(appointmentStatusesGlobalResource).pipe(
      mapNullable((result) => AppointmentStatus.deserializeList(result)),
      mapNullable((statuses) => sortBy(statuses, (status) => status.name)),
      shareReplay(1),
    );

  /** Known statuses that can be set to an appointment. */
  public readonly usableListChanges = this.changes.pipe(
    mapNullable((list) =>
      list.filter((status) => isUsableStatusCode(status.code)),
    ),
  );

  public readonly byCodeChanges = this.usableListChanges.pipe(
    filter(isExistent),
    map((statusList) => {
      const modifiableStatuses = Object.fromEntries(
        usableStatusCodes.map((code) => [code, getStatus(statusList, code)]),
      );
      return {
        [AppointmentStatusCode.Unknown]: AppointmentStatus.unknown,
        // All properties are guaranteed to exist because the object was
        // constructed from a static tuple.
        ...(modifiableStatuses as Required<typeof modifiableStatuses>),
      } as const;
    }),
    shareReplay(1),
  );
}

function getStatus(
  statusList: readonly AppointmentStatus[],
  code: AppointmentStatusCode,
): AppointmentStatus {
  const foundStatus = statusList.find((status) => status.code === code);
  if (!foundStatus) {
    throw new Error(`Missing status "${code}" from status list.`);
  }
  return foundStatus;
}

const usableStatusCodes = [
  AppointmentStatusCode.Open,
  AppointmentStatusCode.OnComplex,
  AppointmentStatusCode.GateIn,
  AppointmentStatusCode.GateOut,
  AppointmentStatusCode.GateOutDoorOccupied,
  AppointmentStatusCode.OffComplex,
  AppointmentStatusCode.Cancelled,
] as const;

const isUsableStatusCode = isOneOfCreator(usableStatusCodes);
