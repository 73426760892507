<header *ngIf="header || isCloseButtonVisible">
  <h4 *ngIf="header">{{ header }}</h4>
  <mr-icon-button
    *ngIf="isCloseButtonVisible"
    (click)="modal.close()"
    label="Close"
  >
    <mr-icon name="close"></mr-icon>
  </mr-icon-button>
</header>

<ng-container
  *ngIf="customContentTemplate; else defaultContentTemplate"
  [ngTemplateOutlet]="customContentTemplate"
></ng-container>

<ng-template #defaultContentTemplate>
  <section>
    <ng-content></ng-content>
  </section>
</ng-template>

<footer *ngIf="actionsTemplate">
  <p class="warning" *ngIf="warningMessage">{{ warningMessage }}</p>
  <div class="actions">
    <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
  </div>
</footer>
