import { ValidatorFn, Validators } from "@angular/forms";
import { isArray, isInteger, isObject } from "lodash-es";
import { Duration } from "luxon";
import { parseDelta } from "../converters/core-converters";
import { hasProperty, isEmptyDelta } from "../miscellaneous";
import {
  EmailValidatorError,
  isEmptyValue,
  MaxLengthValidatorError,
  RequiredValidatorError,
  ValidatorResult,
} from "./validator-utilities";

export * from "./appointment-duration-validator";
export * from "./unique-validator";
export {
  EmailValidatorError,
  MaxLengthValidatorError,
  RequiredValidatorError,
  ValidatorResult,
  makeAsyncValidatorOptional,
  makeValidatorOptional,
  updateValidationError,
} from "./validator-utilities";

export const digitsValidator: ValidatorFn = (control) => {
  if (isEmptyValue(control.value)) {
    return null;
  }

  if (typeof control.value !== "string" && typeof control.value !== "number") {
    throw new Error(
      `Unexpected control value; expected Duration but got: ${String(
        control.value,
      )}`,
    );
  }

  return (Number.isInteger(control.value) && control.value >= 0) ||
    (typeof control.value === "string" && /^\d+$/.test(control.value))
    ? null
    : { digits: { actual: control.value } };
};

export function durationValidator(
  min: Duration | 0,
  max: Duration,
  step: Duration,
): ValidatorFn {
  return Validators.compose([
    minDurationValidator(min),
    maxDurationValidator(max),
    durationStepValidator(step),
  ]);
}

export function durationStepValidator(step: Duration): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    if (!Duration.isDuration(control.value)) {
      throw new Error(
        `Unexpected control value; expected Duration but got: ${String(
          control.value,
        )}`,
      );
    }

    return isInteger(control.value.valueOf() / step.valueOf())
      ? null
      : { durationStep: { step, actual: control.value } };
  };
}

export const emailValidator: ValidatorFn = (
  control,
): ValidatorResult<EmailValidatorError> =>
  isEmptyValue(control.value) ||
  (typeof control.value === "string" && control.value.match(/.+@.+\..+/i))
    ? null
    : { email: true };

export function maxDeltaLengthValidator(requiredLength: number): ValidatorFn {
  return (control): ValidatorResult<MaxLengthValidatorError> => {
    if (isEmptyValue(control.value)) {
      return null;
    }
    if (typeof control.value !== "string") {
      throw new Error(
        `Expected Delta JSON in form control value but got: ${String(
          control.value,
        )}`,
      );
    }
    const delta = parseDelta(control.value);
    // Subtract one from the length to ignore the trailing newline.
    const actualLength = delta ? delta.length() - 1 : 0;
    return actualLength <= requiredLength
      ? null
      : { maxlength: { actualLength, requiredLength } };
  };
}

export function maxDurationValidator(max: Duration): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    if (!Duration.isDuration(control.value)) {
      throw new Error(
        `Unexpected control value; expected Duration but got: ${String(
          control.value,
        )}`,
      );
    }

    return control.value <= max
      ? null
      : { maxDuration: { max, actual: control.value } };
  };
}

export function minDurationValidator(min: Duration | 0): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    if (!Duration.isDuration(control.value)) {
      throw new Error(
        `Unexpected control value; expected Duration but got: ${String(
          control.value,
        )}`,
      );
    }

    return control.value >= min
      ? null
      : {
          minDuration: {
            min: min === 0 ? Duration.fromMillis(0) : min,
            actual: control.value,
          },
        };
  };
}

export function minuteStepValidator(step: number | Duration): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    const actual =
      typeof control.value === "number"
        ? control.value
        : isObject(control.value) &&
          hasProperty(control.value, "minute") &&
          typeof control.value.minute === "number"
        ? control.value.minute
        : null;
    if (actual === null) {
      throw new Error(`Unexpected control value: ${String(control.value)}`);
    }

    const stepInMinutes = typeof step === "number" ? step : step.as("minutes");

    return isInteger(actual / stepInMinutes)
      ? null
      : { minuteStep: { step: stepInMinutes, actual } };
  };
}

export const phoneNumberValidator = Validators.compose([
  Validators.minLength(10),
  Validators.maxLength(10),
  digitsValidator,
]);

export const requiredDeltaValidator: ValidatorFn = (
  control,
): ValidatorResult<RequiredValidatorError> => {
  if (!isEmptyValue(control.value) && typeof control.value !== "string") {
    throw new Error(
      `Expected Delta JSON in form control value but got: ${String(
        control.value,
      )}`,
    );
  }
  return isEmptyDelta(control.value) ? { required: true } : null;
};

export function stepValidator(step: number): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    if (typeof control.value !== "number") {
      throw new Error(
        `Unexpected control value; expected number but got: ${String(
          control.value,
        )}`,
      );
    }

    return isInteger(control.value / step)
      ? null
      : { step: { step, actual: control.value } };
  };
}

export function maxArrayLengthValidator(max: number): ValidatorFn {
  return (control) => {
    if (isEmptyValue(control.value)) {
      return null;
    }

    if (!isArray(control.value)) {
      throw new Error("maxArrayLengthValidator can only be used with arrays.");
    }

    return control.value.length <= max
      ? null
      : { maxArrayLength: { max, actual: control.value } };
  };
}
