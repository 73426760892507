import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-textarea[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [labelHidden]="isLabelHidden"
      [description]="description"
      [kind]="kind"
    >
      <textarea
        #input
        [id]="container.fieldId"
        [attr.title]="isLabelHidden ? label : null"
        [attr.aria-label]="isLabelHidden ? label : null"
        [attr.aria-describedby]="container.descriptionId"
        [attr.maxlength]="maxlength"
        [placeholder]="placeholder"
        [rows]="rows"
        [disabled]="isDisabled"
        [ngModel]="value"
        (ngModelChange)="onInputChange($event)"
        (change)="onBlurChange(input.value); $event.stopPropagation()"
        (blur)="onBlur()"
      ></textarea>
    </mr-field-container>
  `,
  styleUrls: ["textarea.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent extends BaseFieldComponent<string> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public placeholder = "";
  @Input() public rows?: number | string;
  @Input() public updateOn: "blur" | "input" = "input";
  @Input() public maxlength?: number | string;
  @Input() public kind: "form" | "tiled" = "form";

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  public onInputChange(value: string): void {
    if (this.updateOn === "input") {
      this.onChange(value);
    }
  }

  public onBlurChange(value: string): void {
    if (this.updateOn === "blur") {
      this.onChange(value);
    }
  }
}
