import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { Site } from "src/app/core/sites";
import {
  Brand,
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  parseDateTime,
} from "src/utils";
import { HelpAssistUser } from "./help-assist-user.model";
import { HelpAssistTicketReference } from "./models/ticket/base-help-assist-ticket.model";
import type { HelpAssistTicket } from "./models/ticket/help-assist-ticket.model";

export const attachmentsResource = createPartnerResourceUrl(
  "HelpAssistTicketAttachments",
);

const api = getApiDetailsDecorator<Api.Attachment>();

export class Attachment {
  private constructor(args: Omit<ClassProperties<Attachment>, "isDeleted">) {
    this.blobUrl = args.blobUrl;
    this.created = args.created;
    this.deleted = args.deleted;
    this.fileName = args.fileName;
    this.id = args.id;
    this.site = args.site;
    this.ticket = args.ticket;

    this.isDeleted = this.deleted !== null;
  }

  public readonly created: Change;
  public readonly deleted: Change | null;
  @api() public readonly blobUrl: string;
  @api() public readonly fileName: string;
  @api() public readonly id: Brand<number, "attachment">;
  public readonly site: Site;
  @api({ key: "ticketId" }) public readonly ticket: HelpAssistTicketReference;

  public readonly isDeleted: boolean;

  public static deserialize(
    apiModel: ApiModel,
    { site }: DeserializeArguments,
  ): Attachment {
    return new Attachment({
      blobUrl: apiModel.blobUrl,
      created: {
        byUser: HelpAssistUser.deserialize(apiModel.createdByUser),
        on: parseDateTime(apiModel.createdOn),
      },
      deleted:
        apiModel.deletedByUser && apiModel.deletedOn
          ? {
              byUser: HelpAssistUser.deserialize(apiModel.deletedByUser),
              on: parseDateTime(apiModel.deletedOn),
            }
          : null,
      fileName: apiModel.fileName,
      id: apiModel.id,
      site,
      ticket: { id: apiModel.ticketId, site },
    });
  }

  public static deserializeList(
    value: readonly ApiModel[],
    args: DeserializeArguments,
  ): Attachment[] {
    return value.map((ticket) => Attachment.deserialize(ticket, args));
  }
}

interface DeserializeArguments {
  readonly site: Site;
}

export class NewAttachment {
  public constructor(args: ClassProperties<NewAttachment>) {
    this.blobUrl = args.blobUrl;
    this.fileName = args.fileName;
    this.ticket = args.ticket;
  }

  public readonly blobUrl: string;
  public readonly fileName: string;
  public readonly ticket: HelpAssistTicket;

  public serialize(): ApiNewAttachment {
    return {
      blobUrl: this.blobUrl,
      fileName: this.fileName,
      ticketId: this.ticket.id,
    };
  }
}

type ApiNewAttachment = Pick<
  Api.Attachment,
  "blobUrl" | "fileName" | "ticketId"
>;

interface ApiModel extends Api.Attachment {
  createdByUser: Api.HelpAssistTicketUser;
  deletedByUser: Api.HelpAssistTicketUser | null | undefined;
}

interface Change {
  readonly on: DateTime;
  readonly byUser: HelpAssistUser;
}
