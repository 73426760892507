<ng-container *ngIf="getFilter() as filter" [ngSwitch]="filter.type">
  <div *ngSwitchCase="TableColumnFilterType.NumberRange" class="number-filters">
    <input
      attr.aria-label="{{ label }} - Min"
      type="number"
      [ngModel]="$any(filter.value)?.min"
      (ngModelChange)="filterNumberRangeMin($event)"
      (change)="$event.stopPropagation()"
      placeholder="Min"
    />
    <input
      attr.aria-label="{{ label }} - Max"
      type="number"
      [ngModel]="$any(filter.value)?.max"
      (ngModelChange)="filterNumberRangeMax($event)"
      (change)="$event.stopPropagation()"
      placeholder="Max"
    />
  </div>

  <div
    *ngSwitchCase="TableColumnFilterType.DurationRange"
    class="number-filters"
  >
    <input
      attr.aria-label="{{ label }} - Minimum {{ durationScale | titlecase }}"
      title="Minimum {{ durationScale | titlecase }}"
      type="number"
      [ngModel]="getCurrentDurationRangeValue('min')"
      (ngModelChange)="filterDurationRangeMin($event)"
      (change)="$event.stopPropagation()"
      placeholder="Min"
    />
    <input
      attr.aria-label="{{ label }} - Maximum {{ durationScale | titlecase }}"
      title="Maximum {{ durationScale | titlecase }}"
      type="number"
      [ngModel]="getCurrentDurationRangeValue('max')"
      (ngModelChange)="filterDurationRangeMax($event)"
      (change)="$event.stopPropagation()"
      placeholder="Max"
    />
  </div>

  <div
    *ngSwitchCase="TableColumnFilterType.Boolean"
    [attr.aria-label]="label"
    class="check-button-group"
  >
    <label [class.active]="filter.value === true">
      <input
        type="radio"
        name="booleanFilter-{{ filter.key }}"
        [value]="true"
        [ngModel]="filter.value"
        (ngModelChange)="filterBoolean($event)"
        (change)="$event.stopPropagation()"
      />
      {{ trueLabel }}
    </label>

    <label [class.active]="filter.value === false">
      <input
        type="radio"
        name="booleanFilter-{{ filter.key }}"
        [value]="false"
        [ngModel]="filter.value"
        (ngModelChange)="filterBoolean($event)"
        (change)="$event.stopPropagation()"
      />
      {{ falseLabel }}
    </label>

    <mr-icon-button label="Clear Filter" (click)="filterBoolean(null)">
      <mr-icon name="close"></mr-icon>
    </mr-icon-button>
  </div>

  <input
    *ngSwitchCase="TableColumnFilterType.Text"
    [attr.aria-label]="label"
    type="text"
    class="filter-input"
    [ngModel]="filter.value"
    (ngModelChange)="filterText($event)"
    (change)="$event.stopPropagation()"
    placeholder="Search"
  />

  <input
    *ngSwitchCase="TableColumnFilterType.Number"
    [attr.aria-label]="label"
    type="number"
    class="filter-input"
    [ngModel]="filter.value"
    (ngModelChange)="filterNumber($event)"
    (change)="$event.stopPropagation()"
    placeholder="Search"
  />

  <input
    *ngSwitchCase="TableColumnFilterType.NumberIdentifier"
    [attr.aria-label]="label"
    type="number"
    class="filter-input"
    [ngModel]="filter.value"
    (ngModelChange)="filterNumberIdentifier($event)"
    (change)="$event.stopPropagation()"
    placeholder="Search"
  />

  <mr-date-range-filter
    *ngSwitchCase="TableColumnFilterType.DateRange"
    [label]="label"
    labelHidden
    [ngModel]="filter.value"
    (ngModelChange)="filterDateRange($event)"
  ></mr-date-range-filter>

  <mr-date-range-filter
    *ngSwitchCase="TableColumnFilterType.DayRange"
    [label]="label"
    labelHidden
    [ngModel]="filter.value"
    (ngModelChange)="filterDayRange($event)"
  ></mr-date-range-filter>

  <mr-dropdown
    *ngSwitchCase="TableColumnFilterType.Dropdown"
    [label]="label"
    labelHidden
    displayProperty="name"
    [compareWith]="compareDropdown"
    [items]="dropdownData || null"
    [ngModel]="filter.value"
    (ngModelChange)="filterDropdown($event)"
    [placeholder]="
      dropdownSearchNotifier ? 'Select One or Search' : 'Select One'
    "
    [searchable]="!!dropdownSearchNotifier"
    (searchTermChange)="searchDropdown($event)"
  ></mr-dropdown>

  <mr-multi-select
    *ngSwitchCase="TableColumnFilterType.MultiSelect"
    [label]="label"
    labelHidden
    displayProperty="name"
    [compareWith]="compareDropdown"
    [items]="dropdownData || null"
    [ngModel]="filter.value"
    (ngModelChange)="filterMultiSelect($event)"
  ></mr-multi-select>

  <mr-days-filter
    *ngSwitchCase="TableColumnFilterType.Days"
    [label]="label"
    labelHidden
    [ngModel]="filter.value"
    (ngModelChange)="filterDays($event)"
  ></mr-days-filter>

  <mr-timepicker
    *ngSwitchCase="TableColumnFilterType.Time"
    [label]="label"
    labelHidden
    [ngModel]="filter.value"
    (ngModelChange)="filterTime($event)"
    [hideSpinners]="true"
  ></mr-timepicker>
</ng-container>
