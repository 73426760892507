import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms";
import { DateTime, Zone } from "luxon";
import { isInstanceOf, makeValidatorOptional } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-date-time-picker[label][timeZone]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [description]="description"
      [labelHidden]="isLabelHidden"
      [additionalErrorMessageTemplate]="errorMessages"
    >
      <mr-date-time-picker-base
        [fieldId]="container.fieldId"
        [descriptionId]="container.descriptionId"
        [label]="isLabelHidden ? label : ''"
        [timeZone]="timeZone"
        [maxDateTime]="maxDateTime"
        [minDateTime]="minDateTime"
        [disableClear]="disableClear"
        [disabled]="isDisabled"
        [readonly]="isReadonly"
        [value]="value"
        (valueChange)="onChange($event)"
        (unfocus)="onBlur()"
      ></mr-date-time-picker-base>
    </mr-field-container>

    <ng-template #errorMessages let-errors let-errorLabel="errorLabel">
      <li *ngIf="errors.maxDateTime as error">
        {{ errorLabel }} must be at or before
        {{ error.max | dateTime: "long" }}
      </li>
      <li *ngIf="errors.minDateTime as error">
        {{ errorLabel }} must be at or after
        {{ error.min | dateTime: "long" }}
      </li>
    </ng-template>
  `,
  styles: [":host {display: block;}"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerComponent),
    },
  ],
})
export class DateTimePickerComponent extends BaseFieldComponent<
  DateTime | DateTime[] | null
> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public placeholder?: string;
  @Input() public minuteStep = 1;
  @Input() public minDateTime: DateTime | null = null;
  @Input() public maxDateTime: DateTime | null = null;
  @Input() public disableClear = false;
  @Input() public timeZone!: Zone;

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }
}

type MaxDateTimeError = ["maxDateTime", { max: DateTime; actual: DateTime }];
export function maxDateTimeValidator(max: DateTime): ValidatorFn {
  return makeValidatorOptional<MaxDateTimeError>(
    isInstanceOf(DateTime),
    (actual) => (actual <= max ? null : { maxDateTime: { max, actual } }),
  );
}

type MinDateTimeError = ["minDateTime", { min: DateTime; actual: DateTime }];
export function minDateTimeValidator(min: DateTime): ValidatorFn {
  return makeValidatorOptional<MinDateTimeError>(
    isInstanceOf(DateTime),
    (actual) => (actual >= min ? null : { minDateTime: { min, actual } }),
  );
}
