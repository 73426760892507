import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { AlertsService } from "../alerts.service";
import { isPermission, Permission } from "./permissions";
import { UserService } from "./user.service";

@Injectable()
export class HasPermissionGuard implements CanActivate {
  public constructor(
    private readonly router: Router,
    private readonly alerts: AlertsService,
    private readonly user: UserService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): true | UrlTree {
    const singlePermission: unknown = route.data[permissionDataKey];
    const somePermissions: unknown = route.data[multiplePermissionDataKey];

    const permissions = isPermissionsArray(somePermissions)
      ? somePermissions
      : [];
    if (isPermission(singlePermission)) {
      permissions.push(singlePermission);
    }

    if (permissions.length === 0) {
      throw new Error("Missing or invalid permission configuration for route.");
    }

    for (const permission of permissions) {
      if (this.user.permissions.has(permission)) {
        return true;
      }
    }

    this.alerts.warn({
      title: "Access Denied",
      message: "You do not have permission to view that page.",
    });
    return this.router.parseUrl("/");
  }
}

function isPermissionsArray(value: unknown): value is Permission[] {
  return Array.isArray(value) && value.every(isPermission);
}

export const permissionDataKey = "permission";
export const multiplePermissionDataKey = "somePermissions";
