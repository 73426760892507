import { Component, Input } from "@angular/core";

@Component({
  selector: "mr-icon[name]",
  template: `<div [inlineSVG]="iconUrl"></div>`,
  styles: [
    `
      :host {
        display: inline-block;
        width: 1rem;
      }
      div {
        width: 100%;
        line-height: 0;
      }
    `,
  ],
})
export class IconComponent {
  /**
   * Custom SVG icon (by file name).
   * See files "/src/assets/icons/*.svg".
   */
  @Input() public name!: string;

  public get iconUrl(): string {
    return `/assets/icons/${this.name}.svg`;
  }
}
