import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounce } from "lodash-es";
import { Duration } from "luxon";
import { Subject } from "rxjs";
import { Day } from "src/app/core/day.model";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { DurationScale } from "src/app/core/pipes/duration.pipe";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { isExistent, UUID } from "src/utils";
import {
  ClearColumnFilter,
  ColumnFilter,
  DropdownColumnFilterValue,
  TableColumnFilterType,
} from "./table-column-filter.model";

const inputDebounceDuration = 500;

@Component({
  selector: "mr-table-column-filter[label]",
  templateUrl: "./table-column-filter.component.html",
  styleUrls: ["./table-column-filter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableColumnFilterComponent),
    },
  ],
})
export class TableColumnFilterComponent extends BaseFieldComponent<
  ColumnFilter | ClearColumnFilter
> {
  @Input() public key!: string;
  @Input() public trueLabel!: string;
  @Input() public falseLabel!: string;
  @Input() public type?: TableColumnFilterType;
  @Input() public dropdownData?: readonly DropdownColumnFilterValue[];
  @Input() public durationScale!: DurationScale;

  // In order to support detecting when a search notification has been
  // requested, we use a public `Subject` here rather than an `@Output`. This
  // allows us to enable searching without the need for an extra
  // `allowSearching` property as the presence of the notifier implies it.
  // eslint-disable-next-line rxjs/no-exposed-subjects
  @Input() public dropdownSearchNotifier?: Subject<string>;

  public readonly TableColumnFilterType = TableColumnFilterType;

  public readonly filterText = debounce((rawValue: string): void => {
    const value = rawValue.trim();
    this.onChange(
      value
        ? {
            type: TableColumnFilterType.Text,
            key: this.key,
            value,
            isCleared: false,
          }
        : this.getClearFilter(),
    );
  }, inputDebounceDuration);

  public readonly filterNumberRangeMin = debounce(
    (min: number | null): void => {
      this.filterNumberRange(min, this.getCurrentNumberRangeValue("max"));
    },
    inputDebounceDuration,
  );

  public readonly filterNumberRangeMax = debounce(
    (max: number | null): void => {
      this.filterNumberRange(this.getCurrentNumberRangeValue("min"), max);
    },
    inputDebounceDuration,
  );

  public readonly filterDurationRangeMin = debounce(
    (min: number | null): void => {
      this.filterDurationRange(min, this.getCurrentDurationRangeValue("max"));
    },
    inputDebounceDuration,
  );

  public readonly filterDurationRangeMax = debounce(
    (max: number | null): void => {
      this.filterDurationRange(this.getCurrentDurationRangeValue("min"), max);
    },
    inputDebounceDuration,
  );

  public readonly filterDays = debounce((value: Weekdays | null): void => {
    this.onChange(
      value === null || !value.hasAnySelected()
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.Days,
            key: this.key,
            value,
            isCleared: false,
          },
    );
  }, inputDebounceDuration);

  public filterTime(rawValue: TimeOfDay | null): void {
    const value = rawValue;
    this.onChange(
      value
        ? {
            type: TableColumnFilterType.Time,
            key: this.key,
            value,
            isCleared: false,
          }
        : this.getClearFilter(),
    );
  }

  private filterNumberRange(min: number | null, max: number | null): void {
    this.onChange(
      min === null && max === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.NumberRange,
            key: this.key,
            value: { min, max },
            isCleared: false,
          },
    );
  }

  private filterDurationRange(min: number | null, max: number | null): void {
    this.onChange(
      min === null && max === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.DurationRange,
            key: this.key,
            value: {
              min: isExistent(min)
                ? Duration.fromObject({ [this.durationScale]: min })
                : null,
              max: isExistent(max)
                ? Duration.fromObject({ [this.durationScale]: max })
                : null,
            },
            isCleared: false,
          },
    );
  }

  public filterBoolean(value: boolean | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.Boolean,
            key: this.key,
            value,
            isCleared: false,
          },
    );
  }

  public filterNumber(value: number | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.Number,
            key: this.key,
            value,
            isCleared: false,
          },
    );
  }

  public filterNumberIdentifier(value: number | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.NumberIdentifier,
            key: this.key,
            value: value.toString(),
            isCleared: false,
          },
    );
  }

  public filterDateRange(value: { start: Day; end: Day } | null): void {
    this.onChange(
      value
        ? {
            type: TableColumnFilterType.DateRange,
            key: this.key,
            value,
            isCleared: false,
          }
        : this.getClearFilter(),
    );
  }

  public filterDayRange(value: { start: Day; end: Day } | null): void {
    this.onChange(
      value
        ? {
            type: TableColumnFilterType.DayRange,
            key: this.key,
            value,
            isCleared: false,
          }
        : this.getClearFilter(),
    );
  }

  public filterDropdown(value: DropdownColumnFilterValue | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.Dropdown,
            key: this.key,
            value: value.id,
            isCleared: false,
          },
    );
  }

  public filterMultiSelect(value: DropdownColumnFilterValue[] | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
            type: TableColumnFilterType.MultiSelect,
            key: this.key,
            value: value.map((v) => v.id),
            isCleared: false,
          },
    );
  }

  public compareDropdown(
    option: DropdownColumnFilterValue,
    value: DropdownColumnFilterValue | DropdownColumnFilterValue["id"],
  ): boolean {
    if (option.id instanceof UUID && value instanceof UUID) {
      return option.id.isSame(value);
    } else {
      const valueId = (value as DropdownColumnFilterValue)?.id;
      return valueId ? option.id === valueId : option.id === value;
    }
  }

  public searchDropdown(query: string): void {
    if (this.dropdownSearchNotifier) {
      this.dropdownSearchNotifier.next(query);
    }
  }

  public getFilter(): ColumnFilter | ClearColumnFilter {
    return this.value ? this.value : this.getClearFilter();
  }

  public clear(): void {
    // Set the `value` directly rather than calling `onChange` to avoid sending
    // the update notification to the listener. It seems reasonable that any
    // caller of this method would already know about the update and thus would
    // not expect a notification. Perhaps that's an invalid assumption, but this
    // solves the problem of double requesting the model lists backing the table
    // when filters are cleared.
    this.value = this.getClearFilter();
  }

  private getClearFilter(): ClearColumnFilter {
    return {
      type: this.type,
      key: this.key,
      value: null,
      isCleared: true,
    };
  }

  private getCurrentNumberRangeValue(field: "min" | "max"): number | null {
    const filter = this.getFilter();
    if (filter.type !== TableColumnFilterType.NumberRange) {
      throw new Error("Filter type must be number range.");
    }
    return filter.isCleared ? null : filter.value[field];
  }

  public getCurrentDurationRangeValue(field: "min" | "max"): number | null {
    const filter = this.getFilter();
    if (filter.type !== TableColumnFilterType.DurationRange) {
      throw new Error("Filter type must be duration range.");
    }
    return filter.isCleared
      ? null
      : filter.value[field]?.as(this.durationScale) ?? null;
  }
}
