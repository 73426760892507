import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  catchError,
  // eslint-disable-next-line rxjs/no-tap
  tap,
} from "rxjs/operators";
import { ApplicationInsightsService } from "./core/application-insights.service";

@Injectable()
export class NetworkResponseTimeLoggingInterceptor implements HttpInterceptor {
  public constructor(
    private readonly appInsights: ApplicationInsightsService,
  ) {}

  #globalIndex = 0;

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const index = this.#globalIndex++;
    return next.handle(request).pipe(
      tap((httpEvent) => {
        switch (httpEvent.type) {
          case HttpEventType.Sent: {
            this.appInsights.logCustomEventStart(`network-request-${index}`);
            break;
          }
          case HttpEventType.Response: {
            this.appInsights.logCustomEventStop(`network-request-${index}`, {
              request: request.urlWithParams,
              responseStatusCode: String(httpEvent.status),
            });
            break;
          }
        }
      }),
      catchError((error) => {
        this.appInsights.logCustomEventStop(`network-request-${index}`, {
          errorMessage: error instanceof Error ? error.message : String(error),
          request: request.urlWithParams,
        });
        throw error;
      }),
    );
  }
}
