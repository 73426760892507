import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { UnitType } from "src/app/core/sites";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import { throwUnhandledCaseError } from "src/utils";

interface DeserializeArguments {
  readonly doorGroups: readonly DoorGroup[];
}

export class AppointmentSlotDoorGroupCapacities {
  private constructor(
    args: ClassProperties<AppointmentSlotDoorGroupCapacities>,
  ) {
    this.appointmentsScheduled = args.appointmentsScheduled;
    this.casesScheduled = args.casesScheduled;
    this.doorGroup = args.doorGroup;
    this.palletsScheduled = args.palletsScheduled;
    this.unitsScheduled = args.unitsScheduled;
  }

  public readonly appointmentsScheduled: number;
  public readonly casesScheduled: number;
  public readonly doorGroup: DoorGroup;
  public readonly palletsScheduled: number;
  public readonly unitsScheduled: number;

  public static deserialize(
    data: Api.AppointmentSlotDoorGroupCapacities,
    { doorGroups }: DeserializeArguments,
  ): AppointmentSlotDoorGroupCapacities {
    const doorGroup = doorGroups.find(({ id }) => id === data.doorGroupID);
    if (!doorGroup) {
      throw new Error(`Could not find door group with ID ${data.doorGroupID}.`);
    }

    return new AppointmentSlotDoorGroupCapacities({
      appointmentsScheduled: data.apptsScheduled,
      casesScheduled: data.casesScheduled,
      doorGroup,
      palletsScheduled: data.palletsScheduled,
      unitsScheduled: getUnitsScheduled(doorGroup, data),
    });
  }

  public static deserializeList(
    data: readonly Api.AppointmentSlotDoorGroupCapacities[],
    args: DeserializeArguments,
  ): AppointmentSlotDoorGroupCapacities[] {
    return data.map((x) =>
      AppointmentSlotDoorGroupCapacities.deserialize(x, args),
    );
  }
}

// Gets the count of whichever unit type is selected at the door group's site.
function getUnitsScheduled(
  doorGroup: DoorGroup,
  { casesScheduled, palletsScheduled }: Api.AppointmentSlotDoorGroupCapacities,
): number {
  const { unitType } = doorGroup.site;
  switch (unitType) {
    case UnitType.Case:
      return casesScheduled;
    case UnitType.Pallet:
      return palletsScheduled;
    default:
      throwUnhandledCaseError("site unit type", unitType);
  }
}
