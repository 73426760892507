import { ErrorHandler, Injectable } from "@angular/core";
import { ApplicationInsightsService } from "src/app/core/application-insights.service";

@Injectable({ providedIn: "root" })
export class GlobalErrorLoggingService extends ErrorHandler {
  public constructor(private readonly appInsights: ApplicationInsightsService) {
    super();
  }

  public override handleError(error: unknown): void {
    this.appInsights.logException(error);
  }
}
