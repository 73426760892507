import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { PopoverService } from "src/app/core/elements/popover/popover.service";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { mapNullable } from "src/utils";
import {
  IconSelectorPopupComponent,
  IconSelectorPopupContext,
} from "./icon-selector-popup.component";

@Component({
  selector: "mr-icon-selector[label]",
  templateUrl: "./icon-selector.component.html",
  styleUrls: ["./icon-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IconSelectorComponent),
    },
  ],
})
export class IconSelectorComponent extends BaseFieldComponent<string | null> {
  public constructor(private readonly popover: PopoverService) {
    super();
  }

  @Input() public selectFor: string | null = null;

  public readonly selectedIconChanges = this.valueChanges.pipe(
    mapNullable((iconName) =>
      availableIconNames.includes(iconName) ? iconName : null,
    ),
  );

  public async openSelectIconWindow(event: MouseEvent): Promise<void> {
    const currentValue = await firstValueFrom(this.selectedIconChanges);

    this.popover.open<IconSelectorPopupContext>({
      content: IconSelectorPopupComponent,
      data: {
        availableIconNames,
        onSelect: (value) => this.onChange(value),
        selectFor: this.selectFor,
        selectedIcon: currentValue,
      },
      origin: calculatePopupPosition(event),
      shouldCloseOnOutsideClick: true,
    });
  }
}

interface PopupPosition {
  readonly x: number;
  readonly y: number;
}

export function calculatePopupPosition({
  view,
  x,
  y,
}: MouseEvent): PopupPosition {
  const yBottomOffset = 10;
  const yTopOffset = 10;
  const xTopOffset = 5;

  const viewHeightCenter = (view?.innerHeight ?? 0) / 2;
  const xPosition = x + (viewHeightCenter > x ? 0 : -xTopOffset);
  const yPosition = y + (viewHeightCenter > y ? yBottomOffset : -yTopOffset);

  return { x: xPosition, y: yPosition };
}

/**
 * Available icons for the user to choose from.
 *
 * For more Material Icons, see "https://fonts.google.com/icons".
 */
const availableIconNames: string[] = [
  "material/add",
  "material/code",
  "material/done",
  "material/tune",
  "material/highlight_alt",
  "material/grain",
  "material/leak_add",
  "material/file_download",
  "material/edit",
  "material/directions_car",
  "material/settings",
  "material/notifications",
  "material/local_cafe",
  "material/arrow_back",
  "material/change_history",
  "material/done_all",
  "material/filter_tilt_shift",
  "material/border_clear",
  "material/share",
  "material/auto_fix_high",
  "material/data_usage",
  "material/directions_walk",
  "material/settings_system_daydream",
  "material/location_on",
  "material/compare",
  "material/headphones",
  "material/close",
  "material/favorite_border",
  "material/open_in_new",
  "material/flare",
  "material/border_bottom",
  "material/mic",
  "material/local_bar",
  "material/face",
  "material/directions_run",
  "material/local_pizza",
  "material/person_outline",
  "material/explore",
  "material/directions_boat",
  "material/menu",
  "material/error_outline",
  "material/exit_to_app",
  "material/add_circle_outline",
  "material/border_vertical",
  "material/flash_on",
  "material/date_range",
  "material/gradient",
  "material/directions_bike",
  "material/home",
  "material/portrait",
  "material/print",
  "material/insert_photo",
  "material/refresh",
  "material/star_border",
  "material/lock",
  "material/note_add",
  "material/poll",
  "material/flight",
  "material/event",
  "material/filter_b_and_w",
  "material/star",
  "material/build",
  "material/cloud_done",
  "material/beenhere",
  "material/subtitles",
  "material/more_vert",
  "material/flag",
  "material/pie_chart",
  "material/wifi_tethering",
  "material/assignment_ind",
  "material/opacity",
  "material/card_giftcard",
  "material/shopping_cart",
  "material/monochrome_photos",
  "material/local_drink",
  "material/content_paste",
  "material/movie",
  "material/camera_roll",
  "material/search",
  "material/cloud",
  "material/mail_outline",
  "material/grid_on",
  "material/language",
  "material/landscape",
  "material/send",
  "material/business",
  "material/local_florist",
  "material/fastfood",
  "material/description",
  "material/mail",
  "material/comment",
];
