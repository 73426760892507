<ng-container *ngIf="selectedSiteChanges | async as site">
  <h3 class="heading4">
    Appointment Details
    <span *ngIf="changeCount" class="change-count">
      {{ changeCount | amount: "change" }}
    </span>
  </h3>

  <ng-container
    *ngIf="
      {
        currentValues: currentValuesChanges | async,
        updatedValues: updatedValuesChanges | async
      } as data;
      else loading
    "
  >
    <mr-details-list split="thirds" [alignTitle]="alignTitle">
      <mr-details-item
        title="Load Number:"
        *ngIf="(appointmentChanges | async)?.loadNumber as loadNumber"
      >
        {{ loadNumber }}
      </mr-details-item>

      <mr-details-item
        title="Current Appt:"
        *ngIf="appointmentChanges | async as appointment"
      >
        <mr-link [link]="appointment.getRouteUrl()"
          >Appointment {{ appointment.confirmationNumber }}:
          {{ appointment.status.name }}
        </mr-link>
      </mr-details-item>

      <mr-details-item title="Carrier of Record:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.carrier?.name"
          [changeValue]="data.updatedValues?.carrier?.name"
        >
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Drop Load:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.isDropLoad"
          [changeValue]="data.updatedValues?.isDropLoad"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value | boolean }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Unloaded by:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.unloader"
          [changeValue]="data.updatedValues?.unloader"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ { unloader: value, site: site } | unloader }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item
        title="Intermodal:"
        *ngIf="site.isAppointmentIntermodalFlagEnabled"
      >
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.isIntermodal"
          [changeValue]="data.updatedValues?.isIntermodal"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value | boolean }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item
        title="Load Weight:"
        *ngIf="site.isAppointmentLoadWeightEnabled"
      >
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.loadWeight"
          [changeValue]="data.updatedValues?.loadWeight"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value }} LB
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Load Type:" *ngIf="site.isLoadTypeRequired">
        <mr-appointment-details-entry
          [currentValue]="getLoadType(data.currentValues)"
          [changeValue]="data.updatedValues?.loadType"
        >
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Delivery Carrier:">
        <mr-appointment-details-entry
          [currentValue]="
            data.currentValues?.deliveryCarrier?.name ||
            data.currentValues?.unknownDeliveryCarrierName ||
            null
          "
          [changeValue]="
            data.updatedValues?.deliveryCarrier?.name ||
            data.updatedValues?.unknownDeliveryCarrierName
          "
        >
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Notification Email:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.notificationList"
          [changeValue]="data.updatedValues?.notificationList"
        >
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Schedule:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.scheduledArrivalDay"
          [changeValue]="data.updatedValues?.scheduledArrivalDay"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value | day }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Time:">
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.slotStartTime"
          [changeValue]="data.updatedValues?.slotStartTime"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value | dateTimeInSiteZone: "time" }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item
        title="Door:"
        *mrIfHasPermission="
          Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors;
          operator: 'or';
          condition: site.canRestrictedUsersViewAppointmentTimestampsAndDoors
        "
      >
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.door?.name || null"
          [changeValue]="data.updatedValues?.door?.name"
        >
        </mr-appointment-details-entry>
      </mr-details-item>

      <mr-details-item title="Total Units:">
        <mr-appointment-details-entry
          [currentValue]="
            data.currentValues?.totalWarehousePalletCountOverride ||
            data.currentValues?.totalWarehousePalletCount ||
            0
          "
          [changeValue]="
            data.updatedValues?.totalWarehousePalletCountOverride ||
            data.updatedValues?.totalWarehousePalletCount
          "
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">{{
            value | amount: "Pallet"
          }}</ng-template>
        </mr-appointment-details-entry>
        /
        <mr-appointment-details-entry
          [currentValue]="data.currentValues?.totalCaseCount || 0"
          [changeValue]="data.updatedValues?.totalCaseCount"
        >
          <ng-template mrTicketAppointmentDetailsEntry let-value="value">
            {{ value | amount: "Case" }}
          </ng-template>
        </mr-appointment-details-entry>
      </mr-details-item>
    </mr-details-list>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mr-loading></mr-loading>
</ng-template>
