import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { getApiDetailsDecorator } from "src/utils";
import { CarrierType } from "./carrier-type.model";

const api = getApiDetailsDecorator<Api.CarrierUpdate>();

export abstract class BaseCarrier {
  protected constructor(args: ClassProperties<BaseCarrier>) {
    this.comments = args.comments;
    this.contactEmails = args.contactEmails;
    this.isActive = args.isActive;
    this.key = args.key;
    this.name = args.name;
    this.type = args.type;
  }

  @api() public readonly comments: string | null;
  @api({ key: "appointmentNotificationEmails" })
  public readonly contactEmails: string | null;
  @api({ key: "active" }) public readonly isActive: boolean;
  @api({ key: "carrierKey" }) public readonly key: string;
  @api() public readonly name: string;
  @api({ key: "carrierTypeID" }) public readonly type: CarrierType;
}
