import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppointmentFieldComponent } from "./appointment-field.component";
import { AuthModule } from "./auth/auth.module";
import { DirtyFormModalComponent } from "./dirty-form-modal.component";
import { ElementsModule } from "./elements/elements.module";
import { FormsModule } from "./forms/forms.module";
import { GatePassModalComponent } from "./gate-pass-modal.component";
import { LayoutModule } from "./layout/layout.module";
import { NavigationModule } from "./navigation/navigation.module";
import { PipesModule } from "./pipes/pipes.module";
import {
  PurchaseOrderCreationFieldComponent,
  PurchaseOrderCreationFieldFormComponent,
} from "./purchase-order-creation-field.component";
import {
  SiteFieldComponent,
  SiteFieldFormComponent,
} from "./sites/site-field.component";
import { TableModule } from "./table";

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    ElementsModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    NavigationModule,
    PipesModule,
    RouterModule,
    TableModule,
  ],
  declarations: [
    AppointmentFieldComponent,
    DirtyFormModalComponent,
    GatePassModalComponent,
    PurchaseOrderCreationFieldComponent,
    PurchaseOrderCreationFieldFormComponent,
    SiteFieldComponent,
    SiteFieldFormComponent,
  ],
  exports: [
    AppointmentFieldComponent,
    AuthModule,
    CommonModule,
    ElementsModule,
    FormsModule,
    LayoutModule,
    NavigationModule,
    PipesModule,
    PurchaseOrderCreationFieldComponent,
    SiteFieldComponent,
    TableModule,
  ],
})
export class CoreModule {}
