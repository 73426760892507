import { DateTime } from "luxon";
import {
  createGlobalResourceUrl,
  getEnumMember,
  GlobalResourceModel,
  ODataModel,
  parseDateTime,
} from "src/utils";

export const emailActivityResource =
  createGlobalResourceUrl("getEmailActivity");

type ApiEmailActivity = GlobalResourceModel<typeof emailActivityResource>;
type ApiEmailActivityMessage = ODataModel<ApiEmailActivity["value"]>;

export enum EmailStatus {
  Delivered = "delivered",
  NotDelivered = "not_delivered",
  Processing = "processing",
}

export class EmailInformation {
  private constructor(args: ClassProperties<EmailInformation>) {
    this.lastEventTime = args.lastEventTime;
    this.openedCount = args.openedCount;
    this.recipient = args.recipient;
    this.status = args.status;
  }

  public readonly lastEventTime: DateTime | null;
  public readonly openedCount: number | null;
  public readonly recipient: string | null;
  public readonly status: EmailStatus | null;

  public static deserialize(data: ApiEmailActivityMessage): EmailInformation {
    return new EmailInformation({
      lastEventTime: parseDateTime(data.lastEventTime),
      openedCount: data.opensCount ?? null,
      recipient: data.toEmail || null,
      status: getEnumMember(EmailStatus, data.status, null),
    });
  }

  public static deserializeList(
    data: readonly ApiEmailActivityMessage[],
  ): EmailInformation[] {
    return data.map(EmailInformation.deserialize);
  }
}
