import { Component, Input } from "@angular/core";
import { HelpAssistTicketAppointmentDraftUpdate } from "../models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { HelpAssistTicketAppointmentDraft } from "../models/ticket-appointment-draft/help-assist-ticket-appointment-draft.model";
import { HelpAssistTicketAppointmentOrderUpdate } from "../models/ticket-order/help-assist-ticket-appointment-order-update.model";
import { HelpAssistTicketAppointmentOrder } from "../models/ticket-order/help-assist-ticket-appointment-order.model";

@Component({
  selector: "mr-ticket-po-list[title]",
  templateUrl: "./ticket-po-list.component.html",
  styleUrls: ["./ticket-po-list.component.scss"],
})
export class TicketPoListComponent {
  @Input() public title?: string;
  @Input() public appointment!:
    | HelpAssistTicketAppointmentDraft
    | HelpAssistTicketAppointmentDraftUpdate;

  public get orders(): ReadonlyArray<
    HelpAssistTicketAppointmentOrder | HelpAssistTicketAppointmentOrderUpdate
  > | null {
    return this.appointment.orders;
  }
}
