import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { getUpdatedPropertiesFilter } from "src/utils";
import { BaseCarrier } from "./base-carrier.model";
import { Carrier } from "./carrier.model";

export type CarrierUpdateArgs = ClassProperties<CarrierUpdate>;

export class CarrierUpdate extends BaseCarrier {
  public constructor({ base, ...args }: CarrierUpdateArgs) {
    super(args);
    this.base = base;

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, base, args);
  }

  private readonly filterUpdatedProperties: FilterPartial<Api.CarrierUpdate>;
  public readonly base: Carrier | null;

  public serialize(): Partial<Api.CarrierUpdate> {
    return this.filterUpdatedProperties({
      active: this.isActive,
      appointmentNotificationEmails: this.contactEmails?.trim() ?? null,
      carrierKey: this.key,
      carrierTypeID: this.type?.id ?? null,
      comments: this.comments,
      name: this.name,
    });
  }
}
