import { CapstoneSitesApi } from "@capstone/mock-api";
import { UUID } from "src/utils";

export class CapstoneSite {
  public constructor(args: ClassProperties<CapstoneSite>) {
    this.name = args.name;
    this.number = args.number;
    this.partner = args.partner;
  }

  public readonly name: string;
  public readonly number: number;
  public readonly partner: CapstonePartner;

  public static deserialize(data: CapstoneSitesApi.Site): CapstoneSite {
    return new CapstoneSite({
      name: data.displayName,
      number: data.subDept,
      partner: CapstonePartner.deserialize(data.partner),
    });
  }

  public static deserializeList(
    value: CapstoneSitesApi.Site[],
  ): readonly CapstoneSite[] {
    return value.map((x) => CapstoneSite.deserialize(x));
  }
}

type ApiPartner = CapstoneSitesApi.Site["partner"];

export class CapstonePartner {
  private constructor(args: ClassProperties<CapstonePartner>) {
    this.key = args.key;
    this.name = args.name;
  }

  public readonly key: UUID;
  public readonly name: string;

  public static deserialize(data: ApiPartner): CapstonePartner {
    return new CapstonePartner({
      key: new UUID(data.key),
      name: data.name,
    });
  }
}
