<button
  class="button"
  [type]="type"
  [attr.form]="form"
  [class.button--primary]="kind === 'primary' && buttonStyle === 'normal'"
  [class.button--primary-danger]="
    kind === 'primary' && buttonStyle === 'danger'
  "
  [class.button--secondary]="kind === 'secondary' && buttonStyle === 'normal'"
  [class.button--secondary-danger]="
    kind === 'secondary' && buttonStyle === 'danger'
  "
  [class.button--cancel]="kind === 'cancel'"
  [class.button--cancel-important]="kind === 'cancel-important'"
  [class.link]="kind === 'link' || kind === 'link-secondary'"
  [class.link--secondary]="kind === 'link-secondary'"
  [class.link--danger]="kind === 'link' && buttonStyle === 'danger'"
  [class.button--large]="size === 'large'"
  [class.button--extra-large]="size === 'extra-large'"
  [disabled]="isLoading || isDisabled"
>
  <ng-content></ng-content>
  <mr-loading kind="input" *ngIf="isLoading"></mr-loading>
</button>
