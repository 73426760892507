import { AbstractControl, FormArray, ValidatorFn } from "@angular/forms";
import { groupBy } from "lodash-es";
import { updateValidationError, ValidatorResult } from "./validator-utilities";

export type UniqueValidatorError<T> = ["unique", { match: T; actual: T }];
export function uniqueValidator<T>(
  collection: readonly T[],
  compareBy: (value: T) => unknown = (value) => value,
): ValidatorFn {
  return (control): ValidatorResult<UniqueValidatorError<T>> => {
    // It will be up to the caller to ensure that this is only used on a form
    // control that has the same type as the `collection` elements since we
    // can't validate that here before passing it on to `compareBy`.
    const actual = control.value as T;
    const match = collection.find((collectionItem) => {
      return compareBy(collectionItem) === compareBy(actual);
    });
    return match !== undefined ? { unique: { match, actual } } : null;
  };
}

export function allUniqueStringsValidator<T>(
  property: {
    [P in StringKeys<T>]: T[P] extends string ? P : never;
  }[StringKeys<T>],
): ValidatorFn {
  return (formArray) => {
    if (!(formArray instanceof FormArray)) {
      throw new Error("Invalid form control type for unique validator.");
    }

    const items: Array<{ value: string; control: AbstractControl }> = [];
    for (const control of formArray.controls) {
      const propertyControl = control.get(property);
      if (!propertyControl) {
        throw new Error(`Could not find control with name "${property}".`);
      }
      if (!propertyControl.value) {
        // Ignore empty values. That will be handled by the required validator.
        continue;
      }
      if (typeof propertyControl.value !== "string") {
        throw new Error("Unexpected control value type.");
      }
      items.push({ value: propertyControl.value, control: propertyControl });
    }

    const controlsByValue = groupBy(items, ({ value }) =>
      value.toLowerCase().trim(),
    );

    let isError = false;
    for (const [match, controls] of Object.entries(controlsByValue)) {
      const isNotUnique = controls.length > 1;
      isError = isError || isNotUnique;

      for (const { control, value } of controls) {
        updateValidationError<UniqueValidatorError<string>>(
          control,
          "unique",
          isNotUnique ? { match, actual: value } : null,
        );
      }
    }

    return isError ? { allUniqueStrings: true } : null;
  };
}
