import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { combineLatest, merge, of, ReplaySubject, switchMap } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { Permission } from "src/app/core/auth";
import { isAwaitingApproval } from "src/app/core/pipes/help-assist-ticket-status.pipe";
import { SitesService } from "src/app/core/sites";
import { HelpAssistTicketAppointment } from "src/app/partner/help-assist/models/help-assist-ticket-appointment.model";
import { BaseHelpAssistAppointmentDraftTicket } from "src/app/partner/help-assist/models/ticket-appointment-draft/base-help-assist-ticket-appointment-draft.model";
import { HelpAssistTicketAppointmentDraftUpdate } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { HelpAssistTicketAppointmentDraft } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft.model";
import { PartnerHelpAssistTicket } from "src/app/partner/help-assist/models/ticket/help-assist-ticket.model";
import { mapNullable } from "src/utils";
import { TicketAppointmentDetailsEntryComponent } from "./ticket-appointment-details-entry.component";

@Component({
  selector: "mr-ticket-appointment-details",
  templateUrl: "./ticket-appointment-details.component.html",
  styleUrls: ["./ticket-appointment-details.component.scss"],
})
export class TicketAppointmentDetailsComponent implements AfterViewInit {
  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly sites: SitesService,
  ) {}

  @Input() public alignTitle?: string;

  @Input() public set valuesWithNoHighlight(
    value:
      | HelpAssistTicketAppointmentDraftUpdate
      | HelpAssistTicketAppointmentDraft
      | null,
  ) {
    this.valuesWithNoHighlightInput.next(value);
  }
  private readonly valuesWithNoHighlightInput = new ReplaySubject<
    | HelpAssistTicketAppointmentDraftUpdate
    | HelpAssistTicketAppointmentDraft
    | null
  >(1);

  @Input() public set ticket(value: PartnerHelpAssistTicket | null) {
    this.ticketInput.next(value);
  }
  private readonly ticketInput =
    new ReplaySubject<PartnerHelpAssistTicket | null>(1);

  private readonly isTicketResolvedChanges = this.ticketInput.pipe(
    mapNullable((ticket) => ticket.isResolved),
    shareReplay(1),
  );

  private readonly isTicketAwaitingApprovalChanges = this.ticketInput.pipe(
    mapNullable((ticket) =>
      isAwaitingApproval(
        ticket.schedule?.appointment?.appointmentApproval?.status,
      ),
    ),
    shareReplay(1),
  );

  private readonly ticketAppointmentChanges = this.ticketInput.pipe(
    mapNullable((ticket) => ticket.schedule?.ticketAppointment ?? null),
    shareReplay(1),
  );

  public readonly appointmentChanges = this.ticketInput.pipe(
    mapNullable((ticket) => ticket.schedule?.appointment ?? null),
    shareReplay(1),
  );

  public readonly currentValuesChanges = merge(
    this.valuesWithNoHighlightInput,
    combineLatest([
      this.isTicketAwaitingApprovalChanges,
      this.isTicketResolvedChanges,
      this.appointmentChanges,
    ]).pipe(
      switchMap(([isTicketAwaitingApproval, isTicketResolved, appointment]) => {
        return isTicketResolved || isTicketAwaitingApproval || !appointment
          ? this.ticketAppointmentChanges
          : this.appointmentChanges;
      }),
    ),
  ).pipe(shareReplay(1));

  public readonly updatedValuesChanges = combineLatest([
    this.isTicketAwaitingApprovalChanges,
    this.isTicketResolvedChanges,
  ]).pipe(
    switchMap(([isTicketAwaitingApproval, isTicketResolved]) => {
      if (isTicketResolved) {
        return of(null);
      }

      return isTicketAwaitingApproval ? this.appointmentChanges : of(null);
    }),
    shareReplay(1),
  );

  @ViewChildren(TicketAppointmentDetailsEntryComponent)
  public readonly detailsEntryRefs!: QueryList<
    TicketAppointmentDetailsEntryComponent<unknown>
  >;

  public readonly Permission = Permission;
  public readonly selectedSiteChanges = this.sites.selectedChanges;
  public changeCount = 0;

  public ngAfterViewInit(): void {
    // Find the nested `mr-content-change` components with kind='new' (the highlighted ones)
    this.changeCount = this.detailsEntryRefs.filter((entryRef) => {
      return entryRef.isEntryOfKindNew;
    }).length;
    this.cdr.detectChanges();
  }

  public getLoadType(
    values:
      | BaseHelpAssistAppointmentDraftTicket
      | HelpAssistTicketAppointment
      | null,
  ): string | null {
    if (!values) return null;

    return values instanceof BaseHelpAssistAppointmentDraftTicket
      ? values.doorGroup?.name ?? null
      : values?.loadType ?? null;
  }
}
