import { Component } from "@angular/core";
import { ButtonComponent } from "./button.component";

@Component({
  selector: "mr-add-new-button",
  template: `
    <mr-button [type]="type" [kind]="kind" [size]="size">
      <mr-icon name="add-outlined" aria-hidden="true"></mr-icon>
      <ng-content></ng-content>
    </mr-button>
  `,
  styleUrls: ["add-new-button.component.scss"],
})
export class AddNewButtonComponent extends ButtonComponent {}
