import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime } from "luxon";
import { getApiDetailsDecorator, parseDateTime } from "src/utils";

const api = getApiDetailsDecorator<Api.HelpAssistTicketResolution>();

export class HelpAssistTicketResolution {
  private constructor(args: ClassProperties<HelpAssistTicketResolution>) {
    this.id = args.id;
    this.internalResolutionNote = args.internalResolutionNote;
    this.createdOn = args.createdOn;
  }

  @api() public readonly id: number;
  @api() public readonly internalResolutionNote: string | null;
  @api() public readonly createdOn: DateTime;

  public static deserialize(apiModel: ApiModel): HelpAssistTicketResolution {
    return new HelpAssistTicketResolution({
      createdOn: parseDateTime(apiModel.createdOn),
      id: apiModel.id,
      internalResolutionNote: apiModel.internalResolutionNote || null,
    });
  }

  public static deserializeList(
    value: readonly ApiModel[],
  ): HelpAssistTicketResolution[] {
    return value.map((resolution) =>
      HelpAssistTicketResolution.deserialize(resolution),
    );
  }
}

type ApiModel = Api.HelpAssistTicketResolution;
