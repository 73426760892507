import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "phoneNumber" })
export class PhoneNumber implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) {
      return "";
    }

    let result = value.replace(/\D/g, "");

    if (value.length === 0) {
      return "";
    }

    if (result.length <= 3) {
      result = result.replace(/^(\d{0,3})/, "$1");
    } else if (result.length <= 6) {
      result = result.replace(/^(\d{0,3})(\d{0,3})/, "$1-$2");
    } else if (result.length <= 10) {
      result = result.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
    } else {
      result = result.substring(0, 10);
      result = result.replace(/^(\d{0,3})(\d{0,3})(.*)/, "$1-$2-$3");
    }

    return result;
  }

  public parse(value: string | null): string {
    if (!value) {
      return "";
    }

    return value.replace(/\D/g, "");
  }
}
