import { combineLatest, Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { addOrUpdateApiModel } from "./miscellaneous";
import { mapArrayUpdates, mapNullable } from "./operators";

/**
 * Creates an observable for deserializing the provided API model list changes
 * into UI model classes, optionally including any deserialization arguments and
 * any updates to the list.
 *
 * @param args.deserialize - The deserializer to run to get the UI model list.
 * @param args.list - The changes to the API list results to be deserialized.
 * @param args.arguments - The changes to arguments to the deserializer.
 * @param args.updates - The updates over time to make to the list.
 */
export function deserializeApiModelList<
  ApiCollection extends BaseApiCollection,
  Arguments,
  UIModelList,
>(
  args:
    | {
        readonly list: Observable<ApiCollection | null>;
        readonly updates?: Observable<UpdateValue<ApiCollection>>;
        readonly arguments: Observable<Arguments | null>;
        // This needs to be defined with the arrow function syntax for some
        // reason. Otherwise, the `arguments` type isn't properly checked
        // against the `args` parameter to `deserialize`.
        // (`Observable<{ foo: string | null }>` will be allowed for `arguments`
        // even if `args` requires `{ foo: string }`.)
        deserialize: (
          collection: ApiCollection,
          args: Arguments,
        ) => UIModelList;
      }
    | {
        readonly list: Observable<ApiCollection | null>;
        readonly updates?: Observable<UpdateValue<ApiCollection>>;
        deserialize: (collection: ApiCollection) => UIModelList;
      },
): Observable<UIModelList | null> {
  const normalizedUpdates = args.updates?.pipe(
    map((update) =>
      update instanceof ApiModelListChange
        ? update
        : new ApiModelListUpdate(update),
    ),
  );

  const listWithUpdates = normalizedUpdates
    ? args.list.pipe(
        mapArrayUpdates(normalizedUpdates, (collection, { type, value }) =>
          addOrUpdateApiModel(collection, value, { remove: type === "delete" }),
        ),
      )
    : args.list;

  if (!("arguments" in args)) {
    return listWithUpdates.pipe(mapNullable((list) => args.deserialize(list)));
  }

  return combineLatest([listWithUpdates, args.arguments]).pipe(
    // Prevent double update when a common change occurs between both the
    // API results and the deserialize arguments.
    debounceTime(0),
    map(
      ([list, deserializeArguments]) =>
        list &&
        deserializeArguments &&
        args.deserialize(list, deserializeArguments),
    ),
  );
}

/**
 * Creates an observable from a function/action request and arguments
 * observables for deserializing the API models into our model classes.
 *
 * @param deserialize - The function to run to deserialize the API results.
 * @param request - The observable representing the request for the results.
 * @param arguments - The extra arguments to pass to the deserializer
 * function.
 */
export function deserializeApiFunctionResponseModel<
  ApiModel,
  ArgumentsParameter,
  // Need to "extend" the function argument type to ensure that it takes
  // precedence when capturing the type at the call site. That way, we make sure
  // that the `arguments` property must conform to the `deserialize` interface
  // and not the other way around. Otherwise, an object with fewer properties in
  // `arguments` could be provided than is needed for `deserialize`.
  Arguments extends ArgumentsParameter,
  UIModel,
>(args: {
  request: Observable<ApiModel | null>;
  arguments: Observable<Arguments | null>;
  deserialize(result: ApiModel, args: ArgumentsParameter): UIModel;
}): Observable<UIModel | null> {
  return combineLatest([args.request, args.arguments]).pipe(
    // Prevent double update when a common change occurs between both the
    // API results and the deserialize arguments.
    debounceTime(0),
    map(
      ([result, deserializeArguments]) =>
        result &&
        deserializeArguments &&
        args.deserialize(result, deserializeArguments),
    ),
  );
}

interface BaseApiCollection {
  readonly value: ReadonlyArray<{ readonly id: number }>;
}

type UpdateValue<Collection extends BaseApiCollection> =
  | Collection["value"][number]
  | ApiModelListUpdate<Collection["value"][number]>
  | ApiModelListDelete<{ readonly id: number }>;

const apiModelListChangeBrand = Symbol("API Model List Change");
class ApiModelListChange<T extends { readonly id: number }> {
  protected constructor(
    public readonly type: "update" | "delete",
    public readonly value: T,
  ) {}

  protected readonly [apiModelListChangeBrand] = "brand";
}

export class ApiModelListUpdate<
  T extends { readonly id: number },
> extends ApiModelListChange<T> {
  public constructor(value: T) {
    super("update", value);
  }

  public override readonly type = "update";
}

export class ApiModelListDelete<
  T extends { readonly id: number },
> extends ApiModelListChange<T> {
  public constructor(value: T) {
    super("delete", value);
  }

  public override readonly type = "delete";
}
