import { Component } from "@angular/core";
import { UserService } from "src/app/core/auth";

@Component({
  selector: "mr-logout-button",
  template: `
    <mr-icon-button label="Log Out" (click)="logout()">
      <mr-icon name="logout"></mr-icon> Log Out
    </mr-icon-button>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
      mr-icon {
        margin-right: 0.2rem;
        transform: rotate(180deg);
      }
    `,
  ],
})
export class LogoutButtonComponent {
  public constructor(private readonly user: UserService) {}
  public logout(): void {
    this.user.logout();
  }
}
