<mr-button
  type="button"
  kind="primary"
  size="normal"
  mrClickOutside
  (clickOutside)="toggleMenu = false"
  (click)="toggleMenu = !toggleMenu"
  [disabled]="isDisabled"
>
  <mr-icon name="list" aria-hidden="true"></mr-icon>
  {{ buttonLabel }}
</mr-button>

<div class="menu" *ngIf="toggleMenu && !isDisabled">
  <ng-content></ng-content>
</div>
