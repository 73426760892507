import { Inject, Injectable, InjectionToken, Provider } from "@angular/core";
import { keyBy, partition } from "lodash-es";
import { combineLatest, firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { debounceTime, map, shareReplay } from "rxjs/operators";
import { Pagination } from "src/app/core/pagination";
import { SettingsTableColumn } from "src/app/core/settings-table-columns";
import { UserPreferencesService } from "src/app/core/user-preferences.service";
import { ColumnSort, isExistent, SortOrder } from "src/utils";
import { ColumnFilter } from "./table-column-filter.model";

export interface TableConfig {
  readonly key: string;
}

export const TABLE_CONFIG = new InjectionToken<TableConfig>("Table Config");

@Injectable()
export class TableService {
  public constructor(
    @Inject(TABLE_CONFIG) private readonly config: TableConfig,
    private readonly userPreferences: UserPreferencesService,
  ) {}

  private defaultColumns?: readonly SettingsTableColumn[];

  private readonly columnFilters = new Map<string, ColumnFilter>();

  public readonly pagination = new Pagination();

  private readonly availableColumnsSubject = new ReplaySubject<
    readonly ColumnConfig[]
  >(1);

  public readonly displayedColumnsChanges: Observable<
    readonly DisplayedColumn[] | null
  > = combineLatest([
    this.userPreferences.getTableColumnsChanges(this.config.key),
    // Push the first emission to the next tick to avoid ExpressionChangedAfterItHasBeenCheckedError.
    this.availableColumnsSubject.pipe(debounceTime(0)),
  ]).pipe(
    map(
      ([columns, availableColumns]) =>
        columns && Array.from(getDisplayedColumns(columns, availableColumns)),
    ),
    shareReplay(1),
  );

  public readonly columnFilterListChanges: Observable<
    readonly ColumnFilter[] | undefined
  > = this.displayedColumnsChanges.pipe(
    map((columns) => columns?.map(({ filter }) => filter).filter(isExistent)),
  );

  public readonly columnSortChanges: Observable<ColumnSort | undefined> =
    this.userPreferences.getTableSortChanges(this.config.key);

  public static getProviders(config: TableConfig): Provider[] {
    return [{ provide: TABLE_CONFIG, useValue: config }, TableService];
  }

  public setDefaultColumns(keys: readonly string[]): void {
    this.defaultColumns = keys.map((key) => ({ key }));
  }

  /**
   * Sets the list of columns that are currently available to include in the
   * table, regardless of what's configured in the user's preferences.
   *
   * @param keys - The column keys to set.
   */
  public setAvailableColumns(keys: readonly ColumnConfig[]): void {
    this.availableColumnsSubject.next(keys);
  }

  public async updateDisplayedColumns(keys: readonly string[]): Promise<void> {
    const columns = await firstValueFrom(
      this.userPreferences.getTableColumnsChanges(this.config.key),
    );
    const columnsLookup: LookupMap<SettingsTableColumn> = keyBy(
      columns,
      (column) => column.key,
    );

    const updatedColumns = keys.map((key) => columnsLookup[key] ?? { key });
    await this.userPreferences.setTableColumns(this.config.key, updatedColumns);
  }

  public async addFilter(filter: ColumnFilter): Promise<void> {
    this.columnFilters.set(filter.key, filter);
    await this.storeCurrentFilters();
  }

  public async removeFilter(key: string): Promise<void> {
    this.columnFilters.delete(key);
    await this.storeCurrentFilters();
  }

  public async clearFilters(): Promise<void> {
    this.columnFilters.clear();
    await this.storeCurrentFilters();
  }

  private async storeCurrentFilters(): Promise<void> {
    const filters = Array.from(this.columnFilters.values());
    await this.storeTableColumnFilters(filters);
  }

  public async sortColumn(key: string, order: SortOrder): Promise<void> {
    await this.storeTableColumnSort({ key, order });
  }

  public async removeSort(): Promise<void> {
    await this.storeTableColumnSort(undefined);
  }

  private async storeTableColumnFilters(
    filters: readonly ColumnFilter[],
  ): Promise<void> {
    const maybeColumns = await firstValueFrom(
      this.userPreferences.getTableColumnsChanges(this.config.key),
    );
    const columns = maybeColumns ?? this.defaultColumns;
    if (!columns?.length) {
      throw new Error(
        `Default table columns were not set for table "${this.config.key}".`,
      );
    }

    const filtersLookup: LookupMap<ColumnFilter> = keyBy(
      filters,
      (filter) => filter.key,
    );

    const updatedColumns = columns.map((column) => ({
      ...column,
      filter: filtersLookup[column.key],
    }));
    await this.userPreferences.setTableColumns(this.config.key, updatedColumns);
  }

  private async storeTableColumnSort(
    sort: ColumnSort | undefined,
  ): Promise<void> {
    await this.userPreferences.setTableSort(this.config.key, sort);
  }
}

/**
 * Gets the set of columns, including the permanent columns and the fixed
 * columns at the front regardless of the user's configured columns, excluding
 * any that are no currently available in the table.
 *
 * @param configuredColumns - The columns configured in the user's preferences.
 * @param availableColumns - The keys of the columns currently available in the
 * table.
 */
function* getDisplayedColumns(
  configuredColumns: readonly SettingsTableColumn[],
  availableColumns: readonly ColumnConfig[],
): IterableIterator<DisplayedColumn> {
  const [fixedColumns, dynamicColumns] = partition(
    availableColumns,
    (column) => column.isFixed,
  );

  // Show the fixed columns first.
  for (const fixedColumn of fixedColumns) {
    const configuredColumn = configuredColumns.find(
      (column) => column.key === fixedColumn.key,
    );
    if (configuredColumn) {
      // Use the configured column if it's there to maintain the filters.
      yield getDisplayedColumn(configuredColumn);
    } else if (fixedColumn.isPermanent) {
      // Even if the column isn't configured, if it's permanent, show it.
      yield getDisplayedColumn(fixedColumn);
    }
  }

  // Next show the non-fixed (dynamic), permanent columns that aren't configured.
  for (const dynamicColumn of dynamicColumns) {
    if (
      dynamicColumn.isPermanent &&
      configuredColumns.every((column) => column.key !== dynamicColumn.key)
    ) {
      yield getDisplayedColumn(dynamicColumn);
    }
  }

  // And lastly, show the non-fixed (dynamic) columns that are configured.
  for (const configuredColumn of configuredColumns) {
    if (dynamicColumns.some((column) => column.key === configuredColumn.key)) {
      yield getDisplayedColumn(configuredColumn);
    }
  }
}

function getDisplayedColumn(column: SettingsTableColumn): DisplayedColumn {
  return {
    filter: column.filter && {
      ...column.filter,
      isCleared: false,
      key: column.key,
    },
    key: column.key,
  };
}

type LookupMap<T> = Record<string, T | undefined>;

export interface DisplayedColumn {
  readonly key: string;
  readonly filter: ColumnFilter | undefined;
  readonly uiModelKey?: string;
}

interface ColumnConfig {
  readonly isFixed: boolean;
  readonly isPermanent: boolean;
  readonly key: string;
}
