import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "mr-dropdown-item[value]",
  template: "<ng-content></ng-content>",
})
export class DropdownItemComponent<T> {
  public constructor(private readonly element: ElementRef<HTMLElement>) {}

  @Input() public primary = false;
  @Input() public disabled = false;

  @Input() public value!: T;

  public get content(): string {
    return this.element.nativeElement.textContent || "";
  }
}
