import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthModule } from "src/app/core/auth/auth.module";
import { ElementsModule } from "src/app/core/elements/elements.module";
import { NavigationModule } from "src/app/core/navigation/navigation.module";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { TicketAppointmentDetailsEntryComponent } from "./ticket-appointment-details-entry.component";
import { TicketAppointmentDetailsEntryDirective } from "./ticket-appointment-details-entry.directive";
import { TicketAppointmentDetailsComponent } from "./ticket-appointment-details.component";

@NgModule({
  declarations: [
    TicketAppointmentDetailsComponent,
    TicketAppointmentDetailsEntryComponent,
    TicketAppointmentDetailsEntryDirective,
  ],
  imports: [
    AuthModule,
    CommonModule,
    ElementsModule,
    PipesModule,
    NavigationModule,
  ],
  exports: [TicketAppointmentDetailsComponent],
})
export class TicketAppointmentDetailsModule {}
