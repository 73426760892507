import { Pipe, PipeTransform } from "@angular/core";
import { DropdownColumnFilterValue } from "src/app/core/table";
import { HelpAssistTicketApprovalStatus } from "src/app/partner/help-assist/help-assist-enums";

const lookup: Readonly<Record<HelpAssistTicketApprovalStatus, string>> = {
  [HelpAssistTicketApprovalStatus.AwaitingAdminApproval]:
    "Awaiting Admin Approval",
  [HelpAssistTicketApprovalStatus.AwaitingCarrierApproval]:
    "Awaiting Carrier Approval",
  [HelpAssistTicketApprovalStatus.Approved]: "Approved",
  [HelpAssistTicketApprovalStatus.Declined]: "Declined",
};

export const helpAssistTicketApprovalStatusOptions: readonly DropdownColumnFilterValue[] =
  Object.entries(lookup).map(([id, name]) => ({ id, name }));

export function isAwaitingApproval(
  status: HelpAssistTicketApprovalStatus | null | undefined,
): boolean {
  return (
    status === HelpAssistTicketApprovalStatus.AwaitingAdminApproval ||
    status === HelpAssistTicketApprovalStatus.AwaitingCarrierApproval
  );
}

@Pipe({ name: "helpAssistTicketApprovalStatus" })
export class HelpAssistTicketApprovalStatusPipe implements PipeTransform {
  public transform(
    value: HelpAssistTicketApprovalStatus | null | undefined,
  ): string {
    return value ? lookup[value] : "";
  }
}
