<ng-content></ng-content>

<div class="sort-icons">
  <mr-icon
    name="chevron"
    class="ascending"
    [class.active]="(sortOrderChanges | async) === SortOrder.Ascending"
  ></mr-icon>
  <mr-icon
    name="chevron"
    [class.active]="(sortOrderChanges | async) === SortOrder.Descending"
  ></mr-icon>
</div>
