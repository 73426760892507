import {
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  ODataModel,
  PartnerResourceModel,
  UUID,
} from "src/utils";

export const usersResource = createPartnerResourceUrl("Users");

type ApiModelList = PartnerResourceModel<typeof usersResource>;
export interface ApiUser extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiUser>();

export class B2CUser {
  public constructor(args: ClassProperties<B2CUser>) {
    this.userId = args.userId;
    this.userDisplayName = args.userDisplayName;
    this.emailAddress = args.emailAddress;
    this.phoneNumber = args.phoneNumber;
  }

  @api() public readonly userId: UUID;
  @api() public readonly userDisplayName: string;
  @api() public readonly emailAddress: string;
  @api() public readonly phoneNumber: string | null;

  public static deserialize(apiModel: ApiUser): B2CUser {
    return new B2CUser({
      emailAddress: apiModel.emailAddress,
      phoneNumber: apiModel.phoneNumber ?? null,
      userDisplayName: apiModel.userDisplayName,
      userId: new UUID(apiModel.userId),
    });
  }

  public static deserializeList({ value }: ApiModelList): readonly B2CUser[] {
    return value.map((ticket) => B2CUser.deserialize(ticket));
  }
}
