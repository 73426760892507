import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "mr-radio-item[value]",
  templateUrl: "./radio-group-item.component.html",
  styleUrls: ["./radio-group-item.component.scss"],
})
export class RadioGroupItemComponent<T> {
  @Input() public value!: T;
  @Input() public description?: string;
  @ViewChild("contentTemplate", { static: true })
  public template!: TemplateRef<void>;
}
