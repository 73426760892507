import { formatNumber } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "amount" })
export class AmountPipe implements PipeTransform {
  public constructor(@Inject(LOCALE_ID) private readonly locale: string) {}
  public transform(
    value: number,
    unit: string,
    pluralUnit = `${unit}s`,
    digitsInfo = "1.0-3",
  ): string {
    return formatAmount(value, this.locale, unit, pluralUnit, digitsInfo);
  }
}

export function formatAmount(
  value: number,
  locale: string,
  unit: string,
  pluralUnit = `${unit}s`,
  digitsInfo = "1.0-3",
): string {
  const displayValue = formatNumber(value, locale, digitsInfo);
  return `${displayValue} ${Number(displayValue) === 1 ? unit : pluralUnit}`;
}
