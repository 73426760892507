import { DateTime, Interval, SystemZone, Zone } from "luxon";
import { formatDateTime } from "src/utils/converters/date-time-converters";
import { TimeOfDay } from "./time-of-day.model";

/**
 * An abstract calendar day without an associated time or time zone.
 */
export class Day {
  public constructor({ year, month, day }: ClassProperties<Day>) {
    this.base = DateTime.fromObject({ year, month, day });
    if (this.base.invalidExplanation !== null) {
      throw new Error(
        `Invalid arguments to Day: ${this.base.invalidExplanation}`,
      );
    }
  }

  /** Today in the browser's local time zone. */
  public static get todayInLocalTime(): Day {
    return new Day(DateTime.local());
  }

  /** The start of the day (midnight). */
  public static readonly startOfDay: unique symbol = Symbol("Start of Day");
  /** The end of the day (just before midnight). */
  public static readonly endOfDay: unique symbol = Symbol("End of Day");
  /** Coordinated Universal Time time zone. */
  public static readonly utc: unique symbol = Symbol("UTC");
  /** The browser's local time zone. */
  public static readonly localZone = SystemZone.instance;

  private readonly base: DateTime;

  public get year(): number {
    return this.base.year;
  }

  public get month(): number {
    return this.base.month;
  }

  public get day(): number {
    return this.base.day;
  }

  /**
   * Creates a `Day` from an ISO date string, ignoring any time information.
   *
   * @param value - An ISO date string.
   * @param zone - The time zone to interpret the date-time ISO string in, or
   * null for ISO strings that have no time component.
   */
  public static deserialize(value: string, zone: DayZone | null): Day {
    if (zone === null) {
      if (value.includes("T")) {
        throw new Error("Zone info required for parsing strings with times.");
      } else {
        zone = Day.utc;
      }
    }
    const date = DateTime.fromISO(value, { zone: getActualZone(zone) });
    if (date.invalidExplanation !== null) {
      throw new Error(
        `Could not deserialize Day "${value}": ${date.invalidExplanation}`,
      );
    }
    return new Day(date);
  }

  public static getTodayIn(zone: Zone): Day {
    return new Day(DateTime.utc().setZone(zone));
  }

  /**
   * Compares two days (conforms to `Array.prototype.sort`'s compare function).
   * @param first The first half of the comparison.
   * @param second The second half of the comparison.
   * @returns `0` if the two values represent the same day, a negative number if
   * `first` is less than `second`, and a positive number otherwise.
   */
  public static compare(first: Day, second: Day): number {
    return first.base.valueOf() - second.base.valueOf();
  }

  public isAfter(other: Day): boolean {
    return Day.compare(this, other) > 0;
  }

  public isBefore(other: Day): boolean {
    return Day.compare(this, other) < 0;
  }

  public isSameDay(day: Day): boolean {
    return Day.compare(this, day) === 0;
  }

  public isAfterOrSameDay(other: Day): boolean {
    return Day.compare(this, other) >= 0;
  }

  public isTodayIn(zone: Zone): boolean {
    return this.isSameDay(Day.getTodayIn(zone));
  }

  public plus({ years, months, days }: ModifyProperties): Day {
    return new Day(this.base.plus({ years, months, days }));
  }

  public minus({ years, months, days }: ModifyProperties): Day {
    return new Day(this.base.minus({ years, months, days }));
  }

  public setIn(dateTime: DateTime): DateTime {
    const { day, month, year } = this;
    return dateTime.set({ day, month, year });
  }

  public serialize(): string;
  public serialize(time: DayTime, zone: DayZone): string;
  public serialize(time?: DayTime, zone?: DayZone): string {
    if (!time || !zone) {
      return this.base.toISODate();
    }

    return formatDateTime(this.toDateTime(time, zone));
  }

  public toString(): string {
    return this.serialize();
  }

  public toJSON(): string {
    return this.serialize();
  }

  /**
   * Creates a `DateTime` for the day with the provided time information.
   * @param time The time to add to the date.
   * @param zone The time zone to interpret the time and date in.
   */
  public toDateTime(time: DayTime, zone: DayZone): DateTime {
    const { year, month, day } = this;
    const date = DateTime.fromObject(
      { year, month, day },
      { zone: getActualZone(zone) },
    );

    switch (time) {
      case Day.startOfDay:
        return date.startOf("day");
      case Day.endOfDay:
        return date.endOf("day");
      default:
        return date.set(time);
    }
  }

  public toInterval(
    zone: DayZone,
    startTime: DayTime = Day.startOfDay,
    // Default to `startOfDay` to represent midnight to midnight. It will be
    // adjusted to the next day below.
    endTime: DayTime = Day.startOfDay,
  ): Interval {
    const startDate = this.toDateTime(startTime, zone);
    const endDate = this.toDateTime(endTime, zone);
    // If the end time is before the start time, then it should be interpreted
    // as crossing midnight into the next day in order to keep the range valid.
    return startDate.until(
      endDate <= startDate ? endDate.plus({ days: 1 }) : endDate,
    );
  }
}

interface ModifyProperties {
  days?: number;
  months?: number;
  years?: number;
}

type DayTime = TimeOfDay | typeof Day.startOfDay | typeof Day.endOfDay;
type DayZone = Zone | typeof Day.utc;

function getActualZone(zone: DayZone): Zone | string {
  return zone === Day.utc ? "UTC" : zone;
}
