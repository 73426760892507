import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthModule } from "src/app/core/auth/auth.module";
import { ElementsModule } from "src/app/core/elements/elements.module";
import { FormsModule } from "src/app/core/forms/forms.module";
import { NavigationModule } from "src/app/core/navigation/navigation.module";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { TicketAppointmentDetailsModule } from "../ticket-appointment-details/ticket-appointment-details.module";
import { HelpAssistGeneralTicketFormComponent } from "./general-ticket-form.component";
import { HelpAssistModalComponent } from "./help-assist-modal.component";
import { HelpButtonComponent } from "./help-button.component";
import { HelpAssistSchedulingTicketFormComponent } from "./scheduling-ticket-form.component";
import { TicketPoListComponent } from "./ticket-po-list.component";

@NgModule({
  declarations: [
    HelpAssistGeneralTicketFormComponent,
    HelpAssistModalComponent,
    HelpAssistSchedulingTicketFormComponent,
    HelpButtonComponent,
    TicketPoListComponent,
  ],
  imports: [
    AuthModule,
    CommonModule,
    ElementsModule,
    FormsModule,
    NavigationModule,
    PipesModule,
    TicketAppointmentDetailsModule,
  ],
  exports: [HelpButtonComponent, TicketPoListComponent],
})
export class TicketCreationModule {}
