import {
  Component,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { differenceBy } from "lodash-es";
import { Options } from "sortablejs";
import { swapItems } from "src/utils";
import { BaseFieldComponent } from "./base-field.component";

export interface SortableOption<T extends string = string> {
  readonly isPermanent: boolean;
  readonly key: T;
  readonly value: string | TemplateRef<{ $implicit: T }>;
}

interface ManagedOption extends SortableOption {
  isSelected: boolean;
}

@Component({
  selector: "mr-sortable-list[options]",
  template: `
    <p *ngIf="limit">{{ selectedCount }}/{{ limit }}</p>

    <ol
      *ngIf="managedOptions.length > 0"
      [sortablejs]="managedOptions"
      [sortablejsOptions]="sortablejsOptions"
    >
      <li
        *ngFor="
          let option of managedOptions;
          index as index;
          first as isFirst;
          last as isLast
        "
        [class.draggable]="!isDisabled"
      >
        <mr-checkbox
          (valueChange)="setSelected(option, $event)"
          [checked]="option.isSelected"
          [disabled]="
            isDisabled ||
            option.isPermanent ||
            (!option.isSelected && hasReachedLimit)
          "
        >
          <ng-template #stringValueDisplay>{{ option.value }}</ng-template>
          <ng-container
            *ngTemplateOutlet="
              isTemplate(option.value) ? option.value : stringValueDisplay;
              context: { $implicit: option.key }
            "
          >
          </ng-container>
        </mr-checkbox>

        <div class="actions">
          <mr-icon-button
            [disabled]="isDisabled || isFirst"
            class="move-up"
            label="Move up"
            (click)="moveUp(index)"
          >
            <mr-icon name="chevron"></mr-icon>
          </mr-icon-button>

          <mr-icon-button
            [disabled]="isDisabled || isLast"
            class="move-down"
            label="Move down"
            (click)="moveDown(index)"
          >
            <mr-icon name="chevron"></mr-icon>
          </mr-icon-button>
        </div>
      </li>
    </ol>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ol {
        padding: 0;
        margin: 1.8125rem 0 0 0;
        list-style-type: none;
      }
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1.1875rem 0.75rem 0.75rem;
        margin: 0 0 0.625rem 0;
        border-radius: var(--mr-border-radius);
        line-height: 1rem;
        background-color: var(--mr-form-field-background-color);
      }
      .actions {
        display: flex;
        flex-flow: column nowrap;
        height: 1.25rem;
        justify-content: space-between;
        padding-top: 0.0625rem;
        padding-bottom: 0.0625rem;
      }
      .move-up {
        transform: rotate(180deg);
      }
      mr-icon-button {
        color: var(--mr-color-gray-2);
      }
      mr-icon {
        width: 0.5rem;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SortableListComponent),
    },
  ],
})
export class SortableListComponent
  extends BaseFieldComponent<readonly string[]>
  implements OnInit
{
  @Input() public options!: readonly SortableOption[];
  @Input() public selected: readonly SortableOption[] = [];
  @Input() public limit: number | null = null;

  public managedOptions!: ManagedOption[];
  public selectedCount = 0;

  public get hasReachedLimit(): boolean {
    return this.limit === null ? false : this.selectedCount >= this.limit;
  }

  public readonly sortablejsOptions: Options = {
    draggable: ".draggable",
    onEnd: () => {
      this.propagateAndUpdateValues();
    },
  };

  public override ngOnInit(): void {
    super.ngOnInit();

    this.managedOptions = getManagedOptions(this.options, this.selected);
    this.setInitial();
  }

  public isTemplate(value: unknown): value is TemplateRef<unknown> {
    return value instanceof TemplateRef;
  }

  public setSelected(config: ManagedOption, isSelected: boolean): void {
    config.isSelected = isSelected;
    this.propagateAndUpdateValues();
  }

  public moveUp(currentConfigIndex: number): void {
    swapItems(this.managedOptions, currentConfigIndex, currentConfigIndex - 1);
    this.propagateAndUpdateValues();
  }

  public moveDown(currentConfigIndex: number): void {
    swapItems(this.managedOptions, currentConfigIndex, currentConfigIndex + 1);
    this.propagateAndUpdateValues();
  }

  private setInitial(): void {
    this.writeValue(this.getSelectedKeys());
    this.updateSelectedCount();
  }

  private propagateAndUpdateValues(): void {
    this.onChange(this.getSelectedKeys());
    this.updateSelectedCount();
  }

  private updateSelectedCount(): void {
    this.selectedCount = this.managedOptions.filter(
      (option) => option.isSelected,
    ).length;
  }

  private getSelectedKeys(): readonly string[] {
    return this.managedOptions
      .filter((option) => option.isSelected)
      .map((option) => option.key);
  }
}

function getManagedOptions(
  allOptions: readonly SortableOption[],
  selectedOptions: readonly SortableOption[],
): ManagedOption[] {
  const unselectedOptions = differenceBy(
    allOptions,
    selectedOptions,
    ({ key }) => key,
  );

  return [
    ...selectedOptions.map((option) => ({ ...option, isSelected: true })),
    ...unselectedOptions.map((option) => ({ ...option, isSelected: false })),
  ];
}
