import * as t from "io-ts";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { BaseHelpAssistAppointmentDraftTicket } from "./base-help-assist-ticket-appointment-draft.model";
import { TicketPreviousValuesEntry } from "./help-assist-ticket-appointment-previous-entry.model";

interface DeserializeArgs {
  readonly site: Site;
}

// TODO: to be removed once confirmed with admin team (as we don't compare
//  current ticket values to the previous ticket values anymore, but rather
//  current ticket values to current appointment values to show highlights.)
export class TicketPreviousValues {
  private constructor(
    args: Omit<ClassProperties<TicketPreviousValues>, "changeCount">,
  ) {
    this.idealAppointmentDate = args.idealAppointmentDate;
    this.idealStartTime = args.idealStartTime;

    this.changeCount = Object.values(args).filter(
      (arg) => arg.previousExists,
    ).length;
  }

  public readonly changeCount: number;
  public readonly idealAppointmentDate: TicketPreviousValuesEntry<
    BaseHelpAssistAppointmentDraftTicket["idealAppointmentDate"]
  >;
  public readonly idealStartTime: TicketPreviousValuesEntry<
    BaseHelpAssistAppointmentDraftTicket["idealStartTime"]
  >;

  public static deserialize(
    data: object,
    { site }: DeserializeArgs,
  ): TicketPreviousValues {
    if (!previousCodec.is(data)) {
      throw new Error("Unexpected appointment changes properties or values.");
    }

    return new TicketPreviousValues({
      idealAppointmentDate: new TicketPreviousValuesEntry({
        previousExists: "idealAppointmentDate" in data,
        value: data.idealAppointmentDate
          ? Day.deserialize(data.idealAppointmentDate, site.timeZone)
          : null,
      }),
      idealStartTime: new TicketPreviousValuesEntry({
        previousExists: "idealStartTime" in data,
        value: data.idealStartTime
          ? TimeOfDay.deserialize(data.idealStartTime)
          : null,
      }),
    });
  }
}

const previousCodec = t.partial({
  idealAppointmentDate: t.union([t.string, t.null]),
  idealStartTime: t.union([t.string, t.null]),
});
