import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router, UrlTree } from "@angular/router";
import { UserService } from "src/app/core/auth";
import { PartnersService } from "src/app/partner/global/partners.service";

@Injectable()
export class HasPartnersGuard implements CanActivate, CanLoad {
  public constructor(
    private readonly router: Router,
    private readonly partners: PartnersService,
    private readonly user: UserService,
  ) {}

  public async canActivate(): Promise<boolean | UrlTree> {
    return this.canLoad();
  }

  public async canLoad(): Promise<boolean | UrlTree> {
    await this.user.isLoaded;
    if (await this.partners.load()) {
      return true;
    } else {
      return this.router.parseUrl("/account-pending");
    }
  }
}
