import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import {
  createGlobalResourceUrl,
  getApiDetailsDecorator,
  ODataModel,
  ODataResourceModel,
} from "src/utils";

const api = getApiDetailsDecorator<Api.EquipmentType>();

export const equipmentTypesResource = createGlobalResourceUrl("equipmentTypes");

type ApiModelList = ODataResourceModel<typeof equipmentTypesResource>;
export interface ApiEquipmentType extends ODataModel<ApiModelList> {}

export class EquipmentType {
  private constructor(args: ClassProperties<EquipmentType>) {
    this.id = args.id;
    this.name = args.name;
  }

  @api() public readonly id: number;
  @api() public readonly name: string;

  public static deserialize(data: ApiEquipmentType): EquipmentType {
    return new EquipmentType({ id: data.id, name: data.name });
  }

  public static deserializeList({
    value,
  }: ApiModelList): readonly EquipmentType[] {
    return value.map((x) => EquipmentType.deserialize(x));
  }
}
