import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "src/environments";
import { appendUrlPath } from "src/utils";
import { HasPermissionGuard } from "./has-permission-guard.service";
import { IfAnyPermissionOfDirective } from "./if-any-permission-of.directive";
import { IfHasPermissionDirective } from "./if-has-permission.directive";

const {
  appIds,
  clientId,
  loginAuthorityPolicyName,
  passwordResetPolicyName,
  tenantName,
} = environment.azureAD;
const authorityDomain = `${tenantName}.b2clogin.com`;
const rootUrl = `https://${authorityDomain}/tfp/${tenantName}.onmicrosoft.com`;

const authority = `${rootUrl}/${loginAuthorityPolicyName}`;
export const passwordResetAuthority = `${rootUrl}/${passwordResetPolicyName}`;

@NgModule({
  imports: [CommonModule, MsalModule],
  providers: [
    HasPermissionGuard,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory(): MsalGuardConfiguration {
        return {
          authRequest: { prompt: "select_account" },
          interactionType: InteractionType.Redirect,
        };
      },
    },
    {
      provide: MSAL_INSTANCE,
      useFactory(baseHref: string): PublicClientApplication {
        const baseUrl = appendUrlPath(window.location.origin, baseHref);
        return new PublicClientApplication({
          auth: {
            authority,
            clientId,
            knownAuthorities: [authorityDomain],
            navigateToLoginRequestUrl: true,
            redirectUri: appendUrlPath(baseUrl, "login").href,
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
          },
        });
      },
      deps: [APP_BASE_HREF],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory(): MsalInterceptorConfiguration {
        const protectedResourceMap = new Map<string, string[]>();
        protectedResourceMap.set(
          environment.apiUrls.sites.href,
          getSitesScopes(["offline_access", "openid", "sites", "partners"]),
        );
        protectedResourceMap.set(
          environment.apiUrls.mr.href,
          getMRScopes(["read", "write", "user_impersonation"]),
        );

        protectedResourceMap.set(
          environment.ymsUrls.app.href + "api",
          getMRScopes(["write", "user_impersonation"]),
        );

        return {
          interactionType: InteractionType.Redirect,
          protectedResourceMap,
        };
      },
    },
    MsalBroadcastService,
    MsalGuard,
    MsalService,
  ],
  declarations: [IfHasPermissionDirective, IfAnyPermissionOfDirective],
  exports: [IfHasPermissionDirective, IfAnyPermissionOfDirective],
})
export class AuthModule {}

function getSitesScopes(scopeNames: string[]): string[] {
  return scopeNames.map(
    (name) => `https://${appIds.sites}.capstonelogistics.com/common/${name}`,
  );
}

function getMRScopes(scopeNames: string[]): string[] {
  return scopeNames.map(
    (name) => `https://${tenantName}.onmicrosoft.com/${appIds.mr}/${name}`,
  );
}
