import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { PointOfOrigin } from "src/app/partner/purchase-orders/base-purchase-order.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import {
  deserializePurchaseOrderVendor,
  PurchaseOrderVendor,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import {
  ExpandedODataModel,
  getApiDetailsDecorator,
  getEnumMember,
  UUID,
} from "src/utils";
import { BaseHelpAssistTicketAppointmentOrder } from "./base-help-assist-ticket-appointment-order.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketAppointmentOrder>();

interface DeserializeArgs {
  site: Site;
}

export class HelpAssistTicketAppointmentOrder extends BaseHelpAssistTicketAppointmentOrder {
  private constructor(args: ClassProperties<HelpAssistTicketAppointmentOrder>) {
    super(args);
    this.doorGroup = args.doorGroup;
    this.helpAssistTicketAppointmentId = args.helpAssistTicketAppointmentId;
    this.id = args.id;
    this.partnerKey = args.partnerKey;
    this.site = args.site;
    this.vendor = args.vendor;
  }

  @api({
    key: "doorGroupID",
    navigationProperty: "doorGroup",
    uiModel: DoorGroup,
  })
  public override readonly doorGroup: Pick<DoorGroup, "id" | "name"> | null;
  @api() public readonly helpAssistTicketAppointmentId: number;
  @api() public readonly id: number;
  @api() public readonly partnerKey: UUID;
  public readonly site: Site;
  @api({
    key: "vendorId",
    navigationProperty: "vendor",
    uiModel: Vendor,
  })
  public override readonly vendor: PurchaseOrderVendor;

  public static deserialize(
    apiModel: ApiTicketOrder,
    { site }: DeserializeArgs,
  ): HelpAssistTicketAppointmentOrder {
    return new HelpAssistTicketAppointmentOrder({
      asnBillOfLadingNumber: apiModel.asnBolNumber ?? null,
      asnProNumber: apiModel.asnProNumber ?? null,
      backhaulPickupConfirmationNumber:
        apiModel.backhaulPickupConfirmationNumber ?? null,
      billOfLadingNumber: apiModel.bolNumber ?? null,
      caseCount: apiModel.caseCount,
      comments: apiModel.comments ?? null,
      consigneeCode: apiModel.consigneeCode ?? null,
      doorGroup: apiModel.doorGroup ?? null,
      dueDate: apiModel.dueDate
        ? Day.deserialize(apiModel.dueDate, site.timeZone)
        : null,
      entryDate: apiModel.entryDate
        ? Day.deserialize(apiModel.entryDate, site.timeZone)
        : null,
      helpAssistTicketAppointmentId: apiModel.helpAssistTicketAppointmentId,
      id: apiModel.id,
      inboundPalletCount: apiModel.estimatedReceivedPalletCount,
      loadWeight: apiModel.loadWeight ?? null,
      managedType: getEnumMember(ManagedType, apiModel.managedType, null),
      number: apiModel.number,
      orderId: apiModel.orderId ?? null,
      partnerKey: new UUID(apiModel.partnerKey),
      pickupDate: apiModel.pickupDate
        ? Day.deserialize(apiModel.pickupDate, site.timeZone)
        : null,
      pointOfOrigin: PointOfOrigin.deserialize(apiModel) ?? null,
      proNumber: apiModel.proNumber ?? null,
      site,
      vendor: deserializePurchaseOrderVendor(apiModel.vendor, { site }),
      warehousePalletCount: apiModel.palletCount,
    });
  }

  public static deserializeList(
    value: readonly ApiTicketOrder[],
    args: DeserializeArgs,
  ): HelpAssistTicketAppointmentOrder[] {
    return value.map((o) =>
      HelpAssistTicketAppointmentOrder.deserialize(o, args),
    );
  }
}

export type ApiTicketOrder = Pick<
  ExpandedODataModel<
    Api.HelpAssistTicketAppointmentOrder,
    {
      vendor:
        | "allowSameDayAppointment"
        | "appointmentNotificationEmails"
        | "customMinutesPerUnit"
        | "id"
        | "maxCalcMinutesPerUnit"
        | "maxLoadCount"
        | "minutesPerUnit"
        | "name"
        | "offerUnreservedSlots"
        | "vendorNumber";
      doorGroup: "id" | "name";
    }
  >,
  | "asnBolNumber"
  | "asnProNumber"
  | "backhaulPickupConfirmationNumber"
  | "bolNumber"
  | "caseCount"
  | "comments"
  | "consigneeCode"
  | "doorGroupID"
  | "doorGroup"
  | "dueDate"
  | "entryDate"
  | "estimatedReceivedPalletCount"
  | "helpAssistTicketAppointmentId"
  | "id"
  | "loadWeight"
  | "managedType"
  | "number"
  | "orderId"
  | "originCity"
  | "originLatitude"
  | "originLongitude"
  | "originPostalCode"
  | "originState"
  | "palletCount"
  | "partnerKey"
  | "pickupDate"
  | "proNumber"
  | "vendor"
  | "vendorId"
>;
