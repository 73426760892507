import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "mr-loading",
  template: "<div></div><div></div><div></div><div></div>",
  styleUrls: ["./loading.component.scss"],
  host: {
    "aria-busy": "true",
    "aria-label": "loading",
    "aria-live": "polite",
  },
})
export class LoadingComponent {
  @HostBinding("class")
  @Input()
  public kind: "element" | "input" | "page" = "page";
}
