import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounce } from "lodash-es";

@Component({
  selector: "mr-search-box[name]",
  template: `
    <mr-icon name="spyglass"></mr-icon>
    <input
      [disabled]="isLoading"
      placeholder="Search {{ name }}..."
      [(ngModel)]="value"
      (change)="$event.stopPropagation()"
      (ngModelChange)="emitValueOnInput()"
      (keydown.enter)="emitValueOnSubmit()"
    />
    <ng-container *ngIf="updateOn === 'submit'">
      <mr-loading
        kind="input"
        *ngIf="isLoading; else submitButton"
      ></mr-loading>
      <ng-template #submitButton>
        <mr-icon-button
          *ngIf="value"
          label="Search"
          (click)="emitValueOnSubmit()"
        >
          <mr-icon name="arrow-right-outlined"></mr-icon>
        </mr-icon-button>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        border: var(--mr-border);
        border-radius: var(--mr-border-radius);
        padding: 0.6rem 1rem 0.6rem 1rem;
        width: inherit;
        min-width: 15rem;
        background-color: var(--mr-form-field-background-color);

        &:focus-within {
          outline: var(--mr-form-field-focus-outline);
        }
      }
      mr-icon {
        width: var(--mr-text-size-medium-small);
      }
      input {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0 0 0 var(--mr-spacing-small);
        display: block;
        width: 100%;
        color: var(--mr-text-color);
        background-color: inherit;
        line-height: var(--mr-text-size-small);

        /* Hide input focus, instead we're showing focus on ":host" */
        &:focus,
        &:focus-visible {
          outline: 0;
        }
      }
    `,
  ],
})
export class SearchBoxComponent {
  @Input() public name!: string;
  @Input() public updateOn: "input" | "submit" = "input";
  @Input() public isLoading = false;

  @Output() public readonly valueChange = new EventEmitter<string>();

  public value = "";

  public readonly emitValueOnInput = debounce(() => {
    if (this.updateOn === "input") {
      this.emitValue();
    }
  }, 500);

  public emitValueOnSubmit(): void {
    if (this.updateOn === "submit") {
      this.emitValue();
      this.value = "";
    }
  }

  private emitValue(): void {
    this.valueChange.emit(this.value.trim());
  }
}
