import {
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  Input,
  QueryList,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import { FieldContainerParentService } from "./field-container.component";
import { RadioGroupItemComponent } from "./radio-group-item.component";

let counter = 0;

@Component({
  selector: "mr-radio-group[label]",
  templateUrl: "radio-group.component.html",
  styleUrls: ["radio-group.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioGroupComponent),
    },
  ],
})
export class RadioGroupComponent<T> extends BaseFieldComponent<T> {
  private readonly index = counter++;

  public readonly radioGroupName = `mr-radio-group-name-${this.index}`;

  @Input() public kind: "form" | "tiled" = "form";
  @Input() public labelOrientation: "horizontal" | "vertical" = "vertical";
  @Input()
  @HostBinding("class")
  public orientation: "horizontal" | "vertical" = "horizontal";

  @ContentChildren(RadioGroupItemComponent)
  public options!: QueryList<RadioGroupItemComponent<T>>;
}
