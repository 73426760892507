import { Pipe, PipeTransform } from "@angular/core";

export enum ChangedOrderType {
  Added = "Added",
  Removed = "Removed",
  Modified = "Modified",
  Unchanged = "Unchanged",
}

@Pipe({
  name: "changedOrderType",
  pure: true,
})
export class ChangedOrderTypePipe implements PipeTransform {
  public transform(type: ChangedOrderType): string | null {
    if (type === ChangedOrderType.Added) {
      return "(new)";
    }

    if (type === ChangedOrderType.Removed) {
      return "(removed)";
    }

    return null;
  }
}
