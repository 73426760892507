<ul *ngIf="iconsChanges | async as icons">
  <li *ngIf="icons.isInReservation" class="appointment-icons-reservation">
    <mr-icon
      name="reservation"
      aria-labelledby="appointment-icons-reservation-label"
    ></mr-icon>
    <label id="appointment-icons-reservation-label">{{
      "slotWasReserved" | appointmentFieldTitle
    }}</label>
  </li>
  <li *ngIf="icons.lastActivityReasonCodeIcon as icon">
    <mr-icon
      [name]="icon.name"
      aria-labelledby="appointment-icons-reason-code-label"
    ></mr-icon>
    <label id="appointment-icons-reason-code-label">
      {{ icon.reasonCodeTitle }}
    </label>
  </li>
  <li *ngIf="icons.isCFM">
    <mr-icon name="cfm" aria-labelledby="appointment-icons-cfm-label"></mr-icon>
    <label id="appointment-icons-cfm-label">{{
      "isCFM" | appointmentFieldTitle
    }}</label>
  </li>
  <li *ngIf="icons.isDropLoad">
    <mr-icon
      name="drop-load"
      aria-labelledby="appointment-icons-drop-load-label"
    ></mr-icon>
    <label id="appointment-icons-drop-load-label">{{
      "isDropLoad" | appointmentFieldTitle
    }}</label>
  </li>
  <li *ngIf="icons.isBackhaul">
    <mr-icon
      name="backhaul"
      aria-labelledby="appointment-icons-backhaul-label"
    ></mr-icon>
    <label id="appointment-icons-backhaul-label">{{
      "isBackhaul" | appointmentFieldTitle
    }}</label>
  </li>
</ul>
