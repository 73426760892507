import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ElementsModule } from "src/app/core/elements/elements.module";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { TableModule } from "src/app/core/table";
import { EmailInformationModalComponent } from "./email-information-modal.component";
import { EmailInformationService } from "./email-information.service";

@NgModule({
  imports: [
    CommonModule,
    ElementsModule,
    PipesModule,
    TableModule,
    PipesModule,
  ],
  declarations: [EmailInformationModalComponent],
  providers: [EmailInformationService],
  exports: [EmailInformationModalComponent],
})
export class EmailInformationModule {}
