import { getOrElse, map } from "fp-ts/es6/Either";
import { flow } from "fp-ts/es6/function";
import * as t from "io-ts";
import { get } from "lodash-es";
import { DateTime, Zone } from "luxon";
import { Day } from "src/app/core/day.model";
import {
  ColumnFilter,
  TableColumnFilterType,
} from "src/app/core/table/table-column-filter.model";
import {
  dateTimeCodec,
  dayCodec,
  durationCodec,
  timeOfDayCodec,
  weekdaysCodec,
} from "./codecs";
import { isExistent, throwUnhandledCaseError } from "./miscellaneous";

export function filterByTableColumns<Model>(
  models: readonly Model[],
  columnFilters: readonly ColumnFilter[] | undefined,
  { timeZone }: { timeZone: Zone | readonly Zone[] },
): readonly Model[] {
  const timeZones = Array.isArray(timeZone) ? timeZone : [timeZone];
  return (
    columnFilters?.reduce(
      (filteredModels, columnFilter) =>
        filterByColumn(filteredModels, columnFilter, { timeZones }),
      models,
    ) ?? models
  );
}

function filterByColumn<Model>(
  models: readonly Model[],
  columnFilter: ColumnFilter,
  { timeZones }: { timeZones: readonly Zone[] },
): readonly Model[] {
  switch (columnFilter.type) {
    case TableColumnFilterType.Boolean:
    case TableColumnFilterType.Number: {
      const isEqualToValue = getFilter(
        t.union([t.boolean, t.number]),
        columnFilter,
        (value) => value === columnFilter.value,
      );
      return models.filter(isEqualToValue);
    }

    case TableColumnFilterType.DayRange:
    case TableColumnFilterType.DateRange: {
      const ranges = timeZones.map((zone) => {
        const start = columnFilter.value.start.toDateTime(Day.startOfDay, zone);
        const end = columnFilter.value.end.toDateTime(Day.startOfDay, zone);
        const range = start.until(end);
        return [zone, range] as const;
      });

      const isBetweenDays = getFilter(
        t.union([dayCodec, dateTimeCodec]),
        columnFilter,
        (date) =>
          ranges.some(([zone, range]) =>
            range.contains(
              DateTime.isDateTime(date)
                ? date
                : date.toDateTime(Day.startOfDay, zone),
            ),
          ),
      );
      return models.filter(isBetweenDays);
    }

    case TableColumnFilterType.Days: {
      const containsAnyCommonDay = getFilter(
        weekdaysCodec,
        columnFilter,
        (weekdays) => [...weekdays.getCommon(columnFilter.value)].length > 0,
      );
      return models.filter(containsAnyCommonDay);
    }

    case TableColumnFilterType.Dropdown: {
      // Dropdown filters, at least for now, are compared against a model
      // with an ID property, or a plain string for enums.
      const hasValueAsId = getFilter(
        t.union([t.type({ id: t.number }), t.string]),
        columnFilter,
        (model) => {
          const id = typeof model === "string" ? model : model.id;
          return id === columnFilter.value;
        },
      );
      return models.filter(hasValueAsId);
    }

    case TableColumnFilterType.MultiSelect: {
      const hasValueAsId = getFilter(
        t.array(t.union([t.type({ id: t.number }), t.string])),
        columnFilter,
        (value) => {
          return columnFilter.value === value;
        },
      );
      return models.filter(hasValueAsId);
    }

    case TableColumnFilterType.DurationRange: {
      let rangeFilteredModels = models;
      const { min, max } = columnFilter.value;

      if (isExistent(min)) {
        const isGreaterThanMin = getFilter(
          durationCodec,
          columnFilter,
          (value) => value >= min,
        );
        rangeFilteredModels = rangeFilteredModels.filter(isGreaterThanMin);
      }
      if (isExistent(max)) {
        const isLessThanMax = getFilter(
          durationCodec,
          columnFilter,
          (value) => value <= max,
        );
        rangeFilteredModels = rangeFilteredModels.filter(isLessThanMax);
      }
      return rangeFilteredModels;
    }

    case TableColumnFilterType.NumberRange: {
      let rangeFilteredModels = models;
      const { min, max } = columnFilter.value;
      if (isExistent(min)) {
        const isGreaterThanMin = getFilter(
          t.number,
          columnFilter,
          (value) => value >= min,
        );
        rangeFilteredModels = rangeFilteredModels.filter(isGreaterThanMin);
      }
      if (isExistent(max)) {
        const isLessThanMax = getFilter(
          t.number,
          columnFilter,
          (value) => value <= max,
        );
        rangeFilteredModels = rangeFilteredModels.filter(isLessThanMax);
      }
      return rangeFilteredModels;
    }

    case TableColumnFilterType.Time: {
      const isTheSameTime = getFilter(timeOfDayCodec, columnFilter, (value) =>
        value.isSame(columnFilter.value),
      );
      return models.filter(isTheSameTime);
    }

    case TableColumnFilterType.Text: {
      const query = columnFilter.value.toLowerCase();
      const containsValue = getFilter(t.string, columnFilter, (value) =>
        value.toLowerCase().includes(query),
      );
      return models.filter(containsValue);
    }

    case TableColumnFilterType.NumberIdentifier: {
      const query = columnFilter.value;
      const startsWithValue = getFilter(t.string, columnFilter, (value) =>
        value.startsWith(query),
      );
      return models.filter(startsWithValue);
    }

    default: {
      throwUnhandledCaseError("column filter type", columnFilter);
    }
  }
}

function getFilter<T extends t.Mixed>(
  codec: T,
  columnFilter: ColumnFilter,
  func: (value: t.TypeOf<typeof codec>) => boolean,
): (model: unknown) => boolean {
  const matches = flow(
    map(func),
    getOrElse(() => false),
  );
  const arrayPaths = columnFilter.key
    .split("[]")
    .map((path) => path.replace(/^\./, ""));

  return (model) => {
    for (const value of getValues(model, arrayPaths)) {
      if (matches(codec.decode(value))) {
        return true;
      }
    }
    return false;
  };
}

function* getValues(
  value: unknown,
  arrayPaths: string[],
): IterableIterator<unknown> {
  if (arrayPaths.length === 0) {
    return yield value;
  }

  const [nextPath, ...remainingPaths] = arrayPaths;
  const nextValue: unknown = get(value, nextPath, undefined);

  if (remainingPaths.length > 0 && !Array.isArray(nextValue)) {
    throw new Error(
      `The table column key specifies an array for "${nextPath}" but the value there isn't one.`,
    );
  }

  if (Array.isArray(nextValue)) {
    for (const item of nextValue) {
      yield* getValues(item, remainingPaths);
    }
  } else {
    yield* getValues(nextValue, remainingPaths);
  }
}
