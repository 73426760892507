import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import {
  getApiDetailsDecorator,
  getEnumMember,
  parseUUID,
  UUID,
} from "src/utils";
import { HelpAssistTicketApprovalStatus } from "../help-assist-enums";

const api = getApiDetailsDecorator<Api.AppointmentApproval>();

export class AppointmentApproval {
  public constructor(args: ClassProperties<AppointmentApproval>) {
    this.appointmentId = args.appointmentId;
    this.partnerKey = args.partnerKey;
    this.status = args.status;
    this.statusChangedBy = args.statusChangedBy;
  }

  public readonly appointmentId: AppointmentReference["id"];
  public readonly partnerKey: UUID;
  @api() public readonly status: HelpAssistTicketApprovalStatus;
  @api() public readonly statusChangedBy: string | null;

  public static deserialize(
    data: Api.AppointmentApproval,
  ): AppointmentApproval {
    const partnerKey = parseUUID(data.partnerKey);

    if (!partnerKey) {
      throw new Error(
        `Partner key "${data.partnerKey}" is not a valid UUID for partner.`,
      );
    }

    return new AppointmentApproval({
      ...deserializeApprovalStatus(data.status),
      appointmentId: data.appointmentId,
      partnerKey,
      statusChangedBy: data.statusChangedBy,
    });
  }

  public static deserializeList(
    data: readonly Api.AppointmentApproval[],
  ): AppointmentApproval[] {
    return data.map((approval) => AppointmentApproval.deserialize(approval));
  }
}

export function deserializeApprovalStatus(
  status: Api.AppointmentApproval["status"],
): Pick<AppointmentApproval, "status"> {
  return {
    status: getEnumMember(HelpAssistTicketApprovalStatus, status),
  };
}
