import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { PartnerAvailabilityStatus } from "src/app/core/constants";
import { CapstonePartner } from "src/app/core/sites";
import {
  createGlobalResourceUrl,
  getApiDetailsDecorator,
  getEnumMember,
  getUpdatedPropertiesFilter,
  joinPath,
  ODataModel,
  ODataResourceModel,
  OmitMetaProperties,
  parseUUID,
  UUID,
} from "src/utils";

export const partnersResource = createGlobalResourceUrl("partners");
export const expandedPartnersResource = partnersResource.select(
  "name",
  "partnerKey",
  "displayName",
  "status",
);

type ApiModelList = ODataResourceModel<typeof expandedPartnersResource>;
type ApiModel = OmitMetaProperties<ODataModel<ApiModelList>>;

const api = getApiDetailsDecorator<ApiModel>();

abstract class BasePartner {
  protected constructor(args: ClassProperties<BasePartner>) {
    this.customName = args.customName;
    this.defaultName = args.defaultName;
    this.key = args.key;
    this.status = args.status;
  }

  @api({ key: "displayName" }) public readonly customName: string | null;
  @api({ key: "name" }) public readonly defaultName: string;
  public readonly key: UUID;
  @api() public readonly status: PartnerAvailabilityStatus;
}

export class Partner extends BasePartner {
  private constructor(args: Omit<ClassProperties<Partner>, "name">) {
    super(args);

    this.name = this.customName || this.defaultName;
  }

  public readonly name: string;

  public static deserialize(data: ApiModel): Partner {
    return new Partner({
      customName: data.displayName ?? null,
      defaultName: data.name,
      key: new UUID(data.partnerKey),
      status: getEnumMember(PartnerAvailabilityStatus, data.status),
    });
  }

  public static deserializeList({ value }: ApiModelList): readonly Partner[] {
    return value.map((partner) => Partner.deserialize(partner));
  }

  /**
   * Parse the URL for partner info embedded in it.
   *
   * @param url - The URL to parse.
   */
  public static parseRouteInfo(url: string): {
    /**
     * The partner key from the URL, if present, or `"_"` if it's the "selected
     * partner" placeholder.
     */
    readonly partnerKey: UUID | "_" | null;
    /** The remaining part of the URL after the partner path. */
    readonly childPath: string;
  } {
    const groups: UrlMatchGroups = url.match(urlPartnerPartMatch)?.groups ?? {};
    return {
      partnerKey: groups.key === "_" ? "_" : parseUUID(groups.key),
      childPath: groups.childPath ?? url,
    };
  }

  /**
   * Replaces the partner portion of the provided URL and returns the new URL for
   * this partner.
   *
   * @param url - The URL to replace the partner path in.
   */
  public replaceRouted(url: string): string {
    return url.replace(
      urlPartnerPartMatch,
      `/partners/${this.key.toString()}$<childPath>`,
    );
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getPartnerRouteUrl(this, ...childPaths);
  }
}

export interface PartnerAddArguments
  extends Omit<ClassProperties<PartnerAdd>, "defaultName" | "key"> {
  partner: CapstonePartner;
}

export class PartnerAdd extends BasePartner {
  public constructor({ partner, ...args }: PartnerAddArguments) {
    super({ ...args, defaultName: partner.name, key: partner.key });
  }

  public serialize(): Api.Partner {
    return {
      displayName: this.customName,
      name: this.defaultName,
      partnerKey: this.key.toString(),
      status: this.status,
    };
  }
}

export type PartnerUpdateArguments = Omit<
  ClassProperties<PartnerUpdate>,
  "defaultName" | "key"
>;

export class PartnerUpdate extends BasePartner {
  public constructor({ base, ...args }: PartnerUpdateArguments) {
    super({ ...base, ...args });
    this.base = base;

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, base, args);
  }

  public readonly base: Partner;
  private readonly filterUpdatedProperties: FilterPartial<Api.PartnerUpdate>;

  public serialize(): Partial<Api.PartnerUpdate> {
    return this.filterUpdatedProperties({
      displayName: this.customName,
      status: this.status,
    });
  }
}

export function getPartnerRouteUrl(
  partner: Pick<Partner, "key">,
  ...childPaths: string[]
): string {
  return joinPath("/partners", String(partner.key), ...childPaths);
}

const urlPartnerPartMatch =
  /\/partners\/(?<key>_|[a-z0-9-]+)(?<childPath>\/.*|$)/i;
interface UrlMatchGroups {
  readonly key?: string;
  readonly childPath?: string;
}
