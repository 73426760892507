import { APP_BASE_HREF, PlatformLocation } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, Injectable, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  CanActivate,
  Router,
  RouterModule,
  Routes,
  UrlTree,
} from "@angular/router";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { CoreModule } from "src/app/core/core.module";
import { LoadingComponent } from "src/app/core/elements/loading.component";
import { AccountPendingComponent } from "./account-pending.component";
import { AlertsComponent } from "./core/alerts.component";
import { UserService } from "./core/auth";
import { GlobalErrorLoggingService } from "./global-error-logging.service";
import { HasPartnersGuard } from "./has-partners-guard.service";
import { LoginComponent } from "./login.component";
import { NetworkResponseErrorInterceptor } from "./network-response-error.interceptor";
import { NetworkResponseTimeLoggingInterceptor } from "./network-response-time-logging-interceptor";
import { RedirectGuard } from "./redirect-guard.service";
import { RootComponent } from "./root.component";
import {
  NonVendorRedirectGuard,
  VendorPortalComponent,
} from "./vendor-portal.component";

@Injectable()
export class DefaultRouteGuard implements CanActivate {
  public constructor(
    private readonly router: Router,
    private readonly user: UserService,
  ) {}

  public async canActivate(): Promise<UrlTree> {
    await this.user.isLoaded;
    if (this.user.isCarrier()) {
      return this.router.parseUrl("/start");
    } else {
      return this.router.parseUrl("/partners/_/sites/_");
    }
  }
}

const routes: Routes = [
  // This route is needed as the return route for the MSAL login flow and must
  // remain an "unprotected route" (no `MsalGuard`).
  { path: "login", component: LoginComponent },

  // **Note regarding route guards:**
  //
  // `MsalGuard` currently seems to have a bug where it won't work as a
  // `canLoad` guard in our current scenario, so we have to use `canActivate`
  // even when loading a separate module.
  //
  // Likewise, in routes using `MsalGuard` for all other guards that wait for
  // the user to be loaded, we have to use `canActivate` or else they will lock
  // up waiting for the load, apparently preventing MSAL from redirecting to the
  // login page.
  //
  // See: https://github.com/angular/angular/issues/27826#issuecomment-451304062
  //
  // If `MsalGuard` is ever fixed to support `canLoad` properly, the other
  // guards can also likely be switched to `canLoad` where appropriate.

  {
    path: "",
    pathMatch: "full",
    canActivate: [MsalGuard, DefaultRouteGuard],
    component: LoadingComponent,
  },
  {
    path: "partners",
    canActivate: [MsalGuard, HasPartnersGuard],
    loadChildren: () =>
      import("./partner/partner-app.module").then(
        (module) => module.PartnerAppModule,
      ),
  },
  {
    path: "vendor-portal",
    canActivate: [MsalGuard, NonVendorRedirectGuard],
    component: VendorPortalComponent,
  },
  {
    path: "redirect",
    canActivate: [MsalGuard, RedirectGuard],
    // Arbitrary component. It shouldn't ever be rendered as the
    // RedirectGuard should always redirect away regardless.
    component: LoadingComponent,
  },
  {
    path: "start",
    canActivate: [MsalGuard],
    loadChildren: () =>
      import("./carrier/view-select/carrier-view-select.module").then(
        (module) => module.CarrierViewSelectModule,
      ),
  },
  {
    path: "carrier",
    canActivate: [MsalGuard],
    loadChildren: () =>
      import("./carrier/carrier-app.module").then(
        (module) => module.CarrierAppModule,
      ),
  },
  {
    path: "account-pending",
    canActivate: [MsalGuard],
    component: AccountPendingComponent,
  },

  // Redirects for old paths. Prefer not to use these going forward.
  { path: "admin/sites/new", redirectTo: "partners/_/sites/new" },
  { path: "admin/sites/:siteId", redirectTo: "partners/_/sites/:siteId/edit" },
  { path: "admin/sites", redirectTo: "partners/_/sites" },
  { path: "appointments", redirectTo: "partners/_/sites/_/appointments" },
  { path: "carriers", redirectTo: "partners/_/carriers" },
  { path: "dashboard", redirectTo: "partners/_/sites/_/dashboard" },
  { path: "global", redirectTo: "partners/_/sites/_/global" },
  { path: "help-assist", redirectTo: "partners/_/sites/_/help-assist" },
  { path: "purchase-orders", redirectTo: "partners/_/sites/_/purchase-orders" },
  { path: "schedule", redirectTo: "partners/_/sites/_/schedule" },
  { path: "settings", redirectTo: "partners/_/sites/_/settings" },
  { path: "site-setup", redirectTo: "partners/_/first-site" },
  { path: "vendors", redirectTo: "partners/_/sites/_/vendors" },

  // Send everything else through the default route guard.
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    {
      deps: [PlatformLocation],
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) =>
        platformLocation.getBaseHrefFromDOM(),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorLoggingService,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkResponseErrorInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkResponseTimeLoggingInterceptor,
    },
    DefaultRouteGuard,
    HasPartnersGuard,
    NonVendorRedirectGuard,
    RedirectGuard,
  ],
  declarations: [
    AccountPendingComponent,
    AlertsComponent,
    LoginComponent,
    RootComponent,
    VendorPortalComponent,
  ],
  bootstrap: [RootComponent, MsalRedirectComponent],
})
export class RootModule {}
