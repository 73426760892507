import { get, orderBy } from "lodash-es";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { ColumnSort, normalizeColumnSort } from "./set-sort-on-resource";

export function sortByTableColumn<Model extends object>(
  models: readonly Model[],
  sort: string | ColumnSort | undefined,
): readonly Model[] {
  if (!sort) {
    return models;
  }
  const { key, order } = normalizeColumnSort(sort);
  return orderBy(
    models,
    [
      (model) => {
        // The key can be nested which `get` will handle for us.
        const value: unknown = get(model, key);
        if (value instanceof Weekdays || value instanceof TimeOfDay) {
          return value.serialize();
        }
        // Match case-insensitively.
        return typeof value === "string" ? value.toLowerCase() : value;
      },
    ],
    [order],
  );
}
