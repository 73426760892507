<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-fields-wrapper">
    <mr-textbox
      label="Email Address"
      description="Separate email addresses with a semi-colon"
      type="email"
      formControlName="emailAddress"
    ></mr-textbox>

    <ng-container *ngIf="isAppointmentCreation">
      <mr-textarea
        label="Requested Purchase Orders"
        formControlName="orders"
      ></mr-textarea>
      <mr-checkbox formControlName="isOrderEntrySkipped"
        >No Purchase Orders to Submit</mr-checkbox
      >
    </ng-container>

    <mr-textarea
      label="Your Question"
      formControlName="question"
      rows="3"
      placeholder="Type your question here..."
      maxlength="1500"
    ></mr-textarea>

    <div class="form-fields-wrapper__button">
      <mr-button type="submit" [disabled]="!canSubmit"
        >Submit Question</mr-button
      >
    </div>
    <p *ngIf="areGeneralTicketsDisabledChanges | async">
      You must enter Purchase Order(s) to proceed with creating a Help Assist
      ticket related to an appointment. For questions regarding PO information,
      please contact your Buyer, Vendor, or Carrier. For general assistance with
      logging into the MR System or other How To questions, please call the Help
      Desk at 1-800-838-4549 Option 6.
    </p>
  </div>
</form>
