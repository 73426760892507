import { Pipe, PipeTransform } from "@angular/core";
import { GlobalAppointment } from "src/app/carrier/appointments/global-appointment.model";
import { GlobalPurchaseOrderAppointment } from "src/app/carrier/purchase-orders/global-purchase-order.model";
import {
  Appointment,
  SimpleAppointment,
} from "src/app/partner/appointments/appointment.model";
import { getUnloaderTypeOptions } from "src/app/partner/appointments/base-appointment.model";
import { HelpAssistTicketAppointmentDraft } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft.model";
import { PurchaseOrderAppointment } from "src/app/partner/purchase-orders/purchase-order.model";

@Pipe({ name: "unloader" })
export class UnloaderPipe implements PipeTransform {
  public transform(
    value:
      | Appointment
      | GlobalAppointment
      | SimpleAppointment
      | HelpAssistTicketAppointmentDraft
      | GlobalPurchaseOrderAppointment
      | PurchaseOrderAppointment
      // Need to allow passing in just the values for creation flows.
      | Pick<Appointment, "unloader" | "site">
      | null,
  ): string {
    if (!value) {
      return "";
    }

    const { unloader, site } = value;

    //  HelpAssistTicketAppointment currently allows for a null unloader, but it
    //  probably shouldn't or it shouldn't use this pipe.
    if (!unloader) {
      throw new Error("Unloader not provided");
    }

    const selectedUnloader = getUnloaderTypeOptions(site).find(
      (unloaderTypeOption) => unloaderTypeOption.id === unloader,
    );

    if (!selectedUnloader) {
      throw new Error(`Unrecognized unloader type: ${unloader}`);
    }

    return selectedUnloader.name;
  }
}
