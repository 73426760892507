import { fromPairs, invert } from "lodash-es";
import { DateTime } from "luxon";
import { BaseWeek, Weekday } from "./base-week.model";
import { Day } from "./day.model";

const weekdayToIndexMap: { readonly [T in Weekday]: string } = {
  sunday: "0",
  monday: "1",
  tuesday: "2",
  wednesday: "3",
  thursday: "4",
  friday: "5",
  saturday: "6",
};
const indexToWeekdayMap = invert(weekdayToIndexMap) as {
  readonly [index: string]: Weekday | undefined;
};

type WeekdayArguments = Partial<Record<Weekday, boolean>>;

export class Weekdays extends BaseWeek<boolean> {
  public constructor(args?: WeekdayArguments | readonly Weekday[]) {
    super(
      false,
      Array.isArray(args)
        ? fromPairs(args.map((weekday) => [weekday, true]))
        : args,
    );
  }

  public static deserialize(dayIndexList: string | null | undefined): Weekdays {
    if (!dayIndexList) {
      return new Weekdays();
    }

    const weekdaysArgs: WeekdayArguments = {};

    for (const dayIndex of dayIndexList.split(",")) {
      const day = indexToWeekdayMap[dayIndex];
      if (!day) {
        throw new Error(`Unrecognized day index: ${dayIndex}`);
      }
      weekdaysArgs[day] = true;
    }

    return new Weekdays(weekdaysArgs);
  }

  public hasAnySelected(): boolean {
    for (const [, isSelected] of this.getWeekdays()) {
      if (isSelected) {
        return true;
      }
    }
    return false;
  }

  public isSelected(date: Date | DateTime | Day): boolean {
    return this[getWeekday(date)];
  }

  public *getSelected(): IterableIterator<Weekday> {
    for (const [weekday, isSelected] of this.getWeekdays()) {
      if (isSelected) {
        yield weekday;
      }
    }
  }

  /**
   * Get the common selection (intersection) between this and the other value.
   * @param other The other `Weekdays` value to compare `this` to.
   */
  public *getCommon(other: Weekdays): IterableIterator<Weekday> {
    for (const weekday of other.getSelected()) {
      if (this[weekday]) {
        yield weekday;
      }
    }
  }

  public serialize(): string | null {
    return this.hasAnySelected()
      ? [...this.getWeekdays()]
          .filter(([, isSelected]) => isSelected)
          .map(([day]) => weekdayToIndexMap[day])
          .join(",")
      : null;
  }
}

export function getWeekday(date: Date | DateTime | Day): Weekday {
  const dateTime =
    date instanceof Date
      ? DateTime.fromJSDate(date)
      : date instanceof Day
      ? date.toDateTime(Day.startOfDay, Day.localZone)
      : date;

  const index = dateTime.weekday;
  const day = indexToWeekdayMap[index === 7 ? 0 : index];

  // the `weekday` property always returns a value in the range of 1-7,
  // Monday through Sunday, all of which are accounted for,
  // so the assertion is safe.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return day!;
}
