import { DateTime } from "luxon";
import { ChangesHistory } from "src/app/core/history.model";
import { ODataReference } from "src/app/core/odata-reference.model";
import {
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  ODataModel,
  parseDateTime,
  PartnerResourceModel,
} from "src/utils";
import { Dock } from "../docks/dock.model";
import { DoorGroup } from "../door-groups/door-group.model";
import { BaseDoor } from "./base-door.model";

export const doorsResource = createPartnerResourceUrl("doors");
export const expandedDoorsResource = doorsResource.modify((resource) =>
  resource.addExpandFieldAsCount("reservations").withReferenceId(),
);

type ApiModelList = PartnerResourceModel<typeof expandedDoorsResource>;
export interface ApiExpandedDoor extends ODataModel<ApiModelList> {}

const api = getApiDetailsDecorator<ApiExpandedDoor>();

export abstract class AbstractDoor extends BaseDoor {
  public constructor(args: ClassProperties<Door>) {
    super(args);
    this.createdDate = args.createdDate;
    this.history = args.history;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.reference = args.reference;
    this.reservationCount = args.reservationCount;
  }

  @api({ key: "reservations@odata.count" })
  public readonly reservationCount: number;
  @api() public readonly createdDate: DateTime;
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: number;
  @api() public readonly modifiedDate: DateTime;
  @api({ key: "@odata.id" }) public readonly reference: ODataReference;

  public getRouteUrl(...childPaths: string[]): string {
    return this.dock.site.getRouteUrl(
      "settings/doors",
      String(this.id),
      ...childPaths,
    );
  }
}

interface DeserializeArgs {
  docks: readonly Dock[];
  doorGroups: readonly DoorGroup[];
}

export class Door extends AbstractDoor {
  public constructor(args: ClassProperties<Door>) {
    super(args);
  }

  public static deserialize(
    data: ApiExpandedDoor,
    { docks, doorGroups }: DeserializeArgs,
  ): Door {
    const dock = docks.find(({ id }) => id === data.dockID);
    if (!dock) {
      throw new Error(`Could not find dock with ID "${data.dockID}".`);
    }

    const doorGroup = doorGroups.find(({ id }) => id === data.doorGroupID);
    if (!doorGroup) {
      throw new Error(
        `Could not find door group with ID "${data.doorGroupID}".`,
      );
    }

    return new Door({
      createdDate: parseDateTime(data.createdDate, dock.site),
      displaySequence: data.sortOrder,
      dock,
      doorGroup,
      history: ChangesHistory.deserializeList(data.history),
      id: data.id,
      isActive: data.active,
      maxUnitCount: data.maxUnitCount,
      minUnitCount: data.minUnitCount,
      modifiedDate: parseDateTime(data.modifiedDate, dock.site),
      name: data.name,
      physicalSequence: data.sequence,
      priority: data.priority,
      reference: new ODataReference(data["@odata.id"]),
      reservationCount: data["reservations@odata.count"],
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    args: DeserializeArgs,
  ): readonly Door[] {
    return value.map((x) => Door.deserialize(x, args));
  }
}

export enum DoorErrorCode {
  DoorInUse = "DOOR_IN_USE",
  DoorGroupOneDock = "DoorGroupOneDock",
}
