import { Component, Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { UserService } from "src/app/core/auth";

@Component({
  selector: "mr-vendor-portal",
  template: `
    <mr-notice-page
      message="For access regarding your order and appointment information, please click on the button below to be redirected to Insight Portal."
      buttonText="Go to Insight Portal"
      link="https://b2c-insight.capstonelogistics.com"
    ></mr-notice-page>
  `,
})
export class VendorPortalComponent {}

@Injectable()
export class NonVendorRedirectGuard implements CanActivate {
  public constructor(
    private readonly router: Router,
    private readonly user: UserService,
  ) {}

  public canActivate(): boolean | UrlTree {
    if (!this.user.isVendor()) {
      return this.router.parseUrl("/");
    } else {
      return true;
    }
  }
}
