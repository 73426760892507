import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { IANAZone } from "luxon";
import { SiteAvailabilityStatus } from "src/app/core/constants";
import {
  AppointmentOrdersColumnKey,
  getSiteRouteUrl,
  Site,
  SiteReference,
} from "src/app/core/sites";
import { parseAppointmentPurchaseOrderOptions } from "src/app/core/sites/base-site.model";
import { Partner } from "src/app/partner/global/partner.model";
import { getApiDetailsDecorator, getEnumMember } from "src/utils";

interface DeserializeArguments {
  partner: Partner;
}

const api = getApiDetailsDecorator<Api.Site>();

export class GlobalSite implements SiteReference {
  private constructor(
    args: Omit<
      ClassProperties<GlobalSite>,
      "appointmentOrdersDetailsColumns" | "name"
    >,
  ) {
    this.address = args.address;
    this.canRestrictedUsersViewAppointmentTimestampsAndDoors =
      args.canRestrictedUsersViewAppointmentTimestampsAndDoors;
    this.customAppointmentOrdersDetailsColumns =
      args.customAppointmentOrdersDetailsColumns;
    this.customName = args.customName;
    this.defaultName = args.defaultName;
    this.gatePassMessage = args.gatePassMessage;
    this.id = args.id;
    this.partner = args.partner;
    this.status = args.status;
    this.timeZone = args.timeZone;

    // Computed properties

    this.appointmentOrdersDetailsColumns =
      args.customAppointmentOrdersDetailsColumns ??
      Site.defaultAppointmentOrdersColumnKeys;
    this.name = args.customName ? args.customName : args.defaultName;
  }

  @api() public readonly address: string | null;
  public readonly appointmentOrdersDetailsColumns: readonly AppointmentOrdersColumnKey[];
  @api({ key: "apptDetailsOrdersColumnOptions" })
  public readonly customAppointmentOrdersDetailsColumns:
    | readonly AppointmentOrdersColumnKey[]
    | null;
  @api({ key: "appointmentTimeStampsAndDoorDetailsAreViewable" })
  public readonly canRestrictedUsersViewAppointmentTimestampsAndDoors: boolean;
  @api({ key: "displayName" }) public readonly customName: string | null;
  @api({ key: "name" }) public readonly defaultName: string;
  @api() public readonly gatePassMessage: string | null;
  @api() public readonly id: SiteReference["id"];
  public readonly name: string;
  public readonly partner: Pick<Partner, "key" | "name">;
  @api() public readonly status: SiteAvailabilityStatus;
  @api() public readonly timeZone: IANAZone;

  public static deserialize(
    data: Pick<
      Api.Site,
      | "address"
      | "appointmentTimeStampsAndDoorDetailsAreViewable"
      | "apptDetailsOrdersColumnOptions"
      | "displayName"
      | "gatePassMessage"
      | "id"
      | "name"
      | "status"
      | "timeZone"
    >,
    { partner }: DeserializeArguments,
  ): GlobalSite {
    return new GlobalSite({
      address: data.address || null,
      canRestrictedUsersViewAppointmentTimestampsAndDoors:
        data.appointmentTimeStampsAndDoorDetailsAreViewable,
      customAppointmentOrdersDetailsColumns:
        parseAppointmentPurchaseOrderOptions(
          data.apptDetailsOrdersColumnOptions,
        ),
      customName: data.displayName || null,
      defaultName: data.name,
      gatePassMessage: data.gatePassMessage || null,
      id: data.id,
      partner,
      status: getEnumMember(SiteAvailabilityStatus, data.status),
      timeZone: IANAZone.create(data.timeZone),
    });
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getSiteRouteUrl(this, ...childPaths);
  }
}
