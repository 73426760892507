import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { isEmpty, omit, pickBy } from "lodash-es";
import { OrderStatus } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { FieldValidation, Site } from "src/app/core/sites";
import {
  BasePurchaseOrder,
  getPurchaseOrderRouteUrl,
  PurchaseOrderReference,
} from "src/app/partner/purchase-orders/base-purchase-order.model";
import { PurchaseOrder } from "src/app/partner/purchase-orders/purchase-order.model";
import { getUpdatedPropertiesFilter } from "src/utils";

type ComputedProperty =
  | "asnBillOfLadingNumber"
  | "asnProNumber"
  | "asnShipDate"
  | "backhaulPickupConfirmationNumber"
  | "freightBasis"
  | "hasASN"
  | "id"
  | "pointOfOriginValidation"
  | "receivedCaseCount"
  | "receivedPalletCount"
  | "status"
  | "suggestedDoor"
  | "tempZone";

type BaseUpdateArguments = Omit<
  ClassProperties<BaseAppointmentPurchaseOrderUpdate>,
  ComputedProperty
>;

export interface AppointmentPurchaseOrderCreationArguments
  extends Omit<BaseUpdateArguments, "base"> {
  base?: null;
}

export interface AppointmentPurchaseOrderModificationArguments
  extends BaseUpdateArguments {
  base: PurchaseOrder;
}

type ApiAppointmentPurchaseOrderUpdate = Required<
  NonNullable<Api.AppointmentModificationRequest["orders"]>[number]
>;

abstract class BaseAppointmentPurchaseOrderUpdate extends BasePurchaseOrder {
  protected constructor({ base, ...args }: BaseUpdateArguments) {
    super(args);
    this.base = base;
    this.site = args.site;

    // TODO: find a way to consolidate this logic with `PurchaseOrder`.
    this.pointOfOriginValidation = this.managedType
      ? this.site.pointOfOriginValidation[this.managedType]
      : FieldValidation.Disabled;
  }

  public declare readonly site: Site;

  // Fields copied from the base, if available.
  public abstract readonly asnBillOfLadingNumber: string | null;
  public abstract readonly asnProNumber: string | null;
  public abstract readonly asnShipDate: Day | null;
  public abstract readonly backhaulPickupConfirmationNumber: string | null;
  public abstract readonly freightBasis: string | null;
  public abstract readonly id: PurchaseOrderReference["id"] | null;
  public abstract readonly receivedCaseCount: number | null;
  public abstract readonly receivedPalletCount: number | null;
  public abstract readonly status: OrderStatus;
  public abstract readonly suggestedDoor: string | null;
  public abstract readonly tempZone: string | null;

  public readonly base: PurchaseOrder | null;
  public readonly pointOfOriginValidation: FieldValidation;

  // Needs to be a function since the abstract properties used to compute it
  // aren't available in the constructor (only when the derived class runs its
  // constructor after this one).
  public get hasASN(): boolean {
    // TODO: find a way to consolidate this logic with `PurchaseOrder`.
    return this.asnBillOfLadingNumber || this.asnProNumber ? true : false;
  }

  public serializeBase(): Omit<
    ApiAppointmentPurchaseOrderUpdate,
    "id" | "rowVer"
  > {
    return {
      bolNumber: this.billOfLadingNumber,
      caseCount: this.caseCount,
      comments: this.comments,
      doorGroupID: this.doorGroup?.id ?? null,
      dueDate:
        this.dueDate?.serialize(Day.startOfDay, this.site.timeZone) ?? null,
      entryDate:
        this.entryDate?.serialize(Day.startOfDay, this.site.timeZone) ?? null,
      estReceivedPallets: this.inboundPalletCount,
      loadWeight: this.loadWeight,
      managedType: this.managedType,
      originCity: this.pointOfOrigin?.city ?? null,
      originLatitude: this.pointOfOrigin?.latitude ?? null,
      originLongitude: this.pointOfOrigin?.longitude ?? null,
      originPostalCode: this.pointOfOrigin?.zip ?? null,
      originState: this.pointOfOrigin?.state ?? null,
      palletCount: this.warehousePalletCount,
      pickupDate:
        this.pickupDate?.serialize(Day.startOfDay, this.site.timeZone) ?? null,
      poNumber: this.number,
      proNumber: this.proNumber,
      vendorID: this.vendor.id,
    };
  }
}

export class AppointmentPurchaseOrderCreation extends BaseAppointmentPurchaseOrderUpdate {
  private constructor(
    args: Omit<
      ClassProperties<AppointmentPurchaseOrderCreation>,
      ComputedProperty
    >,
  ) {
    super(args);
  }

  public declare readonly base: null;

  // Defaulted values since there is no base.
  public readonly asnBillOfLadingNumber = null;
  public readonly asnProNumber = null;
  public readonly asnShipDate = null;
  public readonly backhaulPickupConfirmationNumber = null;
  public readonly freightBasis = null;
  public readonly id = null;
  public readonly receivedCaseCount = null;
  public readonly receivedPalletCount = null;
  public readonly status = OrderStatus.Active;
  public readonly suggestedDoor = null;
  public readonly tempZone = null;

  public static create(
    args: AppointmentPurchaseOrderCreationArguments,
  ): AppointmentPurchaseOrderCreation {
    return new AppointmentPurchaseOrderCreation({ ...args, base: null });
  }

  public static createClone(
    base: Omit<
      AppointmentPurchaseOrderCreationArguments,
      | "base"
      | "billOfLadingNumber"
      | "caseCount"
      | "pickupDate"
      | "pointOfOrigin"
      | "proNumber"
      | "warehousePalletCount"
    >,
  ): AppointmentPurchaseOrderCreation {
    return new AppointmentPurchaseOrderCreation({
      ...base,
      base: null,
      billOfLadingNumber: null,
      caseCount: 0,
      pickupDate: null,
      pointOfOrigin: null,
      proNumber: null,
      warehousePalletCount: 0,
    });
  }

  public update(
    args: Partial<AppointmentPurchaseOrderCreationArguments>,
  ): AppointmentPurchaseOrderCreation {
    return new AppointmentPurchaseOrderCreation({
      ...this,
      // Ignore `undefined` so it doesn't overwrite the existing value.
      ...pickBy(args, (value) => value !== undefined),
      base: null,
    });
  }

  public serialize(): ApiAppointmentPurchaseOrderUpdate & {
    id: null;
    rowVer: null;
  } {
    return {
      ...this.serializeBase(),
      id: null,
      rowVer: null,
    };
  }
}

export class AppointmentPurchaseOrderModification extends BaseAppointmentPurchaseOrderUpdate {
  private constructor({
    base,
    ...args
  }: Omit<
    ClassProperties<AppointmentPurchaseOrderModification>,
    ComputedProperty
  >) {
    super({ base, ...args });

    this.asnBillOfLadingNumber = base.asnBillOfLadingNumber;
    this.asnProNumber = base.asnProNumber;
    this.asnShipDate = base.asnShipDate;
    this.backhaulPickupConfirmationNumber =
      base.backhaulPickupConfirmationNumber;
    this.freightBasis = base.freightBasis;
    this.id = base.id;
    this.receivedCaseCount = base.receivedCaseCount;
    this.receivedPalletCount = base.receivedPalletCount;
    this.status = base.status;
    this.suggestedDoor = base.suggestedDoor;
    this.tempZone = base.tempZone;

    // Ensure that any excess properties in `args` that conflict with readonly
    // ones here don't get into the filter logic.
    const modifiableArgs = omit(args, [
      "asnBillOfLadingNumber",
      "asnProNumber",
      "id",
      "status",
    ]);
    this.filterUpdatedProperties = getUpdatedPropertiesFilter(
      this,
      base,
      modifiableArgs,
    );
  }

  public declare readonly base: PurchaseOrder;

  private readonly filterUpdatedProperties: FilterPartial<
    Omit<ApiAppointmentPurchaseOrderUpdate, "id" | "rowVer">
  >;

  // Fields copied from the base.
  public readonly asnBillOfLadingNumber: string | null;
  public readonly asnProNumber: string | null;
  public readonly asnShipDate: Day | null;
  public readonly backhaulPickupConfirmationNumber: string | null;
  public readonly freightBasis: string | null;
  public readonly id: PurchaseOrderReference["id"];
  public readonly receivedCaseCount: number | null;
  public readonly receivedPalletCount: number | null;
  public readonly status: OrderStatus;
  public readonly suggestedDoor: string | null;
  public readonly tempZone: string | null;

  /**
   * Creates an order update definition with new property values.
   *
   * @param base - The base order to update properties in.
   * @param args - The properties to update in this order.
   * @returns The update definition or the base order if nothing has changed.
   */
  public static create(
    base: PurchaseOrder,
    args: Partial<AppointmentPurchaseOrderModificationArguments>,
  ): PurchaseOrder | AppointmentPurchaseOrderModification {
    const update = new AppointmentPurchaseOrderModification({
      ...base,
      // Ignore `undefined` so it doesn't overwrite the base value.
      ...pickBy(args, (value) => value !== undefined),
      base,
    });

    return isEmpty(update.serialize()) ? base : update;
  }

  /**
   * Updates this order with new property values.
   *
   * @param args - The properties to update in this order.
   * @returns The update definition or the original order if there are no longer
   * any changes from it.
   */
  public update(
    args: Partial<AppointmentPurchaseOrderModificationArguments>,
  ): PurchaseOrder | AppointmentPurchaseOrderModification {
    const update = new AppointmentPurchaseOrderModification({
      ...this,
      // Ignore `undefined` so it doesn't overwrite the base value.
      ...pickBy(args, (value) => value !== undefined),
      base: this.base,
    });

    return isEmpty(update.serialize()) ? this.base : update;
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getPurchaseOrderRouteUrl(this, ...childPaths);
  }

  public serialize(): Partial<ApiAppointmentPurchaseOrderUpdate> &
    ApiAppointmentUpdatePurchaseOrderReference {
    return {
      ...this.filterUpdatedProperties(super.serializeBase()),
      id: this.base.id,
      rowVer: this.base.rowVersion,
    };
  }
}

export interface ApiAppointmentUpdatePurchaseOrderReference {
  id: NonNullable<ApiAppointmentPurchaseOrderUpdate["id"]>;
  rowVer: NonNullable<ApiAppointmentPurchaseOrderUpdate["rowVer"]>;
}
