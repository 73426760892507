import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { Duration } from "luxon";
import { formatAmount } from "./amount.pipe";

@Pipe({ name: "duration" })
export class DurationPipe implements PipeTransform {
  public constructor(@Inject(LOCALE_ID) private readonly locale: string) {}

  public transform(
    value: Duration | null,
    format: "short" | "full" = "full",
    unit: DurationScale | readonly DurationScale[] = ["hours", "minutes"],
  ): string | null {
    if (!value) {
      return null;
    }

    if (!value.isValid) {
      return "Invalid Duration";
    }

    const units = Array.isArray(unit) ? unit : [unit];

    const duration = value.shiftTo(...units).normalize();
    // `shiftTo` returns _all_ units as negative numbers if the duration is
    // "negative", but we only want the absolute values of each and can get the
    // sign off the "raw" milliseconds value for display.
    const sign = duration.toMillis() < 0 ? "-" : "";
    const days = Math.abs(duration.days);
    const hours = Math.abs(duration.hours);
    const minutes = Math.abs(duration.minutes);

    if (format === "short") {
      if (unit === "days") {
        return `${sign}${days}d`;
      } else {
        return `${sign}${formatShortTime(hours)}:${formatShortTime(minutes)}`;
      }
    } else {
      const formattedValues: string[] = [];
      if (units.includes("days")) {
        formattedValues.push(formatAmount(days, this.locale, "Day"));
      }
      if (units.includes("hours")) {
        formattedValues.push(formatAmount(hours, this.locale, "Hour"));
      }
      if (units.includes("minutes")) {
        formattedValues.push(
          formatAmount(minutes, this.locale, "Minute", "Minutes", "1.0-2"),
        );
      }
      return sign + formattedValues.join(" ");
    }
  }
}

function formatShortTime(value: number): string {
  return Math.round(value).toString().padStart(2, "0");
}

export type DurationScale = "days" | "hours" | "minutes";
