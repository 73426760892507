import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { GlobalSite } from "src/app/carrier/global-site.model";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { getSiteRouteUrl, Site, SiteModelReference } from "src/app/core/sites";
import { Rack } from "src/app/partner/settings/racks/rack.model";
import {
  PurchaseOrderVendor,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import {
  apiIgnored,
  apiPropertyClass,
  ExpandedODataModel,
  getApiDetailsDecorator,
  isExistent,
  OmitMetaProperties,
} from "src/utils";
import { DoorGroup } from "../settings/door-groups/door-group.model";

const api = getApiDetailsDecorator<Api.PurchaseOrder>();

@apiPropertyClass
export class PointOfOrigin {
  public constructor(args: PointOfOriginArguments) {
    this.city = args.city || null;
    this.latitude = args.latitude;
    this.longitude = args.longitude;
    this.state = args.state || null;
    this.zip = args.zip || null;

    this.displayAddress = [
      this.city,
      [this.state, this.zip].filter(isExistent).join(" "),
    ]
      .filter(isExistent)
      .join(", ");
  }

  @api({ key: "originCity" }) public readonly city: string | null;
  @api({ key: "originLatitude" }) public readonly latitude: number | null;
  @api({ key: "originLongitude" }) public readonly longitude: number | null;
  @api({ key: "originState" }) public readonly state: string | null;
  @api({ key: "originPostalCode" }) public readonly zip: string | null;

  @apiIgnored public readonly displayAddress: string;

  public static deserialize(
    data: Pick<
      Api.PurchaseOrder,
      | "originCity"
      | "originLatitude"
      | "originLongitude"
      | "originPostalCode"
      | "originState"
    >,
  ): PointOfOrigin {
    return new PointOfOrigin({
      city: data.originCity ?? null,
      latitude: data.originLatitude ?? null,
      longitude: data.originLongitude ?? null,
      state: data.originState ?? null,
      zip: data.originPostalCode ?? null,
    });
  }
}

export abstract class BasePurchaseOrder {
  protected constructor(args: ClassProperties<BasePurchaseOrder>) {
    this.billOfLadingNumber = args.billOfLadingNumber;
    this.caseCount = args.caseCount;
    this.comments = args.comments;
    this.consigneeCode = args.consigneeCode;
    this.doorGroup = args.doorGroup;
    this.dueDate = args.dueDate;
    this.entryDate = args.entryDate;
    this.inboundPalletCount = args.inboundPalletCount;
    this.loadWeight = args.loadWeight;
    this.managedType = args.managedType;
    this.number = args.number;
    this.pickupDate = args.pickupDate;
    this.pointOfOrigin = args.pointOfOrigin;
    this.proNumber = args.proNumber;
    this.site = args.site;
    this.vendor = args.vendor;
    this.warehousePalletCount = args.warehousePalletCount;
  }

  @api({ key: "bolNumber" }) public readonly billOfLadingNumber: string | null;
  @api() public readonly caseCount: number;
  @api() public readonly comments: string | null;
  @api() public readonly consigneeCode: string | null;
  @api({
    key: "doorGroupID",
    navigationProperty: "doorGroup",
    uiModel: DoorGroup,
  })
  public readonly doorGroup: Pick<DoorGroup, "id" | "name"> | null;
  @api() public readonly dueDate: Day | null;
  @api() public readonly entryDate: Day | null;
  @api({ key: "estReceivedPallets" })
  public readonly inboundPalletCount: number;
  @api() public readonly loadWeight: number;
  @api() public readonly managedType: ManagedType | null;
  @api({ key: "poNumber" }) public readonly number: string;
  @api() public readonly pickupDate: Day | null;
  @api(PointOfOrigin) public readonly pointOfOrigin: PointOfOrigin | null;
  @api() public readonly proNumber: string | null;
  @api({ key: "siteID" }) public readonly site: Site | GlobalSite;
  @api({ key: "vendorID", navigationProperty: "vendor", uiModel: Vendor })
  public readonly vendor: PurchaseOrderVendor;
  @api({ key: "palletCount" }) public readonly warehousePalletCount: number;
}

export function getPurchaseOrderRouteUrl(
  order: PurchaseOrderReference,
  ...childPaths: string[]
): string {
  return getSiteRouteUrl(
    order.site,
    "purchase-orders",
    String(order.id),
    ...childPaths,
  );
}

export interface PurchaseOrderReference
  extends SiteModelReference<"purchase-order"> {}

export interface PurchaseOrderDetail {
  readonly caseCount: number | null;
  readonly consigneeCode: string | null;
  readonly pack: string | null;
  readonly palletHeight: number | null;
  readonly palletTier: number | null;
  readonly productDescription: string | null;
  readonly productKey: string;
  readonly rack: Pick<Rack, "id" | "name">;
  readonly size: string | null;
  readonly tempZone: string | null;
  readonly vendor: Pick<Vendor, "id" | "name">;
}

export function deserializePurchaseOrderDetail(
  data: OmitMetaProperties<
    ExpandedODataModel<
      Api.PurchaseOrderDetail,
      {
        rack: "id" | "name";
        vendor: "id" | "name";
      }
    >
  >,
): PurchaseOrderDetail {
  return {
    caseCount: data.case_Count ?? null,
    consigneeCode: data.consigneeCode ?? null,
    pack: data.packSize ?? null,
    palletHeight: data.palletHI ?? null,
    palletTier: data.palletTI ?? null,
    productDescription: data.productDesc ?? null,
    productKey: data.productKey,
    rack: { id: data.rack.id, name: data.rack.name },
    size: data.unitSize ?? null,
    tempZone: data.tempZone ?? null,
    vendor: { id: data.vendor.id, name: data.vendor.name },
  };
}

export type PointOfOriginArguments = Omit<
  ClassProperties<PointOfOrigin>,
  "displayAddress"
>;
