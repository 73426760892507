import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { isError } from "lodash-es";
import { environment } from "src/environments";
import { UUID } from "src/utils";
import { ApiError } from "./api-error";

@Injectable({ providedIn: "root" })
export class ApplicationInsightsService {
  private appInsights?: ApplicationInsights;

  private initializeClient(): ApplicationInsights {
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        instrumentationKey,
      },
    });
    return this.appInsights;
  }

  public load(): void {
    if (!supportedEnvironments.includes(environment.type)) {
      return;
    }

    const appInsights = this.initializeClient();

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((envelope) => {
      if (!envelope.tags) {
        envelope.tags = [];
      }

      // Exclude secret session data returned during the login flow from the
      // telemetry logging.
      if (appInsights.context.telemetryTrace.name?.startsWith("/login#")) {
        envelope.tags["ai.operation.name"] = "/login";
      }

      envelope.tags["ai.cloud.role"] = roleName;
      envelope.tags["ai.cloud.roleInstance"] = roleInstance;
    });
  }

  public setUserId(userId: UUID): void {
    if (!this.appInsights) {
      return;
    }
    this.appInsights.setAuthenticatedUserContext(userId.toString());
  }

  public logException(
    error: unknown,
    customProperties?: { [key: string]: unknown },
  ): void {
    // This is one of the few places where we actually want to see console logs
    // to help diagnose unexpected errors like this.
    /* eslint-disable no-console */
    if (error instanceof HttpErrorResponse) {
      // Convert the ApiErrors to strings so they display similar to normal errors.
      console.error(ApiError.create(error).toString());
    } else if (error instanceof ApiError) {
      console.error(error.toString());
    } else {
      console.error(error);
    }
    /* eslint-enable no-console */

    if (!this.appInsights) {
      return;
    }

    if (isError(error)) {
      this.appInsights.trackException({
        exception: error,
        properties: customProperties,
      });
    } else if (typeof error === "string") {
      this.appInsights.trackException({
        properties: { ...customProperties, errorMessage: error },
      });
    } else {
      let errorDetails: string;
      try {
        errorDetails = JSON.stringify(error);
      } catch {
        errorDetails = String(error);
      }
      this.appInsights.trackException({
        properties: {
          ...customProperties,
          errorDetails,
          errorMessage: "An unknown issue occurred.",
        },
      });
    }
  }

  public logCustomEvent(
    eventName: string,
    customProperties?: { [key: string]: unknown },
  ): void {
    if (!this.appInsights) {
      return;
    }
    this.appInsights.trackEvent({
      name: eventName,
      properties: customProperties,
    });
  }

  public logCustomEventStart(eventName: string): void {
    if (!this.appInsights) {
      return;
    }
    this.appInsights.startTrackEvent(eventName);
  }

  public logCustomEventStop(
    eventName: string,
    customProperties?: { [key: string]: string },
  ): void {
    if (!this.appInsights) {
      return;
    }
    this.appInsights.stopTrackEvent(eventName, customProperties);
  }
}

const supportedEnvironments: ReadonlyArray<typeof environment.type> = [
  "dev",
  "qa",
  "qa-di",
  "perf",
  "rc",
  "preprod",
  "sandbox",
  "prod",
];

const { instrumentationKey, roleName, roleInstance } =
  environment.applicationInsights;
