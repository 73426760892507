import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  ViewChild,
} from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { NgbTimeAdapter, NgbTimepicker } from "@ng-bootstrap/ng-bootstrap";
import { Duration } from "luxon";
import { ValidatorResult } from "src/utils";
import { TimeOfDay } from "../time-of-day.model";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";
import { TimepickerAdapter } from "./timepicker-adapter.service";

@Component({
  selector: "mr-timepicker[label]",
  templateUrl: "./timepicker.component.html",
  styleUrls: ["./timepicker.component.scss"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimepickerComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => TimepickerComponent),
    },
    { provide: NgbTimeAdapter, useClass: TimepickerAdapter },
  ],
})
export class TimepickerComponent
  extends BaseFieldComponent<TimeOfDay | null>
  implements Validator
{
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public hideSpinners = false;

  @Input() public set minuteStep(value: number | Duration) {
    this.minuteStepInMinutes =
      typeof value === "number" ? value : value.as("minutes");
  }
  public minuteStepInMinutes = 1;

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  @ViewChild(NgbTimepicker, { static: true })
  public readonly picker!: NgbTimepicker;

  private hasEmptyHour = false;

  private notifyValidatorChange = (): void => {
    // Do nothing until registered.
  };
  public registerOnValidatorChange(fn: () => void): void {
    this.notifyValidatorChange = fn;
  }

  public validate(): ValidatorResult<["emptyHour", true]> {
    return this.hasEmptyHour ? { emptyHour: true } : null;
  }

  public override onChange(value: TimeOfDay | null): void {
    // Since the blur even seems not to be exposed via the ngb-timepicker
    // component, we have to fall back on marking the component touched whenever
    // they change the value.
    this.onBlur();
    this.hasEmptyHour = false;

    if (value) {
      super.onChange(value);
      return;
    }

    // We can't use `value` directly because it's null if no hour/minute is set
    // (https://github.com/ng-bootstrap/ng-bootstrap/issues/3221)
    // so we have to interrogate the model directly.
    const { hour, minute } = this.picker.model;

    if (Number.isNaN(hour)) {
      // Clear the value entirely if hour isn't set.
      super.onChange(null);
      if (!Number.isNaN(minute)) {
        this.hasEmptyHour = true;
      }
    } else if (Number.isNaN(minute)) {
      // Set the minutes to zero when the hour is set but no minute is selected.
      super.onChange(new TimeOfDay({ hour, minute: 0 }));
    }

    this.notifyValidatorChange();
  }
}
