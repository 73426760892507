import { Observable, of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { isExistent } from "./miscellaneous";

/**
 * Maps a source observable and an observable of a default value to a new
 * observable that emits the current default value when the source emits
 * "not-found". Also returns a "notifier" observable which emits whenever the
 * source emitted a found value.
 *
 * This is useful for subscribing to the returned updater and using it to cause
 * an update to the source through side effects.
 * @param observable The source observable to check for a "not-found" value,
 * ignoring any "loading" state.
 * @param defaultObservable The observable that should be used for setting the
 * default value in the returned updater observable.
 * @returns A tuple of the updater observable and the defined source notifier.
 */
export function getDefaultUpdater<T, U extends T>(
  observable: Observable<T | "loading" | "not-found">,
  defaultObservable: Observable<U | null>,
): [Observable<U>, Observable<boolean>] {
  const updater = observable.pipe(
    filter((value) => value === "not-found"),
    // Only take the first valid default emitted so that we only emit once
    // whenever the main observable is nil. This ensures that we don't override
    // the previously set valid value if the default changes.
    switchMap(() => defaultObservable.pipe(filter(isExistent), take(1))),
  );

  const notifier = defaultObservable.pipe(
    switchMap((defaultValue) =>
      // Wait for the selection to load only if we definitely have a default.
      defaultValue
        ? observable.pipe(
            filter((value) => value !== "loading" && value !== "not-found"),
            map(() => true),
          )
        : of(false),
    ),
  );

  return [updater, notifier];
}
