import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserUtils } from "@azure/msal-browser";
import { RootModule } from "./app/root.module";
import { environment } from "./environments";

// MSAL will render the full app in an iframe when doing silent token renewal.
// We don't want to log every time the renewal takes place.
if (!BrowserUtils.isInIframe()) {
  // eslint-disable-next-line no-console
  console.log(`App version: ${environment.build}`);
}

if (environment.isProductionBuild) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(RootModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
