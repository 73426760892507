import { Component } from "@angular/core";
import { PopoverRef } from "src/app/core/elements/popover/popover-ref";

@Component({
  selector: "mr-icon-selector-popup",
  templateUrl: "./icon-selector-popup.component.html",
  styleUrls: ["./icon-selector-popup.component.scss"],
})
export class IconSelectorPopupComponent {
  public constructor(
    private readonly popoverRef: PopoverRef<IconSelectorPopupContext>,
  ) {}

  public selectedIcon = this.popoverRef.data.selectedIcon;

  public readonly availableIconNames = this.popoverRef.data.availableIconNames;
  public readonly selectFor = this.popoverRef.data.selectFor;

  public selectIcon(value: string): void {
    const updatedValue =
      value === this.selectedIcon
        ? null // Deselect on same icon select
        : value;

    this.selectedIcon = updatedValue;
    this.popoverRef.data.onSelect(updatedValue);
  }

  public clear(): void {
    this.popoverRef.data.onSelect(null);
    this.close();
  }

  public close(): void {
    this.popoverRef.close();
  }
}

export interface IconSelectorPopupContext {
  availableIconNames: string[];
  selectFor: string | null;
  selectedIcon: string | null;
  onSelect(value: string | null): void;
}
