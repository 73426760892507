import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, ReplaySubject } from "rxjs";
import { startWith } from "rxjs/operators";
import { Permission } from "./permissions";
import { UserService } from "./user.service";

@UntilDestroy()
@Directive({ selector: "[mrIfHasPermission]" })
export class IfHasPermissionDirective<Operator extends "and" | "or", Condition>
  implements OnInit
{
  public constructor(
    private readonly user: UserService,
    private readonly template: TemplateRef<Context<Condition>>,
    private readonly viewContainer: ViewContainerRef,
  ) {}

  @Input() public set mrIfHasPermission(value: Permission) {
    this.permissionInput.next(value);
  }
  private readonly permissionInput = new ReplaySubject<Permission>(1);

  @Input() public set mrIfHasPermissionOperator(value: Operator) {
    this.operatorInput.next(value);
  }
  private readonly operatorInput = new ReplaySubject<Operator>(1);

  @Input() public set mrIfHasPermissionCondition(value: Condition) {
    this.conditionInput.next(value);
  }
  private readonly conditionInput = new ReplaySubject<Condition>(1);

  @Input() public set mrIfHasPermissionThen(
    value: TemplateRef<Context<Condition>>,
  ) {
    this.thenTemplateInput.next(value);
  }
  private readonly thenTemplateInput = new BehaviorSubject<TemplateRef<
    Context<Condition>
  > | null>(null);

  @Input() public set mrIfHasPermissionElse(value: TemplateRef<void>) {
    this.elseTemplateInput.next(value);
  }
  private readonly elseTemplateInput =
    new BehaviorSubject<TemplateRef<void> | null>(null);

  public ngOnInit(): void {
    combineLatest([
      this.permissionInput,
      this.operatorInput.pipe(startWith("and")),
      this.conditionInput.pipe(startWith(initialValueFlag)),
      this.thenTemplateInput,
      this.elseTemplateInput,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([permission, operator, condition, thenTemplate, elseTemplate]) => {
          const andCondition =
            operator !== "and" || condition === initialValueFlag || condition;
          const orCondition =
            operator === "or" && condition !== initialValueFlag && condition;

          this.viewContainer.clear();
          if (
            (this.user.permissions.has(permission) && andCondition) ||
            orCondition
          ) {
            this.viewContainer.createEmbeddedView(
              thenTemplate ?? this.template,
              {
                mrIfHasPermission: permission,
                mrIfHasPermissionCondition: condition,
              },
            );
          } else if (elseTemplate) {
            this.viewContainer.createEmbeddedView(elseTemplate);
          }
        },
      );
  }

  /* <Template type guards> */
  /* eslint-disable @typescript-eslint/member-ordering */
  public static ngTemplateGuard_mrIfHasPermissionCondition<
    Operator extends "and",
    Condition,
  >(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _expression: Condition,
  ): _expression is Exclude<Condition, false | 0 | "" | null | undefined>;
  public static ngTemplateGuard_mrIfHasPermissionCondition<
    Operator extends "or",
    Condition,
  >(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _expression: Condition,
  ): _expression is Condition;
  public static ngTemplateGuard_mrIfHasPermissionCondition<
    Operator extends "and" | "or",
    Condition,
  >(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _expression: Condition,
  ): boolean {
    return true;
  }

  public static ngTemplateContextGuard<Condition, Operator extends "and">(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _context: unknown,
  ): _context is Context<Exclude<Condition, false | 0 | "" | null | undefined>>;
  public static ngTemplateContextGuard<Condition, Operator extends "or">(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _context: unknown,
  ): _context is Context<Condition>;
  public static ngTemplateContextGuard<
    Condition,
    Operator extends "and" | "or",
  >(
    _directive: IfHasPermissionDirective<Operator, Condition>,
    _context: unknown,
  ): boolean {
    return true;
  }
  /* eslint-enable @typescript-eslint/member-ordering */
  /* </Template type guards> */
}

interface Context<Condition> {
  mrIfHasPermission: Permission;
  mrIfHasPermissionCondition: Condition;
}

const initialValueFlag = Symbol("initial condition value");
