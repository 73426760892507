import { ManagedReceivingGlobalApi as Api } from "@capstone/mock-api";
import { DateTime, SystemZone } from "luxon";
import { ODataReference } from "src/app/core/odata-reference.model";
import {
  Brand,
  createGlobalResourceUrl,
  getApiDetailsDecorator,
  joinPath,
  ODataModel,
  ODataResourceModel,
  parseDateTime,
} from "src/utils";
import { BaseCarrier } from "./base-carrier.model";
import { CarrierType } from "./carrier-type.model";

const api = getApiDetailsDecorator<Api.Carrier>();

export const carriersResource =
  createGlobalResourceUrl("carriers").withReferenceId();

type ApiModelList = ODataResourceModel<typeof carriersResource>;
export interface ApiCarrier extends ODataModel<ApiModelList> {}

interface DeserializeArgs {
  types: readonly CarrierType[];
}

export class Carrier
  extends BaseCarrier
  implements CarrierReference, SimpleCarrier
{
  private constructor(args: Omit<ClassProperties<Carrier>, "carrierId">) {
    super(args);
    this.carrierId = args.id;
    this.createdDate = args.createdDate;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.reference = args.reference;
  }

  /** The time zone used by the carrier models (local/system time). */
  public static readonly timeZone = SystemZone.instance;

  public readonly carrierId: CarrierReference["id"];
  @api() public readonly createdDate: DateTime;
  @api() public readonly id: CarrierReference["id"];
  @api() public readonly modifiedDate: DateTime;
  public readonly reference: ODataReference;

  public static deserialize(
    data: ApiCarrier,
    { types }: DeserializeArgs,
  ): Carrier {
    const type = types.find(({ id }) => id === data.carrierTypeID);
    if (!type) {
      throw new Error(
        `Could not find carrier type with ID "${data.carrierTypeID}".`,
      );
    }

    const timeZone = Carrier.timeZone;

    return new Carrier({
      ...deserializeSimpleCarrier(data),
      comments: data.comments ?? null,
      contactEmails: data.appointmentNotificationEmails ?? null,
      createdDate: parseDateTime(data.createdDate, { timeZone }),
      isActive: data.active,
      key: data.carrierKey,
      modifiedDate: parseDateTime(data.modifiedDate, { timeZone }),
      reference: new ODataReference(data["@odata.id"]),
      type,
    });
  }

  public static deserializeList(
    { value }: ApiModelList,
    args: DeserializeArgs,
  ): readonly Carrier[] {
    return value.map((x) => Carrier.deserialize(x, args));
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getCarrierRouteUrl(this, ...childPaths);
  }
}

export function getCarrierRouteUrl(
  carrier: CarrierReference,
  ...childPaths: string[]
): string {
  return joinPath("/global/carriers", String(carrier.id), ...childPaths);
}

export interface CarrierReference {
  readonly id: Brand<number, "global-carrier">;
}

export function deserializeSimpleCarrier(
  data: Pick<Api.Carrier, "id" | "name">,
): SimpleCarrier {
  return {
    getRouteUrl: (...childPaths) =>
      getCarrierRouteUrl({ id: data.id }, ...childPaths),
    // Duplicate ID to match the PartnerCarrier signature so we can use both
    // interchangeably.
    carrierId: data.id,
    id: data.id,
    name: data.name,
  };
}

export interface SimpleCarrier
  extends Pick<Carrier, "carrierId" | "id" | "name"> {
  getRouteUrl(...childPaths: string[]): string;
}
